import React, {useState} from 'react';
import AllMaterialPageItemModule from "../../../components/UI/modul/AllMaterialPageItemModule";

const AllMaterials = () => {

    const [pageSize, setPageSize] = useState(10);

    return <div>
        <AllMaterialPageItemModule
            refMaterial={true}
            isAddMaterial={true}
            pageSize={pageSize}
            setPageSize={setPageSize}
        />
    </div>
};

export default AllMaterials;