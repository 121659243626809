import React from 'react';
import {Button, Dialog, DialogContent, DialogTitle, TextField} from "@mui/material";

const ModalOpenSetValues = ({title, label, modalOpen, handleClose, setValue, handleSubmit}) => {
    return (
        <div>
            <Dialog open={modalOpen} onClose={handleClose}>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <form onSubmit={handleSubmit}>
                        <TextField
                            sx={{
                                width: '100%',
                                minWidth: {xs: '300px', sm: '360px', md: '400px'},
                                gap: '1.5rem',
                            }}
                            margin="normal"
                            key={label}
                            label={label}
                            name={label}
                            onChange={(e) =>
                                setValue(e.target.value)
                            }
                        />
                        <Button type="submit" variant="contained" color="secondary">
                            Применить
                        </Button>
                    </form>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default ModalOpenSetValues;