import React, {useCallback, useEffect, useMemo, useState} from 'react';
import MaterialReactTable from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Tooltip} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {Delete} from "@mui/icons-material";
import Box from "@mui/material/Box";
import ButtonSettingsOrders from "../../../button/ButtonSettingsOrders";
import UserService from "../../../../../API/common/UserService";
import {useNavigate} from "react-router-dom";
import TextFieldPassword from "./TextFieldPassword";
import WorkerService from "../../../../../API/orders/orders/organization/job/WorkerService";

const RolesUserTable = ({id, change, setChange}) => {

        const navigate = useNavigate();
        const [data, setData] = useState([]);
        const [isFullScreen, setIsFullScreen] = useState(false);
        const [createModalOpen, setCreateModalOpen] = useState(false);

        const redirectPackagingSet = () => {
            navigate('/organization/users');
        };

        const resetPassword = () => {
            setCreateModalOpen(true)
        };

        const addInWorkers = () => {
            UserService.get(id)
                .then(function (response) {
                    let data = response.data;

                    data = {
                        "firstName": data.firstName,
                        "lastName": data.lastName,
                        "middleName": data.attributes.middleName[0],
                        "email": data.email,
                        "birthday": data.attributes.birthday[0],
                        "phoneNumber": data.attributes.phoneNumber[0],
                        "production": false,
                        "constructor": false,
                        "defaultConstructor": false,
                        "defaultTechnologist": false,
                        "enabled": data.enabled
                    }

                    WorkerService.save(data)
                        .then(function (response) {
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                })
                .catch(function (error) {
                    console.log(error);
                });
        };

        useEffect(() => {

            UserService.getAllRolesUser(id).then(function (response) {
                setData(response.data);
                setChange(null);
            })
                .catch(function (error) {
                    console.log(error);
                });

        }, [change]);


        const handleDeleteRow = useCallback(
            (row) => {
                UserService.deleteRole(id, row.original)
                    .then(function () {
                        data.splice(row.index, 1);
                        setData([...data]);
                        setChange(!change);
                    })
                    .catch(function (error) {
                        console.log(error);
                    })
            },
            [data],
        );

        const columns = useMemo(
            () => [
                {
                    id: 'name',
                    header: 'Название роли',
                    accessorKey: 'name',
                    minSize: 25,
                },

                {
                    id: 'description',
                    header: 'Описание роли',
                    accessorKey: 'description',
                    minSize: 300,
                },
                {
                    id: 'id',
                    header: 'id',
                    accessorKey: 'id',
                    enableHiding: false,
                    enableColumnActions: false,
                    enableGrouping: false,
                    enableEditing: false,
                },
            ],
            [data, setData],
        );

        return (
            <>
                <MaterialReactTable
                    muiTableBodyProps={{
                        sx: () => ({
                            '& tr:nth-of-type(odd)': {
                                backgroundColor: 'lightgray',
                            },
                        }),
                    }}
                    muiTableBodyCellProps={{
                        sx: {
                            p: '0px 0px 0px 0px',
                            m: '0px',
                        },
                    }}
                    muiTableBodyRowProps={{
                        sx: {
                            height: '10px',
                        },
                    }}
                    isFullScreen={isFullScreen}
                    displayColumnDefOptions={{
                        'mrt-row-actions': {
                            muiTableHeadCellProps: {
                                align: 'center',
                            },
                            size: 120,
                        },
                    }}
                    columns={columns}
                    data={data}
                    editingMode="modal"
                    enableEditing
                    renderRowActions={({row, table}) => (
                        <Box sx={{display: 'flex', gap: '1rem'}}>
                            <Tooltip arrow placement="right" title="Удалить">
                                <IconButton color="error" onClick={() => handleDeleteRow(row)}>
                                    <Delete/>
                                </IconButton>
                            </Tooltip>
                        </Box>
                    )}
                    initialState={{
                        columnVisibility: {
                            id: false,
                        },
                        isFullScreen: false,
                        density: "compact",
                        pagination: {pageIndex: 0, pageSize: 20},
                        columnPinning: {left: ['number']}
                    }}
                    localization={MRT_Localization_RU}
                    renderTopToolbarCustomActions={() => (
                        <Box
                            sx={{display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap'}}
                        >
                            <ButtonSettingsOrders/>
                            <Button
                                color="secondary"
                                onClick={redirectPackagingSet}
                                variant="contained"
                            >
                                Вернуться к пользователям
                            </Button>
                            <Button
                                color="secondary"
                                onClick={resetPassword}
                                variant="contained"
                            >
                                Установить пароль пользователю
                            </Button>
                            <Button
                                color="secondary"
                                onClick={addInWorkers}
                                variant="contained"
                            >
                                Добавить в работники
                            </Button>
                        </Box>
                    )}
                />
                <CreateNewPasswordModal
                    id={id}
                    open={createModalOpen}
                    onClose={() => setCreateModalOpen(false)}
                    change={change}
                    setChange={setChange}
                />
            </>
        );
    }
;

export const CreateNewPasswordModal = ({id, open, onClose, change, setChange}) => {

    const [attributes, setAttributes] = useState({type: "password", value: "", temporary: true});


    const resetPassword = () => {
        console.log(attributes)

        UserService.resetPassword(id, attributes).then(function () {
            setChange(!change)
            exit()
        })
            .catch(function (error) {
                console.log(error);
            });
    };


    const exit = () => {
        attributes.value = null;
        setAttributes(attributes);
        onClose()
    }

    return (
        <Dialog open={open}>
            <DialogTitle textAlign="center">Установить пароль пользователю</DialogTitle>
            <DialogContent>
                <form onSubmit={(e) => e.preventDefault()}>
                    <Stack spacing={2}>
                    </Stack>
                    <Stack
                        sx={{
                            width: '100%',
                            minWidth: {xs: '300px', sm: '360px', md: '400px'},
                            gap: '1.5rem',
                        }}
                    >
                        <TextFieldPassword
                            label="Пароль"
                            values={attributes}
                            setValues={setAttributes}
                            field={'value'}
                        />
                    </Stack>
                </form>
            </DialogContent>
            <DialogActions sx={{p: '1.25rem'}}>
                <Button onClick={exit}>Выход</Button>
                <Button color="secondary" onClick={resetPassword} variant="contained">
                    Установить
                </Button>
            </DialogActions>
        </Dialog>
    );
};
export default RolesUserTable;