import React, {useMemo} from 'react';
import MaterialReactTable from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";

const SupplierTable = ({data, selectSupplier}) => {

    const columns = useMemo(
        () => [
            {
                header: 'Найменование',
                accessorKey: 'name',
                minSize: 30,
            },
            {
                header: 'id',
                accessorKey: 'id',
                enableHiding: false,
                enableColumnActions: false,
                enableGrouping: false,
                enableEditing: false,
            },
        ],
        [data],
    );

    return <MaterialReactTable
        muiTableBodyProps={{
            sx: () => ({
                '& tr:nth-of-type(odd)': {
                    backgroundColor: 'lightgray',
                },
            }),
        }}
        muiTableBodyCellProps={{
            sx: {
                p: '0px 0px 0px 10px',
                m: '0px 0px 0px 0px',
            },
        }}
        muiTableBodyRowProps={({row}) => ({
            onClick: () => selectSupplier && selectSupplier(row.original),
            sx: {
                height: '10px',
                cursor: selectSupplier && "pointer"
            },
        })}
        displayColumnDefOptions={{
            'mrt-row-actions': {
                muiTableHeadCellProps: {
                    align: 'center',
                },
                size: 120,
            },
        }}
        columns={columns}
        data={data}
        initialState={{
            columnVisibility: {
                id: false,
            },
            density: "compact",
            pagination: {pageIndex: 0, pageSize: 10},
        }}
        localization={MRT_Localization_RU}
    />;
};

export default SupplierTable;