export const optimizationStateStatuses = {
    "CALCULATION": "Расчетная",
    "NOT_CUTTING_REQUESTS": "Без заявок",
    "EXPECTATION": "Ожидание карт раскроя",
    "CREATED_CUTTING_MAP_CALCULATION": "Созданы рас. карты",
    "CREATED_CUTTING_MAP": "Созданы карты",
    "FOR_CUTTING": "Кроить",
    "CUTTING_STARTED": "Начат раскрой",
    "CUT_OUT": "Раскроена",
    "ARCHIVE": "Архив",
    "ARCHIVE_CALCULATION": "Архив"
}