import React, {useCallback, useEffect, useMemo, useState} from 'react';
import MaterialReactTable from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import {Button, Tooltip} from "@mui/material";
import {Delete, Edit} from "@mui/icons-material";
import Box from "@mui/material/Box";

import ModelService from "../../../../../API/model-estimate/model/ModelService";
import ButtonSettingsOrders from "../../../button/ButtonSettingsOrders";
import {CreateNewModelModal, UpdateModelModal} from "./BasicModelsTable";
import ModelReplacementService from "../../../../../API/model-estimate/model/ModelReplacementService";

export const ModelReplacementsTable = ({materialId}) => {

    const [data, setData] = useState([]);
    const [isFullScreen, setIsFullScreen] = useState(false);

    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [updateModalOpen, setUpdateModalOpen] = useState(false);
    const [currentEditRowData, setCurrentEditRowData] = useState(null);
    const [currentEditRowId, setCurrentEditRowId] = useState(null);
    const [unable, setUnable] = useState({});
    const [change, setChange] = useState(null);

    const [rowSelection, setRowSelection] = useState({});

    const handleCreateNewRow = (values) => {
        data.push(values);
        setData([...data]);
    };

    const handleUpdate = (row, values) => {
        data[row[currentEditRowId]] = values;
        setData([...data]);
    };

    useEffect(() => {
        ModelReplacementService.getAllByMaterialId(materialId).then(function (response) {
            let data = response.data;
            setData(data);
        })
            .catch(function (error) {
                console.log(error);
            });
    }, [change]);

    const handleSaveRowEdits = async ({exitEditingMode, row, values}) => {
        ModelService.update(values)
            .then(function () {
                data[row.index] = values;
                setData([...data]);
            })
            .catch(function (error) {
                console.log(error);
            })
        exitEditingMode();
    };

    const handleDeleteRow = useCallback(
        (row) => {
            ModelService.delete(row.original.id)
                .then(function () {
                    data.splice(row.index, 1);
                    setData([...data]);
                })
                .catch(function (error) {
                    console.log(error);
                })
        },
        [data],
    );

    const handleUpdateModel = useCallback(
        (row) => {
            setCurrentEditRowData(row.original);
            setCurrentEditRowId(row.index);
            setUpdateModalOpen(true);

        },
        [data, currentEditRowData, setCurrentEditRowData],
    );

    const handleUnableCheckbox = (id, status) => {
        ModelService.unable(id, status)
            .then(function () {
                handleUnable(id, status)
            })
            .catch(function (error) {
                console.log(error);
            })
    };

    const handleUnable = (id, status) => {
        unable[id] = status
        setUnable(prevState => ({
            ...prevState,
            [id]: status
        }));
    };

    const columns = useMemo(
        () => [
            {
                id: 'tempName',
                header: 'Заменяемое поле',
                accessorKey: 'tempName',
                minSize: 250,
            },
            {
                id: 'materialName',
                header: 'Имя материала',
                accessorKey: 'materialName',
                minSize: 150,
            },
            {
                header: 'id',
                id: 'id',
                accessorKey: 'id',
                enableHiding: false,
                enableColumnActions: false,
                enableGrouping: false,
                enableEditing: false,
            },
        ],
        [data, setData],
    );

    return (
        <div>
            <MaterialReactTable
                muiTableBodyProps={{
                    sx: () => ({
                        '& tr:nth-of-type(odd)': {
                            backgroundColor: 'lightgray',
                        },
                    }),
                }}
                muiTableBodyCellProps={{
                    sx: {
                        p: '0px 0px 0px 10px',
                        m: '0px 0px 0px 0px',
                    },
                }}
                muiTableBodyRowProps={{
                    sx: {
                        height: '10px',
                    },
                }}
                isFullScreen={isFullScreen}
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        size: 15,
                        minSize: 15,
                    },
                }}
                columns={columns}
                data={data}
                rowHeight={13}
                columnSpacing={1}
                initialState={{
                    columnVisibility: {
                        id: false,
                    },
                    isFullScreen: false,
                    density: "compact",
                    pagination: {pageIndex: 0, pageSize: 20},
                    columnPinning: {left: ['number']}
                }}
                enableEditing={false}
                /* editingMode="modal"
                 onEditingRowSave={handleSaveRowEdits}*/
                onRowSelectionChange={setRowSelection}
                state={{rowSelection}}
                enableRowSelection={false}
                getRowId={(originalRow) => originalRow.id}
                selectionPadding={1}
                selectionCss={{
                    backgroundColor: 'lightblue',
                    borderWidth: '2px',
                    borderStyle: 'solid',
                    borderColor: 'blue',
                    borderRadius: '4px',
                }}
                renderRowActions={({row, table}) => (
                    <Box sx={{
                        display: 'flex',
                        gap: '0.1rem',
                        justifyContent: 'flex-start',
                        maxWidth: '50px'
                    }}>
                        <Tooltip arrow placement="left" title="Редактировать">
                            <Button size="small" sx={{minWidth: 'auto', marginLeft: 'auto'}} color="pencil"
                                    onClick={() => handleUpdateModel(row)}>
                                <Edit sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                            </Button>
                        </Tooltip>
                        <Tooltip arrow placement="right" title="Удалить">
                            <Button size="small" sx={{minWidth: 'auto', marginLeft: 'auto'}} color="error"
                                    onClick={() => handleDeleteRow(row)}>
                                <Delete sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                            </Button>
                        </Tooltip>
                    </Box>
                )}
                localization={MRT_Localization_RU}
                renderTopToolbarCustomActions={({table}) => {
                    const setCurrency = (typeCurrentCurrency) => {
                    };

                    return (
                        <div>
                            <Box
                                sx={{
                                    display: 'flex',
                                    gap: '0.5rem',
                                    paddingLeft: '0.5rem',
                                    paddingTop: '0.5rem',
                                    paddingBottom: '0.2rem',
                                    flexWrap: 'wrap',
                                }}
                            >
                                <Button
                                    size="small"
                                    color="secondary"
                                    onClick={() => setCreateModalOpen(true)}
                                    variant="contained"
                                >
                                    Создать модель
                                </Button>
                                <ButtonSettingsOrders/>
                            </Box>
                        </div>
                    );
                }}
            />
            <CreateNewModelModal
                columns={columns}
                open={createModalOpen}
                onClose={() => setCreateModalOpen(false)}
                onSubmit={handleCreateNewRow}
            />
            <UpdateModelModal
                columns={columns}
                open={updateModalOpen}
                onClose={() => setUpdateModalOpen(false)}
                onSubmit={handleUpdate}
                values={currentEditRowData}
                currentEditRowId={currentEditRowId}
                change={change}
                setChange={setChange}
            />
        </div>
    );
};