import React from 'react';
import {Box, Checkbox, Stack, Tooltip} from "@mui/material";
import TextFieldObjectWithEntitySupplierList from "./TextFieldObjectWithEntitySupplierList";
import {materialCutDefault} from "../../../../data/MaterialCutDefault";
import MaterialTextFieldObjectWithEntityListCutDefault from "./MaterialTextFieldObjectWithEntityListCutDefault";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextFieldSimple from "../../textfield/TextFieldSimple";

const SettingsCuttingModule = ({
                                   material,
                                   setSupplierId,
                                   supplierId,
                                   supplierList,
                                   cutDefault,
                                   setCutDefault,
                                   drawEdge,
                                   setDrawEdge,
                                   cutWeight,
                                   setCutWeight,
                                   limitNumberTurns,
                                   setLimitNumberTurns,
                                   rotatePartInsideStrip,
                                   setRotatePartInsideStrip,
                                   trimmingLeft,
                                   setTrimmingLeft,
                                   trimmingRight,
                                   setTrimmingRight,
                                   trimmingBottom,
                                   setTrimmingBottom,
                                   trimmingTop,
                                   setTrimmingTop,
                                   setMinBalanceUpToWholeSheet,
                                   minBalanceUpToWholeSheet,
                                   minACutOff,
                                   setMinACutOff,
                                   minBCutOff,
                                   setMinBCutOff,
                               }) => {

    const handleCheckbox = (checkbox) => {
        switch (checkbox) {
            case 'drawEdge':
                setDrawEdge(!drawEdge);
                break;
            case 'rotatePartInsideStrip':
                setRotatePartInsideStrip(!rotatePartInsideStrip);
                break;
            default:
                break;
        }
    }

    return (
        <div>
            <Stack
                sx={{
                    width: '100%',
                    minWidth: {xs: '300px', sm: '360px', md: '400px'},
                    maxWidth: '500px',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        gap: '0.5rem',
                        p: '0.5rem',
                        flexWrap: 'wrap',
                        flexDirection: 'column'
                    }}
                >
                    <TextFieldObjectWithEntitySupplierList
                        label="Поставщик"
                        values={material}
                        field={'defaultSupplier'}
                        setId={setSupplierId}
                        id={supplierId}
                        entityList={supplierList}
                    />
                    <MaterialTextFieldObjectWithEntityListCutDefault
                        label="Кроить по умолчанию"
                        value={cutDefault}
                        setValue={setCutDefault}
                        field={'cutDefault'}
                        entityList={materialCutDefault}
                    />
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        '& > *': {
                            marginRight: '10px',
                        }
                    }}>
                        <TextFieldSimple
                            label="Отсуп слева"
                            value={trimmingLeft}
                            setValue={setTrimmingLeft}
                            field={'trimmingLeft'}
                        />
                        <TextFieldSimple
                            label="Отсуп справа"
                            value={trimmingRight}
                            setValue={setTrimmingRight}
                            field={'trimmingRight'}
                        />
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        '& > *': {
                            marginRight: '10px',
                        }
                    }}>
                        <TextFieldSimple
                            label="Отсуп сверху"
                            value={trimmingTop}
                            setValue={setTrimmingTop}
                            field={'trimmingTop'}
                        />
                        <TextFieldSimple
                            label="Отсуп снизу"
                            value={trimmingBottom}
                            setValue={setTrimmingBottom}
                            field={'trimmingBottom'}
                        />
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        '& > *': {
                            marginRight: '10px',
                        }
                    }}>
                        <TextFieldSimple
                            label="Ширина реза"
                            value={cutWeight}
                            setValue={setCutWeight}
                            field={'cutWeight'}
                        />
                        <TextFieldSimple
                            label="Макс кол. поворотов"
                            value={limitNumberTurns}
                            setValue={setLimitNumberTurns}
                            field={'limitNumberTurns'}
                        />
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        '& > *': {
                            marginRight: '10px',
                        }
                    }}>
                        <TextFieldSimple
                            label="Мин. ширина обрезка"
                            value={minACutOff}
                            setValue={setMinACutOff}
                            field={'minACutOff'}
                        />
                        <TextFieldSimple
                            label="Мин. высота обрезка"
                            value={minBCutOff}
                            setValue={setMinBCutOff}
                            field={'minBCutOff'}
                        />
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        '& > *': {
                            marginRight: '10px',
                        }
                    }}>
                        <TextFieldSimple
                            label="Минимальный остаток до целого листа"
                            value={minBalanceUpToWholeSheet}
                            setValue={setMinBalanceUpToWholeSheet}
                            field={'minBalanceUpToWholeSheet'}
                        />
                    </Box>
                    <Box sx={{width: '400px', marginRight: '20px'}}>
                        <Tooltip title={'Отображать кромки на карте раскроя'}>
                            <FormControlLabel
                                control={<Checkbox
                                    onChange={() => handleCheckbox('drawEdge')}
                                    checked={drawEdge}
                                />}
                                label="Отображать кромки"
                            />
                        </Tooltip>
                    </Box>
                    <Tooltip title={'Запрещает или разрешает поворот в полосе'}>
                        <FormControlLabel
                            control={<Checkbox
                                onChange={() => handleCheckbox('rotatePartInsideStrip')}
                                checked={rotatePartInsideStrip}
                            />}
                            label="Поворот детали в полосе"
                        />
                    </Tooltip>
                </Box>
            </Stack>
        </div>
    );
};

export default SettingsCuttingModule;