import React, {useEffect, useState} from 'react';
import WorkingCuttingMapTable from "../../../../components/UI/table/cutting/workingcuttingmap/WorkingCuttingMapTable";
import WorkingCuttingMapService from "../../../../API/orders/cutting/cuttingmap/WorkingCuttingMapService";

const WorkingCuttingMaps = () => {

    const [data, setData] = useState([]);
    useEffect(() => {

        WorkingCuttingMapService.getAll().then(function (response) {
            setData(response.data);
        })
            .catch(function (error) {
                console.log(error);
            });
    }, []);

    return (
        <div>
            <WorkingCuttingMapTable
                data={data}
                setData={setData}
            />
        </div>
    );
};

export default WorkingCuttingMaps;