import React from 'react';
import UnitTable from "../../../components/UI/table/warehouse/unit/UnitTable";

const Units = () => {
    return (
        <div>
            <UnitTable/>
        </div>
    );
};

export default Units;