import React from 'react';
import CurrentCurrencyNationalBankTable from "../../../../components/UI/table/warehouse/currency/CurrentCurrencyNationalBankTable";

const CurrentCurrencyNationalBank = () => {
    return (
        <div>
            <CurrentCurrencyNationalBankTable/>
        </div>
    );
};

export default CurrentCurrencyNationalBank;