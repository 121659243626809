import axios from "axios";
import {apiVersionCutting, apiVersionDetail, gatewayUrl, nameCutting, nameDetail} from "../../Constants";

const restUrl = gatewayUrl + nameCutting + apiVersionCutting + '/cutting'

export default class CutFileService {

    static async downloadFile(workingCuttingMapId, cutFileNameType) {
        return axios.post(restUrl + '/cut-file' + "?workingCuttingMapId=" + workingCuttingMapId + "&cutFileNameType=" + cutFileNameType, [],{
            responseType: "arraybuffer"
        });
    }
}