import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack} from "@mui/material";
import ChoiceTextFieldObjectWithEntityListSetSimpleValue from "./ChoiceTextFieldObjectWithEntityListSetSimpleValue";
import {materialCutDefault} from "../../../../../../../data/MaterialCutDefault";

const ChoiceSlabMaterialModal = ({open, onClose, handleSubmit, value, setValue, keyFilter}) => {

    return (
        <Dialog open={open}>
            <DialogTitle textAlign="center">Выбор типа прихода плитного материала</DialogTitle>
            <DialogContent>
                <form onSubmit={(e) => e.preventDefault()}>
                    <Stack
                        sx={{
                            width: '100%',
                            minWidth: {xs: '300px', sm: '360px', md: '400px'},
                            gap: '1.5rem',
                        }}
                    >
                        <ChoiceTextFieldObjectWithEntityListSetSimpleValue
                            label="Тип прихода"
                            value={value}
                            setValue={setValue}
                            field={'materialCutDefault'}
                            entityList={materialCutDefault}
                            keyFilter={keyFilter}
                        />
                    </Stack>
                </form>
            </DialogContent>
            <DialogActions sx={{p: '1.25rem'}}>
                <Button onClick={onClose}>Выйти</Button>
                <Button color="secondary" onClick={handleSubmit} variant="contained">
                    Выбрать
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ChoiceSlabMaterialModal;