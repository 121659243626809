export const INIT_SCALE = 0.005;

export const calcNewScale = (canvas, detailData) => {
    const {clientHeight, clientWidth} = canvas;

    const lengthDetailRatio = clientWidth / detailData.length
    const widthDetailRatio = clientHeight / detailData.width

    return lengthDetailRatio < widthDetailRatio
        ? lengthDetailRatio * INIT_SCALE
        : widthDetailRatio * INIT_SCALE;

}
