import axios from "axios";
import {apiVersionModelEstimate, gatewayUrl, modelEstimate} from "../../../Constants";
import FileService from "../../orders/orders/organization/FileService";

const fileRestOrdersUrl = gatewayUrl + modelEstimate + apiVersionModelEstimate + '/files';

export default class ModelFileService {

    static getFormData(selectedFile) {
        const formData = new FormData();
        formData.append("selectedFile", selectedFile);
        return formData;
    }

    static async uploadModelEstimateFile(modelId, selectedFile) {
        return await axios.post(fileRestOrdersUrl + '/upload/exel-estimate'
            + "?modelId=" + modelId, ModelFileService.getFormData(selectedFile));
    }

    static async uploadModelDetailsFile(files, estimateId) {
        return await axios.post(fileRestOrdersUrl + '/upload/exel-model-details' + "?estimateId="
            + estimateId,
            FileService.getFormDataArray(files));
    }

    static async uploadBasicModelsFile(selectedFile) {
        return await axios.post(fileRestOrdersUrl + '/upload/basic-models-file',
            FileService.getFormData(selectedFile));
    }

    static async uploadModelsFile(selectedFile) {
        return await axios.post(fileRestOrdersUrl + '/upload/models-file',
            FileService.getFormData(selectedFile));
    }

    static async uploadReplacementModelMaterialsFile(files) {
        return await axios.post(fileRestOrdersUrl + '/upload/replacement-model-materials-file',
            FileService.getFormDataArray(files));
    }

    static async uploadModelEstimatesFiles(files) {
        return await axios.post(fileRestOrdersUrl + '/upload/exel-model-estimates-files',
            FileService.getFormDataArray(files));
    }

    static async uploadModelRequestsCuttingFiles(files) {
        return await axios.post(fileRestOrdersUrl + '/upload/exel-model-requests-cutting-files',
            FileService.getFormDataArray(files));
    }
}