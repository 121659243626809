import axios from "axios";
import {apiVersionOrders, gatewayUrl, nameOrders} from "../../../../Constants";

const issuingEdgesItemRestUrl = gatewayUrl +  nameOrders + apiVersionOrders + '/organizations/issuing-edges-item'

export default class IssuingEdgeItemService {

    static async getAllByIssuingEdgeId(issuingEdgeId) {
        return axios.get(issuingEdgesItemRestUrl + '/by-issuing-edge-id' + '?issuingEdgeId=' + issuingEdgeId);
    }

    static async performRefund(id, issuingEdgeId, refund) {
        return await axios.post(issuingEdgesItemRestUrl + '/' + id + '/perform-refund' + '?refund=' + refund + "&issuingEdgeId=" + issuingEdgeId,
            [], {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
    }

    static async closeIssuingEdgeItem(id) {
        await axios.patch(issuingEdgesItemRestUrl + '/' + id + '/close',
            [], {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
    }
}