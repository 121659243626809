import React, {useState} from 'react';
import {Checkbox} from "@mui/material";
import OrderService from "../../../../../../API/orders/orders/organization/OrderService";

const CellCheckboxOATUnable = ({cell, id, attributeId}) => {

    const [status, setStatus] = useState(cell.getValue);
    const handle = () => {
        OrderService.unableAttributeByOrderId(id, attributeId, !status)
            .then(function () {
                setStatus(!status)
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    return (
        <div style={{display: "flex", alignItems: "center", height: "13px", lineHeight: "13px"}}>
            <Checkbox
                onChange={handle}
                size="small"
                checked={status}
                sx={{"& .MuiSvgIcon-root": {fontSize: 20}}}
            />
        </div>
    );
};

export default CellCheckboxOATUnable;