import axios from "axios";
import {apiVersionOrders, gatewayUrl, nameOrders} from "../../../../Constants";

const typeJobRestUrl = gatewayUrl +  nameOrders + apiVersionOrders + '/organizations/type-jobs'

export default class TypeJobService {

    static async getAllByOrganizationId() {
        return axios.get(typeJobRestUrl);
    }
}