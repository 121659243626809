import React, {useState} from 'react';
import AllRolesTable from "../../../components/UI/table/common/user/AllRolesTable";
import RolesUserTable from "../../../components/UI/table/common/user/RolesUserTable";
import {useParams} from "react-router-dom";
import Typography from "@mui/material/Typography";

const UsersItem = () => {

    const params = useParams();
    const [change, setChange] = useState(false);

    return (
        <div>
            <Typography variant="h6" component="h6">
                Роли пользователя {params.name}
            </Typography>
            <RolesUserTable
                id={params.id}
                change={change}
                setChange={setChange}
            />
            <Typography variant="h6" component="h6">
                Все роли
            </Typography>
            <AllRolesTable
                id={params.id}
                change={change}
                setChange={setChange}
            />
        </div>
    );
};

export default UsersItem;