import React from 'react';
import PlanTable from "../../../../components/UI/table/optimization/plans/PlanTable";
import Typography from "@mui/material/Typography";

const Plans = () => {
    return (
        <div>
            <Typography variant="h6" component="h6">
                Планы
            </Typography>
            <PlanTable/>
        </div>
    );
};

export default Plans;