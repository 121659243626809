import {Button, Dialog, DialogActions, DialogTitle} from "@mui/material";

const ModalConfirm = ({confirmModal, onClose, onSubmit}) => {
    return (
        <Dialog open={!!confirmModal}>
            <DialogTitle textAlign="center">
                {confirmModal === "reset" ? "Вернутся к начальному контуру?" : "Вы уверены, что хотите удалить?"}
            </DialogTitle>
            <DialogActions sx={{p: '1.25rem'}}>
                <Button size="small" onClick={onClose}> отменить </Button>
                <Button size="small" color="secondary" onClick={onSubmit} variant="contained">
                    да
                </Button>
            </DialogActions>
        </Dialog>
    )
}
export default ModalConfirm
