import React from 'react';
import PathTable from "../../../components/UI/table/orders/organization/paths/PathTable";

const Paths = () => {
    return (
        <div>
            <PathTable/>
        </div>
    );
};

export default Paths;