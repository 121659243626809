import React, {useCallback, useEffect, useState} from 'react';
import MaterialReactTable from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import Box from "@mui/material/Box";
import {Button, Tooltip} from "@mui/material";
import {Delete, Edit} from "@mui/icons-material";
import MaterialPriceUnitService from "../../../../../../API/orders/warehouse/MaterialPriceUnitService";
import {useNavigate} from "react-router-dom";
import ModalOpenSetValues from "./ModalOpenSetValues";
import UpdateOrAddMaterialPriceUnitModule from "./UpdateOrAddMaterialPriceUnitModule";
import MaterialPriceUnitUti from "../../../../../../utils/warehouse/MaterialPriceUnitUtil";
import MaterialPriceUnitUtil from "../../../../../../utils/warehouse/MaterialPriceUnitUtil";
import ModalConfirm from "../../../../modal/ModalConfirm";
import ArrivalMaterialService from "../../../../../../API/orders/warehouse/material/ArrivalMaterialService";
import {ArrivalMaterialPriceUnitColumns} from "../../../../../../colums/ArrivalMaterialPriceUnitColumns";


const ArrivalMaterialPriceUnitTable = ({
                                           arrivalMaterialId, currentCurrency, isBaseCurrency, isAlternativeCurrency,
                                           baseCurrency, alternativeCurrency, supplierId, warehouseId
                                       }) => {

        const [isFullScreen, setIsFullScreen] = useState(false);
        const [data, setData] = useState([]);

        const [modalCurrencyOpen, setModalCurrencyOpen] = useState(false);
        const [currentCurrencyBM, setCurrentCurrencyBM] = useState([]);
        const [typeCurrentCurrency, setTypeCurrentCurrency] = useState([]);

        const [selectedRows, setSelectedRows] = useState([]);
        const [selectedIds, setSelectedIds] = useState([]);

        const [updateModalOpen, setUpdateModalOpen] = useState(false);
        const [addModalOpen, setAddModalOpen] = useState(false);

        const [currentEditRowData, setCurrentEditRowData] = useState(null);
        const [currentEditRowId, setCurrentEditRowId] = useState(null);

        const [titleModalOpenSetValues, setTitleModalOpenSetValues] = useState(null);
        const [labelModalOpenSetValues, setLabelModalOpenSetValues] = useState(null);
        const [exchangeRate, setExchangeRate] = useState(null);
        const [materialPriceUnits, setMaterialPriceUnits] = useState(new Map());
        const [currentBasePriceCurrency, setCurrentBasePriceCurrency] = useState(null);
        const [rowSelection, setRowSelection] = useState({});
        const [disabledButtonBasePriceCurrency, setDisabledButtonBasePriceCurrency] = useState(true);
        const [showConfirmationModalArrivalMaterial, setConfirmationModalArrivalMaterial] = useState(false);
        const [slabMaterial, setSlabMaterial] = useState(true);

        useEffect(() => {

            MaterialPriceUnitService.getAllByArrivalMaterialId(parseInt(arrivalMaterialId)).then(function (response) {
                let data = response.data;
                setSlabMaterial(MaterialPriceUnitUtil.checkSlab(data));
                setData(data);
                MaterialPriceUnitUti.addIdMaterialInMap(data, materialPriceUnits)
            })
                .catch(function (error) {
                    console.log(error);
                });
        }, []);

        useEffect(() => {
            data.forEach((row) => {
                if (rowSelection[row.id]) {
                    setCurrentBasePriceCurrency(row.supplierMaterial.basePrice.currency.curAbbreviation)
                }
            });
        }, [rowSelection]);

        const isNotSelected = () => {
            return Object.keys(rowSelection).length === 0;
        };

        const unlockBaseCoursePrices = () => {
            const checkCurrency = currentBasePriceCurrency === currentCurrency || currentBasePriceCurrency === baseCurrency
                || currentBasePriceCurrency === alternativeCurrency
            const length = Object.keys(rowSelection).length !== 1
            return !disabledButtonBasePriceCurrency ? false : checkCurrency ? true : length;
        };

        const disabledOrUnableButtonBasePriceCurrency = () => {
            setDisabledButtonBasePriceCurrency(!disabledButtonBasePriceCurrency);
        };

        const handleDeleteRow = useCallback(
            (row) => {
                const id = row.original.id;
                const materialId = row.original.id
                MaterialPriceUnitService.delete(id)
                    .then(function () {
                        data.splice(row.index, 1);
                        setData([...data]);
                        materialPriceUnits.delete(materialId);
                    })
                    .catch(function (error) {
                        console.log(error);
                    })
            },
            [data],
        );

        const handleUpdateModel = useCallback(
            (row) => {
                setCurrentEditRowData(row.original);
                setCurrentEditRowId(row.index);
                setUpdateModalOpen(true);
            },
            [data, currentEditRowData, setCurrentEditRowData],
        );

        const updateCurrencyByIds = async () => {
            MaterialPriceUnitService.updateCurrencyByIds(selectedIds, currentCurrencyBM, typeCurrentCurrency, supplierId)
                .then(function () {
                    selectedRows.forEach((row) => {
                        row.setValue(exchangeRate, data.value);
                    });
                })
                .catch(function (error) {
                    console.log(error);
                })
            handleCloseModalCurrency();
        }

        const setTitleModalOpenMethod = (typeCurrentCurrency) => {
            return typeCurrentCurrency === "base" ?
                'Установть курс к базовой валюте склада' : "basePrice" ?
                    'Установть курс к базовой цене' : 'Установть курс к альтернативной валюте склада';
        };

        const setLabelModalOpenMethod = (typeCurrentCurrency) => {
            return typeCurrentCurrency === "base" ?
                'Базовый курс' : "basePrice" ?
                    'Курс к базовой цене' : 'Альтернативный курс';
        };

        const setExchangeRateMethod = (typeCurrentCurrency) => {
            return typeCurrentCurrency === "base" ?
                'exchangeRateToBaseCurrencyBaseMaterials' : "basePrice" ?
                    'exchangeRateToBasePrice' : 'exchangeRateToAlternativeCurrencyBaseMaterials';
        };

        const handleCloseModalCurrency = () => {
            setModalCurrencyOpen(false);
        };

        const getIsBaseCurrency = () => {
            return isBaseCurrency === 'true';
        };

        const getIsAlternativeCurrency = () => {
            return isAlternativeCurrency === 'true';
        };

        const navigate = useNavigate();

        const redirectByArrivalMaterials = () => {
            navigate('/organization/materials-arrival');
        };

        const handleSubmitArrivalMaterial = () => {
            ArrivalMaterialService.performMaterialReceipt(arrivalMaterialId, warehouseId)
                .then(function () {
                    navigate('/organization/materials-arrival');
                })
                .catch(function (error) {
                    console.log(error);
                })
        };

        const cancelArrivalMaterial = () => {
            setConfirmationModalArrivalMaterial(false);
        };

        const columns = ArrivalMaterialPriceUnitColumns({
            data,
            setData,
            currentCurrency,
            alternativeCurrency,
            baseCurrency,
            slabMaterial,
        });

        return (
            <div>
                <MaterialReactTable
                    muiTableBodyProps={{
                        sx: () => ({
                            '& tr:nth-of-type(odd)': {
                                backgroundColor: 'lightgray',
                            },
                        }),
                    }}
                    muiTableBodyCellProps={{
                        sx: {
                            p: '0px 0px 0px 10px',
                            m: '0px 0px 0px 0px',
                        },
                    }}
                    muiTableBodyRowProps={{
                        sx: {
                            height: '10px',
                        },
                    }}
                    isFullScreen={isFullScreen}
                    displayColumnDefOptions={{
                        'mrt-row-actions': {
                            muiTableHeadCellProps: {
                                align: 'center',
                            },
                            size: 15,
                            minSize: 15,
                        },
                    }}
                    columns={columns}
                    data={data}
                    rowHeight={13}
                    columnSpacing={1}
                    initialState={{
                        columnVisibility: {
                            id: false,
                            exchangeRateToBaseCurrencyBaseMaterials: getIsBaseCurrency(),
                            exchangeRateToAlternativeCurrencyBaseMaterials: getIsAlternativeCurrency(),
                            priceInCurrencyBase: getIsBaseCurrency(),
                            priceInCurrencyAlternative: getIsAlternativeCurrency(),
                        },
                        isFullScreen: false,
                        density: "compact",
                        pagination: {pageIndex: 0, pageSize: 50},
                    }}
                    enableEditing
                    onRowSelectionChange={setRowSelection}
                    state={{rowSelection}}
                    enableRowSelection={(row) => disabledButtonBasePriceCurrency || isNotSelected() ||
                        row.original.supplierMaterial.basePrice.currency.curAbbreviation === currentBasePriceCurrency}
                    getRowId={(originalRow) => originalRow.id}
                    selectionPadding={1}
                    selectionCss={{
                        backgroundColor: 'lightblue',
                        borderWidth: '2px',
                        borderStyle: 'solid',
                        borderColor: 'blue',
                        borderRadius: '4px',
                    }}
                    renderRowActions={({row, table}) => (
                        <Box sx={{
                            display: 'flex',
                            gap: '0.1rem',
                            justifyContent: 'flex-start',
                            maxWidth: '50px'
                        }}>
                            <Tooltip arrow placement="left" title="Редактировать">
                                <Button size="small" sx={{minWidth: 'auto', marginLeft: 'auto'}} color="pencil"
                                        onClick={() => handleUpdateModel(row)}>
                                    <Edit sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                                </Button>
                            </Tooltip>
                            <Tooltip arrow placement="right" title="Удалить">
                                <Button size="small" sx={{minWidth: 'auto', marginLeft: 'auto'}} color="error"
                                        onClick={() => handleDeleteRow(row)}>
                                    <Delete sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                                </Button>
                            </Tooltip>
                        </Box>
                    )}
                    localization={MRT_Localization_RU}
                    renderTopToolbarCustomActions={({table}) => {
                        const setCurrency = (typeCurrentCurrency) => {
                            setModalCurrencyOpen(true)
                            setTypeCurrentCurrency(typeCurrentCurrency)
                            setTitleModalOpenSetValues(setTitleModalOpenMethod(typeCurrentCurrency))
                            setLabelModalOpenSetValues(setLabelModalOpenMethod(typeCurrentCurrency))
                            setExchangeRate(setExchangeRateMethod(typeCurrentCurrency))
                            const selectedRows = table.getSelectedRowModel().flatRows;
                            const selectedIds = selectedRows.map((row) => row.getValue('id'));
                            setSelectedIds(selectedIds);
                        };

                        return (
                            <div>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        gap: '0.5rem',
                                        paddingLeft: '0.5rem',
                                        paddingTop: '0.5rem',
                                        paddingBottom: '0.2rem',
                                        flexWrap: 'wrap',
                                    }}
                                >
                                    <Button
                                        size="small"
                                        color="secondary"
                                        onClick={() => redirectByArrivalMaterials()}
                                        variant="contained"
                                    >
                                        Вернутся в заявки
                                    </Button>
                                    <Button
                                        size="small"
                                        color="secondary"
                                        onClick={() => setConfirmationModalArrivalMaterial(true)}
                                        variant="contained"
                                    >
                                        Ввести приход
                                    </Button>
                                </Box>
                                <Box
                                    sx={{display: 'flex', gap: '0.5rem', p: '0.5rem', flexWrap: 'wrap'}}
                                >
                                    <Button
                                        size="small"
                                        color="secondary"
                                        onClick={() => setAddModalOpen(true)}
                                        variant="contained"
                                    >
                                        Добавить материал
                                    </Button>
                                    {getIsBaseCurrency() && <Button
                                        size="small"
                                        color="success"
                                        disabled={table.getIsSomeRowsSelected() ? false : !table.getIsAllRowsSelected()}
                                        onClick={() => setCurrency('base')}
                                        variant="contained"
                                    >
                                        Установить баз. курс
                                    </Button>}
                                    {getIsAlternativeCurrency() && <Button
                                        size="small"
                                        color="success"
                                        disabled={table.getIsSomeRowsSelected() ? false : !table.getIsAllRowsSelected()}
                                        onClick={() => setCurrency('alternative')}
                                        variant="contained"
                                    >
                                        Установить ал. курс
                                    </Button>}
                                    <Tooltip arrow placement="right"
                                             title={unlockBaseCoursePrices() ? "Можно разблокрировать выбрав только одну строку" : ""}>
                           <span>
                           <Button
                               size="small"
                               color="success"
                               disabled={unlockBaseCoursePrices()}
                               onClick={() => disabledOrUnableButtonBasePriceCurrency()}
                               variant="contained"
                           >
                            {disabledButtonBasePriceCurrency ? 'Разблокировать курс баз. цены' : 'Заблокировать курс баз. цены'}
                           </Button>
                           </span>
                                    </Tooltip>
                                    <Button
                                        size="small"
                                        color="success"
                                        disabled={disabledButtonBasePriceCurrency ? true : (table.getIsSomeRowsSelected() ? false : !table.getIsAllRowsSelected())}
                                        onClick={() => setCurrency('basePrice')}
                                        variant="contained"
                                    >
                                        Установить курс баз. цены
                                    </Button>
                                </Box>
                            </div>
                        );
                    }}
                />
                <UpdateOrAddMaterialPriceUnitModule
                    data={data}
                    setData={setData}
                    open={addModalOpen}
                    setOpen={setAddModalOpen}
                    currentCurrency={currentCurrency}
                    baseCurrency={baseCurrency}
                    alternativeCurrency={alternativeCurrency}
                    isBaseCurrency={isBaseCurrency}
                    isAlternativeCurrency={isAlternativeCurrency}
                    supplierId={supplierId}
                    updateModalOpen={updateModalOpen}
                    setUpdateModalOpen={setUpdateModalOpen}
                    onCloseModalOpen={() => setUpdateModalOpen(false)}
                    currentEditRowData={currentEditRowData}
                    setCurrentEditRowData={setCurrentEditRowData}
                    currentEditRowId={currentEditRowId}
                    materialPriceUnits={materialPriceUnits}
                    arrivalMaterialId={arrivalMaterialId}
                />
                <ModalOpenSetValues
                    title={titleModalOpenSetValues}
                    label={labelModalOpenSetValues}
                    modalOpen={modalCurrencyOpen}
                    handleClose={handleCloseModalCurrency}
                    setValue={setCurrentCurrencyBM}
                    handleSubmit={updateCurrencyByIds}
                />
                <ModalConfirm
                    title={"Подтвердить приход?"}
                    modalOpen={showConfirmationModalArrivalMaterial}
                    handleClose={cancelArrivalMaterial}
                    handleSubmit={handleSubmitArrivalMaterial}
                    action={"Подтвердить"}
                />
            </div>
        );
    }
;

export default ArrivalMaterialPriceUnitTable;

