import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack} from "@mui/material";
import MaterialWarehouseCutOffsTable from "./MaterialWarehouseCutOffsTable";

const ModalFinishCutCuttingMap = ({
                                      open,
                                      handleClose,
                                      handleSubmit,
                                      data,
                                      setData,
                                      unableCut,
                                      setUnableCut
                                  }) => {
    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle textAlign="center">Подтвердить раскрой карты и полученных обрезков</DialogTitle>
            <DialogContent>
                <form onSubmit={(e) => e.preventDefault()}>
                    <MaterialWarehouseCutOffsTable
                        data={data}
                        setData={setData}
                        unableCut={unableCut}
                        setUnableCut={setUnableCut}
                    />
                    <Stack
                        sx={{
                            width: '100%',
                            minWidth: {xs: '300px', sm: '360px', md: '400px'},
                            gap: '0.01rem',
                        }}
                    >
                    </Stack>
                </form>
            </DialogContent>
            <DialogActions sx={{p: '1.25rem'}}>
                <Button onClick={handleClose}>Выйти</Button>
                <Button color="secondary" onClick={() => handleSubmit()} variant="contained">
                    Подтвердить
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ModalFinishCutCuttingMap;