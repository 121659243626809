import axios from "axios";
import {apiVersionOrders, gatewayUrl, nameOrders} from "../../../../Constants";

const requestsCuttingServiceRestUrl = gatewayUrl + nameOrders + apiVersionOrders + '/customers/requests-cutting'

export default class CustomerRequestsCuttingService {

    static async getAllByCustomerOrderId(orderId) {
        return axios.get(requestsCuttingServiceRestUrl + '/by-order-id?orderId=' + orderId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
}