import React, {useState} from 'react';
import BasicModelEstimateTableItem
    from "../../../../components/UI/table/model-estimate/estimate/BasicModelEstimateTableItem";
import {useParams} from "react-router-dom";
import {TabContext, TabPanel} from "@mui/lab";
import {Box, Tabs} from "@mui/material";
import Tab from "@mui/material/Tab";
import ModelDetails from "./ModelDetails";
import ModelProductionCosts
    from "../../../../components/UI/table/model-estimate/modelproductioncosts/ModelProductionCosts";

const BasicModelsMaterialEstimateUnit = () => {

    const params = useParams();
    const id = params.id;
    const estimateId = params.estimateId;

    const [value, setValue] = useState('1');
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div>
            <TabContext value={value}>
                <Box sx={{
                    borderBottom: 1, borderColor: 'divider'
                }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Материалы" value='1'/>
                        <Tab label="Детали" value='2'/>
                        <Tab label="ОПР" value='3'/>
                    </Tabs>
                </Box>
                <TabPanel value='1'>
                    <BasicModelEstimateTableItem
                        estimateId={estimateId}
                    />
                </TabPanel>
                <TabPanel value='2'>
                    <ModelDetails
                        estimateId={estimateId}
                    />
                </TabPanel>
                <TabPanel value='3'>
                    <ModelProductionCosts
                        estimateId={estimateId}
                    />
                </TabPanel>
            </TabContext>


        </div>
    );
};

export default BasicModelsMaterialEstimateUnit;