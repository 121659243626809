import React, {useContext} from 'react';
import RequestsCuttingTable from "../../../../components/UI/table/optimization/requestscutting/RequestsCuttingTable";
import RequestsCuttingService from "../../../../API/orders/optimization/organization/RequestsCuttingService";
import Typography from "@mui/material/Typography";

const RequestsCuttingCalculation = () => {

    return (
        <div>
            <Typography variant="h6" component="h6">
                Заявки на раскрой для расчета
            </Typography>
            <RequestsCuttingTable
                getAll={RequestsCuttingService.getAllWithMaterialNotArchiveTypeCalculation}
                isRedirectRequestsCutting={false}
                isButtonAddRequestsCutting={false}
                isOrder={true}
                isCreateOptimizationCalculation={true}
                isRedirectOrders={true}
                isSetForOptimization={true}
                page={'RequestsCuttingCalculation'}
                isCalculation={true}
            />
        </div>
    );
};

export default RequestsCuttingCalculation;