import React, {useEffect, useState} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack} from "@mui/material";
import TextFieldValidation from "./TextFieldValidation";
import {constantsOfInputValues} from "../../../../Constants";

const ModalAddDetail = ({initValue, open, onClose, title, labelButtonSubmit, handleSubmit, uniqueNames}) => {

    const [formState, setFormState] = useState(initValue)
    const [validationState, setValidationState] = useState(initValue);

    const {MAX_LENGTH, MAX_WIDTH} = constantsOfInputValues

    useEffect(() => {
        setFormState(initValue)
        setValidationState(Object.keys(initValue).reduce((acc, it) => ({...acc, [it]: ""}), {}))
    }, [initValue])

    const handleValidationData = () => {
        const newErrors = {...validationState}

        Object.entries(formState).forEach(([name, value]) => {
            if (name === "position" && uniqueNames.includes(value)) {
                newErrors.position = "значение должно быть уникальным"
            } else if (name === "length" && (Number(value) > MAX_LENGTH || Number(value) < 1)) {
                newErrors.length = `значени должно быть от 1 до ${MAX_LENGTH}`
            } else if (name === "width" && (Number(value) > MAX_WIDTH || Number(value) < 1)) {
                newErrors.width = `значени должно быть от 1 до ${MAX_WIDTH}`
            }

            if (value === "") {
                newErrors[name] = "поле не должно быть пустым"
            }
        })

        setValidationState(newErrors)

        if (Object.values(newErrors).every(el => el === "")) {
            handleSubmit(formState)
        }
    }

    return (
        <Dialog open={open}>
            <DialogTitle textAlign="center">{title}</DialogTitle>
            <DialogContent sx={{paddingBottom: 0}}>
                <form onSubmit={(e) => e.preventDefault()}>
                    <Stack
                        sx={{
                            width: '100%',
                            minWidth: {xs: '300px', sm: '360px', md: '400px'},
                        }}
                    >
                        <TextFieldValidation
                            name={"position"}
                            label={"Позиция"}
                            value={formState?.position}
                            setCurrValues={setFormState}
                            validationError={validationState?.position}
                            setValidationState={setValidationState}
                        />
                        <TextFieldValidation
                            name={"name"}
                            label={"Наименование"}
                            value={formState?.name}
                            setCurrValues={setFormState}
                            validationError={validationState?.name}
                            setValidationState={setValidationState}
                        />
                        <TextFieldValidation
                            type="number"
                            name={"length"}
                            label={"Длина"}
                            value={formState?.length}
                            setCurrValues={setFormState}
                            validationError={validationState?.length}
                            setValidationState={setValidationState}
                        />
                        <TextFieldValidation
                            type="number"
                            name={"width"}
                            label={"Ширина"}
                            value={formState?.width}
                            setCurrValues={setFormState}
                            validationError={validationState?.width}
                            setValidationState={setValidationState}
                        />
                        <TextFieldValidation
                            type="number"
                            name={"height"}
                            label={"Высота"}
                            value={formState?.height}
                            disabled={true}
                            setCurrValues={setFormState}
                            validationError={validationState?.height}
                            setValidationState={setValidationState}
                        />
                    </Stack>
                </form>
            </DialogContent>
            <DialogActions sx={{p: '1.25rem'}}>
                <Button onClick={onClose}> Выйти </Button>
                <Button color="secondary" onClick={handleValidationData} variant="contained">
                    {labelButtonSubmit}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ModalAddDetail;
