import React from 'react';
import {useParams} from "react-router-dom";
import Typography from "@mui/material/Typography";
import ModelSetPackagingSetTable from "../../../../components/UI/table/model-estimate/model/ModelSetPackagingSetTable";

const ModelSetPackagingSet = () => {

    const params = useParams();

    return (
        <div>
            <Typography variant="h6" component="h6">
                Установить комплект упаковки в модель артикул : {params.article}
            </Typography>
            <ModelSetPackagingSetTable
                idModel={params.id}/>
        </div>
    );
};

export default ModelSetPackagingSet;