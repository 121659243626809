import React, {useEffect, useState} from 'react';
import CustomerOrderService from "../../../API/orders/orders/customer/CustomerOrderService";
import CustomerOrderTable from "../../../components/UI/table/orders/customer/order/CustomerOrderTable";

const CustomerOrders = () => {

    const [data, setData] = useState([]);

    useEffect(() => {
        CustomerOrderService.getAllCustomerOrders().then(function (response) {
            let data = response.data;
            setData(data);

        })
            .catch(function (error) {
                console.log(error);
            });
    }, []);

    return (
        <div>
            <CustomerOrderTable
                data={data}
                setData={setData}
                enableEditing={true}
            />
        </div>
    );
};

export default CustomerOrders;