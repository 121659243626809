import axios from "axios";
import {apiVersionDetail, gatewayUrl, nameDetail} from "../../Constants";

const detailControllerRestUrl = gatewayUrl + nameDetail + apiVersionDetail + '/customers/orders/'


export default class QuarterControllerService {
    static async add(orderId, detailId, sideStartPointId, data) {
        return await axios.post(`${detailControllerRestUrl}${orderId}/details/${detailId}/quarters?sideStartPointId=${sideStartPointId}`, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async delete(orderId, detailId, quarterId) {
        return await axios.delete(`${detailControllerRestUrl}${orderId}/details/${detailId}/quarters/${quarterId}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async put(orderId, detailId, quarterId, sideStartPointId, data) {
        return await axios.put(`${detailControllerRestUrl}${orderId}/details/${detailId}/quarters/${quarterId}?sideStartPointId=${sideStartPointId}`, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
}