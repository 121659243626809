import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack} from "@mui/material";
import EntityList from "./EntityList";

const ModalUpdateStatus = ({
                               title,
                               data,
                               setData,
                               open,
                               handleClose,
                               values,
                               setValues,
                               currentEditRowId,
                               currentAllowedStatuses,
                               statusesList,
                               field,
                               unableStatus
                           }) => {

        const handleSubmit = () => {
            unableStatus(values.id, values[field],
            ).then(function () {
                data[currentEditRowId] = values;
                setData([...data]);
                if (values[field] === 'ARCHIVE') {
                    data.splice(currentEditRowId, 1);
                    setData([...data]);
                }
                handleClose();
            })
                .catch(function (error) {
                    console.log(error);
                });
        }

        return (
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle textAlign="center">{title}</DialogTitle>
                <DialogContent>
                    <form onSubmit={(e) => e.preventDefault()}>
                        <Stack
                            sx={{
                                width: '100%',
                                minWidth: {xs: '300px', sm: '360px', md: '400px'},
                                gap: '1.5rem',
                            }}
                        >
                            <EntityList
                                label="Статус"
                                values={values}
                                setValues={setValues}
                                field={field}
                                allowedStatuses={currentAllowedStatuses}
                                statusesList={statusesList}
                            />
                        </Stack>
                    </form>
                </DialogContent>
                <DialogActions sx={{p: '1.25rem'}}>
                    <Button onClick={handleClose}>Выйти</Button>
                    <Button color="secondary" onClick={() => handleSubmit()} variant="contained">
                        Обновить
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
;

export default ModalUpdateStatus;