import React from "react";
import EstimateItem from "./EstimateItem";
import {useParams} from "react-router-dom";

const OrganizationEstimate = () => {

    const params = useParams();
    const year = params.year;
    const orderNumber = params.orderNumber;
    const orderId = params.orderId;
    const orderHeaderId = params.orderHeaderId;

    return (
        <div>
            <EstimateItem
                orderId={orderId}
                isButtonPurchase={true}
                enableEditing={true}
                isActual={true}
                inWork={true}
                orderCloud={true}
                isImport={true}
                detailed={false}
                orderNumber={orderNumber}
                orderHeaderId={orderHeaderId}
                year={year}
            />
        </div>
    );
};

export default OrganizationEstimate;