import React from 'react';
import RequestsPurchaseTable from "../../../../components/UI/table/warehouse/purchase/RequestsPurchaseTable";

const RequestsPurchase = () => {

    return (
        <div>
            <RequestsPurchaseTable/>
        </div>
    );
};

export default RequestsPurchase;