import React from 'react';
import AttributesTable from "../../../components/UI/table/orders/organization/attributes/AttributesTable";

const Attributes = () => {
    return (
        <div>
            <AttributesTable/>
        </div>
    );
};

export default Attributes;