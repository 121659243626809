import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack} from "@mui/material";
import TextFieldWithSetValue from "../../../../textfield/TextFieldWithSetValue";
import MaterialOptimizationTextFieldObjectWithEntityList
    from "../../../../textfield/MaterialOptimizationTextFieldObjectWithEntityList";
import CurrencyTextFieldObjectWithEntityList from "../../../../textfield/CurrencyTextFieldObjectWithEntityList";
import TextFieldSimpleSupplierMaterial from "../supplier/TextFieldSimpleSupplierMaterial";
import TextFieldWithSetValueMainOptions from "../../../../modul/mainoptions/TextFieldWithSetValueMainOptions";
import MaterialTextFieldObjectWithEntityList from "../../../../modul/mainoptions/MaterialTextFieldObjectWithEntityList";
import {statusesYesOrNo} from "../../../../../../data/StatusesYesOrNo";

const ModalCreateOrUpdateMaterialStripe = ({
                                               open,
                                               onClose,
                                               title,
                                               values,
                                               setValues,
                                               labelButtonSSubmit,
                                               handleSubmit,
                                               supplierList,
                                               setSupplierId,
                                               price,
                                               setPrice,
                                               currencyList,
                                               setCurrencyId,
                                               isNew
                                           }) => {

    return (
        <Dialog open={open}>
            <DialogTitle textAlign="center">{title}</DialogTitle>
            <DialogContent>
                <form onSubmit={(e) => e.preventDefault()}>
                    <Stack
                        sx={{
                            width: '100%',
                            minWidth: {xs: '300px', sm: '360px', md: '400px'},
                            gap: '0.01rem',
                        }}
                    >
                        {isNew && <MaterialOptimizationTextFieldObjectWithEntityList
                            label="Поставщик"
                            field={'material'}
                            setId={setSupplierId}
                            entityList={supplierList}
                        />}
                        <TextFieldWithSetValueMainOptions
                            label="Артикул поставщика"
                            values={values}
                            setValues={setValues}
                            field={'article'}
                        />
                        <TextFieldWithSetValue
                            label="Ширина"
                            values={values}
                            setValues={setValues}
                            field={'length'}
                            disabled={!isNew}
                        />
                        <TextFieldWithSetValue
                            label="Высота"
                            values={values}
                            setValues={setValues}
                            field={'width'}
                            disabled={!isNew}
                        />
                        <TextFieldWithSetValue
                            label="Отступ слева"
                            values={values}
                            setValues={setValues}
                            field={'trimmingLeft'}
                        />
                        <TextFieldWithSetValue
                            label="Отступ справа"
                            values={values}
                            setValues={setValues}
                            field={'trimmingRight'}
                        />
                        <TextFieldWithSetValue
                            label="Отступ сверху"
                            values={values}
                            setValues={setValues}
                            field={'trimmingTop'}
                        />
                        <TextFieldWithSetValue
                            label="Отступ снизу"
                            values={values}
                            setValues={setValues}
                            field={'trimmingBottom'}
                        />
                        <CurrencyTextFieldObjectWithEntityList
                            label="Валюта базовой цены"
                            values={values}
                            field={'basePrice'}
                            setId={setCurrencyId}
                            entityList={currencyList}
                            disabled={!isNew}
                        />
                        {isNew && <TextFieldSimpleSupplierMaterial
                            label="Базовая цена"
                            value={price}
                            setValue={setPrice}
                            field={'price'}
                        />}
                        {!isNew && <TextFieldWithSetValueMainOptions
                            label="Базовая цена"
                            values={values ? values.basePrice : null}
                            setValues={setValues}
                            field={'price'}
                            disabled={true}
                        />}
                        <MaterialTextFieldObjectWithEntityList
                            label="Фиксированная цена"
                            values={values}
                            setValues={setValues}
                            field={'fixPrice'}
                            entityList={statusesYesOrNo}
                        />
                    </Stack>
                </form>
            </DialogContent>
            <DialogActions sx={{p: '1.25rem'}}>
                <Button onClick={onClose}>Выйти</Button>
                <Button color="secondary" onClick={handleSubmit} variant="contained">
                    {labelButtonSSubmit}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ModalCreateOrUpdateMaterialStripe;