import React, {useEffect, useState} from 'react';
import {Box} from "@mui/material";
import WarehouseFinishedDetailService
    from "../../../../API/orders/cutting/warehousefinisheddetail/WarehouseFinishedDetailService";
import DetailTable from "../../../../components/UI/table/cutting/details/DetailTable";

const Cutting = () => {

    const [data, setData] = useState([]);
    const [unableCut, setUnableCut] = useState({});
    const [unableTurn, setUnableTurn] = useState({});
    const [selectedIds, setSelectedIds] = useState([]);
    const [modalStatusOpen, setModalStatusOpen] = useState(false);
    const [status, setStatus] = useState(true);
    const [typeStatus, setTypeStatus] = useState(null);

    useEffect(() => {

        WarehouseFinishedDetailService.getAll().then(function (response) {
            let data = response.data;
            setData(data);
            data.forEach((row) => {
                unableCut[row.id] = row.cut;
                unableTurn[row.id] = row.turn;
            });
        })
            .catch(function (error) {
                console.log(error);
            });
    }, []);

    return (
        <Box sx={{
            '& > *': {
                marginTop: '20px',
            }
        }}>
            <DetailTable
                data={data}
                setData={setData}
                unableCut={unableCut}
                unableTurn={unableTurn}
                setUnableCut={setUnableCut}
                setUnableTurn={setUnableTurn}
                selectedIds={selectedIds}
                setSelectedIds={setSelectedIds}
                modalStatusOpen={modalStatusOpen}
                setModalStatusOpen={setModalStatusOpen}
                status={status}
                setStatus={setStatus}
                typeStatus={typeStatus}
                setTypeStatus={setTypeStatus}
                isColumnMaterial={false}
                warehouse={'WarehouseFinishedDetail'}
            />
            />
        </Box>
    );
};

export default Cutting;