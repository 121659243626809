import React, {useEffect, useMemo, useState} from 'react';
import MaterialReactTable from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import CurrencyNationalBankService
    from "../../../../../API/orders/warehouse/currency/CurrencyNationalBankService";

const CurrentCurrencyNationalBankTable = ({}) => {

    const [data, setData] = useState([]);
    const [isFullScreen, setIsFullScreen] = useState(false);

    useEffect(() => {
        CurrencyNationalBankService.getAll().then(function (response) {
            setData(response.data);
        })
            .catch(function (error) {
                console.log(error);
            });
    }, []);

    const columns = useMemo(
        () => [
            {
                id: 'curName',
                header: 'Наименование иностранной валюты',
                accessorKey: 'curName',
                size: 8,
                maxSize: 8,
            },
            {
                id: 'curAbbreviation',
                header: 'Аббревиатура',
                accessorKey: 'curAbbreviation',
                size: 8,
                maxSize: 8,
            },
            {
                id: 'curScale',
                header: 'Едениц',
                accessorKey: 'curScale',
                size: 8,
                maxSize: 8,
            },
            {
                id: 'curOfficialRate',
                header: 'Официальный курс',
                accessorKey: 'curOfficialRate',
                size: 8,
                maxSize: 8,
            },
            {
                header: 'id',
                id: 'id',
                accessorKey: 'id',
                enableHiding: false,
                enableColumnActions: false,
                enableGrouping: false,
            },
        ],
        [data, setData],
    );

    return (
        <>
            <MaterialReactTable
                muiTableBodyProps={{
                    sx: () => ({
                        '& tr:nth-of-type(odd)': {
                            backgroundColor: 'lightgray',
                        },
                    }),
                }}
                muiTableBodyCellProps={{
                    sx: {
                        p: '0px 0px 0px 10px',
                        m: '0px 0px 0px 0px',
                    },
                }}
                muiTableBodyRowProps={{
                    sx: {
                        height: '10px',
                    },
                }}
                isFullScreen={isFullScreen}
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        size: 120,
                    },
                }}
                columns={columns}
                data={data}
                initialState={{
                    columnVisibility: {
                        id: false,
                    },
                    isFullScreen: false,
                    density: "compact",
                    pagination: {pageIndex: 0, pageSize: 50},
                }}
                localization={MRT_Localization_RU}
            />
        </>
    );
};

export default CurrentCurrencyNationalBankTable;