export default class ArrivalMaterialUtil {

    static findKeyByValue = (obj, value) => {
        return Object.keys(obj).find(key => obj[key] === value);
    }

    static checkCurrencyAndSupplierAndCountRows(data, rowSelection) {


        const currencies = new Set();
        const suppliers = new Set();

        data.forEach((row) => {
            if (rowSelection[row.id]) {
                currencies.add(row.currencyArrival.id);
                suppliers.add(row.supplier.id);
            }
        });

        return currencies.size === 1 && suppliers.size === 1 && Object.keys(rowSelection).length > 1;
    }
}