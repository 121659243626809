import axios from "axios";
import {apiVersionDetail, gatewayUrl, nameDetail} from "../../Constants";

const detailControllerRestUrl = gatewayUrl + nameDetail + apiVersionDetail + '/customers/orders/'

export default class EdgesControllerService {
    static async getAll(orderId) {
        return axios.get(`${detailControllerRestUrl}${orderId}/edges`);
    }

    static async save(orderId, data) {
        return await axios.post(`${detailControllerRestUrl}${orderId}/edges`, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async applyToSide(orderId, detailId, sides, orderEdgeId) {
        return await axios.post(`${detailControllerRestUrl}${orderId}/edges/sides?detailId=${detailId}?sides=${sides}?orderEdgeId=${orderEdgeId}`, JSON.stringify({}), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async delete(orderId, edgeId) {
        return await axios.delete(`${detailControllerRestUrl}${orderId}/edges/${edgeId}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async edit(orderId, edgeId, data) {
        return await axios.put(`${detailControllerRestUrl}${orderId}/edges/${edgeId}`, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
}
