import React from 'react';
import {Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import Box from "@mui/material/Box";
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import CallMadeIcon from '@mui/icons-material/CallMade';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import LineWeightIcon from '@mui/icons-material/LineWeight';
import ViewAgendaIcon from '@mui/icons-material/ViewAgenda';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import {useNavigate} from "react-router-dom";

const SettingsOrders = () => {

    const navigate = useNavigate();

    const redirectWorkers = () => {
        navigate('/organization/workers');
    };

    const redirectCustomers = () => {
        navigate('/organization/customers');
    };

    const redirectPackagingUnit = () => {
        navigate('/organization/packaging-units');
    };

    const redirectPackagingSet = () => {
        navigate('/organization/packaging-sets');
    };

    const redirectModels = () => {
        navigate('/organization/models');
    };

    const redirectBasicModels = () => {
        navigate('/organization/basic-models');
    };

    const redirectModelHeaders = () => {
        navigate('/organization/model-headers');
    };

    const redirectAttributes = () => {
        navigate('/organization/attributes');
    };

    const redirectUsers = () => {
        navigate('/organization/users');
    };

    const redirectProductionCosts = () => {
        navigate('/organization/productionCosts');
    };

    return (
        <div>
            <Box>
                <nav aria-label="secondary mailbox folders">
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton onClick={redirectCustomers}>
                                <ListItemIcon>
                                    <CallMadeIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Заказчики"/>
                            </ListItemButton>
                        </ListItem>
                    </List>
                </nav>
                <nav aria-label="secondary mailbox folders">
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton onClick={redirectPackagingUnit}>
                                <ListItemIcon>
                                    <LineWeightIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Единицы упаковки"/>
                            </ListItemButton>
                        </ListItem>
                    </List>
                </nav>
                <nav aria-label="secondary mailbox folders">
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton onClick={redirectPackagingSet}>
                                <ListItemIcon>
                                    <WorkspacesIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Комплекты упаковки"/>
                            </ListItemButton>
                        </ListItem>
                    </List>
                </nav>
                <nav aria-label="secondary mailbox folders">
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton onClick={redirectModelHeaders}>
                                <ListItemIcon>
                                    <AccountTreeIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Найменования моделей"/>
                            </ListItemButton>
                        </ListItem>
                    </List>
                </nav>
                <nav aria-label="secondary mailbox folders">
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton onClick={redirectBasicModels}>
                                <ListItemIcon>
                                    <ViewAgendaIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Базовые модели"/>
                            </ListItemButton>
                        </ListItem>
                    </List>
                </nav>
                <nav aria-label="secondary mailbox folders">
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton onClick={redirectModels}>
                                <ListItemIcon>
                                    <ViewAgendaIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Модели"/>
                            </ListItemButton>
                        </ListItem>
                    </List>
                </nav>
                <nav aria-label="secondary mailbox folders">
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton onClick={redirectProductionCosts}>
                                <ListItemIcon>
                                    <EditCalendarIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Операции над материалами"/>
                            </ListItemButton>
                        </ListItem>
                    </List>
                </nav>
                <nav aria-label="secondary mailbox folders">
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton onClick={redirectAttributes}>
                                <ListItemIcon>
                                    <MoreHorizIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Атрибуты заказов"/>
                            </ListItemButton>
                        </ListItem>
                    </List>
                </nav>
                <Divider/>
                <nav aria-label="secondary mailbox folders">
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton onClick={redirectWorkers}>
                                <ListItemIcon>
                                    <AccessibilityIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Сотрудники"/>
                            </ListItemButton>
                        </ListItem>
                    </List>
                </nav>
               {/* <nav aria-label="secondary mailbox folders">
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton onClick={redirectUsers}>
                                <ListItemIcon>
                                    <AccessibilityNewIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Пользователи"/>
                            </ListItemButton>
                        </ListItem>
                    </List>
                </nav>*/}
            </Box>
        </div>
    );
};

export default SettingsOrders;