import React from 'react';
import {BrowserRouter} from "react-router-dom";
import AppRouter from "./components/AppRouter";
import MenuAppBar from "./components/UI/navbar/MenuAppBar";
import {createTheme, ThemeProvider} from "@mui/material";

const theme = createTheme({
    palette: {
        text: {
            main: '#2C3038',
        },
        primary: {
            main: '#3B8BEC',
            light:'#D7E8FA',
            stroke:"#EAEBEA"
        },
        secondary: {
            main: '#B23850',
            add: '#F87B0E',
        },
        success: {
            main: '#2e7d32',
            light: '#4caf50',
        },
        error: {
            main: '#d32f2f',
            light: '#ef5350',
        },
        pencil: {
            main: '#ea8508'
        },
    },
    typography: {
        fontWeight: 700,
        fontFamily: [
            'Manrope',
            'Roboto',
            'Arial',
            'sans-serif',
        ].join(','),
        "fontSize": 16,
    },
});

function App() {
    return (
        <ThemeProvider theme={theme}>
            <MenuAppBar></MenuAppBar>
            <BrowserRouter>
                <AppRouter></AppRouter>
            </BrowserRouter>
        </ThemeProvider>
    );
}

export default App;
