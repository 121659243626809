import axios from "axios";
import {apiVersionOrders, gatewayUrl, nameOrders} from "../../../../../Constants";

const signJobTableUrl = gatewayUrl + nameOrders + apiVersionOrders + '/organizations/sign-jobs'

export default class SignJobService {

    static async getAll() {
        return axios.get(signJobTableUrl);
    }

    static async getAllByMaterialId(materialId) {
        return axios.get(signJobTableUrl + "/by-material-id/" + materialId);
    }
}