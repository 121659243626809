import React, {useMemo} from 'react';
import MaterialReactTable from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import Box from "@mui/material/Box";
import {Button, Tooltip} from "@mui/material";
import {Link} from "react-router-dom";
import {Cached, PlaylistAdd} from "@mui/icons-material";
import CellShortenedName from "../../../orders/organization/estimate/CellShortenedName";
import ModalAddSignJob from "./ModalAddSignJob";

const MaterialTable = ({
                           currentData,
                           setCurrentData,
                           refMaterial,
                           addMaterialInArrival,
                           addMaterial,
                           enableEditing,
                           isAddMaterial,
                           isSelectInArrival,
                           isSelectInEstimate,
                           selectInEstimate,
                           openModalAddSignJob,
                           setOpenModalAddSignJob,
                           handleCloseModalAddSignJob,
                           handleSubmitAddSignJobInMaterial,
                           groupNames,
                           rowSelection,
                           setRowSelection,
                           rowIndexSelection,
                           setRowIndexSelection,
                           selectedIds,
                           setSelectedIds,
                           disabledAddMaterial,
                           dataSignJob,
                           setDataSignJob,
                           change,
                           setChange,
                           selectMaterialId,
                           selectMaterial,
                           tableOptions,
                           tableOptionsHandle
                       }) => {

    const handleOpenModalAddSignJob = () => {
        setOpenModalAddSignJob(true)
    };

    const columns = useMemo(
        () => [
            {
                header: 'Артикул',
                accessorKey: 'articleNumber',
                minSize: 50,
                maxSize: 75,
                Cell: ({cell}) => <CellShortenedName
                    value={cell.getValue()}
                    numberOfCharacters={15}
                />,
            },
            {
                header: 'Найменование',
                accessorKey: 'name',
                minSize: refMaterial ? 800 : 430,
                Cell: ({cell, row}) => (refMaterial ?
                        <li key={cell.getValue()}>
                            <Link
                                to={'/organization/materials/' + currentData[row.index].id + '/' + currentData[row.index].unit.name + '/' + currentData[row.index].typeMaterial + '/' + currentData[row.index].materialId}>
                                {cell.getValue()}</Link>
                        </li> : cell.getValue()
                ),
            },
            {
                header: 'Ед. изм.',
                accessorKey: 'unit.name',
                maxSize: 100,
            },
            {
                header: 'id',
                accessorKey: 'id',
                enableHiding: false,
                enableColumnActions: false,
                enableGrouping: false,
                enableEditing: false,
            },
        ],
        [currentData, setCurrentData, refMaterial],
    );

    return (<div>
        <MaterialReactTable
            muiTableBodyCellProps={{
                sx: {
                    p: '0px 0px 0px 10px',
                    m: '0px 0px 0px 0px',
                    border: "none",
                    "&:not(:last-of-type)": {
                        borderRight: '1px solid #EAEBEA',
                    },
                    "& li": {
                        listStyleType: "none",
                        "& a": {
                            color: "#006CCC"
                        }
                    }
                },
            }}
            muiTableHeadCellProps={{
                sx: {
                    border: '1px solid #EAEBEA',
                    backgroundColor: "#F5F6FA",
                    paddingBottom: " 0.2rem",
                    paddingTop: "0.2rem"
                },
            }}

            muiTableBodyRowProps={({row}) => {
                return ({
                    onClick: () => selectMaterial && selectMaterial(row.original),
                    sx: () => ({
                        height: '36px',
                        cursor: selectMaterial && "pointer",
                        '&:nth-of-type(even)': {
                            backgroundColor: row.original.id === selectMaterialId ? "#D7E8FA" : '#F5F6FA',
                        },
                        backgroundColor: row.original.id === selectMaterialId ? "#D7E8FA" : "white",
                    })
                })
            }}
            displayColumnDefOptions={{
                'mrt-row-actions': {
                    muiTableHeadCellProps: {
                        align: 'center',
                    },
                    size: 120,
                },
            }}
            columns={columns}
            data={currentData}
            localization={MRT_Localization_RU}
            enableEditing={enableEditing}
            renderRowActions={({row}) => (
                <Box sx={{
                    display: 'flex',
                    gap: '0.1rem',
                    justifyContent: 'center',
                    alignItems: 'center',
                    maxWidth: '50px'
                }}>
                    {isSelectInArrival && <Tooltip arrow placement="left" title="Добавить">
                        <Button size="small" sx={{minWidth: 'auto', marginLeft: 'auto'}} color="success"
                                onClick={() => addMaterialInArrival(row)}>
                            <PlaylistAdd sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                        </Button>
                    </Tooltip>}
                    {isSelectInEstimate && <Tooltip arrow placement="left" title="Заменить">
                        <Button size="small" sx={{minWidth: 'auto', marginLeft: 'auto'}} color="pencil"
                                onClick={() => selectInEstimate(row)}>
                            <Cached sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                        </Button>
                    </Tooltip>}
                </Box>
            )}
            renderTopToolbarCustomActions={() => (
                <Box
                    sx={{display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap'}}
                >
                    {isAddMaterial && <Button
                        size="small"
                        color="secondary"
                        onClick={addMaterial}
                        variant="contained"
                    >
                        Добавить материал
                    </Button>}
                    {isAddMaterial && <Button
                        size="small"
                        color="secondary"
                        onClick={handleOpenModalAddSignJob}
                        variant="contained"
                        disabled={disabledAddMaterial}
                    >
                        Добавить группе работы
                    </Button>}
                </Box>
            )}
            onPaginationChange={(updater) => {
                tableOptionsHandle(updater(tableOptions));
            }}
            onGlobalFilterChange={value => tableOptionsHandle({...tableOptions, searchData: value})}
            manualPagination={true}
            rowCount={tableOptions.totalElements}
            state={{
                columnVisibility: {
                    id: false,
                },
                pagination: tableOptions,
                showGlobalFilter: true,
            }}

            muiSearchTextFieldProps={{
                placeholder: `Поиск`,
                sx: {
                    paddingBottom: "32px",
                    "& .MuiOutlinedInput-root": {
                        borderRadius: '12px',
                        border: '1 px solid red',
                        height: 40, width: 290,
                        "& .MuiSvgIcon-root": {
                            color: "#737478"
                        },
                        '& .MuiOutlinedInput-input': {
                            padding: 0
                        }
                    }
                },
                variant: 'outlined',
            }}
            enableToolbarInternalActions={false}
        />
        <ModalAddSignJob
            title={"Добавить работы на группу " + groupNames}
            open={openModalAddSignJob}
            handleClose={handleCloseModalAddSignJob}
            handleSubmit={handleSubmitAddSignJobInMaterial}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            rowIndexSelection={rowIndexSelection}
            setRowIndexSelection={setRowIndexSelection}
            selectedIds={selectedIds}
            setSelectedIds={setSelectedIds}
            data={dataSignJob}
            setData={setDataSignJob}
            change={change}
            setChange={setChange}
        />
    </div>);
};

export default MaterialTable;
