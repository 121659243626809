import React, {useMemo, useState} from 'react';
import MaterialReactTable from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import Box from "@mui/material/Box";
import {Stack} from "@mui/material";

const CustomerEstimateOrganizationCostMaterialTable = ({
                                                           data,
                                                           setData,
                                                           baseCurrency,
                                                           alternativeCurrency
                                                       }) => {
    const [rowSelection, setRowSelection] = useState({});
    const sumAmountAlternativePrice = useMemo(
        () => data.reduce((acc, mEU) => {
            if (mEU.alternativePrice && mEU.alternativePrice.price !== 0) {
                return acc + mEU.alternativePrice.price;
            } else {
                return acc;
            }
        }, 0),
        [data]
    );

    const sumCalculationAmountBasePrice = useMemo(
        () => data.reduce((acc, mEU) => {
            if (mEU.basePrice && mEU.basePrice.price !== 0) {
                return acc + mEU.basePrice.price;
            } else {
                return acc;
            }
        }, 0),
        [data]
    );

    const columns = useMemo(
        () => [
            {
                id: 'articleNumber',
                header: 'Артикул',
                accessorKey: 'articleNumber',
                minSize: 15,
                maxSize: 25,
            },
            {
                id: 'name',
                header: 'Найменование',
                accessorKey: 'name',
                minSize: 50,
                maxSize: 50,
                enableEditing: false,
            },
            {
                id: 'unit.name',
                header: 'Ед. изм.',
                accessorKey: 'unitName',
                minSize: 5,
                maxSize: 5,
            },
            {
                id: 'quantity',
                header: 'Кол.',
                accessorKey: 'quantity',
                minSize: 5,
                maxSize: 5,
            },
            {
                id: 'basePrice',
                header: `Стоимость ${baseCurrency}`,
                accessorKey: 'basePrice',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => {
                    const value = cell.getValue();
                    return value === null || value === undefined ? 'Ожидание' : value.price.toFixed(2)
                },
                Footer: () => (
                    <Stack>
                        <Box color="warning.main">
                            {sumCalculationAmountBasePrice?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2,
                            })} {baseCurrency}
                        </Box>
                    </Stack>
                ),
            },
            {
                id: 'alternativePrice',
                header: `Стоимость ${alternativeCurrency}`,
                accessorKey: 'alternativePrice',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => {
                    const value = cell.getValue();
                    return value === null || value === undefined ? 'Ожидание' : value.price.toFixed(2)
                },
                Footer: () => (
                    <Stack>
                        <Box color="warning.main">
                            {sumAmountAlternativePrice?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2,
                            })} {alternativeCurrency}
                        </Box>
                    </Stack>
                ),
            },
            {
                header: 'id',
                accessorKey: 'id',
                enableColumnActions: false,
                enableGrouping: false,
                enableEditing: false,
                size: 2,
            },
        ],
        [data, setData],
    );

    return (
        <div>
            <MaterialReactTable
                muiTableBodyProps={{
                    sx: () => ({
                        '& tr:nth-of-type(odd)': {
                            backgroundColor: 'lightgray',
                        },
                    }),
                }}
                muiTableBodyCellProps={{
                    sx: {
                        p: '0px 0px 0px 10px',
                        m: '0px 0px 0px 0px',
                    },
                }}
                muiTableBodyRowProps={{
                    sx: {
                        height: '10px',
                    },
                }}
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        size: 15,
                        minSize: 15,
                    },
                }}
                columns={columns}
                data={data}
                rowHeight={13}
                columnSpacing={1}
                initialState={{
                    columnVisibility: {
                        id: false,
                    },
                    isFullScreen: false,
                    density: "compact",
                    pagination: {pageIndex: 0, pageSize: 50},
                }}
                enableEditing={false}
                onRowSelectionChange={setRowSelection}
                state={{rowSelection}}
                enableRowSelection={false}
                getRowId={(originalRow) => originalRow.id}
                selectionPadding={1}
                selectionCss={{
                    backgroundColor: 'lightblue',
                    borderWidth: '2px',
                    borderStyle: 'solid',
                    borderColor: 'blue',
                    borderRadius: '4px',
                }}
                localization={MRT_Localization_RU}
            />
        </div>
    );
};

export default CustomerEstimateOrganizationCostMaterialTable;

