import React, {useEffect, useState} from 'react';
import {Button, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import TextFieldValidation from "../../../../../../components/UI/table/customer-detales/TextFieldValidation";
import {SIDE} from "../../../../../../Constants";
import {hasOnlyDigitsAndSymbols} from "../../../../../../utils/processing/hasOnlyDigits";
import {calcValueOfStringWithVar} from "../../Canvas/CanvasCalcPositions/calcValueOfStringWithVar";

const NotchModalContent = ({onClose, onSubmit, setActivePointId, detailData, calcPrevInnerContour, variables}) => {

    const initFormState = {
        "position": SIDE[0].value,
        "length": "",
        "width": "",
        "depth": '',
        "x": "",
        "y": ""
    }

    const [formState, setFormState] = useState(initFormState)
    const [validationState, setValidationState] = useState({
        "position": "",
        "length": "",
        "width": "",
        "depth": '',
        "x": "",
        "y": ""
    })

    const handleValidationData = () => {
        const newErrors = {...validationState}

        const calcValues = Object.fromEntries(Object.entries(formState).map(([name, value]) => {
            return name === "position" || !hasOnlyDigitsAndSymbols(value) ? [name, value] : [name, calcValueOfStringWithVar(variables, value.toString())]
        }))

        const endX = calcValues.x + calcValues.length
        const endY = calcValues.y + calcValues.width

        Object.entries(calcValues).forEach(([name, value]) => {
            if (name === "depth" && +value > detailData.height) {
                newErrors.depth = `Глубина должна быть < ${detailData.height}`
            } else if (name === "x" && +value === 0) {
                newErrors.x = "'x' должен быть > 0"
            } else if (name === "y" && +value === 0) {
                newErrors.y = "'y' должен быть > 0"
            } else if (name.length && (endX >= detailData.length)) {
                newErrors.length = `'x' + длинна должно быть < ${detailData.length}`
            } else if (name.width && (endY >= detailData.width)) {
                newErrors.width = `'y' + ширина должно быть < ${detailData.width}`
            }
            if (value === "") {
                newErrors[name] = "пустое поле"
            }
        })

        setValidationState(newErrors)

        if (Object.values(newErrors).every(el => el === "")) {
            onSubmit({
                "basePoint": {x: formState.x, y: formState.y},
                "length": formState.length,
                "width": formState.width,
                "depth": formState.depth,
                "position": formState.position
            })
        }
    }
    const inputHandler = (getData) => {
        const data = getData()
        const currState = {...formState, ...data}
        setFormState(currState)
        data.pointId && setActivePointId(data.pointId)

        if ([currState.x, currState.y, currState.width, currState.length].every(str => str.toString().trim() !== '')) {
             calcPrevInnerContour({
                "position": currState.position,
                "length": currState.length,
                "width": currState.width,
                "x": currState.x,
                "y": currState.y,
            }, false)
        }
    }

    return (<>
        <DialogTitle
            textAlign="center">Внутрений контур</DialogTitle>
        <DialogContent style={{paddingBottom: 7}}>
            <FormControl sx={{minWidth: 300}}>
                <TextFieldValidation
                    select
                    name="position"
                    label="Позиция"
                    value={formState.position}
                    setCurrValues={inputHandler}
                    setValidationState={setValidationState}
                >
                    {[SIDE[0], SIDE[1]].map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextFieldValidation>
                <TextFieldValidation
                    name="length"
                    label="Длинна"
                    value={formState.length}
                    setCurrValues={inputHandler}
                    validationError={validationState?.length}
                    setValidationState={setValidationState}
                />
                <TextFieldValidation
                    name={"width"}
                    value={formState?.width}
                    label="Высота"
                    setCurrValues={inputHandler}
                    validationError={validationState?.width}
                    setValidationState={setValidationState}
                />
                <TextFieldValidation
                    name={"depth"}
                    value={formState?.depth}
                    label="Глубина"
                    setCurrValues={inputHandler}
                    validationError={validationState?.depth}
                    setValidationState={setValidationState}
                />
                <TextFieldValidation
                    name={"x"}
                    value={formState?.x}
                    label="X"
                    setCurrValues={inputHandler}
                    validationError={validationState?.x}
                    setValidationState={setValidationState}
                />
                <TextFieldValidation
                    name={"y"}
                    value={formState?.y}
                    label="Y"
                    setCurrValues={inputHandler}
                    validationError={validationState?.y}
                    setValidationState={setValidationState}
                />
            </FormControl>
        </DialogContent>
        <DialogActions sx={{p: '1.25rem'}}>
            <Button size="small" onClick={onClose}> отменить </Button>
            <Button size="small" color="secondary" onClick={handleValidationData} variant="contained">
                выбрать
            </Button>
        </DialogActions>
    </>)
}
export default NotchModalContent
