import React, {useCallback, useEffect, useMemo, useState} from 'react';
import MaterialReactTable from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import Box from "@mui/material/Box";
import {Button, Tooltip} from "@mui/material";
import {Delete, Edit} from "@mui/icons-material";

import {Link, useNavigate} from "react-router-dom";
import PlanService from "../../../../../API/orders/optimization/organization/PlanService";
import {planStateStatuses} from "../../../../../data/PlanState";
import DateTimeUtil from "../../../../../utils/common/DateTimeUtil";
import DateRangeFilter from "../../../filter/DateRangeFilter";
import ModalUpdateStatus from "../ModalUpdateStatus";
import NavigateButton from "../NavigateButton";
import ModalOpenCreatePlanOrOptimization from "./ModalOpenCreatePlanOrOptimization";

const PlanTable = () => {


        const [data, setData] = useState([]);

        const [updateModalOpen, setUpdateModalOpen] = useState(false);
        const [currentEditRowData, setCurrentEditRowData] = useState(null);
        const [newRowData, setNewRowData] = useState(null);
        const [currentAllowedStatuses, setCurrentAllowedStatuses] = useState(['EXPECTATION', 'PLANNED', 'WORK', 'ARCHIVE']);
        const [currentEditRowId, setCurrentEditRowId] = useState(null);
        const [addModalOpen, setAddModalOpen] = useState(false);

        useEffect(() => {

            PlanService.getAllNotArchive().then(function (response) {
                let data = response.data;
                setData(data);
            })
                .catch(function (error) {
                    console.log(error);
                });
        }, []);
        ;

        const handleDeleteRow = useCallback(
            (row) => {
                const id = row.original.id;
                PlanService.delete(id)
                    .then(function () {
                        data.splice(row.index, 1);
                        setData([...data]);
                    })
                    .catch(function (error) {
                        console.log(error);
                    })
            },
            [data],
        );

        const handleUpdateModel = useCallback(
            (row) => {
                setCurrentAllowedStatuses([...currentAllowedStatuses, row.original.planState]);
                setCurrentEditRowData(row.original);
                setCurrentEditRowId(row.index);
                setUpdateModalOpen(true);
            },
            [data, currentEditRowData, setCurrentEditRowData],
        );

        const handleClose = () => {
            currentAllowedStatuses.splice(1, 1);
            setUpdateModalOpen(false)
        };

        const navigate = useNavigate();

        const redirectOrders = () => {
            navigate('/orders');
        };

        const redirectOrdersProduction = () => {
            navigate('/orders-production');
        };

        const redirectRequestsCutting = () => {
            navigate('/requests-cutting');
        };

        const redirectOptimizations = () => {
            navigate('/optimizations');
        };

        const handleCreatePlan = () => {
            const plan = {
                id: null,
                name: "P",
                planState: 'EXPECTATION',
                planned: null,
            }
            setNewRowData(plan);
            setAddModalOpen(true)
        };

        const createPlan = () => {
            PlanService.createPlan(newRowData).then(function (response) {
                handleCreateNewRow(response.data);
                setNewRowData(null);
                setAddModalOpen(false)
            })
                .catch(function (error) {
                    console.log(error);
                });
        };

        const handleCreateNewRow = (values) => {
            data.push(values);
            setData([...data]);
        };

        const columns = useMemo(
            () => [
                {
                    id: 'name',
                    header: 'Найменование',
                    accessorKey: 'name',
                    minSize: 15,
                    maxSize: 15,
                    Cell: ({cell, row}) => (
                        <li key={cell.getValue()}>
                            <Link to={'/plans/' + row.original.id}>
                                {cell.getValue() === null ? '' : cell.getValue()}
                            </Link>
                        </li>
                    ),
                },
                {
                    id: 'requests_cutting',
                    header: 'Заявки на раскрой',
                    accessorKey: 'id',
                    minSize: 55,
                    maxSize: 55,
                    Cell: ({row}) => (
                        <div
                            style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: '100px'}}>
                            <NavigateButton
                                row={row}
                                url={'/requests-cutting/plan/'}
                                suffix={row.original.name}
                            />
                        </div>
                    ),
                },
                {
                    id: 'planState',
                    header: 'Статус',
                    accessorKey: 'planState',
                    minSize: 55,
                    maxSize: 55,
                    Cell: ({cell}) => planStateStatuses[cell.getValue()],
                },
                {
                    accessorFn: (row) => {
                        if (row.planned === null) {
                        } else {
                            return new Date(row.planned)
                        }
                    },
                    id: 'planned',
                    header: 'Дата планирования',
                    sortingFn: 'planned',
                    size: 10,
                    Cell: ({cell}) => {
                        if (cell.getValue() !== undefined) {
                            return cell.getValue().toLocaleDateString();
                        }
                    },
                    filterFn: (row, id, filterValue) => {
                        const startDate = filterValue?.startDate;
                        const endDate = filterValue?.endDate;
                        if (startDate && endDate) {
                            return (
                                DateTimeUtil.checkDateBefore(row.getValue(id), startDate) &&
                                DateTimeUtil.checkDateAfter(row.getValue(id), endDate)
                            );
                        }
                        return true;
                    },
                    Filter: ({column}) => <DateRangeFilter
                        column={column}
                    />,
                },
                {
                    header: 'id',
                    accessorKey: 'id',
                    enableColumnActions: false,
                    enableGrouping: false,
                    enableEditing: false,
                    size: 2,
                    maxSize: 2,
                },
            ],
            [data, setData],
        );

        return (
            <div>
                <MaterialReactTable
                    muiTableBodyProps={{
                        sx: () => ({
                            '& tr:nth-of-type(odd)': {
                                backgroundColor: 'lightgray',
                            },
                        }),
                    }}
                    muiTableBodyCellProps={{
                        sx: {
                            p: '0px 0px 0px 10px',
                            m: '0px 0px 0px 0px',
                        },
                    }}
                    muiTableBodyRowProps={{
                        sx: {
                            height: '10px',
                        },
                    }}
                    displayColumnDefOptions={{
                        'mrt-row-actions': {
                            muiTableHeadCellProps: {
                                align: 'center',
                            },
                            size: 15,
                            minSize: 15,
                        },
                    }}
                    columns={columns}
                    data={data}
                    rowHeight={13}
                    columnSpacing={1}
                    initialState={{
                        columnVisibility: {
                            id: false,
                        },
                        isFullScreen: false,
                        density: "compact",
                        pagination: {pageIndex: 0, pageSize: 50},
                    }}
                    enableEditing
                    renderRowActions={({row, table}) => (
                        <Box sx={{
                            display: 'flex',
                            gap: '0.1rem',
                            justifyContent: 'flex-start',
                            maxWidth: '50px'
                        }}>
                            <Tooltip arrow placement="left" title="Редактировать">
                                <Button size="small" sx={{minWidth: 'auto', marginLeft: 'auto'}} color="pencil"
                                        onClick={() => handleUpdateModel(row)}>
                                    <Edit sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                                </Button>
                            </Tooltip>
                            <Tooltip arrow placement="right" title="Удалить">
                                <Button size="small" sx={{minWidth: 'auto', marginLeft: 'auto'}} color="error"
                                        onClick={() => handleDeleteRow(row)}>
                                    <Delete sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                                </Button>
                            </Tooltip>
                        </Box>
                    )}
                    localization={MRT_Localization_RU}
                    renderTopToolbarCustomActions={({table}) => {
                        return (
                            <div>
                                <Box
                                    sx={{display: 'flex', gap: '0.5rem', p: '0.5rem', flexWrap: 'wrap'}}
                                >
                                    <Button
                                        size="small"
                                        color="secondary"
                                        onClick={redirectOrders}
                                        variant="contained"
                                    >
                                        Заказы
                                    </Button>
                                    <Button
                                        size="small"
                                        color="secondary"
                                        onClick={redirectOrdersProduction}
                                        variant="contained"
                                    >
                                        Производство
                                    </Button>
                                    <Button
                                        size="small"
                                        color="secondary"
                                        onClick={redirectRequestsCutting}
                                        variant="contained"
                                    >
                                        Заявки на раскрой
                                    </Button>
                                    <Button
                                        size="small"
                                        color="secondary"
                                        onClick={redirectOptimizations}
                                        variant="contained"
                                    >
                                        Оптимизации
                                    </Button>
                                    <Button
                                        size="small"
                                        color="success"
                                        onClick={handleCreatePlan}
                                        variant="contained"
                                    >
                                        Создать план
                                    </Button>
                                </Box>
                            </div>
                        );
                    }}
                />
                <ModalUpdateStatus
                    title={'Обновить статус'}
                    data={data}
                    setData={setData}
                    open={updateModalOpen}
                    handleClose={handleClose}
                    currentEditRowId={currentEditRowId}
                    values={currentEditRowData}
                    setValues={setCurrentEditRowData}
                    currentAllowedStatuses={currentAllowedStatuses}
                    statusesList={planStateStatuses}
                    field={'planState'}
                    unableStatus={PlanService.unableStatus}
                />
                <ModalOpenCreatePlanOrOptimization
                    label={'Название плана'}
                    field={'name'}
                    values={newRowData}
                    setValues={setNewRowData}
                    title={'Создать план'}
                    modalOpen={addModalOpen}
                    handleClose={() => setAddModalOpen(false)}
                    handleSubmit={createPlan}
                />
            </div>
        );
    }
;

export default PlanTable;