import axios from "axios";
import ObjectUtil from "../../../../utils/common/ObjectUtil";
import {apiVersionOrders, gatewayUrl, nameOrders} from "../../../../Constants";

const issuingEdgesRestUrl = gatewayUrl + nameOrders + apiVersionOrders + '/organizations/issuing-edges'

export default class IssuingEdgeService {

    static async getAllByOrganizationNotClose() {
        return axios.get(issuingEdgesRestUrl + '/not-close');
    }

    static async createByOrderIds(ids) {
        let data = ObjectUtil.createRequestObjectIds(ids);
        return await axios.post(issuingEdgesRestUrl + '/create-by-orders',
            JSON.stringify(data), {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
    }

    static async deleteOrderByIds(id, ids) {
        let data = ObjectUtil.createRequestObjectIds(ids);
        return await axios.post(issuingEdgesRestUrl + '/' + id + '/delete-orders-by-ids',
            JSON.stringify(data), {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
    }

    static async delete(id) {
        await axios.delete(issuingEdgesRestUrl + '/' + id);
    }

    static async issuanceEdgeByIds(id, ids) {
        let data = ObjectUtil.createRequestObjectIds(ids);
        return await axios.post(issuingEdgesRestUrl + '/' + id + '/issuance-edge',
            JSON.stringify(data), {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
    }

    static async issuanceEdgeRetail(materialWarehouseId, issue, id) {
        return await axios.post(issuingEdgesRestUrl + '/' + id + '/issuance-edge-retail' + "?materialWarehouseId=" + materialWarehouseId + "&issue=" + issue,
            [], {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
    }
}