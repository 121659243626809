import React from 'react';
import {Checkbox} from "@mui/material";

const CellCheckboxCurrentWithUnable = ({id, unable, setUnable, disabled, orderId, unableMethod}) => {
        const handleUnable = (id, status) => {
            unable[id] = status
            setUnable(prevState => ({
                ...prevState,
                [id]: status
            }));
        };

        const handleCheckboxChange = (event) => {
            unableMethod(id, orderId).then(function () {
                for (const key in unable) {
                    unable[key] = false;
                }
                const newValue = event.target.checked;
                handleUnable(id, !newValue)
            })
                .catch(function (error) {
                    console.log(error);
                });
        };

        return (
            <div style={{display: "flex", alignItems: "center", height: "13px", lineHeight: "13px"}}>
                <Checkbox
                    disabled={disabled}
                    onChange={handleCheckboxChange}
                    size="small"
                    checked={unable[id]}
                    color={"success"}
                    sx={{"& .MuiSvgIcon-root": {fontSize: 20}}}
                />
            </div>
        );
    }
;

export default CellCheckboxCurrentWithUnable;