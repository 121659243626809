import axios from "axios";
import {apiVersionOrders, gatewayUrl, nameOrders} from "../../../../Constants";

const restCurrencyNationalBankRestUrl = gatewayUrl + nameOrders + apiVersionOrders + '/organizations/currencies-national-bank'

export default class CurrencyNationalBankService {

    static async getAll() {
        return axios.get(restCurrencyNationalBankRestUrl, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async getByCurAbbreviation(curAbbreviation) {
        return axios.get(restCurrencyNationalBankRestUrl + '/' + curAbbreviation, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
}