import React from 'react';
import CustomerTable from "../../../components/UI/table/orders/organization/customers/CustomerTable";

const Customers = () => {
    return (
        <div>
            <CustomerTable/>
        </div>
    );
};

export default Customers;