import React, {useEffect, useState} from 'react';
import ReservationMaterialEstimateUnitTable
    from "../../../../components/UI/table/warehouse/reservation/ReservationMaterialEstimateUnitTable";
import {useParams} from "react-router-dom";
import {Box} from "@mui/material";
import Typography from "@mui/material/Typography";
import MaterialReserveService from "../../../../API/orders/reservation/MaterialReserveService";

const ReservationMaterialEstimateUnit = () => {

    const [data, setData] = useState([]);
    const [change, setChange] = useState(false);

    const params = useParams();

    const materialEstimateUnitId = params.materialEstimateUnitId;
    const estimateId = params.estimateId;
    const orderId = params.orderId;
    const orderNumber = params.orderNumber;
    const orderHeaderId = params.orderHeaderId;
    const year = params.year;
    const isButtonPurchase = params.isButtonPurchase;
    const isSlab = params.isSlab;
    const articleNumber = params.articleNumber;
    const type = params.type;
    const name = params.name;
    const isIssue = params.isIssue;

    useEffect(() => {
        MaterialReserveService.getAllByMaterialEstimateUnitId(type, materialEstimateUnitId).then(function (response) {
            const data = response.data;
            setData(data);
        })
            .catch(function (error) {
                console.log(error);
            });
    }, [change]);

    return (
        <div>

            <Box
                sx={{
                    display: 'flex',
                    gap: '0.5rem',
                    p: '0.5rem',
                    flexWrap: 'wrap',
                    flexDirection: 'column'
                }}
            >
                <Typography
                    sx={{
                        marginBottom: '3px',
                    }}
                    variant="body1" component="h3">
                    Арикул: {articleNumber}
                </Typography>
                <Typography
                    sx={{
                        marginBottom: '3px',
                    }}
                    variant="body1" component="h3">
                    Найменование: {name}
                </Typography>
            </Box>

            <ReservationMaterialEstimateUnitTable
                data={data}
                setData={setData}
                orderId={orderId}
                nameOrder={orderNumber}
                orderHeaderId={orderHeaderId}
                year={year}
                estimateId={estimateId}
                isButtonPurchase={isButtonPurchase}
                isSlab={isSlab}
                isColumnOrder={false}
                isIssue={isIssue === "true"}
                enableEditing={true}
                materialEstimateUnitId={materialEstimateUnitId}
            />
        </div>
    );
};

export default ReservationMaterialEstimateUnit;