import React from 'react';
import {Dialog} from "@mui/material";
import EdgeModalContent from "./ModalsContent/EdgeModalContent";
import HoleModalContent from "./ModalsContent/HoleModalContent";
import PointModalContent from "./ModalsContent/PointModalContent";
import ArcModalContent from "./ModalsContent/ArcModalContent";
import CutModalContent from "./ModalsContent/CutModalContent";
import NotchModalContent from "./ModalsContent/NotchModalContent";
import NotchRoundModalContent from "./ModalsContent/NotchRoundModalContent";
import GrooveModalContent from "./ModalsContent/GrooveModalContent";
import QuarterModalContent from "./ModalsContent/QuarterModalContent";

const ModalProcessing = ({
                             type,
                             onClose,
                             onSubmit,
                             edgesData,
                             detailData,
                             editableElementId,
                             activePointId,
                             setActivePointId,
                             openConfirmModal,
                             cutData,
                             setCutData,
                             calcPrevInnerContour,
                             getPrevContour,
                             refCanvas,
                             variables
                         }) => {
    const getContent = () => {
        switch (type) {
            case 'addEdge':
            case 'editEdge':
                return <EdgeModalContent type={type} edgesData={edgesData} detailData={detailData} onClose={onClose}
                                         onSubmit={onSubmit}
                                         editableElementId={editableElementId}
                                         openConfirmModal={openConfirmModal}/>
            case 'addHole':
            case "editHole":
                return <HoleModalContent editableElementId={editableElementId}
                                         onClose={onClose} onSubmit={onSubmit} detailData={detailData}
                                         openConfirmModal={openConfirmModal}
                                         variables={variables}/>
            case "addPoint":
            case "editPoint":
                return <PointModalContent type={type} activePointId={activePointId}
                                          onClose={onClose} onSubmit={onSubmit} detailData={detailData}
                                          setActivePointId={setActivePointId}
                                          variables={variables}
                />
            case "arc":
            case "round":
                return <ArcModalContent type={type} onClose={onClose} onSubmit={onSubmit}
                                        detailData={detailData}
                                        activePointId={activePointId}
                                        editableElementId={editableElementId}
                                        setActivePointId={setActivePointId}
                                        cutData={cutData}
                                        getPrevContour={getPrevContour}
                                        variables={variables}/>
            case "cut":
                return <CutModalContent onClose={onClose} onSubmit={onSubmit}
                                        detailData={detailData}
                                        activePointId={activePointId}
                                        editableElementId={editableElementId}
                                        setActivePointId={setActivePointId}
                                        cutData={cutData}
                                        setCutData={setCutData}/>
            case "notch":
                return <NotchModalContent onClose={onClose} onSubmit={onSubmit}
                                          setActivePointId={setActivePointId}
                                          calcPrevInnerContour={calcPrevInnerContour}
                />
            case "notchRound":
            case "editNotchRound":
                return <NotchRoundModalContent type={type} detailData={detailData}
                                               onClose={onClose} onSubmit={onSubmit}
                                               editableElementId={editableElementId}
                                               openConfirmModal={openConfirmModal}
                                               setActivePointId={setActivePointId}
                                               calcPrevInnerContour={calcPrevInnerContour}
                                               variables={variables}
                />
            case "groove":
            case "editGroove":
                return <GrooveModalContent type={type} detailData={detailData}
                                           onClose={onClose} onSubmit={onSubmit}
                                           editableElementId={editableElementId}
                                           openConfirmModal={openConfirmModal}
                                           setActivePointId={setActivePointId}
                                           calcPrevInnerContour={calcPrevInnerContour}
                                           variables={variables}/>
            case "quarter":
            case "editQuarter":
                return <QuarterModalContent type={type} onClose={onClose}
                                            onSubmit={onSubmit}
                                            detailData={detailData}
                                            editableElementId={editableElementId}
                                            openConfirmModal={openConfirmModal}
                                            setActivePointId={setActivePointId}
                                            points={cutData.possible}
                                            activePointId={activePointId}
                                            calcPrevInnerContour={calcPrevInnerContour}
                                            variables={variables}
                                           />
        }
    }

    return <Dialog
        open={!!type}
        PaperProps={{
            style: {
                minWidth: (type === "addEdge" || type === "editEdge") && "870px",
                position: 'absolute',
                left: refCanvas?.current?.clientWidth - refCanvas?.current?.offsetLeft,
            }
        }}
        BackdropProps={{
            style: {
                opacity: 0.1,
            },
        }}>
        {getContent()}
    </Dialog>
}
export default ModalProcessing;
