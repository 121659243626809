import React from 'react';
import ArchiveTable from "../../../components/UI/table/orders/organization/archive/ArchiveTable";
import Typography from "@mui/material/Typography";

const Archive = () => {
    return (
        <div>
            <Typography variant="h6" component="h6">
                Архив заказов
            </Typography>
            <ArchiveTable/>
        </div>
    );
};

export default Archive;