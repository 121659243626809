import React from 'react';
import BasicModelsTable from "../../../../components/UI/table/model-estimate/model/BasicModelsTable";
import BasicModelService from "../../../../API/model-estimate/model/BasicModelService";

const BasicModels = () => {
    return (
        <div>
            <BasicModelsTable
                isColumnUnable={true}
                isButtonUpdate={true}
                isButtonSettings={true}
                isButtonCreateModel={true}
                isButtonDelete={true}
                isButtonEstimate={true}
                isColumnPackaging={true}
                enableRowSelection={true}
                isButtonModels={false}
                getAll={BasicModelService.getAll}
                upLoad={true}
            />
        </div>
    );
};

export default BasicModels;