import React from 'react';
import {useParams} from "react-router-dom";
import ArrivalMaterialPriceUnitTableArchive
    from "../../../../../components/UI/table/warehouse/material/arrival/ArrivalMaterialPriceUnitTableArchive";

const ArrivalMaterialPriceUnitArchive = () => {

    const params = useParams();

    return (
        <div>
            <ArrivalMaterialPriceUnitTableArchive
                arrivalMaterialId={params.arrivalMaterialId}
                currentCurrency={params.currentCurrency}
                baseCurrency={params.baseCurrency}
                alternativeCurrency={params.alternativeCurrency}
            />
        </div>
    );
};

export default ArrivalMaterialPriceUnitArchive;