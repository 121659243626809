import React, {useMemo} from 'react';
import MaterialReactTable from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import {Link, useNavigate} from 'react-router-dom';
import HeaderOPT from "./HeaderOPT";
import DateRangeFilter from "../../../../filter/DateRangeFilter";
import Box from "@mui/material/Box";
import {Button} from "@mui/material";
import CellOPTm from "./CellOPTm";
import DateTimeUtil from "../../../../../../utils/common/DateTimeUtil";

const OrderProductionTable = ({
                                  additionallyColumn,
                                  data,
                                  setData,
                                  customerList,
                                  nameList,
                                  planningList,
                                  isColumnPlan,
                                  isIssue
                              }) => {

    const navigate = useNavigate();

    const redirectPlans = () => {
        navigate('/organization/plans');
    };

    const columns = useMemo(() => {
        const baseColumns = [
            {
                id: 'number',
                header: 'Номер заказа',
                accessorKey: 'number',
                filterFn: 'contains',
                maxSize: 23,
                minSize: 35,
                sx: {
                    p: '0px 0px 0px 10px',
                    m: '0px 0px 0px 0px',
                },
                Header: ({column}) => <HeaderOPT column={column} height={400}></HeaderOPT>,
                Cell: ({cell, row}) => (isIssue ? <li key={cell.getValue()}>
                            <Link
                                to={'/organization/issuance-piece-materials/' + data[row.index].id + '/' + cell.getValue()}>{cell.getValue()}</Link>
                        </li> :
                        <li key={cell.getValue()}>
                            <Link
                                to={'/organization/technical-map/' + data[row.index].id + '/' + cell.getValue()}>{cell.getValue()}</Link>
                        </li>
                ),
            },
            {
                id: 'customerName',
                header: 'Заказчик',
                accessorKey: 'customerName',
                enableStickyHeader: true,
                enableStickyFooter: true,
                enableGrouping: true,
                filterVariant: 'multi-select',
                filterSelectOptions: customerList,
                minSize: 15,
                maxSize: 150,
                Header: ({column}) => <HeaderOPT column={column} height={400}></HeaderOPT>,
            },
            {
                id: 'header',
                header: 'Найменование',
                accessorKey: 'header',
                filterVariant: 'multi-select',
                filterSelectOptions: nameList,
                enableGrouping: true,
                minSize: 15,
                Header: ({column}) => <HeaderOPT column={column} height={400}></HeaderOPT>,
            },
            /* {
                 id: 'attributes',
                 header: 'Атрибуты',
                 accessorKey: 'attributes',
                 filterVariant: 'multi-select',
                 filterSelectOptions: attributeList,
                 filterFn: (row, id, filterValue) => {
                     return OrderUtil.checkStatus(row.getValue(id), filterValue)
                 },
                 minSize: 20,
                 Cell: ({cell}) => (<div>{AttributeUtil.convertAttributes(cell.getValue())}</div>),
                 Header: ({column}) => <HeaderOPT column={column} height={400}></HeaderOPT>,

             },*/
            {
                accessorFn: (row) => {
                    if (row.dateOfPlannedProduction === null) {
                    } else {
                        return new Date(row.dateOfPlannedProduction)
                    }
                },
                id: 'dateOfPlannedProduction',
                header: 'Плановая дата произодства',
                sortingFn: 'datetime',
                size: 10,
                Cell: ({cell}) => {
                    if (cell.getValue() !== undefined) {
                        return cell.getValue().toLocaleDateString();
                    }
                },
                Header: ({column}) => <HeaderOPT column={column} height={400}></HeaderOPT>,
                filterFn: (row, id, filterValue) => {
                    const startDate = filterValue?.startDate;
                    const endDate = filterValue?.endDate;
                    if (startDate && endDate) {
                        return (
                            DateTimeUtil.checkDateBefore(row.getValue(id), startDate) &&
                            DateTimeUtil.checkDateAfter(row.getValue(id), endDate)
                        );
                    }
                    return true;
                },
                Filter: ({column}) => <DateRangeFilter
                    column={column}
                />,
            },
            {
                header: 'id',
                accessorKey: 'id',
                enableHiding: false,
                enableColumnActions: false,
                enableGrouping: false,
                enableColumnFilter: false,
            },
        ];

        {
            additionallyColumn.map((statusKey) => (
                baseColumns.splice(5, 0, {
                    id: statusKey.name,
                    header: statusKey.name,
                    accessorKey: 'id',
                    minSize: 7,
                    maxSize: 7,
                    enableColumnActions: false,
                    enableGrouping: false,
                    enableColumnFilter: false,
                    Header: ({column}) => <HeaderOPT column={column} height={400}></HeaderOPT>,
                    Cell: ({row, cell}) => <CellOPTm
                        row={row}
                        cell2={cell}
                    >
                    </CellOPTm>,
                })
            ))
        }

        if (isColumnPlan) {
            baseColumns.splice(5, 0, {
                id: 'state',
                header: 'Планирование',
                accessorKey: 'state',
                filterVariant: 'multi-select',
                filterSelectOptions: planningList,
                minSize: 50,
                maxSize: 6,
                Header: ({column}) => <HeaderOPT column={column} height={400}></HeaderOPT>,
            });
        }

        return baseColumns;

    }, [data, setData, isColumnPlan, additionallyColumn]);

    return (
        <MaterialReactTable
            sx={{
                minWidth: 'fit-content',
            }}
            enableColumnFilterModes
            enableGrouping
            enableStickyHeader
            enablePinning
            muiTableBodyProps={{
                sx: () => ({
                    '& tr:nth-of-type(odd)': {
                        backgroundColor: 'lightgray',
                    },
                }),
            }}
            muiTableBodyCellProps={{
                sx: {
                    p: '0px 0px 0px 0px',
                    m: '0px 0px 0px 0px',
                },
            }}
            muiTableBodyRowProps={{
                sx: {
                    height: '10px',
                },
            }}
            displayColumnDefOptions={{
                'mrt-row-actions': {
                    muiTableHeadCellProps: {
                        align: 'center',
                    },
                    size: 15,
                    minSize: 15,
                },
            }}
            rowHeight={13}
            columnSpacing={0}
            columns={columns}
            data={data}
            initialState={{
                columnVisibility: {
                    id: false,
                    customerId: false,
                    dateOfPlannedProduction: false
                },
                isFullScreen: false,
                density: "compact",
                pagination: {pageIndex: 0, pageSize: 100},
                columnPinning: {left: ['number']},
            }}
            muiToolbarAlertBannerChipProps={{color: 'primary'}}
            localization={MRT_Localization_RU}
            renderTopToolbarCustomActions={({table}) => {
                return (
                    <div>
                        <Box
                            sx={{
                                display: 'flex',
                                gap: '0.5rem',
                                paddingLeft: '0.5rem',
                                paddingTop: '0.5rem',
                                paddingBottom: '0.2rem',
                                flexWrap: 'wrap',
                            }}
                        >
                            <Button
                                size="small"
                                color="secondary"
                                onClick={redirectPlans}
                                variant="contained"
                            >
                                Планы
                            </Button>
                        </Box>
                    </div>
                );
            }}
        />
    );
};

export default OrderProductionTable;