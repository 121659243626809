import React from 'react';
import PackagingSetTable from "../../../../components/UI/table/model-estimate/packaging/PackagingSetTable";

const PackagingSet = () => {
    return (
        <div>
            <PackagingSetTable/>
        </div>
    );
};

export default PackagingSet;