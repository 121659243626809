export const calcEndHoleOutline = (hole, radius) => {
    if (hole.contour.position === "LEFT") {
        return [
            [[hole.center.x, hole.center.y - radius], [hole.center.x, hole.center.y + radius]],
            [[hole.center.x, hole.center.y + radius], [hole.center.x + hole.depth, hole.center.y + radius]],
            [[hole.center.x + hole.depth, hole.center.y + radius], [hole.center.x + hole.depth, hole.center.y - radius]],
            [[hole.center.x + hole.depth, hole.center.y - radius], [hole.center.x, hole.center.y - radius]]
        ]
    } else if (hole.contour.position === "RIGHT") {
        return [
            [[hole.center.x, hole.center.y - radius], [hole.center.x, hole.center.y + radius]],
            [[hole.center.x, hole.center.y + radius], [hole.center.x - hole.depth, hole.center.y + radius]],
            [[hole.center.x - hole.depth, hole.center.y + radius], [hole.center.x - hole.depth, hole.center.y -radius]],
            [[hole.center.x - hole.depth, hole.center.y - radius], [hole.center.x, hole.center.y - radius]]
        ]
    } else if (hole.contour.position === "FRONT") {
        return [
            [[hole.center.x - radius, hole.center.y], [hole.center.x -radius, hole.center.y + hole.depth]],
            [[hole.center.x - radius, hole.center.y + hole.depth], [hole.center.x + radius, hole.center.y + hole.depth]],
            [[hole.center.x + radius, hole.center.y + hole.depth], [hole.center.x + radius, hole.center.y]],
            [[hole.center.x + radius, hole.center.y], [hole.center.x - radius, hole.center.y]]
        ]
    } else {
        return [
            [[hole.center.x - radius, hole.center.y], [hole.center.x - radius, hole.center.y - hole.depth]],
            [[hole.center.x - radius, hole.center.y - hole.depth], [hole.center.x + radius, hole.center.y - hole.depth]],
            [[hole.center.x + radius, hole.center.y - hole.depth], [hole.center.x + radius, hole.center.y]],
            [[hole.center.x + radius, hole.center.y], [hole.center.x - radius, hole.center.y]]
        ]
    }

}
