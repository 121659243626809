import React, {useState} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import SignJobTable from "../../../prodaction/productioncosts/SignJobTable";

const ModalAddSignJob = ({
                             open,
                             handleClose,
                             title,
                             handleSubmit,
                             rowSelection,
                             setRowSelection,
                             rowIndexSelection,
                             setRowIndexSelection,
                             selectedIds,
                             setSelectedIds,
                             data,
                             setData,
                             change,
                             setChange,
                         }) => {

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="xl"
        >
            <DialogTitle textAlign="center">{title}</DialogTitle>
            <Typography textAlign="center" variant="subtitle2" color="text.secondary">
                {"Предыдущие настройки удаляются"}
            </Typography>
            <DialogContent>
                <form onSubmit={(e) => e.preventDefault()}>
                    <Stack
                        sx={{
                            width: '100%',
                            minWidth: {xs: '300px', sm: '360px', md: '400px'},
                            gap: '0.01rem',
                        }}
                    >
                        <SignJobTable
                            data={data}
                            setData={setData}
                            change={change}
                            setChange={setChange}
                            handleSubmitAddSign={handleSubmit}
                            rowSelection={rowSelection}
                            setRowSelection={setRowSelection}
                            rowIndexSelection={rowIndexSelection}
                            setRowIndexSelection={setRowIndexSelection}
                            selectedIds={selectedIds}
                            setSelectedIds={setSelectedIds}
                            isChooseSignJob={true}
                        />
                    </Stack>
                </form>
            </DialogContent>
            <DialogActions sx={{p: '1.25rem'}}>
                <Button onClick={handleClose}>Выйти</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ModalAddSignJob;