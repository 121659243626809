import React, {useEffect, useState} from 'react';
import {Box, Button, CircularProgress, ImageList, ImageListItem} from "@mui/material";
import {Pagination} from "@mui/lab";

const CuttingImageGallery = ({
                                 images,
                                 isLoading,
                                 setOpenModalImageGallery,
                                 height,
                                 weight,
                                 buttonClose,
                                 currentPage,
                                 setCurrentPage
                             }) => {

    const itemsPerPage = 1;
    const totalPages = images ? Math.ceil(images.length / itemsPerPage) : 0;
    const [displayedImages, setDisplayedImages] = useState(images ? images.slice(0, itemsPerPage) : []);

    useEffect(() => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        setDisplayedImages(images.slice(startIndex, endIndex));
    }, [currentPage, images]);

    const handlePageChange = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const handleImageClick = (index) => {
        setOpenModalImageGallery(true);
    };

    const close = () => {
        setOpenModalImageGallery(false);
    };

    return (
        <div>
            <ImageList sx={{
                position: 'absolute',
                top: '46%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: weight,
                height: height,
            }} cols={1}>
                {displayedImages.map((image, index) => (
                    <ImageListItem key={index} onClick={() => handleImageClick(index)}>
                        <img src={image.src} alt={index}/>
                    </ImageListItem>
                ))}
            </ImageList>
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)'
            }}>
                {isLoading && (
                    <CircularProgress color="secondary" size={60} thickness={4}/>
                )}
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    gap: '0.5rem',
                    paddingLeft: '0.5rem',
                    paddingTop: '0.5rem',
                    paddingBottom: '0.2rem',
                    flexWrap: 'wrap',
                }}
            >
                <Pagination size="small" sx={{
                    position: 'absolute',
                    bottom: 0,
                    left: '50%',
                    transform: 'translateX(-50%)',
                    margin: 0,
                    marginBottom: '3px',
                }} color="primary" variant="outlined" shape="rounded" count={totalPages} page={currentPage}
                            onChange={handlePageChange}/>
                {buttonClose && <Button
                    sx={{
                        position: 'absolute',
                        bottom: 0,
                        left: '78%',
                        transform: 'translateX(-50%)',
                        margin: 0,
                        marginBottom: '5px',
                    }}
                    onClick={close}
                    variant="contained" color="secondary"
                >Выйти</Button>}
            </Box>
        </div>
    );
};

export default CuttingImageGallery;
