import React, {useEffect} from 'react';
import {Dialog, DialogContent, Slide, SxProps} from "@mui/material";
import Typography from "@mui/material/Typography";

const infoSlide = React.forwardRef((props, ref) =>
    <Slide direction="left" {...props} ref={ref}/>);

const InfoModal = ({open, setOpen, message}) => {

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (open) {
            setTimeout(() => {
                handleClose();
            }, 2000);
        }
    }, [open]);

   /* const sx: SxProps = {
        "& .MuiDialog-container": {
            alignItems: "flex-end",
        }
    };*/

    return (
        <div>
            <div>
                <Dialog
                    open={open}
                    TransitionComponent={infoSlide}
                    keepMounted
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                    /*sx={sx}*/
                    PaperProps={{
                        sx: {
                            background: (theme) => `${theme.palette.success.light}`,
                            m: 0,
                            top: -50,
                            left: 600
                        }
                    }}
                >
                    <DialogContent>
                        <Typography variant="h6" component="h6">
                            {message}
                        </Typography>
                    </DialogContent>
                </Dialog>
            </div>
        </div>
    );
};

export default InfoModal;
