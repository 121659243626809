import React, {useEffect, useState} from 'react';
import {Stack} from "@mui/material";
import Box from "@mui/material/Box";
import {statusesYesOrNo} from "../../../../data/StatusesYesOrNo";
import MaterialPriceUnitService from "../../../../API/orders/warehouse/MaterialPriceUnitService";
import TextFieldArrivalMaterialModuleItem from "./TextFieldArrivalMaterialModuleItem";
import DateTimeUtil from "../../../../utils/common/DateTimeUtil";

const ArrivalMaterialModuleItem = ({
                                       id,
                                       isSlabMaterial,
                                       baseUnit,
                                   }) => {
    const [data, setData] = useState([]);


    useEffect(() => {

        MaterialPriceUnitService.getByIdWithPrices(id).then(function (response) {
            setData(response.data);
        })
            .catch(function (error) {
                console.log(error);
            });
    }, []);

    const isSlab = data && data.typeMaterial === 'SLAB';

    return (
        <div>
            <Box>
                <Stack
                    sx={{
                        width: '100%',
                        minWidth: {xs: '300px', sm: '360px', md: '400px'},
                        maxWidth: '1200px',
                    }}
                >
                    <TextFieldArrivalMaterialModuleItem
                        label="Ед. измерения"
                        value={baseUnit}
                        field={baseUnit}
                        maxWidth={'220px'}
                    />
                    {isSlabMaterial && <TextFieldArrivalMaterialModuleItem
                        label="Обезок"
                        value={statusesYesOrNo[data.cutOff]}
                        field={'cutOff'}
                        maxWidth={'220px'}
                    />}
                    {isSlabMaterial && <Box sx={{display: 'flex', gap: '0.5rem', flexWrap: 'wrap'}}>
                        <TextFieldArrivalMaterialModuleItem
                            label="Ширина"
                            value={data.length}
                            field={'length'}
                            maxWidth={'220px'}
                        />
                        <TextFieldArrivalMaterialModuleItem
                            label="Высота"
                            value={data.width}
                            field={'width'}
                            maxWidth={'220px'}
                        />
                    </Box>}
                    {data.dateTime && <TextFieldArrivalMaterialModuleItem
                        label="Дата и время прихода"
                        value={DateTimeUtil.dateToString(data.dateTime)}
                        field={'dateTime'}
                        maxWidth={'220px'}
                    />}
                    <TextFieldArrivalMaterialModuleItem
                        label="Количество"
                        value={data.quantity}
                        field={'quantity'}
                        maxWidth={'220px'}
                    />
                    {data.materialPriceInCurrencyArrival && <TextFieldArrivalMaterialModuleItem
                        label="Цена в валюте прихода"
                        value={data.materialPriceInCurrencyArrival.price}
                        field={'materialPriceInCurrencyArrival.price'}
                        maxWidth={'220px'}
                    />}
                    <TextFieldArrivalMaterialModuleItem
                        label="Сумма в валюте прихода"
                        value={data.amountInCurrencyArrival}
                        field={'amountInCurrencyArrival'}
                        maxWidth={'220px'}
                    />
                    <Box sx={{display: 'flex', gap: '0.5rem', flexWrap: 'wrap'}}>
                        <TextFieldArrivalMaterialModuleItem
                            label="Курс НБРБ к базовой цене"
                            value={data.officialExchangeRateToBasePrice}
                            field={'officialExchangeRateToBasePrice'}
                            maxWidth={'220px'}
                        />
                        <TextFieldArrivalMaterialModuleItem
                            label="Реальный курс к базовой цене"
                            value={data.exchangeRateToBasePrice}
                            field={'exchangeRateToBasePrice'}
                            maxWidth={'220px'}
                        />
                        <TextFieldArrivalMaterialModuleItem
                            label="Кф. первышения реал. курса"
                            value={data.coefficientCurrencyBasePrice}
                            field={'coefficientCurrencyBasePrice'}
                            maxWidth={'220px'}
                        />
                    </Box>
                    <Box sx={{display: 'flex', gap: '0.5rem', flexWrap: 'wrap'}}>
                        {data.materialPriceInRealBasePrice && <TextFieldArrivalMaterialModuleItem
                            label="Реальная базовая цена"
                            value={data.materialPriceInRealBasePrice.price}
                            field={'materialPriceInRealBasePrice.price'}
                            maxWidth={'220px'}
                        />}
                        {data.materialPriceInRealBasePrice && data.materialPriceInRealBasePrice.currency &&
                            <TextFieldArrivalMaterialModuleItem
                                label="Валюта"
                                value={data.materialPriceInRealBasePrice.currency.curAbbreviation}
                                field={'materialPriceInRealBasePrice.price.currency'}
                                maxWidth={'220px'}
                            />}
                        <TextFieldArrivalMaterialModuleItem
                            label="Потери"
                            value={data.losses}
                            field={'losses'}
                            maxWidth={'220px'}
                        />
                    </Box>
                    <Box sx={{display: 'flex', gap: '0.5rem', flexWrap: 'wrap'}}>
                        <TextFieldArrivalMaterialModuleItem
                            label="Курс НБРБ к базовой валюте"
                            value={data.officialExchangeRateToBaseCurrencyBaseMaterials}
                            field={'officialExchangeRateToBaseCurrencyBaseMaterials'}
                            maxWidth={'220px'}
                        />
                        <TextFieldArrivalMaterialModuleItem
                            label="Реальный курс к базовой валюте"
                            value={data.exchangeRateToBaseCurrencyBaseMaterials}
                            field={'exchangeRateToBaseCurrencyBaseMaterials'}
                            maxWidth={'220px'}
                        />
                    </Box>
                    <Box sx={{display: 'flex', gap: '0.5rem', flexWrap: 'wrap'}}>
                        {data.materialPriceInCurrencyBase && <TextFieldArrivalMaterialModuleItem
                            label="Цена в базовой валюте"
                            value={data.materialPriceInCurrencyBase.price}
                            field={'materialPriceInCurrencyBase.price'}
                            maxWidth={'220px'}
                        />}
                        {data.materialPriceInCurrencyBase && data.materialPriceInCurrencyBase.currency &&
                            <TextFieldArrivalMaterialModuleItem
                                label="Валюта"
                                value={data.materialPriceInCurrencyBase.currency.curAbbreviation}
                                field={'materialPriceInCurrencyBase.price.currency'}
                                maxWidth={'220px'}
                            />}
                    </Box>
                    <Box sx={{display: 'flex', gap: '0.5rem', flexWrap: 'wrap'}}>
                        <TextFieldArrivalMaterialModuleItem
                            label="Курс НБРБ к альт. валюте"
                            value={data.officialExchangeRateToAlternativeCurrencyBaseMaterials}
                            field={'officialExchangeRateToAlternativeCurrencyBaseMaterials'}
                            maxWidth={'220px'}
                        />
                        <TextFieldArrivalMaterialModuleItem
                            label="Реальный курс к альт. вал."
                            value={data.exchangeRateToAlternativeCurrencyBaseMaterials}
                            field={'exchangeRateToAlternativeCurrencyBaseMaterials'}
                            maxWidth={'220px'}
                        />
                    </Box>
                    <Box sx={{display: 'flex', gap: '0.5rem', flexWrap: 'wrap'}}>
                        {data.materialPriceInCurrencyAlternative && <TextFieldArrivalMaterialModuleItem
                            label="Цена в альтернативной валюте"
                            value={data.materialPriceInCurrencyAlternative.price}
                            field={'materialPriceInCurrencyAlternative.price'}
                            maxWidth={'220px'}
                        />}
                        {data.materialPriceInCurrencyAlternative && data.materialPriceInCurrencyAlternative.currency &&
                            <TextFieldArrivalMaterialModuleItem
                                label="Валюта"
                                value={data.materialPriceInCurrencyAlternative.currency.curAbbreviation}
                                field={'materialPriceInCurrencyAlternative.price.currency'}
                                maxWidth={'220px'}
                            />}
                    </Box>
                </Stack>
            </Box>
        </div>
    );
};

export default ArrivalMaterialModuleItem;