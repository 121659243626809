import axios from "axios";
import {apiVersionMaterialCloud, gatewayUrl, nameMaterialCloud} from "../../../Constants";

const userSupplierMaterialRestUrl = gatewayUrl + nameMaterialCloud + apiVersionMaterialCloud + 'user/supplier-materials'

export default class UserSupplierMaterialCloudService {

    static async getAllCurrentWholeByMaterialId(materialId) {
        return axios.get(userSupplierMaterialRestUrl + '/material/' + materialId + '/current', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async getAllCurrentByMaterialIdMinStripe(materialId) {
        return axios.get(userSupplierMaterialRestUrl + '/material/' + materialId + '/current-min-stripe', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async getAllCurrentByMaterialIdFixedStripe(materialId) {
        return axios.get(userSupplierMaterialRestUrl + '/material/' + materialId + '/current-fixed-stripe', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async getAllByMaterialId(materialId) {
        return axios.get(userSupplierMaterialRestUrl + '/material/' + materialId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async getAllByMaterialIdMinStripe(materialId) {
        return axios.get(userSupplierMaterialRestUrl + '/material/' + materialId + '/min-stripe', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async getAllByMaterialIdFixedStripe(materialId) {
        return axios.get(userSupplierMaterialRestUrl + '/material/' + materialId + '/fixed-stripe', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async getAll() {
        return axios.get(userSupplierMaterialRestUrl, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
}