import React from 'react';
import {Tooltip} from "@mui/material";

const CellMaterialReserveOptimizationQuantity = ({value, row}) => {

    const isSlab = row.original.materialOrganizationOption.typeMaterial === 'SLAB'
    const quantity = row.original.quantity;
    const factualQuantity = row.original.factualQuantity;
    const equally = quantity === factualQuantity;
    const isFactualQuantity = factualQuantity !== null;
    const requestsCutting = row.original.requestsCutting
    const isExpectation = isSlab ? (requestsCutting !== null ? requestsCutting.requestsCuttingState === 'EXPECTATION' : false) : false;
    const currentValue = isFactualQuantity ? (equally ? '-' : factualQuantity.toFixed(3)) : (isSlab ? (value ? value.toFixed(3) : 'Ожидание') : '-');
    const titleSlab = isSlab ? (isExpectation ? 'Ожидание' : "Площадь раскроенных карт") : 'только для заявок на раскрой'
    const titleFactual = isSlab ? "Площадь раскроенных карт" : "Количество по выдаче"
    const title = isFactualQuantity ? (equally ? 'Количество по заявке' : titleFactual) : titleSlab

    return (
        <div>
            <Tooltip arrow placement="right" title={title}>
                <span
                    size="small"
                    sx={{minWidth: 'auto', marginLeft: 'auto'}}
                    color="error">
                   {currentValue}
                </span>
            </Tooltip>
        </div>
    );
};

export default CellMaterialReserveOptimizationQuantity;