import axios from "axios";
import {apiVersionMaterialCloud, gatewayUrl, nameMaterialCloud} from "../../../Constants";

const adminMaterialCloudFileRestUrl = gatewayUrl + nameMaterialCloud + apiVersionMaterialCloud + '/admin/files'

export default class AdminMaterialCloudFileService {

    static getFormData(selectedFile) {
        const formData = new FormData();
        formData.append("selectedFile", selectedFile);
        return formData;
    }

    static async downloadGroupFile(selectedFile) {
        return await axios.post(adminMaterialCloudFileRestUrl + '/upload/material-group',
            AdminMaterialCloudFileService.getFormData(selectedFile));
    }

    static async downloadMaterialsFile(selectedFile) {
        return await axios.post(adminMaterialCloudFileRestUrl + '/upload/materials',
            AdminMaterialCloudFileService.getFormData(selectedFile));
    }
}