export default class AttributeUtil {

    static convertAttributes(attributes) {

        let count = attributes.length;
        let i = 0;
        let resultAttributes = "";

        for (const attribute of attributes) {
            i++;
            resultAttributes += attribute.name;
            if (i !== count) {
                resultAttributes += ", "
            }
        }
        return resultAttributes;
    }

    static getDefaultAttribute(attributes) {
        let defaultAttribute;
        for (const attribute of attributes) {
            if (attribute.enabledDefault) {
                defaultAttribute = attribute;
                break;
            }
        }
        return defaultAttribute
    }
}