import axios from "axios";
import {
    apiVersionAuthorizationServer,
    apiVersionOrders,
    gatewayUrl,
    nameAuthorizationServer,
    nameOrders
} from "../../../../Constants";

const customerRestUrl = gatewayUrl + nameOrders + apiVersionOrders + '/organizations/customers'
const customerAuthRestUrl = gatewayUrl + nameAuthorizationServer + apiVersionAuthorizationServer + '/organization/customers'

export default class OrganizationCustomerService {

    static async getAll() {
        return axios.get(customerRestUrl);
    }

    static async profile() {
        return axios.get(gatewayUrl + nameAuthorizationServer+ "/api/v1/users/profile");
    }

    static async profileHead() {
        return axios.head(gatewayUrl + nameAuthorizationServer+ "/api/v1/users/profile");
    }

    static async getAllUnable() {
        return axios.get(customerAuthRestUrl + '/enabled?enabled=true', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async update(data) {
        await axios.post(customerRestUrl + '/' + +data.id, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async save(data) {
        return await axios.post(customerRestUrl, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async delete(id) {
        await axios.delete(customerRestUrl + '/' + id)
    }

    static async unable(id, status) {
        return axios.patch(customerRestUrl + '/' + id + '/unable?status=' + status, [], {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
}
