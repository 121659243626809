import {Box, Button, Tooltip} from "@mui/material";
import {ReactComponent as HoleIcon} from "../../../../../components/Icons/addHole.svg";
import {COLORS} from "../../../../../Constants";
import {ReactComponent as ArcIcon} from "../../../../../components/Icons/arc.svg";
import {ReactComponent as RoundIcon} from "../../../../../components/Icons/round.svg";
import {ReactComponent as CutIcon} from "../../../../../components/Icons/cut.svg";
import {ReactComponent as NotchIcon} from "../../../../../components/Icons/notch.svg";
import {ReactComponent as NotchCircleIcon} from "../../../../../components/Icons/notchRound.svg";
import {ReactComponent as GrooveIcon} from "../../../../../components/Icons/groove.svg"
import {ReactComponent as QuarterIcon} from "../../../../../components/Icons/quarter.svg"

const SideBar = ({detailData, processingHandle, cutPossible}) => {
    const pointsForQuarter = cutPossible.filter(el => {
        return ((el.curr.x === el.next.x || el.curr.y === el.next.y) && el.mainContour)
    })

    const pointsForCut = cutPossible.filter(point => {
        const allPointsLieLine = point.next?.x * (point.curr?.y - point.prev?.y) - point.next?.y * (point.curr?.x - point.prev?.x) === point.prev?.x * point.curr?.y - point.curr?.x * point.prev?.y
        return !allPointsLieLine
    })

    return <Box style={{
        border: "solid 1px black",
        width: "50px",
        minWidth: "50px",
        display: "flex",
        flexDirection: "column",
    }}>
        <Tooltip arrow placement="right" title="Добавить отверстие">
            <Button size="small"
                    sx={{
                        minWidth: 'auto', margin: '5px auto 0', border: "1px black solid"
                    }}
                    onClick={() => processingHandle("addHole", null)}>
                <HoleIcon style={{width: "30px", height: "30px"}}/>
            </Button>
        </Tooltip>
        <Tooltip arrow placement="right" title="Добавить узел">
            <Button size="small"
                    sx={{minWidth: 'auto', margin: '5px auto 0', border: "1px black solid"}}
                    onClick={() => processingHandle("addPoint", null, detailData.detailContour.points[0].id)}>
                <Box style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "30px",
                    height: "30px"
                }}>
                    <Box style={{
                        width: "10px",
                        height: "10px",
                        border: "1px solid black",
                        backgroundColor: COLORS[1][600],
                    }}/>
                </Box>
            </Button>
        </Tooltip>
        <Tooltip arrow placement="right" title="дуга">
            <Button size="small"
                    sx={{
                        minWidth: 'auto', margin: '5px auto 0', border: "1px black solid"
                    }}
                    onClick={() => processingHandle("arc", null, detailData.detailContour.points[0].id)}>
                <ArcIcon style={{width: "30px", height: "30px"}}/>
            </Button>
        </Tooltip>
        <Tooltip arrow placement="right" title="Скруглить угол">
            <Button size="small"
                    sx={{
                        minWidth: 'auto',
                        margin: '5px auto 0',
                        border: "1px black solid",
                        opacity: `${pointsForCut.length === 0 ? 0.2 : 1}`
                    }}
                    disabled={pointsForCut.length === 0}
                    onClick={() => processingHandle("round", null, pointsForCut[0].curr.id)}>
                <RoundIcon style={{width: "30px", height: "30px"}}/>
            </Button>
        </Tooltip>
        <Tooltip arrow placement="right" title="Срез">
            <Button size="small"
                    sx={{
                        minWidth: 'auto',
                        margin: '5px auto 0',
                        border: "1px black solid",
                        opacity: `${pointsForCut.length === 0 ? 0.2 : 1}`
                    }}
                    disabled={pointsForCut.length === 0}
                    onClick={() => processingHandle("cut", null, pointsForCut[0].curr.id)}>
                <CutIcon style={{width: "30px", height: "30px"}}/>
            </Button>
        </Tooltip>
        <Tooltip arrow placement="right" title="Внутрений контур">
            <Button size="small"
                    sx={{
                        minWidth: 'auto', margin: '5px auto 0', border: "1px black solid"
                    }}
                    onClick={() => processingHandle("notch", null)}>
                <NotchIcon style={{width: "30px", height: "30px"}}/>
            </Button>
        </Tooltip>
        <Tooltip arrow placement="right" title="Внутрений контур (круг)">
            <Button size="small"
                    sx={{
                        minWidth: 'auto', margin: '5px auto 0', border: "1px black solid"
                    }}
                    onClick={() => processingHandle("notchRound", null)}>
                <NotchCircleIcon style={{width: "24px", height: "24px", padding: "3px"}}/>
            </Button>
        </Tooltip>
        <Tooltip arrow placement="right" title="Паз">
            <Button size="small"
                    sx={{
                        minWidth: 'auto', margin: '5px auto 0', border: "1px black solid"
                    }}
                    onClick={() => processingHandle("groove", null)}>
                <GrooveIcon style={{width: "30px", height: "30px"}}/>
            </Button>
        </Tooltip>
        <Tooltip arrow placement="right" title="Четверь">
            <Button size="small"
                    sx={{
                        minWidth: 'auto',
                        margin: '5px auto 0',
                        border: "1px black solid",
                        opacity: `${pointsForQuarter.length === 0 ? 0.2 : 1}`
                    }}
                    disabled={pointsForQuarter.length === 0}
                    onClick={() => processingHandle("quarter", null, pointsForQuarter[0].curr.id)}>
                <QuarterIcon style={{width: "30px", height: "30px"}}/>
            </Button>
        </Tooltip>
    </Box>
}
export default SideBar
