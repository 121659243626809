import React, {useCallback, useEffect, useMemo, useState} from 'react';
import MaterialReactTable from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import {Button, Tooltip} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {ArrowRight} from "@mui/icons-material";
import Box from "@mui/material/Box";

import PackagingSetService from "../../../../../API/model-estimate/packaging/PackagingSetService";
import {Link, useNavigate} from "react-router-dom";
import ModelService from "../../../../../API/model-estimate/model/ModelService";
import CellCheckboxCurrent from "../../../сheckbox/CellCheckboxCurrent";

const ModelSetPackagingSetTable = ({idModel}) => {

    const [data, setData] = useState([]);
    const [currentPackagingSetId, setCurrentPackagingSetId] = useState([]);
    const [isFullScreen, setIsFullScreen] = useState(false);

    const navigate = useNavigate();

    const redirectModels = () => {
        navigate('/models/');
    };

    useEffect(() => {

        PackagingSetService.getAllPackagingSetCheckForModelId(idModel).then(function (response) {
            setData(response.data);
        })
            .catch(function (error) {
                console.log(error);
            });

    }, [currentPackagingSetId]);


    const setPackagingSet = useCallback(
        (row) => {
            const idPackagingSet = row.original.id;
            ModelService.setPackagingSetById(idModel, idPackagingSet)
                .then(function () {
                    setCurrentPackagingSetId(idPackagingSet)
                    setData([...data]);
                })
                .catch(function (error) {
                    console.log(error);
                })
        },
        [],
    );

    const deletePackagingSet = useCallback(
        () => {
            ModelService.deletePackagingSet(idModel)
                .then(function () {
                    setCurrentPackagingSetId(null)
                    setData([...data]);
                })
                .catch(function (error) {
                    console.log(error);
                })
        },
        [],
    );


    const columns = useMemo(
        () => [
            {
                id: 'inCurrentModel',
                header: 'Текущий',
                accessorKey: 'inCurrentModel',
                size: 8,
                maxSize: 8,
                enableEditing: false,
                Cell: ({cell, row}) => <CellCheckboxCurrent cell={cell}></CellCheckboxCurrent>,
            },
            {
                id: 'articleNumber',
                header: 'Артикул',
                accessorKey: 'articleNumber',
                minSize: 30,
                Cell: ({cell, row}) => (
                    <li key={cell.getValue()}>
                        <Link
                            to={'/packaging-sets/' + data[row.index].id + '/' + cell.getValue()}>{cell.getValue()}</Link>
                    </li>
                ),
            },
            {
                id: 'name',
                header: 'name',
                accessorKey: 'name',
                minSize: 25,
            },
            {
                id: 'weight',
                header: 'Вес',
                accessorKey: 'weight',
                minSize: 50,
                enableEditing: false,
            },
            {
                header: 'id',
                id: 'id',
                accessorKey: 'id',
                enableHiding: false,
                enableColumnActions: false,
                enableGrouping: false,
                enableEditing: false,
            },
        ],
        [data, setData],
    );

    return (
        <>
            <MaterialReactTable
                muiTableBodyProps={{
                    sx: () => ({
                        '& tr:nth-of-type(odd)': {
                            backgroundColor: 'lightgray',
                        },
                    }),
                }}
                muiTableBodyCellProps={{
                    sx: {
                        p: '0px 0px 0px 0px',
                        m: '0px',
                    },
                }}
                muiTableBodyRowProps={{
                    sx: {
                        height: '10px',
                    },
                }}
                isFullScreen={isFullScreen}
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        size: 120,
                    },
                }}
                columns={columns}
                data={data}
                editingMode="modal"
                enableEditing
                renderRowActions={({row, table}) => (
                    <Box sx={{display: 'flex', gap: '1rem'}}>
                        <Tooltip arrow placement="right" title="Установить">
                            <IconButton color="success" onClick={() => setPackagingSet(row)}>
                                <ArrowRight/>
                            </IconButton>
                        </Tooltip>
                    </Box>
                )}
                initialState={{
                    columnVisibility: {
                        id: false,
                    },
                    isFullScreen: false,
                    density: "compact",
                    pagination: {pageIndex: 0, pageSize: 20},
                    columnPinning: {left: ['number']}
                }}
                localization={MRT_Localization_RU}
                renderTopToolbarCustomActions={() => (
                    <Box
                        sx={{display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap'}}
                    >
                        <Button
                            color="secondary"
                            onClick={redirectModels}
                            variant="contained"
                        >
                            Вернутся к моделям
                        </Button>
                        <Button
                            color="secondary"
                            onClick={deletePackagingSet}
                            variant="contained"
                        >
                            Сбросить на не определено
                        </Button>
                    </Box>
                )}
            />
        </>
    );
};

export default ModelSetPackagingSetTable;