import React from 'react';
import {TextField} from "@mui/material";

const MainOptionsMaterialModuleEntityTextField = ({
                                                      field,
                                                      label,
                                                      values,
                                                      entityList,
                                                      childFirstField,
                                                      disabled,
                                                      setId
                                                  }) => {
    return (
        <div>
            <TextField
                size={"small"}
                disabled={disabled}
                key={field}
                sx={{
                    width: '100%',
                    minWidth: {xs: '300px', sm: '360px', md: '400px'},
                    gap: '1.5rem',
                }}
                margin="normal"
                select
                label={label}
                defaultValue={values === null ? "" : (values[field] === null ? "" : values[field][childFirstField])}
                name={field}
                onChange={(e) =>
                    setId(e.target.value)
                }
                SelectProps={{
                    native: true,
                }}
            >
                {entityList !== null &&
                    entityList.map((entity) => (
                        <React.Fragment key={entity.id}>
                            <option value={entity.id}>
                                {entity.name}
                            </option>
                        </React.Fragment>
                    ))}
            </TextField>
        </div>
    );
};

export default MainOptionsMaterialModuleEntityTextField;