import Box from "@mui/material/Box";
import UploadGroupOfMaterialFileForm from "../../../components/UI/cloud/UploadGroupOfMaterialFileForm";
import AdminMaterialCloudFileService from "../../../API/material-cloud/admin/AdminMaterialCloudFileService";

const SettingsCloudLoadGroupMaterial = () => {
    return (
        <div>

            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '3px'
            }}>
                <UploadGroupOfMaterialFileForm
                    label={'Отправить'}
                    downloadFile={AdminMaterialCloudFileService.downloadMaterialsFile}
                />
            </Box>
        </div>
    );
};

export default SettingsCloudLoadGroupMaterial;