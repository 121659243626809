import React, {useEffect, useState} from 'react';
import {Box} from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import DetailControllerService from "../../../API/details/DetailControllerService";
import ModalAlert from "../../../components/UI/table/customer-detales/ModalAlert";
import {constantsErrorMessages} from "../../../Constants";
import {useParams} from "react-router-dom";
import CustomerDetailsTable from "../../../components/UI/table/customer-detales/CustomerDetailsTable";

const CustomerRequestsCutting = () => {

    const params = useParams();
    const orderId = params.orderId
    const requestsCuttingId = params.requestsCuttingId

    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);

    const [alertData, setAlertData] = useState({}); //type 'error'| 'info' | 'success'| 'warning'

    const [data, setData] = useState([]);
    const [unable, setUnable] = useState({unableTurn: {}, unablePackage: {}});


    const handleError = (error) => {
        setIsError(true)
        setAlertData({
            type: 'error',
            open: true,
            message: constantsErrorMessages[error.response.status] || error.message
        })
    }
    const handleCloseAlert = () => {
        setAlertData((alert) => {
            return {...alert, open: false}
        })
    }

    useEffect(() => {
        setIsLoading(true)
        DetailControllerService.getAll(orderId, requestsCuttingId)
            .then(function (response) {
                const data = response?.data
                setData(data);
                setUnable(({unableTurn, unablePackage}) => {
                    data.forEach(row => {
                        unableTurn[row.id] = row.turn;
                        unablePackage[row.id] = row.package;
                    })
                    return {unableTurn, unablePackage}
                })
                setIsError(false)
            })
            .catch(function (error) {
                handleError(error)
            })
            .finally(() => setIsLoading(false))
    }, []);

    return (
        isLoading ?
            <Box sx={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}>
                <CircularProgress disableShrink/>
            </Box> :
            <Box>
                <ModalAlert data={alertData} onClose={handleCloseAlert}/>
                <CustomerDetailsTable
                    data={data}
                    unableTurn={unable.unableTurn}
                    unablePackage={unable.unablePackage}
                    pageSize={100}
                    isError={isError}
                    setData={setData}
                    enableEditing={true}
                    orderId={orderId}
                    requestsCuttingId={requestsCuttingId}
                />
            </Box>
    )
}
export default CustomerRequestsCutting
