import React, {useEffect, useState} from 'react';
import OptimizationTable from "../../../../components/UI/table/optimization/optimization/OptimizationTable";
import OptimizationService from "../../../../API/orders/optimization/organization/OptimizationService";

const OptimizationsCalculation = () => {


    const [data, setData] = useState([]);
    const [selectedIds, setSelectedIds] = useState([]);

    useEffect(() => {
        OptimizationService.getAllNotArchiveTypeCalculation().then(function (response) {
            let data = response.data;
            setData(data);
        })
            .catch(function (error) {
                console.log(error);
            });
    }, []);

    return (
        <div>
            <OptimizationTable
                data={data}
                setData={setData}
                selectedIds={selectedIds}
                setSelectedIds={setSelectedIds}
                isCalculation={true}
            />
        </div>
    );
};

export default OptimizationsCalculation;