import React from 'react';
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {TextField} from "@mui/material";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";

const DateRangeFilter = ({column}) => {

    return (
        <div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    onChange={newValue => {
                        const filterValue = column.getFilterValue() || {};
                        column.setFilterValue({...filterValue, startDate: newValue});
                    }}
                    renderInput={params => (
                        <TextField
                            {...params}
                            helperText={'От'}
                            sx={{minWidth: '120px'}}
                            variant="standard"
                        />
                    )}
                    value={column.getFilterValue()?.startDate}
                    inputFormat="DD/MM/YYYY"
                />
                <DatePicker
                    onChange={newValue => {
                        const filterValue = column.getFilterValue() || {};
                        column.setFilterValue({...filterValue, endDate: newValue});
                    }}
                    renderInput={params => (
                        <TextField
                            {...params}
                            helperText={'До'}
                            sx={{minWidth: '120px'}}
                            variant="standard"
                        />
                    )}
                    value={column.getFilterValue()?.endDate}
                    inputFormat="DD/MM/YYYY"
                />
            </LocalizationProvider>
        </div>
    );
};

export default DateRangeFilter;