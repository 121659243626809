import {Box, Tabs} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import {TabContext, TabPanel} from "@mui/lab";
import ModalAlert from "../../../components/UI/table/customer-detales/ModalAlert";
import Tab from "@mui/material/Tab";
import React, {useEffect, useState} from "react";
import DetailProcessing from "./DetailProcessing/DetailProcessing";
import {constantsErrorMessages} from "../../../Constants";
import DetailControllerService from "../../../API/details/DetailControllerService";
import {useNavigate, useParams} from "react-router-dom";
import VariableControllerService from "../../../API/details/VariableControllerService";
import VariablesTable from "../../../components/UI/table/customer-detales/processing/VariablesTable";
import {EditLocalStoragePagesData} from "../../../utils/EditLocalStoragePagesData";

const LOADING_BOX_SX = {
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
};

const Loading = React.memo(() => (
    <Box sx={LOADING_BOX_SX}>
        <CircularProgress disableShrink/>
    </Box>
))

const Processing = () => {
    const params = useParams();
    const detailId = params.id
    const orderId = params.orderId
    const navigate = useNavigate();

    const redirectOrders = () => {
        navigate('/customer/orders/');
    };

    const redirectDetails = () => {
        const pathname = `/customer/orders/${orderId}/N${orderId}`
        EditLocalStoragePagesData(pathname, "tab", "6")
        navigate(pathname);
    };

    const [currTab, setCurrTab] = useState('1');

    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [change, setChange] = useState(true);

    const [alertData, setAlertData] = useState({}); //type 'error'| 'info' | 'success'| 'warning'
    const [detailData, setDetailData] = useState({});
    const [variables, setVariables] = useState({});

    const handleError = (error) => {
        // setIsError(true)
        setAlertData({
            type: 'error',
            open: true,
            message: constantsErrorMessages[error.response?.status] || error.response.data.message || error.message
        })
    }
    const getDetailData = () => {
        DetailControllerService.getById(orderId, detailId).then((response) => {
            setDetailData(response.data);
            setIsError(false);
        })
            .catch((error) => {
                handleError(error)
                redirectDetails()
            })
            .finally(() => setIsLoading(false))
    }

    const getVariablesData = () => {
        VariableControllerService.get(orderId, detailId)
            .then(function (response) {
                const data = response?.data
                setVariables(data);
                setIsError(false)
            })
            .catch(function (error) {
                handleError(error)
            })
    }

    const handleEditVariables = (data) => {
        setVariables(data)
        getDetailData()
    }

    useEffect(() => {
        setIsLoading(true)
        getDetailData()
        getVariablesData()

    }, [change]);


    const handleTabsChange = (target) => {
        setCurrTab(target)
    }

    return (
        isLoading
            ? <Loading/>
            : (
                <TabContext value={currTab}>
                    <ModalAlert
                        data={alertData}
                        onClose={() => setAlertData((alert) => ({...alert, open: false}))}
                    />
                    <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                        <Tabs
                            value={currTab}
                            onChange={(e, target) => handleTabsChange(target)}
                            aria-label="basic tabs example"
                        >
                            <Tab label="Обработка" value='1'/>}
                            <Tab label="Переменные" value='2'/>
                        </Tabs>
                    </Box>
                    <TabPanel value='1' sx={{padding: 0}}>
                        {/*<ProcessingContext.Provider value={processingContextValue}>*/}
                        <DetailProcessing
                            setIsLoading={setIsLoading}
                            setIsError={setIsError}
                            detailId={detailId}
                            handleError={handleError}
                            setChange={setChange}
                            orderId={orderId}
                            detailData={detailData}
                            redirect={{orders: redirectOrders, details: redirectDetails}}
                            variables={variables}
                        />
                        {/*</ProcessingContext.Provider>*/}
                    </TabPanel>
                    <TabPanel value='2'>
                        <VariablesTable
                            data={variables}
                            isError={isError}
                            setData={handleEditVariables}
                            enableEditing={true}
                            orderId={orderId}
                            detailId={detailId}/>
                    </TabPanel>
                </TabContext>
            )
    )
}
export default Processing
