import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DateTimePicker} from '@mui/x-date-pickers';
import {TextField} from "@mui/material";

const DateTimeInput = ({value, setValue, helperText, variant}) => {
    const handleDateTimeChange = (newValue) => {
        setValue(newValue);
    };

    return (
        <div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                    size="small"
                    value={value}
                    onChange={handleDateTimeChange}
                    ampm={false}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            helperText={helperText ? helperText : null}
                            sx={{maxWidth: '250px'}}
                            size="small"
                            variant={variant ? variant : 'standard'}
                        />
                    )}
                    inputFormat="DD/MM/YYYY HH:mm"
                />
            </LocalizationProvider>
        </div>
    );
};

export default DateTimeInput;