import React, {useEffect, useState} from 'react';
import WarehouseMaterialSettingsService from "../../../API/orders/warehouse/WarehouseMaterialSettingsService";
import IssuanceWarehouseMaterialTable from "../../../components/UI/table/prodaction/issue/IssuanceWarehouseMaterialTable";
import IssuanceService from "../../../API/orders/prodaction.issueedge/issuance/IssuanceService";

const AllIssuanceMaterialReserve = () => {

    const [data, setData] = useState([]);
    const [baseCurrency, setBaseCurrency] = useState("");
    const [alternativeCurrency, setAlternativeCurrency] = useState("");
    const [unableAfterCut, setUnableAfterCut] = useState({});

    useEffect(() => {
        WarehouseMaterialSettingsService.getWithBaseAndAlternativeCurrency().then(function (response) {
            let data = response.data;
            setBaseCurrency(data.baseCurrency.curAbbreviation);
            setAlternativeCurrency(data.alternativeCurrency.curAbbreviation);
        })
            .catch(function (error) {
                console.log(error);
            });
        IssuanceService.getAllWithFilter().then(function (response) {
            const data = response.data;
            setData(data);
            data.forEach((row) => {
                unableAfterCut[row.id] = row.afterCut;
            });
        })
            .catch(function (error) {
                console.log(error);
            });
    }, []);

    return (
        <div>
            <IssuanceWarehouseMaterialTable
                data={data}
                setData={setData}
                baseCurrency={baseCurrency}
                alternativeCurrency={alternativeCurrency}
                unableAfterCut={unableAfterCut}
                setUnableAfterCut={setUnableAfterCut}
                afterCut={true}
            />
        </div>
    );
};

export default AllIssuanceMaterialReserve;