import {Box} from "@mui/material";
import React, {useEffect, useState} from "react";
import DetailService from "../../../../../API/orders/cutting/detail/DetailService";
import DetailTable from "../../../../../components/UI/table/cutting/details/DetailTable";
import {useParams} from "react-router-dom";
import DetailUtil from "../../../../../utils/cutting/DetailUtil";
import Typography from "@mui/material/Typography";

const DetailsRequestsCutting = () => {

    const [data, setData] = useState([]);
    const [isError, setIsError] = useState(false);
    const [unableCut, setUnableCut] = useState({});
    const [unableTurn, setUnableTurn] = useState({});
    const [selectedIds, setSelectedIds] = useState([]);
    const [modalStatusOpen, setModalStatusOpen] = useState(false);
    const [status, setStatus] = useState(true);
    const [typeStatus, setTypeStatus] = useState(null);
    const [change, setChange] = useState(false);

    const params = useParams();

    const requestsCuttingId = params.id;
    const numberRequestsCutting = params.numberRequestsCutting;

    useEffect(() => {
        DetailService.getAllByRequestsCuttingId(requestsCuttingId).then(function (response) {
            let data = response.data;
            const error = DetailUtil.checkDuplicatePositions(data, false);
            if (error) {
                setIsError(true);
            }
            setData(data);
            data.forEach((row) => {
                unableCut[row.id] = row.cut;
                unableTurn[row.id] = row.turn;
            });
        })
            .catch(function (error) {
                console.log(error);
            });
    }, [change]);

    return (
        <Box sx={{
            '& > *': {
                marginTop: '20px',
            }
        }}>
            <Typography variant="h6" component="h6">
                Детали на раскрой заявки {numberRequestsCutting}
            </Typography>
            <DetailTable
                data={data}
                setData={setData}
                isError={isError}
                unableCut={unableCut}
                unableTurn={unableTurn}
                setUnableCut={setUnableCut}
                setUnableTurn={setUnableTurn}
                selectedIds={selectedIds}
                setSelectedIds={setSelectedIds}
                modalStatusOpen={modalStatusOpen}
                setModalStatusOpen={setModalStatusOpen}
                status={status}
                setStatus={setStatus}
                typeStatus={typeStatus}
                setTypeStatus={setTypeStatus}
                isColumnMaterial={false}
                isColumnOrder={false}
                warehouse={'orders'}
                enableGrouping={false}
                columnGrouping={[]}
                expanded={false}
                pageSize={20}
                requestsCuttingId={requestsCuttingId}
                change={change}
                setChange={setChange}
            />
        </Box>
    );
};

export default DetailsRequestsCutting;