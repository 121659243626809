import React, {useCallback, useMemo, useState} from 'react';
import MaterialReactTable from "material-react-table";
import Box from "@mui/material/Box";
import {Button, Tooltip} from "@mui/material";
import {Edit} from "@mui/icons-material";
import Delete from "@mui/icons-material/DeleteOutlined";
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import {constantsErrorMessages} from "../../../../../Constants";
import ModalAlert from "../ModalAlert";

const ProcessingTable = ({
                             data,
                             processingHandle,
                             openConfirmModal,
                             selectedHole,
                             setSelectedHole,
                             hoveredHole,
                             setHoveredHole,
                         }) => {
    const [alertData, setAlertData] = useState({}); //type 'error'| 'info' | 'success'| 'warning'

    const handleCloseAlert = () => {
        setAlertData((alert) => {
            return {...alert, open: false}
        })
    }
    const handleError = (error) => {
        setAlertData({
            type: 'error',
            open: true,
            message: constantsErrorMessages[error.response.status] || error.message
        })
    }

    const columns = useMemo(() => {
        const baseColumns = [
            {
                id: 'position',
                header: 'Позиция',
                accessorKey: "position",
                maxSize: 50,
                Cell: ({cell, row}) => <span>{row.original.contour.position}</span>,
            },

            {
                id: 'name',
                header: 'Наименование',
                accessorKey: "name",
                maxSize: 50,
                Cell: ({cell, row}) => {
                    return <Tooltip title={row.original.name}><span>{row.original.name}</span>
                    </Tooltip>
                },
            },

            {
                id: 'x',
                header: 'x',
                accessorKey: 'x',
                maxSize: 50,
                Cell: ({cell, row}) => <span>{row.original.center.x}</span>,
            },
            {
                id: 'y',
                header: 'y',
                accessorKey: 'y',
                maxSize: 50,
                Cell: ({cell, row}) => <span>{row.original.center.y}</span>,
            },
            {
                id: 'z',
                header: 'z',
                accessorKey: 'z',
                maxSize: 50,
                Cell: ({cell, row}) => <span>{row.original.center.z}</span>,
            },
            {
                id: 'depth',
                header: 'Глубина',
                accessorKey: 'depth',
                maxSize: 50,
                Cell: ({cell, row}) => <span>{cell.getValue()}</span>,
            },
            {
                id: 'radius',
                header: 'Радиус',
                accessorKey: 'radius',
                maxSize: 50,
                Cell: ({cell, row}) => <span>{cell.getValue()}</span>,
            },
        ];

        return baseColumns;

    }, [data]);

    return <><MaterialReactTable

        muiTableBodyCellProps={{
            sx: {
                p: '0px 0px 0px 10px', m: '0px 0px 0px 0px',
            },
        }}
        muiTableBodyRowProps={{
            sx: {
                height: '10px',
            },
        }}

        displayColumnDefOptions={{
            'mrt-row-actions': {
                muiTableHeadCellProps: {
                    align: 'center',
                }, size: 15, minSize: 50,
            },
        }}

        muiTableBodyRowProps={({row}) => ({
            onClick: () => row.id === selectedHole ? setSelectedHole(null) : setSelectedHole(row.id),
            onPointerOver: (event) => (event.stopPropagation(), setHoveredHole(`${row.id}${row.original.name}`)),
            onPointerOut: (event) => (event.stopPropagation(), setHoveredHole(null)),
            sx: {
                '&.MuiTableRow-hover:hover': {
                    backgroundColor: 'lightblue',
                },
                backgroundColor: `${row.id}${row.original.name}` === hoveredHole ? "lightblue" : "white",
                cursor: "pointer"
            },
        })
        }

        muiTableBodyCellProps={({cell}) => {
            const isSelected = cell.row.id === selectedHole;
            const borderStyle = isSelected ? "2px solid red" : "2px solid transparent";

            return {
                sx: {
                    overflow: "hidden", maxWidth: cell.column.columnDef.maxSize,
                    whiteSpace: "nowrap", textOverflow: "ellipsis",
                    borderTop: borderStyle,
                    borderBottom: borderStyle,
                    " :first-of-type": {
                        borderTop: borderStyle,
                        borderLeft: borderStyle,
                        borderBottom: borderStyle,
                    },
                    ":last-child": {
                        borderTop: borderStyle,
                        borderRight: borderStyle,
                        borderBottom: borderStyle,
                    }
                },
            }
        }
        }

        columns={columns}
        data={data.holes}
        rowHeight={13}
        columnSpacing={1}
        initialState={{
            columnVisibility: {
                id: false,
            },
            density: "compact",
            pagination: {pageIndex: 0},
            sorting: [{id: 'position', desc: false}],
        }}

        enableEditing={true}
        getRowId={(originalRow) => originalRow.id}
        selectionPadding={1}
        selectionCss={{
            backgroundColor: 'lightblue',
            borderWidth: '2px',
            borderStyle: 'solid',
            borderColor: 'blue',
            borderRadius: '4px',
        }}
        localization={MRT_Localization_RU}
        renderRowActions={({row, table}) => (<Box sx={{
            display: 'flex', gap: '0.1rem', justifyContent: 'flex-start', maxWidth: '50px'
        }}>
            <Tooltip arrow placement="left" title="Редактировать">
                <Button size="small" sx={{minWidth: 'auto', marginLeft: 'auto'}} color="pencil"
                        onClick={() => processingHandle("editHole", row.original.id)}
                >
                    <Edit sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                </Button>
            </Tooltip>
            <Tooltip arrow placement="right" title="Удалить">
                <Button size="small" sx={{minWidth: 'auto', marginLeft: 'auto'}} color="error"
                        onClick={() => openConfirmModal("deleteHole", row.original.id)}
                >
                    <Delete sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                </Button>
            </Tooltip>

        </Box>)}

    />
        <ModalAlert data={alertData} onClose={handleCloseAlert}/>
    </>

}
export default ProcessingTable
