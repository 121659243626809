import React, {useState} from "react";
import UploadFilesForm from "../../../../form/UploadFilesForm";

const UploadModelEstimateFilesForm = ({
                                          labelForm,
                                          upload
                                      }) => {

    const [selectedFiles, setSelectedFiles] = useState([]);

    const handleUpload = async (event) => {

        event.preventDefault();
        upload(selectedFiles)
            .then(function () {
                setSelectedFiles([]);
                event.target.reset();
            })
            .catch(function (error) {
                console.log(error);
                event.target.reset();
            })
    }

    return (
        <div>
            <UploadFilesForm
                handleUpload={handleUpload}
                selectedFiles={selectedFiles}
                setSelectedFiles={setSelectedFiles}
                label={labelForm}
            />
        </div>
    );
};

export default UploadModelEstimateFilesForm;