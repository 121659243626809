import React from 'react';
import {List, ListItem, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import Box from "@mui/material/Box";
import CallMadeIcon from '@mui/icons-material/CallMade';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import {useNavigate} from "react-router-dom";

const SettingsStorage = () => {

    const navigate = useNavigate();

    const redirectUnits = () => {
        navigate('/organization/units');
    };

    const redirectDatabaseOfMaterials = () => {
        navigate('/organization/database-of-materials');
    };

    const redirectDatabaseOfAllMaterials = () => {
        navigate('/organization/all-materials');
    };

    const redirectSettingsLoadGroupOrganization = () => {
        navigate('/organization/settings-load-group-organization');
    };

    const redirectSettingsLoadMaterialOrganization = () => {
        navigate('/organization/settings-load-material-organization');
    };

    const redirectSettingsLoadSupplierMaterials = () => {
        navigate('/organization/settings-load-supplier-material-organization');
    };

    return (
        <div>
            <Box>
                <nav aria-label="secondary mailbox folders">
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton onClick={redirectUnits}>
                                <ListItemIcon>
                                    <CallMadeIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Единицы измерения"/>
                            </ListItemButton>
                        </ListItem>
                    </List>
                </nav>
                <nav aria-label="secondary mailbox folders">
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton onClick={redirectSettingsLoadGroupOrganization}>
                                <ListItemIcon>
                                    <UploadFileIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Импорт групп"/>
                            </ListItemButton>
                        </ListItem>
                    </List>
                </nav>
                <nav aria-label="secondary mailbox folders">
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton onClick={redirectSettingsLoadMaterialOrganization}>
                                <ListItemIcon>
                                    <UploadFileIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Импорт базы материалов"/>
                            </ListItemButton>
                        </ListItem>
                    </List>
                </nav>
                <nav aria-label="secondary mailbox folders">
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton onClick={redirectSettingsLoadSupplierMaterials}>
                                <ListItemIcon>
                                    <UploadFileIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Импорт цен поставщиков"/>
                            </ListItemButton>
                        </ListItem>
                    </List>
                </nav>
            </Box>
        </div>
    );
};

export default SettingsStorage;