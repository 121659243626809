import React from 'react';
import RequestsCuttingTable from "../../../../components/UI/table/optimization/requestscutting/RequestsCuttingTable";
import RequestsCuttingService from "../../../../API/orders/optimization/organization/RequestsCuttingService";
import Typography from "@mui/material/Typography";

const RequestsCuttingsAll = () => {

    return (
        <div>
            <Typography variant="h6" component="h6">
                Заявки на раскрой
            </Typography>
            <RequestsCuttingTable
                getAll={RequestsCuttingService.getAllWithMaterialNotArchive}
                isOptimization={true}
                isRedirectRequestsCutting={false}
                isButtonAddRequestsCutting={false}
                isOrder={true}
                isPlan={true}
                isAddOptimization={true}
                isCreateOptimization={true}
                isRedirectPlans={true}
                isRedirectOptimizations={true}
                isRedirectOrders={true}
                page={'RequestsCuttingsAll'}
            />
        </div>
    );
};

export default RequestsCuttingsAll;