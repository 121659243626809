import React, {useCallback, useEffect, useMemo, useState} from 'react';
import MaterialReactTable from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import Box from "@mui/material/Box";
import ArrivalMaterialService from "../../../../../../API/orders/warehouse/material/ArrivalMaterialService";
import DateTimeUtil from "../../../../../../utils/common/DateTimeUtil";
import DateFilter from "../../../../filter/DateRangeFilter";
import {Button, Tooltip} from "@mui/material";
import {Delete, Edit, PlaylistAdd} from "@mui/icons-material";
import {Link, useNavigate} from "react-router-dom";
import WarehouseMaterialSettingsService
    from "../../../../../../API/orders/warehouse/WarehouseMaterialSettingsService";
import ModalConfirm from "../../../../modal/ModalConfirm";
import ModalUpdateOrAddMaterialArrival from "./ModalUpdateOrAddMaterialArrival";
import CurrencyService from "../../../../../../API/orders/warehouse/currency/CurrencyService";
import SupplierService from "../../../../../../API/orders/warehouse/supplier/SupplierService";
import WarehouseService from "../../../../../../API/orders/warehouse/WarehouseService";
import {statuses} from "../../../../../../data/ArrivalState";
import ArrivalMaterialUtil from "../../../../../../utils/warehouse/ArrivalMaterialUtil";

const ArrivalMaterialTable = ({isCreateOrUpdate, method}) => {


        const [isFullScreen, setIsFullScreen] = useState(false);
        const [data, setData] = useState([]);

        const [baseCurrency, setBaseCurrency] = useState("");
        const [alternativeCurrency, setAlternativeCurrency] = useState("");

        const [showConfirmationModalDelete, setShowConfirmationModalDelete] = useState(false);
        const [showMergeArrivalMaterial, setShowMergeArrivalMaterial] = useState(false);
        const [rowToDelete, setRowToDelete] = useState(null);

        const [createOrUpdateModalOpen, setCreateOrUpdateModalOpen] = useState(false);
        const [currentEditRowData, setCurrentEditRowData] = useState(null);
        const [currentEditRow, setCurrentEditRow] = useState(null);
        const [date, setDate] = useState(null);
        const [materialDeliveryDate, setMaterialDeliveryDate] = useState(null);
        const [rowSelection, setRowSelection] = useState({});
        const [rowIndexSelection, setRowIndexSelection] = useState([])
        const [selectedIds, setSelectedIds] = useState([]);

        const [currencyList, setCurrencyList] = useState([]);
        const [currencyId, setCurrencyId] = useState(null);

        const [supplierList, setSupplierList] = useState([]);
        const [supplierId, setSupplierId] = useState(null);

        const [warehouseList, setWarehouseList] = useState([]);
        const [warehouseId, setWarehouseId] = useState(null);

        const navigate = useNavigate();

        useEffect(() => {

            ArrivalMaterialService.getAll(method).then(function (response) {
                setData(response.data);
            })
                .catch(function (error) {
                    console.log(error);
                });
            WarehouseMaterialSettingsService.getWithBaseAndAlternativeCurrency().then(function (response) {
                let data = response.data;
                setBaseCurrency(data.baseCurrency.curAbbreviation);
                setAlternativeCurrency(data.alternativeCurrency.curAbbreviation);
            })
                .catch(function (error) {
                    console.log(error);
                });
            CurrencyService.getAll().then(function (response) {
                let data = response.data;
                data.sort((a, b) =>
                    a.defaultBase === b.defaultBase ? 0 : a.defaultBase ? -1 : 1
                );
                setCurrencyList(data);
            })
                .catch(function (error) {
                    console.log(error);
                });
            SupplierService.getAllByOrganization().then(function (response) {
                let data = response.data;
                setSupplierList(data);
            })
                .catch(function (error) {
                    console.log(error);
                });
            WarehouseService.getAll().then(function (response) {
                let data = response.data;
                setWarehouseList(data);
            })
                .catch(function (error) {
                    console.log(error);
                });
        }, []);

        const handleUpdateModel = useCallback(
            (row) => {
                setCurrentEditRowData(row.original);
                setCurrentEditRow(row);
                setDate(row.original.date);
                setMaterialDeliveryDate(row.original.materialDeliveryDate)
                setCreateOrUpdateModalOpen(true);
            },
            [data, currentEditRowData, setCurrentEditRowData],
        );

        const handleMergeArrivalMaterial = async () => {
            ArrivalMaterialService.merge(selectedIds)
                .then(function (response) {
                    for (let i = rowIndexSelection.length - 1; i >= 0; i--) {
                        const index = rowIndexSelection[i];
                        data.splice(index, 1);
                    }

                    setData([...data]);
                    handleCreateNewRow(response.data)
                })
                .catch(function (error) {
                    console.log(error);
                })

            cancelMergeArrivalMaterial()
        };

        const cancelMergeArrivalMaterial = () => {
            setShowMergeArrivalMaterial(false);
        };

        const handleCreateRow = () => {
            const ArrivalMaterial = {
                arrivalState: 'EXECUTION_OF_APPLICATION',
                comment: '',
                currencyArrival: null,
                date: null,
                id: null,
                materialDeliveryDate: null,
                supplier: null,
                warehouse: null,
            };
            setCurrentEditRowData(ArrivalMaterial);
            setDate(new Date());
            setMaterialDeliveryDate(new Date())
            setCreateOrUpdateModalOpen(true);
        };

        const handleDeleteRow = (row) => {
            setShowConfirmationModalDelete(true);
            setRowToDelete(row);
        };

        const handleDeleteRowWithConfirm = () => {
            ArrivalMaterialService.delete(rowToDelete.original.id)
                .then(function () {
                    data.splice(rowToDelete.index, 1);
                    setData([...data]);
                })
                .catch(function (error) {
                    console.log(error);
                })
            cancelDelete()
        };

        const cancelDelete = () => {
            setShowConfirmationModalDelete(false);
            setRowToDelete(null);
        };

        const redirectByArrivalMaterialId = useCallback(
            (row) => {
                const rowCurAbbreviation = row.original.currencyArrival.curAbbreviation;
                const supplierId = row.original.supplier.id;
                const isBaseCurrency = rowCurAbbreviation !== baseCurrency;
                const isAlternativeCurrency = rowCurAbbreviation !== alternativeCurrency;
                navigate('/organization/materials-price-unit-arrival/' + row.original.id + '/' + row.original.currencyArrival.curAbbreviation + '/'
                    + isBaseCurrency + '/' + isAlternativeCurrency
                    + '/' + baseCurrency + '/' + alternativeCurrency + '/' + supplierId + '/' + row.original.warehouse.id);
            },
            [baseCurrency, alternativeCurrency],
        );

        const redirectByArrivalMaterials = () => {
            navigate('/organization/materials-arrival');
        };

        const redirectByArrivalMaterialsArchive = () => {
            navigate('/organization/materials-arrival-archive');
        };

        const redirectReservationPieceMaterial = () => {
            navigate('/organization/reservation-piece-material');
        };

        const redirectReservationSlabMaterial = () => {
            navigate('/organization/reservation-slab-material');
        };

        const redirectReservationEdgeMaterial = () => {
            navigate('/organization/reservation-linear-material');
        };

        const handleCloseCreateOrUpdateModalOpen = () => {
            setCreateOrUpdateModalOpen(false)
            setCurrentEditRowData(null);
            setCurrentEditRow(null);
            setCurrencyId(null);
            setSupplierId(null);
            setWarehouseId(null);
        };

        const handleCreateNewRow = (values) => {
            data.push(values);
            setData([...data]);
        };

        const handleUpdate = (values) => {
            data[currentEditRow.index] = values;
            setData([...data]);
        };

        function CellComponent({isCreateOrUpdate, cell, row, baseCurrency, alternativeCurrency}) {
            return !isCreateOrUpdate ? (
                <li key={cell.getValue()}>
                    <Link
                        to={'/materials-price-unit-arrival-archive/' + row.original.id + '/'
                            + row.original.currencyArrival.curAbbreviation + '/' + baseCurrency + '/' + alternativeCurrency}
                    >
                        {cell.getValue()}
                    </Link>
                </li>
            ) : (
                <span>{cell.getValue()}</span>
            );
        }

        const columns = useMemo(
            () => [
                {
                    header: 'id',
                    accessorKey: 'id',
                    enableColumnActions: false,
                    enableGrouping: false,
                    enableEditing: false,
                    size: 2,
                    maxSize: 2,
                    flex: 0.5,
                    enableHiding: false,
                    enableColumnFilter: false,
                },
                {
                    header: 'Номер',
                    accessorKey: 'number',
                    enableColumnActions: false,
                    enableGrouping: false,
                    enableEditing: false,
                    size: 2,
                    maxSize: 2,
                    flex: 0.5,
                },
                {
                    id: 'supplier.name',
                    header: 'Поставщик',
                    accessorKey: 'supplier.name',
                    minSize: 20,
                    maxSize: 20,
                },
                {
                    accessorFn: (row) => {
                        if (row.date === null) {
                        } else {
                            return new Date(row.date)
                        }
                    },
                    id: 'date',
                    header: 'Дата оформления',
                    sortingFn: 'date',
                    size: 10,
                    Cell: ({cell}) => {
                        if (cell.getValue() !== undefined) {
                            return cell.getValue().toLocaleDateString();
                        }
                    },
                    filterFn: (row, id, filterValue) => {
                        return DateTimeUtil.checkDate(row.getValue(id), filterValue)
                    },
                    Filter: ({column}) => <DateFilter column={column}/>,
                },
                {
                    accessorFn: (row) => {
                        if (row.materialDeliveryDate === null) {
                        } else {
                            return new Date(row.materialDeliveryDate)
                        }
                    },
                    id: 'materialDeliveryDate',
                    header: 'Дата поставки',
                    sortingFn: 'materialDeliveryDate',
                    size: 10,
                    Cell: ({cell}) => {
                        if (cell.getValue() !== undefined) {
                            return cell.getValue().toLocaleDateString();
                        }
                    },
                    filterFn: (row, id, filterValue) => {
                        return DateTimeUtil.checkDate(row.getValue(id), filterValue)
                    },
                    Filter: ({column}) => <DateFilter column={column}/>,
                },
                {
                    id: 'currencyArrival.curAbbreviation',
                    header: 'Валюта прихода',
                    accessorKey: 'currencyArrival.curAbbreviation',
                    minSize: 10,
                    axSize: 10,
                },
                {
                    id: 'warehouse',
                    header: 'Склад',
                    accessorKey: 'warehouse.name',
                    minSize: 20,
                    maxSize: 20,
                },
                {
                    id: 'arrivalState',
                    header: 'Статус',
                    accessorKey: 'arrivalState',
                    minSize: 55,
                    maxSize: 55,
                    Cell: ({cell}) => statuses[cell.getValue()],
                },
                {
                    id: 'comment',
                    header: 'Коментарий',
                    accessorKey: 'comment',
                    minSize: 30,
                    maxSize: 150,
                },
            ],
            [data, setData, baseCurrency, alternativeCurrency],
        );

        const unlockCurrencyAndSupplierAndCountRows = () => {
            return ArrivalMaterialUtil.checkCurrencyAndSupplierAndCountRows(data, rowSelection);
        };

        const isNew = currentEditRowData === null ? true : currentEditRowData.id === null

        const handleSubmit = () => {
            currentEditRowData.date = date === null ? null : DateTimeUtil.dateConvert(date);
            currentEditRowData.materialDeliveryDate = materialDeliveryDate === null ? null : DateTimeUtil.dateConvert(materialDeliveryDate);

            const currentCurrencyId = isNew ? (currencyId === null ? currencyList[0].id : currencyId) : currentEditRowData.currencyArrival.id
            const currentSupplierIdId = isNew ? (supplierId === null ? supplierList[0].id : supplierId) : currentEditRowData.supplier.id
            const currentWarehouseId = isNew ? (warehouseId == null ? warehouseList[0].id : warehouseId) : (warehouseId === null ? currentEditRowData.warehouse.id : warehouseId)

            ArrivalMaterialService.createOrUpdate(currentEditRowData, currentCurrencyId, currentSupplierIdId, currentWarehouseId,
            ).then(function (response) {
                let data = response.data;
                isNew ? handleCreateNewRow(data) : handleUpdate(data)
                handleCloseCreateOrUpdateModalOpen();
            })
                .catch(function (error) {
                    console.log(error);
                });
        }

        return (
            <div>
                {<MaterialReactTable
                    muiTableBodyProps={{
                        sx: () => ({
                            '& tr:nth-of-type(odd)': {
                                backgroundColor: 'lightgray',
                            },
                        }),
                    }}
                    muiTableBodyCellProps={{
                        sx: {
                            p: '0px 0px 0px 10px',
                            m: '0px 0px 0px 0px',
                        },
                    }}
                    muiTableBodyRowProps={{
                        sx: {
                            height: '10px',
                        },
                    }}
                    isFullScreen={isFullScreen}
                    displayColumnDefOptions={{
                        'mrt-row-actions': {
                            muiTableHeadCellProps: {
                                align: 'center',
                            },
                            size: 110,
                            minSize: 110,
                        },
                    }}
                    columns={columns}
                    data={data}
                    initialState={{
                        isFullScreen: false,
                        density: "compact",
                        pagination: {pageIndex: 0, pageSize: 50},
                        columnVisibility: {
                            id: false,
                        },
                    }}
                    editingMode="modal"
                    enableEditing={isCreateOrUpdate}
                    onEditingRowSave
                    onRowSelectionChange={setRowSelection}
                    state={{rowSelection}}
                    enableRowSelection
                    getRowId={(originalRow) => originalRow.id}
                    selectionPadding={1}
                    selectionCss={{
                        backgroundColor: 'lightblue',
                        borderWidth: '2px',
                        borderStyle: 'solid',
                        borderColor: 'blue',
                        borderRadius: '4px',
                    }}
                    renderRowActions={({row, table}) => (
                        <Box sx={{
                            display: 'flex',
                            gap: '0.1rem',
                            justifyContent: 'flex-start',
                            maxWidth: '50px'
                        }}>
                            <Tooltip arrow placement="left" title="Редактировать">
                                <Button size="small" sx={{minWidth: 'auto', marginLeft: 'auto'}} color="pencil"
                                        onClick={() => handleUpdateModel(row)}>
                                    <Edit sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                                </Button>
                            </Tooltip>
                            <Tooltip arrow placement="right" title="Удалить">
                                <Button size="small" sx={{minWidth: 'auto', marginLeft: 'auto'}} color="error"
                                        onClick={() => handleDeleteRow(row)}>
                                    <Delete sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                                </Button>
                            </Tooltip>
                            <Tooltip arrow placement="right" title="Редактировать материалы">
                                <Button size="small" sx={{minWidth: 'auto', marginLeft: 'auto'}} color="success"
                                        onClick={() => redirectByArrivalMaterialId(row)}>
                                    <PlaylistAdd sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                                </Button>
                            </Tooltip>
                        </Box>
                    )}
                    localization={MRT_Localization_RU}
                    renderTopToolbarCustomActions={({table}) => {
                        const mergeAM = () => {
                            const selectedRows = table.getSelectedRowModel().flatRows;
                            const selectedIds = selectedRows.map((row) => row.getValue('id'));
                            const selectedIndexRows = selectedRows.map((row) => row.index);
                            setSelectedIds(selectedIds);
                            setRowIndexSelection(selectedIndexRows);
                            setShowMergeArrivalMaterial(true);
                        };

                        return (
                            <div>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        gap: '0.5rem',
                                        paddingLeft: '0.5rem',
                                        paddingTop: '0.5rem',
                                        paddingBottom: '0.2rem',
                                        flexWrap: 'wrap',
                                    }}
                                >
                                    {!isCreateOrUpdate && <Button
                                        color="secondary"
                                        onClick={() => redirectByArrivalMaterials()}
                                        variant="contained"
                                        size="small"
                                    >
                                        Вернуться в заявки
                                    </Button>}
                                    {isCreateOrUpdate && <Button
                                        color="success"
                                        onClick={() => handleCreateRow()}
                                        variant="contained"
                                        size="small"
                                    >
                                        Создать приход материалов
                                    </Button>}
                                    <Button
                                        color="success"
                                        onClick={() => mergeAM()}
                                        variant="contained"
                                        size="small"
                                        disabled={!unlockCurrencyAndSupplierAndCountRows()}
                                    >
                                        Объединить заявки
                                    </Button>
                                </Box>
                                <Box
                                    sx={{display: 'flex', gap: '0.5rem', p: '0.5rem', flexWrap: 'wrap'}}
                                >
                                    <Button
                                        color="secondary"
                                        onClick={() => redirectReservationPieceMaterial()}
                                        variant="contained"
                                        size="small"
                                    >
                                        Резервы фурнитуры
                                    </Button>
                                    <Button
                                        color="secondary"
                                        onClick={() => redirectReservationSlabMaterial()}
                                        variant="contained"
                                        size="small"
                                    >
                                        Резервы плит. мат.
                                    </Button>
                                    <Button
                                        color="secondary"
                                        onClick={() => redirectReservationEdgeMaterial()}
                                        variant="contained"
                                        size="small"
                                    >
                                        Резервы погон. мат.
                                    </Button>
                                    {isCreateOrUpdate && <Button
                                        color="secondary"
                                        onClick={() => redirectByArrivalMaterialsArchive()}
                                        variant="contained"
                                        size="small"
                                    >
                                        Архив
                                    </Button>}
                                </Box>
                            </div>
                        );
                    }}
                />}
                <ModalConfirm
                    title={"Вы действительно хотите удалить?"}
                    modalOpen={showConfirmationModalDelete}
                    handleClose={cancelDelete}
                    handleSubmit={handleDeleteRowWithConfirm}
                    action={'Удалить'}
                />
                <ModalConfirm
                    title={"Вы действительно хотите oбъединить заявки?"}
                    modalOpen={showMergeArrivalMaterial}
                    handleClose={cancelMergeArrivalMaterial}
                    handleSubmit={handleMergeArrivalMaterial}
                    action={'Объединить'}
                />
                <ModalUpdateOrAddMaterialArrival
                    values={currentEditRowData}
                    setValues={setCurrentEditRowData}
                    open={createOrUpdateModalOpen}
                    handleClose={() => handleCloseCreateOrUpdateModalOpen()}
                    date={date}
                    setDate={setDate}
                    materialDeliveryDate={materialDeliveryDate}
                    setMaterialDeliveryDate={setMaterialDeliveryDate}
                    currencyList={currencyList}
                    currencyId={currencyId}
                    setCurrencyId={setCurrencyId}
                    supplierList={supplierList}
                    supplierId={supplierId}
                    setSupplierId={setSupplierId}
                    warehouseList={warehouseList}
                    warehouseId={warehouseId}
                    setWarehouseId={setWarehouseId}
                    handleCreateNewRow={handleCreateNewRow}
                    handleUpdate={handleUpdate}
                    handleSubmit={handleSubmit}
                    isNew={isNew}
                    isSupplier={true}
                />
            </div>
        );
    }
;
export default ArrivalMaterialTable;