export const materialCutDefault = {
    "WHOLE": "По листам",
    "BY_MIN_STRIPE": "По минимальной полосе",
    "BY_FIXED_STRIPE": "По фиксированной полосе",
    "BY_MIN_PRICE": "По минимальной стоимости",
    "BY_MIN_AREA": "По минимальной площади",
}

export const materialCut = {
    "WHOLE": "По листам",
    "BY_MIN_STRIPE": "По минимальной полосе",
    "BY_FIXED_STRIPE": "По фиксированной полосе",
}