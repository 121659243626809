export const calcPrevGroove = (data, height, side) => {
    const length = data.direction === "vertical" ? +data.width : +data.length
    const width = data.direction === "vertical" ? +data.length : +data.width
    const x = +data.x
    const y = +data.y
    const z = +data.z
    let startX, startY, nextX, nextY

    if (data.position === "RIGHT") {
        startX = side.x - (z * side.x / height)
        startY = y
        nextX = side.x - ((z - length) * side.x / height)
        nextY = y + width
    } else if (data.position === "LEFT") {
        startX = z * side.x / height
        startY = y
        nextX = (z + length) * side.x / height
        nextY = y + width
    } else if (data.position === "BACK") {
        startX = x
        startY = side.y - (z * side.y / height)
        nextX = x + length
        nextY = side.y -((z-width) * side.y / height)
    } else if (data.position === "FRONT") {
        startX = x
        startY = z * side.y / height
        nextX = x + length
        nextY = (z + width) * side.y / height
    } else {
        startX = x
        startY = y
        nextX = x + length
        nextY = y + width
    }

    return {
        position: data.position, lines: [
            {id: 1, line: [[startX, startY], [startX, nextY]]},
            {id: 2, line: [[startX, nextY], [nextX, nextY]]},
            {id: 3, line: [[nextX, nextY], [nextX, startY]]},
            {id: 4, line: [[nextX, startY], [startX, startY]]}
        ]
    }
}
