import axios from "axios";
import {apiVersionOrders, gatewayUrl, nameOrders} from "../../../../Constants";

const orderRestUrl = gatewayUrl + nameOrders  + apiVersionOrders + '/customers/orders'

export default class CustomerOrderService {

    static async getAllCustomerOrders() {
        return axios.get(orderRestUrl, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
}