import React, {useState} from 'react';
import {Checkbox} from "@mui/material";
import HeaderService from "../../../../../../API/orders/orders/organization/HeaderService";

const CellCheckboxMHTUnableModels = ({cell, id, change, setChange}) => {

    const [status, setStatus] = useState(cell.getValue);
    const handle = () => {
        HeaderService.unableForModels(id, !status)
            .then(function () {
                setStatus(!status)
                setChange(!change)
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    return (
        <div>
            <Checkbox onChange={handle}
                      checked={status}
                      sx={{'& .MuiSvgIcon-root': {fontSize: 28}}}
            />
        </div>
    );
};

export default CellCheckboxMHTUnableModels;