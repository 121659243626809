import React, {useState} from 'react';
import UploadFileForm from "../../../../form/UploadFileForm";
import FileService from "../../../../../API/orders/orders/organization/FileService";

export const UploadSignJobFileForm = ({label, change, setChange}) => {

    const [selectedFile, setSelectedFile] = useState(null);

    const handleUpload = async (event) => {
        event.preventDefault();
        FileService.uploadSignJobFile(selectedFile)
            .then(function () {
                setChange(!change)
                setSelectedFile(null)
                event.target.reset();
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    return (
        <div>
            <UploadFileForm
                handleUpload={handleUpload}
                selectedFiles={selectedFile}
                setSelectedFile={setSelectedFile}
                label={label}
            />
        </div>
    );
};