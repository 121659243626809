import React, {useState} from 'react';
import {Button, Box, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import TextFieldValidation from "../../../../../../components/UI/table/customer-detales/TextFieldValidation";
import {calcDistance} from "../../../../../../utils/processing/calcDistance";

const CutModalContent = ({
                             onClose,
                             onSubmit,
                             activePointId,
                             editableElementId,
                             setActivePointId,
                             cutData,
                             setCutData
                         }) => {

    const pointsArr = cutData.possible.filter(point => {
        const allPointsLieLine = point.next?.x * (point.curr?.y - point.prev?.y) - point.next?.y * (point.curr?.x - point.prev?.x) === point.prev?.x * point.curr?.y - point.curr?.x * point.prev?.y
        return !allPointsLieLine
    })


    const initFormState = {
        "distancePrev": "",
        "distanceNext": "",
        "pointId": editableElementId ? pointsArr.find(el => el.curr.id === activePointId).curr.id : pointsArr[0].curr.id
    }

    const [formState, setFormState] = useState(initFormState)
    const [validationState, setValidationState] = useState({
        "distancePrev": "", "distanceNext": "", "pointId": "",
    })

    const handleValidationData = () => {
        const newErrors = {...validationState}

        Object.entries(formState).forEach(([name, value]) => {
            const {prev, curr, next} = pointsArr.find((el) => el.curr.id === formState.pointId)

            const maxDistancePrev = calcDistance(prev, curr)
            const maxDistanceNext = calcDistance(curr, next)

            if (name === "distancePrev" && value > maxDistancePrev) {
                newErrors.distancePrev = `от 0 до ${maxDistancePrev.toFixed()}`
            } else if (name === "distanceNext" && value > maxDistanceNext) {
                newErrors.distanceNext = `от 0 до ${maxDistanceNext.toFixed()}`
            }

            if (value === "") {
                newErrors[name] = "пустое поле"
            }
        })

        setValidationState(newErrors)

        if (Object.values(newErrors).every(el => el === "")) {
            onSubmit({
                pointId: formState.pointId,
                params: {
                    distanceToPrevPoint: formState.distancePrev, distanceToNextPoint: formState.distanceNext
                }
            })
        }
    }
    const selectPoint = (getData) => {
        const data = getData()
        setFormState(values => ({...values, ...data}))
        setCutData((values) => ({...values, ...data}))
        data.pointId && setActivePointId(data.pointId)
    }

    return (<>
        <DialogTitle textAlign="center">Срез</DialogTitle>
        <DialogContent style={{paddingBottom: 7}}>
            <FormControl sx={{minWidth: 300}}>
                {!editableElementId && <TextFieldValidation
                    select
                    name="pointId"
                    label="Узел"
                    value={formState.pointId}
                    setCurrValues={selectPoint}
                    setValidationState={setValidationState}
                >
                    {pointsArr.map((option) => (
                        <MenuItem key={option.curr.id} value={option.curr.id}>
                            x= {+option.curr.x.toFixed(2)}, y= {+option.curr.y.toFixed(2)}
                        </MenuItem>))}
                </TextFieldValidation>}
                <TextFieldValidation
                    type={"number"}
                    name={"distancePrev"}
                    value={formState?.distancePrev}
                    label="К предыдущему узлу"
                    setCurrValues={selectPoint}
                    validationError={validationState?.distancePrev}
                    setValidationState={setValidationState}
                />
                <TextFieldValidation
                    type={"number"}
                    name={"distanceNext"}
                    value={formState?.distanceNext}
                    label="К следующему узлу"
                    setCurrValues={selectPoint}
                    validationError={validationState?.distanceNext}
                    setValidationState={setValidationState}
                />

            </FormControl>
        </DialogContent>
        <DialogActions sx={{p: '1.25rem'}}>
            <Button size="small" onClick={onClose}> отменить </Button>
            <Button size="small" color="secondary" onClick={handleValidationData} variant="contained">
                выбрать
            </Button>
        </DialogActions>
    </>)
}
export default CutModalContent
