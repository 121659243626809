import React from 'react';
import WorkerJobTable from "../../../components/UI/table/orders/organization/workerJobTable/WorkerJobTable";
import {useParams} from "react-router-dom";

const WorkerJob = () => {

    let params = useParams();

    return (
        <div>
            <h4>Отметить работников выполнившех работу: {params.jobName}</h4>
            <h4>Материал: {params.jobMaterial}</h4>
            <WorkerJobTable id = {params.id}/>
        </div>
    );
};

export default WorkerJob;