import React from 'react';
import {Button} from "@mui/material";
import {useNavigate} from "react-router-dom";

const ButtonSettingsStorage = () => {

    const navigate = useNavigate();

    const  redirectSettingsStorage = () => {
        navigate('/organization/settings-storage');
    };

    return (
        <div>
            <Button
                color="secondary"
                onClick={redirectSettingsStorage}
                variant="contained"
            >
                Вернуться к настройкам
            </Button>
        </div>
    );
};

export default ButtonSettingsStorage;