import axios from "axios";
import {apiVersionModelEstimate, gatewayUrl, modelEstimate} from "../../../Constants";

const materialEstimateUnitUrl = gatewayUrl + modelEstimate + apiVersionModelEstimate + '/model-material-estimate-units'

export default class ModelMaterialEstimateUnitService {

    static async getAllByEstimateId(modelEstimateId) {
        return axios.get(materialEstimateUnitUrl + '/by-estimateId' + '?modelEstimateId=' + modelEstimateId);
    }

    static async delete(id) {
        await axios.delete(materialEstimateUnitUrl + '/' + id);
    }

    static async update(data, estimateId) {
        console.log(data)
        return await axios.put(materialEstimateUnitUrl + '/' + data.id + '?estimateId=' + estimateId,
            JSON.stringify(data), {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
    }

    static async create(data, estimateId) {
        console.log(data)
        return await axios.post(materialEstimateUnitUrl + '?estimateId=' + estimateId,
            JSON.stringify(data), {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
    }
}