import React, {useEffect, useState} from 'react';
import {
    Button,
    Checkbox,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    InputAdornment
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import TextFieldValidation from "../../../../../../components/UI/table/customer-detales/TextFieldValidation";
import {SIDE} from "../../../../../../Constants";
import {noExponents} from "../../../../../../utils/processing/noExponents";
import {calcValueOfStringWithVar} from "../../Canvas/CanvasCalcPositions/calcValueOfStringWithVar";
import {hasOnlyDigitsAndSymbols} from "../../../../../../utils/processing/hasOnlyDigits";

const DIRECTION = [{value: 'vertical', label: 'По вертикали'}, {value: 'horizontal', label: 'По горизонтали'}]
const PROCESSED = [{value: 'saw', label: 'Пилой'}, {value: 'сut', label: 'Фрезой'}]
const initValidation = {
    "position": "", "direction": "", "length": "", "width": "", "depth": '', "x": "", "y": "", "z": "", "processed": ""
}
const GrooveModalContent = ({
                                type,
                                onClose,
                                onSubmit,
                                detailData,
                                openConfirmModal,
                                editableElementId,
                                setActivePointId,
                                calcPrevInnerContour,
                                variables
                            }) => {
    const initData = detailData.grooves.find(el => el.id === editableElementId)

    const initFormState = editableElementId ? {
        "position": initData.contour.position,
        "direction": initData.length < initData.width ? DIRECTION[0].value : DIRECTION[1].value,
        "depth": initData.variables.find(el => el.name === "DEPTH")?.pattern || initData.depth,
        "length": initData.length < initData.width ? initData.variables.find(el => el.name === "WIDTH")?.pattern || initData.width : initData.variables.find(el => el.name === "LENGTH")?.pattern || initData.length,
        "width": initData.length < initData.width ? initData.variables.find(el => el.name === "LENGTH")?.pattern || initData.length : initData.variables.find(el => el.name === "WIDTH")?.pattern || initData.width,
        "x": initData.basePoint.variables.find(el => el.name === "x")?.pattern || initData.basePoint.x,
        "y": initData.basePoint.variables.find(el => el.name === "y")?.pattern || initData.basePoint.y,
        "z": initData.basePoint.variables.find(el => el.name === "z")?.pattern || initData.basePoint.z,
        "processed": initData.processedBySaw ? PROCESSED[0].value : PROCESSED[1].value,
        "toolRadiusCompensation": initData.toolRadiusCompensation
    } : {
        "position": SIDE[0].value,
        "direction": DIRECTION[0].value,
        "length": "",
        "width": "",
        "depth": '',
        "x": "",
        "y": "",
        "z": "0",
        "processed": PROCESSED[0].value,
        "toolRadiusCompensation": false
    }
    const [formState, setFormState] = useState(initFormState)
    const [validationState, setValidationState] = useState(initValidation)


    const handleValidationData = () => {
        const newErrors = {...validationState}
        const positionSide = ["LEFT", "RIGHT", "FRONT", "BACK"].includes(formState.position)

        const calcValues = Object.fromEntries(Object.entries(formState).map(([name, value]) => {
            return ["processed", "toolRadiusCompensation", "position", "direction"].include(name) || !hasOnlyDigitsAndSymbols(value) ? [name, value] : [name, calcValueOfStringWithVar(variables, value.toString())]
        }))

        Object.entries(calcValues).forEach(([name, value]) => {
            const maxLength = formState.direction === "vertical" ? detailData.width + +formState.y : detailData.length + +formState.x

            if (!positionSide && name === "depth" && +value > detailData.height) {
            } else if (name === "width" && +value > detailData.height) {
                newErrors.width = `Ширина должна быть < ${detailData.height}`
            } else if (name === "length" && +value > maxLength) {
                newErrors.length = `начальная точка + длинна должно быть < ${maxLength}`
            }

            if (value === "") {
                newErrors[name] = "пустое поле"
            }
        })

        setValidationState(newErrors)

        if (Object.values(newErrors).every(el => el === "")) {
            onSubmit({
                "basePoint": {x: formState.x, y: formState.y, z: formState.z},
                "length": formState.direction === "vertical" ? formState.width : formState.length,
                "width": formState.direction === "vertical" ? formState.length : formState.width,
                "depth": formState.depth,
                "position": formState.position,
                "processedBySaw": formState.processed === "saw",
                "toolRadiusCompensation": formState.toolRadiusCompensation
            })
        }

    }

    const inputHandler = (getData) => {
        const data = getData()
        const currField = Object.keys(data)[0]

        let xData = currField === "x" ? data.x : formState.x,
            yData = currField === "y" ? data.y : formState.y,
            zData = currField === "z" ? data.z : formState.z,
            direction = currField === "direction" ? data.direction : formState.direction

        if (["TOP", "BOTTOM"].includes(data.position)) {
            xData = ""
            yData = ""
            zData = 0
        } else if (data.position === "LEFT") {
            yData = ""
            zData = ""
            xData = 0
            direction = "vertical"
        } else if (data.position === "RIGHT") {
            yData = ""
            zData = ""
            xData = detailData.length
            direction = "vertical"
        } else if (data.position === "FRONT") {
            xData = ""
            zData = ""
            yData = 0
            direction = "horizontal"
        } else if (data.position === "BACK") {
            xData = ""
            zData = ""
            yData = detailData.width
            direction = "horizontal"
        }

        const currState = {...formState, ...data, x: xData, y: yData, z: zData, direction: direction}

        setFormState(currState)

        data.pointId && setActivePointId(data.pointId)

        if ([currState.x, currState.y, currState.width, currState.length].every(str => str.toString().trim() !== '')) {
            calcPrevInnerContour({
                "position": currState.position,
                "direction": currState.direction,
                "length": currState.length,
                "width": currState.width,
                "x": currState.x,
                "y": currState.y,
            }, false)
        }
    }

    return (<>
        <DialogTitle
            textAlign="center">{type === "groove" ? "Добавить паз" : "Изменить паз"}</DialogTitle>
        <DialogContent style={{paddingBottom: 7}}>
            <FormControl sx={{minWidth: 320}}>
                <TextFieldValidation
                    select
                    name="position"
                    label="Позиция"
                    value={formState.position}
                    setCurrValues={inputHandler}
                    setValidationState={setValidationState}
                >
                    {SIDE.map((option) => (<MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>))}
                </TextFieldValidation>
                <TextFieldValidation
                    select
                    name="direction"
                    label="Направление"
                    value={formState.direction}
                    setCurrValues={inputHandler}
                    setValidationState={setValidationState}
                    disabled={!["TOP", "BOTTOM"].includes(formState.position)}
                >
                    {DIRECTION.map((option) => (<MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>))}
                </TextFieldValidation>
                <TextFieldValidation
                    select
                    name="processed"
                    label="Обработка"
                    value={formState.processed}
                    validationError={validationState?.processed}
                    setCurrValues={inputHandler}
                    setValidationState={setValidationState}
                >
                    {PROCESSED.map((option) => (<MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>))}
                </TextFieldValidation>
                <TextFieldValidation
                    name="length"
                    label="Длинна"
                    value={formState.length}
                    setCurrValues={inputHandler}
                    validationError={validationState?.length}
                    setValidationState={setValidationState}
                    adornment={editableElementId ? initData.length < initData.width ? +parseFloat(noExponents(initData?.width)).toFixed(2) : +parseFloat(noExponents(initData?.length)).toFixed(2) : ""}
                />
                <TextFieldValidation
                    name={"width"}
                    value={formState?.width}
                    label="Ширина"
                    setCurrValues={inputHandler}
                    validationError={validationState?.width}
                    setValidationState={setValidationState}
                    adornment={editableElementId ? initData.length < initData.width ? +parseFloat(noExponents(initData?.length)).toFixed(2) : +parseFloat(noExponents(initData?.width)).toFixed(2) : ""}
                />
                <TextFieldValidation
                    name={"depth"}
                    value={formState?.depth}
                    label="Глубина"
                    setCurrValues={inputHandler}
                    validationError={validationState?.depth}
                    setValidationState={setValidationState}
                    adornment={editableElementId && +parseFloat(noExponents(initData?.depth)).toFixed(2)}
                />
                <TextFieldValidation
                    name={"x"}
                    value={formState?.x}
                    label="X"
                    setCurrValues={inputHandler}
                    validationError={validationState?.x}
                    setValidationState={setValidationState}
                    disabled={!!["LEFT", "RIGHT"].includes(formState.position)}
                    adornment={editableElementId && +parseFloat(noExponents(initData?.basePoint?.x)).toFixed(2)}
                />
                <TextFieldValidation
                    name={"y"}
                    value={formState?.y}
                    label="Y"
                    setCurrValues={inputHandler}
                    validationError={validationState?.y}
                    setValidationState={setValidationState}
                    disabled={!!["FRONT", "BACK"].includes(formState.position)}
                    adornment={editableElementId && +parseFloat(noExponents(initData?.basePoint?.y)).toFixed(2)}
                />
                <TextFieldValidation
                    name={"z"}
                    value={formState?.z}
                    label="z"
                    setCurrValues={inputHandler}
                    validationError={validationState?.z}
                    setValidationState={setValidationState}
                    disabled={!!["TOP", "BOTTOM"].includes(formState.position)}
                    adornment={
                        `${formState.position === "RIGHT" ? "🡸"
                            : formState.position === "LEFT" ? "🡺"
                                : formState.position === "FRONT" ? "🡹"
                                    : formState.position === "BACK" ? "🡻"
                                        : ""} ${editableElementId ? +parseFloat(noExponents(initData?.basePoint?.z)).toFixed(2) : ""}`}
                />
                <FormControlLabel control={<Checkbox
                    checked={formState.toolRadiusCompensation}
                    onChange={() => setFormState(value => ({
                        ...value,
                        "toolRadiusCompensation": !value.toolRadiusCompensation
                    }))}
                />} label="Корректировка радиуса резца"/>


                {editableElementId && <Button
                    size="small"
                    color="secondary"
                    onClick={() => openConfirmModal("deleteGroove", editableElementId)}
                    variant="outlined"
                >
                    Удалить паз
                </Button>}
            </FormControl>
        </DialogContent>
        <DialogActions sx={{p: '1.25rem'}}>
            <Button size="small" onClick={onClose}> отменить </Button>
            <Button size="small" color="secondary" onClick={handleValidationData} variant="contained">
                выбрать
            </Button>
        </DialogActions>
    </>)
}
export default GrooveModalContent
