import axios from "axios";
import {apiVersionMaterialCloud, gatewayUrl, nameMaterialCloud} from "../../../Constants";

const restManufacturerUrl = gatewayUrl + nameMaterialCloud + apiVersionMaterialCloud + '/user/manufacturers'

export default class UserManufacturerMaterialCloudService {

    static async getAll() {
        return axios.get(restManufacturerUrl, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
}