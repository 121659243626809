import React from 'react';
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import MaterialPageItemModule from "../../../../components/UI/modul/MaterialPageItemModule";

const ReplacementSimilarMaterials = ({
                                         open,
                                         setOpen,
                                         currentEditRowData,
                                         setCurrentEditRowData,
                                         replacement
                                     }) => {

    const onClose = () => {
        setOpen(false);
        setCurrentEditRowData(null)
    };

    const selectMaterial = (material) => {
        setCurrentEditRowData(material)
    }

    return (
        <div>
            <Dialog open={open} fullWidth={true} maxWidth='lg'>
                <DialogTitle textAlign="center">Заменить материал</DialogTitle>
                <DialogContent>
                    <Box style={{
                        height: 24,
                        marginTop: 30,
                        padding: 2,
                        display: "flex",
                        alignItems: "center",
                        border: currentEditRowData ? "red 1px solid" : "transparent 1px solid"
                    }}>
                        <Box style={{marginRight: 40}}>{currentEditRowData.articleNumber}</Box>
                        <Box> {currentEditRowData.name}</Box>
                    </Box>
                    <Box style={{height: 700}}>
                        <MaterialPageItemModule
                            pageSize={30}
                            selectMaterial={selectMaterial}
                        />
                    </Box>
                </DialogContent>
                <DialogActions sx={{p: '1.25rem'}}>
                    <Button onClick={onClose}>Выйти</Button>
                    <Button onClick={replacement}>Замена</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ReplacementSimilarMaterials;