import React from 'react';
import {Button, Input} from "@mui/material";

const UploadFileForm = ({handleUpload, setSelectedFile, label}) => {

    const handleFileSelect = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    return (
        <div>
            <form onSubmit={handleUpload} style={{display: "flex", gap: "10px"}}>
                <Input type="file"
                       onChange={handleFileSelect}
                       disableUnderline={true}
                       sx={{
                           height: 30,
                           padding: "2px",
                           fontWeight: 400,
                           fontSize: "0.875rem",
                           lineHeight: 1.75,
                           letterSpacing: "0.02857em",
                           borderRadius: "4px",
                           border: '1px solid #3B8BEB',
                           zIndex: 0,
                       }}/>
                <Button
                    variant="contained"
                    color="primary"
                    sx={{
                        padding: "5px",
                        fontWeight: 500,
                        fontSize: "0.7rem",
                        lineHeight: 1.75,
                        letterSpacing: "0.02857em",
                        borderRadius: "4px",
                        border: '1px solid #3B8BEB',
                        zIndex: 0,
                        bgcolor: (theme) => theme.palette.primary.main,
                        "&:hover": {
                            bgcolor: (theme) => theme.palette.primary.main,
                            boxShadow: "0 0 0 0.2rem #C4DBF6"
                        }
                    }}
                    type="submit"
                    value={label}
                >
                    {label}
                </Button>
            </form>
        </div>
    );
};

export default UploadFileForm;