import React, {useCallback, useEffect, useState} from 'react';
import SupplierMaterialTable from "../../table/warehouse/material/supplier/SupplierMaterialTable";
import CurrencyService from "../../../../API/orders/warehouse/currency/CurrencyService";
import ModalCreateOrUpdateSupplierMaterial
    from "../../table/warehouse/material/supplier/ModalCreateOrUpdateSupplierMaterial";
import SupplierMaterialUtil from "../../../../utils/warehouse/SupplierMaterialUtil";
import Box from "@mui/material/Box";
import {TabContext, TabPanel} from "@mui/lab";
import {Tabs} from "@mui/material";
import Tab from "@mui/material/Tab";
import MaterialStripeTable from "../../table/warehouse/material/materialStripe/MaterialStripeTable";
import SupplierService from "../../../../API/orders/warehouse/supplier/SupplierService";

const SupplierMaterialItemModule = ({
                                        materialId,
                                        baseUnit,
                                        typeMaterial,
                                        dataMaterialWholeData,
                                        dataMaterialMinStripeData,
                                        dataMaterialFixedStripeData,
                                        setDataMaterialWholeData,
                                        setDataMaterialMinStripeData,
                                        setDataMaterialFixedStripeData,
                                        allPrice,
                                        create,
                                        update,
                                        urlArchiveSM,
                                        urlArchiveMS,
                                        urlArchiveFS,
                                        addArchive,
                                        isFixPrice
                                    }) => {

    const [createOrUpdateModalOpen, setCreateOrUpdateModalOpen] = useState(false);
    const [isNew, setIsNew] = useState(null);
    const [currentEditRowId, setCurrentEditRowId] = useState(null);

    const [currencyList, setCurrencyList] = useState([]);
    const [currencyId, setCurrencyId] = useState(null);

    const [supplierList, setSupplierList] = useState(null);
    const [supplierId, setSupplierId] = useState(null);
    const [price, setPrice] = useState(null);

    const [currentEditRowData, setCurrentEditRowData] = useState(null);

    useEffect(() => {

        SupplierService.getAllByOrganization().then(function (response) {
            setSupplierList(response.data);
        })
            .catch(function (error) {
                console.log(error);
            });
        CurrencyService.getAll().then(function (response) {
            let data = response.data;
            data.sort((a, b) =>
                a.defaultBase === b.defaultBase ? 0 : a.defaultBase ? -1 : 1
            );
            setCurrencyList(data);
        })
    }, []);

    const handleAddSupplierMaterial = () => {
        setIsNew(true);

        const basePrice = {
            id: null,
            currency: currencyList[0],
            price: "",
        };

        const supplierMaterial = {
            id: null,
            fixPrice: false,
            article: "",
            typeSupplierMaterial: "WHOLE",
            basePrice: basePrice,
        };

        setCurrentEditRowData(supplierMaterial);
        setCreateOrUpdateModalOpen(true);
    };

    const handleCloseCreateOrUpdate = () => {
        setCurrentEditRowData(null);
        setIsNew(null);
        setCurrencyId(null);
        setSupplierId(null);
        setPrice(null);
        setCreateOrUpdateModalOpen(false);
    };

    const handleSubmitCreateSupplierMaterial = () => {

        const currentSupplierId = supplierId === null ? supplierList[0].id : supplierId;
        const currentCurrencyId = currencyId === null ? currencyList[0].id : currencyId;

        create(currentEditRowData, materialId, currentSupplierId, currentCurrencyId, price).then(function (response) {
            let data = response.data;
            handleCreateNewRow(data)
            handleCloseCreateOrUpdate();
        })
            .catch(function (error) {
                console.log(error);
            });
    }

    const handleCreateNewRow = (values) => {
        SupplierMaterialUtil.deleteSupplierId(dataMaterialWholeData, supplierId === null ? supplierList[0].id : supplierId);
        setDataMaterialWholeData(dataMaterialWholeData);
        dataMaterialWholeData.push(values);
        setDataMaterialWholeData([...dataMaterialWholeData]);
    };

    const handleUpdateSupplierMaterial = useCallback(
        (row) => {
            setCurrentEditRowData(row.original);
            setCurrentEditRowId(row.index);
            setCreateOrUpdateModalOpen(true);
            setIsNew(false);
        },
        [setDataMaterialWholeData, currentEditRowData, setCurrentEditRowData],
    );

    const handleSubmitUpdateSupplierMaterial = () => {
        update(currentEditRowData,
        ).then(function (response) {
            let data = response.data;
            handleUpdate(data)
            handleCloseCreateOrUpdate();
        })
            .catch(function (error) {
                console.log(error);
            });
    }

    const handleUpdate = (values) => {
        dataMaterialWholeData[currentEditRowId] = values;
        setDataMaterialWholeData([...dataMaterialWholeData]);
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [value, setValue] = useState('0');

    return (
        <div>
            <Box sx={{flexGrow: 1}}>
                <TabContext value={value}>
                    <Box sx={{
                        borderBottom: 1, borderColor: 'divider'
                    }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Основной" value='0'/>
                            {typeMaterial === 'SLAB' && <Tab label="По минимальной полосе" value='1'/>}
                            {typeMaterial === 'SLAB' && <Tab
                                label="По фиксированной полосе"
                                value='2'
                            />}
                        </Tabs>
                    </Box>
                    <TabPanel value='0'>
                        <SupplierMaterialTable
                            data={dataMaterialWholeData}
                            setData={setDataMaterialWholeData}
                            handleAddSupplierMaterial={handleAddSupplierMaterial}
                            handleUpdateSupplierMaterial={handleUpdateSupplierMaterial}
                            isArchive={false}
                            materialId={materialId}
                            baseUnit={baseUnit}
                            typeMaterial={typeMaterial}
                            allPrice={allPrice}
                            urlArchive={urlArchiveSM}
                            addArchive={addArchive}
                        />
                    </TabPanel>
                    <TabPanel value='1'>
                        <Box
                            sx={{
                                display: 'flex',
                                gap: '0.5rem',
                                p: '0.5rem',
                                flexWrap: 'wrap',
                                flexDirection: 'column'
                            }}
                        >
                            <MaterialStripeTable
                                data={dataMaterialMinStripeData}
                                setData={setDataMaterialMinStripeData}
                                materialId={materialId}
                                supplierList={supplierList}
                                isArchive={false}
                                urlArchive={urlArchiveMS}
                                baseUnit={baseUnit}
                                typeMaterial={typeMaterial}
                                currencyList={currencyList}
                                setSupplierId={setSupplierId}
                                setCurrencyId={setCurrencyId}
                                price={price}
                                setPrice={setPrice}
                                supplierId={supplierId}
                                currencyId={currencyId}
                                isMin={true}
                                allPrice={allPrice}
                                create={create}
                                update={update}
                                addArchive={addArchive}
                            />
                        </Box>
                    </TabPanel>
                    <TabPanel value='2'>
                        <Box
                            sx={{
                                display: 'flex',
                                gap: '0.5rem',
                                p: '0.5rem',
                                flexWrap: 'wrap',
                                flexDirection: 'column'
                            }}
                        >
                            <MaterialStripeTable
                                data={dataMaterialFixedStripeData}
                                setData={setDataMaterialFixedStripeData}
                                materialId={materialId}
                                supplierList={supplierList}
                                isArchive={false}
                                urlArchive={urlArchiveFS}
                                baseUnit={baseUnit}
                                typeMaterial={typeMaterial}
                                currencyList={currencyList}
                                setSupplierId={setSupplierId}
                                setCurrencyId={setCurrencyId}
                                price={price}
                                setPrice={setPrice}
                                supplierId={supplierId}
                                currencyId={currencyId}
                                isMin={false}
                                allPrice={allPrice}
                                create={create}
                                update={update}
                                addArchive={addArchive}
                            />
                        </Box>
                    </TabPanel>
                </TabContext>
            </Box>

            <ModalCreateOrUpdateSupplierMaterial
                open={createOrUpdateModalOpen}
                onClose={handleCloseCreateOrUpdate}
                title={isNew ? 'Добавить базовую цену поставщика' : 'Редактировать базовую цену поставщика'}
                values={currentEditRowData}
                setValues={setCurrentEditRowData}
                labelButtonSSubmit={isNew ? 'Создать' : 'Обновить'}
                handleSubmit={isNew ? handleSubmitCreateSupplierMaterial : handleSubmitUpdateSupplierMaterial}
                supplierList={supplierList}
                setSupplierId={setSupplierId}
                currencyList={currencyList}
                setCurrencyId={setCurrencyId}
                price={price}
                setPrice={setPrice}
                isNew={isNew}
                isFixPrice={isFixPrice}
            />
        </div>
    );
};

export default SupplierMaterialItemModule;