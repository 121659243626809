import React from 'react';
import RequestsCuttingTable from "../../../../components/UI/table/optimization/requestscutting/RequestsCuttingTable";
import RequestsCuttingService from "../../../../API/orders/optimization/organization/RequestsCuttingService";
import {useParams} from "react-router-dom";
import Typography from "@mui/material/Typography";

const RequestsCuttingOptimization = () => {

    const params = useParams();

    let optimizationId = params.optimizationId;
    const name = params.name;

    return (
        <div>
            <Typography variant="h6" component="h6">
                Заявки на раскрой оптимизации {name}
            </Typography>
            <RequestsCuttingTable
                getAll={RequestsCuttingService.getAllWithMaterialByOptimizationId}
                optimizationId={optimizationId}
                isOptimization={false}
                isRedirectRequestsCutting={true}
                isAddOptimization={true}
            />
        </div>
    );
};

export default RequestsCuttingOptimization;