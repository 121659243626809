export const workingCuttingMapStatuses = {
    "CALCULATION": "Расчетная",
    "EXPECTATION": "Ожидание",
    "RESERVES_CREATED": "Созданы резервы",
    "RESERVES_CANCEL": "Резервы отменены",
    "MAPS_APPROVED": "Утверждены карты",
    "MAPS_CANCEL": "Карты отменены",
    "FOR_CUTTING": "Кроить",
    "CUTTING_STARTED": "Начат раскрой",
    "CUT_OUT": "Раскроены",
    "ARCHIVE": "Архив",
    "CALCULATION_RESERVES_CREATED": "Созданы рас. резервы",
    "CALCULATION_RESERVES_CANCEL": "Рас. резервы отменены",
    "ARCHIVE_CALCULATION": "Архив",
}