import React, {useMemo} from 'react';
import MaterialReactTable from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import Box from "@mui/material/Box";
import PriceWithCurrency from "../PriceWithCurrency";
import FilterUtil from "../../../../../../utils/common/FilterUtil";
import CellCheckbox from "../../../../сheckbox/CellCheckbox";
import {materialWarehouseStatuses} from "../../../../../../data/MaterialWarehouseState";

const MaterialWarehouseTableRMEU = ({
                                        data,
                                        setData,
                                        isColumnReserve,
                                        selectMaterial,
                                        isPriceInCurrencyArrival,
                                        isPriceInCurrencyBase,
                                        isPriceInCurrencyAlternative,
                                        isWarehouse,
                                        isPlace,
                                        baseCurrency,
                                        alternativeCurrency


                                    }) => {

        const columns = useMemo(() => {
            const baseColumns = [
                {
                    id: 'cutOff',
                    header: 'Обрезок',
                    accessorKey: 'materialPriceUnit.cutOff',
                    size: 8,
                    enableEditing: false,
                    filterVariant: 'multi-select',
                    filterSelectOptions: FilterUtil.statusList,
                    filterFn: (row, id, filterValue) => {
                        return FilterUtil.checkStatus(row.getValue(id), filterValue)
                    },
                    Cell: ({cell, row}) => <CellCheckbox
                        cell={cell}
                        disabled={true}
                    />,
                },
                {
                    id: 'length',
                    header: 'W',
                    accessorKey: 'materialPriceUnit.length',
                    minSize: 10,
                    maxSize: 10,
                },
                {
                    id: 'width',
                    header: 'H',
                    accessorKey: 'materialPriceUnit.width',
                    minSize: 10,
                    maxSize: 10,
                },
                {
                    id: 'trimming',
                    header: 'Отступы',
                    accessorKey: 'materialPriceUnit.trimmingLeft',
                    minSize: 10,
                    maxSize: 10,
                    Cell: ({row}) => row.original.materialPriceUnit.cutOff ? '['
                        + row.original.materialPriceUnit.trimmingLeft + ', '
                        + row.original.materialPriceUnit.trimmingRight + ', '
                        + row.original.materialPriceUnit.trimmingTop + ', '
                        + row.original.materialPriceUnit.trimmingBottom + ']' : '',
                },
                {
                    id: 'materialPriceUnit.quantity',
                    header: 'Кол.',
                    accessorKey: 'materialPriceUnit.quantity',
                    minSize: 10,
                    maxSize: 10,
                    Cell: ({cell}) => {
                        const value = cell.getValue();
                        return value === null || value === undefined ? 'Ожидание' : value.toFixed(3)
                    },
                },
                {
                    id: 'quantity',
                    header: 'Наличие.',
                    accessorKey: 'quantity',
                    size: 8,
                    maxSize: 8,
                    Cell: ({cell}) => {
                        const value = cell.getValue();
                        return value === null || value === undefined ? 'Ожидание' : value.toFixed(3)
                    },
                },
                {
                    id: 'materialWarehouseState',
                    header: 'Статус',
                    accessorKey: 'materialWarehouseState',
                    size: 8,
                    maxSize: 8,
                    Cell: ({cell}) => materialWarehouseStatuses[cell.getValue()],
                },
                {
                    id: 'note',
                    header: 'Ком.',
                    accessorKey: 'note',
                    size: 8,
                    maxSize: 8,
                },
                {
                    header: 'id',
                    id: 'id',
                    accessorKey: 'id',
                    enableColumnActions: false,
                    enableGrouping: false,
                    enableEditing: false,
                    enableHiding: false,
                },
            ];

            if (isColumnReserve) {
                baseColumns.splice(7, 0, {
                    id: 'inReserve',
                    header: 'Резерв',
                    accessorKey: 'inReserve',
                    size: 8,
                    maxSize: 8,
                });
            }

            if (isPriceInCurrencyArrival) {
                baseColumns.splice(9, 0, {
                    id: 'PriceInCurrencyArrival',
                    header: 'Цена в валюте прихода',
                    accessorKey: 'materialPriceUnit.materialPriceInCurrencyArrival.price',
                    size: 8,
                    maxSize: 8,
                    enableEditing: false,
                    Cell: ({cell, row}) => row.original.materialPriceUnit.materialPriceInCurrencyArrival === null ? '' :
                        <PriceWithCurrency cell={cell} row={row}/>,
                });
            }
            if (isPriceInCurrencyBase) {
                baseColumns.splice(10, 0, {
                    id: 'PriceInCurrencyBase',
                    header: `Цена в ${baseCurrency}`,
                    accessorKey: 'materialPriceUnit.materialPriceInCurrencyBase.price',
                    size: 8,
                    maxSize: 8,
                    enableEditing: false,
                    Cell: ({
                               cell,
                               row
                           }) => row.original.materialPriceUnit.materialPriceInCurrencyBase === null ? '' : cell.getValue(),
                });
            }
            if (isPriceInCurrencyAlternative) {
                baseColumns.splice(11, 0, {
                    id: 'PriceInCurrencyAlternative',
                    header: `Цена в ${alternativeCurrency}`,
                    accessorKey: 'materialPriceUnit.materialPriceInCurrencyAlternative.price',
                    size: 8,
                    maxSize: 8,
                    enableEditing: false,
                    Cell: ({
                               cell,
                               row
                           }) => row.original.materialPriceUnit.materialPriceInCurrencyAlternative === null ? '' : cell.getValue(),
                });

            }
            if (isWarehouse) {
                baseColumns.splice(12, 0, {
                    id: 'warehouse.name',
                    header: 'Склад',
                    accessorKey: 'warehouse.name',
                    size: 8,
                    maxSize: 8,
                    enableEditing: false,
                    Cell: ({cell, row}) => row.original.warehouse === null ? '' : cell.getValue(),
                });
            }

            if (isPlace) {
                baseColumns.splice(13, 0, {
                    id: 'place',
                    header: 'Место',
                    accessorKey: 'warehouse.name',
                    size: 8,
                    maxSize: 8,
                    enableEditing: false,
                });
            }

            return baseColumns;

        }, [data, setData]);

        return (
            <div>
                <MaterialReactTable
                    muiTableBodyProps={{
                        sx: () => ({
                            '& tr:nth-of-type(odd)': {
                                backgroundColor: 'lightgray',
                            },
                        }),
                    }}
                    muiTableBodyCellProps={{
                        sx: {
                            p: '0px 0px 0px 10px',
                            m: '0px 0px 0px 0px',
                        },
                    }}
                    muiTableBodyRowProps={({row}) => ({
                        onClick: () => selectMaterial && selectMaterial(row.original),
                        sx: {
                            height: '10px',
                            cursor: selectMaterial && "pointer"
                        },
                    })}

                    displayColumnDefOptions={{
                        'mrt-row-actions': {
                            muiTableHeadCellProps: {
                                align: 'center',
                            },
                            size: 15,
                            minSize: 15,
                        },
                    }}
                    columns={columns}
                    data={data}
                    rowHeight={13}
                    columnSpacing={1}
                    initialState={{
                        columnVisibility: {
                            id: false,
                        },
                        isFullScreen: false,
                        density: "compact",
                        pagination: {pageIndex: 0, pageSize: 50},
                        sorting: [
                            {
                                id: 'materialWarehouseState',
                                desc: false,
                            },]
                    }}
                    enableEditing={false}
                    getRowId={(originalRow) => originalRow.id}
                    selectionPadding={1}
                    selectionCss={{
                        backgroundColor: 'lightblue',
                        borderWidth: '2px',
                        borderStyle: 'solid',
                        borderColor: 'blue',
                        borderRadius: '4px',
                    }}
                    renderRowActions={({row, table}) => (
                        <Box sx={{
                            display: 'flex',
                            gap: '0.1rem',
                            justifyContent: 'flex-start',
                            maxWidth: '50px'
                        }}>
                        </Box>
                    )}
                    localization={MRT_Localization_RU}
                    renderTopToolbarCustomActions={({table}) => {
                        return (
                            <div>
                                <Box
                                    sx={{display: 'flex', gap: '0.5rem', p: '0.5rem', flexWrap: 'wrap'}}
                                >
                                </Box>
                            </div>
                        );
                    }}
                />
            </div>
        );
    }
;

export default MaterialWarehouseTableRMEU;