import React, {useCallback, useMemo, useState} from 'react';
import MaterialReactTable from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import {Button, Stack, Tooltip} from "@mui/material";
import Box from "@mui/material/Box";
import {Delete} from "@mui/icons-material";
import MaterialEstimateUnitService
    from "../../../../../../API/orders/estimate/organization/MaterialEstimateUnitService";

const JobMetricOrderOrganizationTable = ({
                                             data,
                                             setData,
                                             baseCurrency,
                                             alternativeCurrency
                                         }) => {

    const [modalOpenCreate, setModalOpenCreate] = useState(false);

    const sumAmountBasePrice = useMemo(
        () => data.reduce((acc, mEU) => {
            if (mEU.baseWorth !== null) {
                return acc + mEU.baseWorth.price;
            } else {
                return acc;
            }
        }, 0),
        [data]
    );

    const sumAmountAlternativePrice = useMemo(
        () => data.reduce((acc, mEU) => {
            if (mEU.alternativeWorth !== null) {
                return acc + mEU.alternativeWorth.price;
            } else {
                return acc;
            }
        }, 0),
        [data]
    );

    const handleDeleteRow = useCallback(
        (row) => {
            const id = row.original.id;
            const materialId = row.original.id
            MaterialEstimateUnitService.delete(id)
                .then(function () {
                    /*   materialEstimateUnits.splice(row.index, 1);
                       setMaterialEstimateUnits([...materialEstimateUnits]);
                       materialPriceUnits.delete(materialId);*/
                })
                .catch(function (error) {
                    console.log(error);
                })
        },
        [/*materialEstimateUnits*/],
    );

    const handleCloseModalCreate = () => {
        setModalOpenCreate(false);
    };

    const handleOpenModalCreate = () => {
        setModalOpenCreate(false);
    };

    const createOptimizationByIds = () => {
        /*const requestsCuttingState = isCalculationOptimization ? 'FORMED_CALCULATION_OPTIMIZATION' : 'FORMED_OPTIMIZATION';
        OptimizationService.createOptimizationByIds(selectedIds, newRowDataOptimization)
            .then(function (response) {
                const newData = data.map(item => {
                    if (selectedIds.includes(item.id)) {
                        return {
                            ...item,
                            optimization: response.data,
                            requestsCuttingState: requestsCuttingState
                        };
                    } else {
                        return item;
                    }
                });
                setData(newData);
                handleCloseModalOpenCreateOptimization();
                setChange(!change)
            })
            .catch(function (error) {
                console.log(error);
            })*/
    };

    const columns = useMemo(
        () => [
            {
                id: "name",
                header: 'Материал',
                accessorKey: 'materialName',
                enableEditing: false,
                enableGrouping: true,
                minSize: 35,
            },
            {
                id: "metricName",
                header: 'Найменование операции',
                accessorKey: 'metricName',
                enableEditing: false,
                enableGrouping: true,
                minSize: 35,
            },
            {
                id: "unit",
                header: 'Ед.изм.',
                accessorKey: 'unitName',
                enableColumnActions: false,
                enableEditing: false,
                size: 8,
            },
            {
                header: 'Кол',
                accessorKey: 'quantity',
                enableColumnActions: false,
                enableEditing: false,
                Cell: ({cell, row}) => {
                    const value = cell.getValue();
                    return value === null || value === undefined ? 'Ожидание' : value.toFixed(3)
                },
            },
            {
                header: `Стоимость: ${baseCurrency}`,
                accessorKey: 'baseWorth.price',
                enableColumnActions: false,
                enableEditing: false,
                size: 8,
                Cell: ({cell, row}) => {
                    const value = cell.getValue();
                    return value === null || value === undefined ? 'Ожидание' : value.toFixed(2)
                },
                AggregatedCell: ({cell, table}) => (
                    <>
                        <Box sx={{
                            color: 'secondary.main',
                            fontWeight: 'bold'
                        }}>
                            {cell.getValue()?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2,
                            })}
                        </Box>
                    </>
                ),
                Footer: () => (
                    <Stack>
                        <Box color="warning.main">
                            {sumAmountBasePrice?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2,
                            })} {baseCurrency}
                        </Box>
                    </Stack>
                ),
            },
            {
                header: `Стоимость: ${alternativeCurrency}`,
                accessorKey: 'alternativeWorth.price',
                enableColumnActions: false,
                enableEditing: false,
                size: 8,
                Cell: ({cell, row}) => {
                    const value = cell.getValue();
                    return value === null || value === undefined ? 'Ожидание' : value.toFixed(2)
                },
                AggregatedCell: ({cell, table}) => (
                    <>
                        <Box sx={{
                            color: 'secondary.main',
                            fontWeight: 'bold'
                        }}>
                            {cell.getValue()?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2,
                            })}
                        </Box>
                    </>
                ),
                Footer: () => (
                    <Stack>
                        <Box color="warning.main">
                            {sumAmountAlternativePrice?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2,
                            })} {alternativeCurrency}
                        </Box>
                    </Stack>
                ),
            }
        ],
        [data, setData],
    );

    return (
        <div>
            <MaterialReactTable
                muiTableBodyProps={{
                    sx: () => ({
                        '& tr:nth-of-type(odd)': {
                            backgroundColor: 'lightgray',
                        },
                    }),
                }}
                muiTableBodyCellProps={{
                    sx: {
                        p: '0px 0px 0px 10px',
                        m: '0px 0px 0px 0px',
                    },
                }}
                muiTableBodyRowProps={{
                    sx: {
                        height: '10px',
                    },
                }}
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        size: 15,
                        minSize: 15,
                    },
                }}
                columns={columns}
                data={data}
                rowHeight={13}
                columnSpacing={1}
                enableExpanding={true}
                enableEditing={true}
                renderRowActions={({row, table}) => (
                    <Box sx={{
                        display: 'flex',
                        gap: '0.1rem',
                        justifyContent: 'flex-start',
                        maxWidth: '50px'
                    }}>
                        {true ? <Tooltip arrow placement="right" title="Удалить">
                            <Button
                                size="small"
                                sx={{minWidth: 'auto', marginLeft: 'auto'}}
                                color="error"
                                onClick={() => handleDeleteRow(row)}
                            >
                                <Delete sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                            </Button>
                        </Tooltip> : <Button
                            size="small"
                            sx={{minWidth: 'auto', marginLeft: 'auto'}}
                            color="error"
                            onClick={() => handleDeleteRow(row)}
                            disabled={true}
                        >
                            <Delete sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                        </Button>}
                    </Box>
                )}
                renderTopToolbarCustomActions={({table}) => {

                    return (
                        <div>
                            <Box
                                sx={{
                                    display: 'flex',
                                    gap: '0.5rem',
                                    paddingLeft: '0.5rem',
                                    paddingTop: '0.5rem',
                                    paddingBottom: '0.2rem',
                                    flexWrap: 'wrap',
                                }}
                            >
                                {/* {true && <Button
                                    size="small"
                                    color="success"
                                    onClick={setModalOpenCreate}
                                    variant="contained"
                                >
                                    Добавить
                                </Button>}*/}
                            </Box>
                        </div>
                    );
                }}
                initialState={{
                    expanded: true,
                    columnVisibility: {
                        id: false,
                    },
                    isFullScreen: false,
                    density: "compact",
                    pagination: {pageIndex: 0, pageSize: 50},
                    grouping: ['name'],
                    sorting: [{id: 'name', desc: false}, {id: 'metricName', desc: false}],
                }}
                getRowId={(originalRow) => originalRow.id}
                localization={MRT_Localization_RU}
            />
        </div>
    );
};

export default JobMetricOrderOrganizationTable;