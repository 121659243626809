import axios from "axios";
import {apiVersionOrders, gatewayUrl, nameOrders} from "../../../../Constants";

const restCurrencyUrl = gatewayUrl + nameOrders + apiVersionOrders + '/organizations/currencies'

export default class CurrencyService {

    static async getAll() {
        return axios.get(restCurrencyUrl, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
}