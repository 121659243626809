import React, {useState} from 'react';
import {Checkbox} from "@mui/material";

const CellCheckboxWithUnable = ({id, unable, setUnable, disabled}) => {

    const [checkboxStates, setCheckboxStates] = useState(unable);
    const [isChecked, setIsChecked] = useState(checkboxStates[id] || false);

    const handleCheckboxChange = (event) => {
        const newValue = event.target.checked;
        setUnable(id, newValue);
        setIsChecked(newValue);
        setCheckboxStates((prevState) => ({
            ...prevState,
            [id]: newValue,
        }));
    };

    return (
        <div style={{display: "flex", alignItems: "center", height: "13px", lineHeight: "13px"}}>
            <Checkbox
                disabled={disabled}
                onChange={handleCheckboxChange}
                size="small"
                checked={isChecked}
                sx={{"& .MuiSvgIcon-root": {fontSize: 20}}}
            />
        </div>
    );
};

export default CellCheckboxWithUnable;