import React from 'react';
import {Checkbox, Tooltip} from "@mui/material";

const CellCheckboxAMPUTFixPrice = ({price, fixPrice}) => {
    const value = price.price === null || price.price=== undefined ? 0 : price.price.toFixed(3)
    return (
        <div style={{display: 'flex', alignItems: 'center', height: '13px', lineHeight: '13px'}}>
            <div style={{marginLeft: '0px'}}>{value} {price.currency.curAbbreviation}</div>
            {fixPrice && <Tooltip title={'Фиксированная цена'}>
                <Checkbox checked={fixPrice}
                          size="small"
                          color="secondary"
                          sx={{'& .MuiSvgIcon-root': {fontSize: 17}}}
                />
            </Tooltip>}
        </div>
    );
};

export default CellCheckboxAMPUTFixPrice;
