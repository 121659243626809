import axios from "axios";
import {apiVersionDetail, gatewayUrl, nameDetail} from "../../Constants";

const detailControllerRestUrl = gatewayUrl + nameDetail + apiVersionDetail + '/customers/orders/'

export default class GrooveControllerService {
    static async add(orderId,detailId, data) {
        return await axios.post(`${detailControllerRestUrl}${orderId}/details/${detailId}/grooves`, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async delete(orderId,detailId, grooveId) {
        return await axios.delete(`${detailControllerRestUrl}${orderId}/details/${detailId}/grooves/${grooveId}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async put(orderId,detailId, grooveId,data) {
        return await axios.put(`${detailControllerRestUrl}${orderId}/details/${detailId}/grooves/${grooveId}`, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
}
