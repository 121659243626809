import React, {useCallback, useMemo, useState} from 'react';
import MaterialReactTable from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import Box from "@mui/material/Box";
import {Button, Stack, Tooltip} from "@mui/material";
import {Cached, Delete} from "@mui/icons-material";
import MaterialEstimateUnitService
    from "../../../../../../API/orders/estimate/organization/MaterialEstimateUnitService";
import ModalConfirm from "../../../../modal/ModalConfirm";
import EstimateService from "../../../../../../API/orders/estimate/organization/EstimateService";
import {useNavigate} from "react-router-dom";

const initColumn = {
    id: false,
    basePrice: true,
    baseJobPrice: true,
    baseTotalPrice: true,
    calculationCostBasePrice: true,
    costBasePrice: true,
    calculationCostBaseJobPrice: true,
    costBaseJobPrice: true,
    calculationTotalCostBasePrice: true,
    totalCostBasePrice: true,
    profitCalculationBaseMaterialPrice: true,
    profitBaseMaterialPrice: true,
    profitCalculationBaseJobPrice: true,
    profitBaseJobPrice: true,
    profitCalculationBaseTotalPrice: true,
    profitBaseTotalPrice: true,
    alternativePrice: false,
    alternativeJobPrice: false,
    alternativeTotalPrice: false,
    calculationCostAlternativePrice: false,
    costAlternativePrice: false,
    calculationCostAlternativeJobPrice: false,
    costAlternativeJobPrice: false,
    calculationTotalCostAlternativePrice: false,
    totalCostAlternativePrice: false,
    profitCalculationAlternativeMaterialPrice: false,
    profitAlternativeMaterialPrice: false,
    profitCalculationAlternativeJobPrice: false,
    profitAlternativeJobPrice: false,
    profitCalculationAlternativeTotalPrice: false,
    profitAlternativeTotalPrice: false,
}
const CalculationTable = ({
                              data,
                              setData,
                              baseCurrency,
                              alternativeCurrency,
                              estimateId,
                              orderId,
                              orderNumber,
                              orderHeaderId,
                              year,
                              isButtonPurchase,
                              isActual,
                              enableEditing,
                              inWork,
                              isFact,
                              detailed
                          }) => {

    const [currentEditRowData, setCurrentEditRowData] = useState(null);
    const [materialPriceUnits, setMaterialPriceUnits] = useState(new Map());
    const [columnVisibility, setColumnVisibility] = useState(initColumn)

    const [setPurchaseModalOpen, setSetPurchaseModalOpen] = useState(false);
    const [currencyIsBase, setCurrencyIsBase] = useState(true);

    const [change, setChange] = useState(false);
    const [currentInWork, setCurrentInWork] = useState(inWork);

    const [searchSimilarMaterials, setSearchSimilarMaterials] = useState(false);

    const handleColumnVisibilityChange = () => {
        setCurrencyIsBase(!currencyIsBase)
        const newColumn = Object.entries(columnVisibility).map(el => el[0] === "id" ? el : [el[0], !el[1]])
        setColumnVisibility(Object.fromEntries(newColumn))
    };

    const navigate = useNavigate();

    const redirectEstimateItem = () => {
        navigate('/organization/orders/' + orderId + '/' + orderNumber + '/' + orderHeaderId + '/' + year + '/estimate');
    };

    const sumAmountBaseTotalPrice = useMemo(
        () => data.reduce((acc, mEU) => {
            if (mEU.baseTotalPrice !== null) {
                return acc + mEU.baseTotalPrice.price;
            } else {
                return acc;
            }
        }, 0),
        [data]
    );

    const sumAmountAlternativeTotalPrice = useMemo(
        () => data.reduce((acc, mEU) => {
            if (mEU.alternativeTotalPrice !== null) {
                return acc + mEU.alternativeTotalPrice.price;
            } else {
                return acc;
            }
        }, 0),

        [data]
    );

    const sumCalculationAmountBasePrice = useMemo(
        () => data.reduce((acc, mEU) => {
            if (mEU.calculationCostBasePrice !== null) {
                return acc + mEU.calculationCostBasePrice.price;
            } else {
                return acc;
            }
        }, 0),
        [data]
    );

    const sumProfitCalculationBaseMaterialPrice = useMemo(
        () => data.reduce((acc, mEU) => {
            if (mEU.profitCalculationBaseMaterialPrice !== null) {
                return acc + mEU.profitCalculationBaseMaterialPrice.price;
            } else {
                return acc;
            }
        }, 0),
        [data]
    );

    const sumProfitCalculationBaseJobPrice = useMemo(
        () => data.reduce((acc, mEU) => {
            if (mEU.profitCalculationBaseJobPrice !== null) {
                return acc + mEU.profitCalculationBaseJobPrice.price;
            } else {
                return acc;
            }
        }, 0),
        [data]
    );

    const sumProfitCalculationBaseTotalPrice = useMemo(
        () => data.reduce((acc, mEU) => {
            if (mEU.profitCalculationBaseTotalPrice !== null) {
                return acc + mEU.profitCalculationBaseTotalPrice.price;
            } else {
                return acc;
            }
        }, 0),
        [data]
    );

    const averageCalculationGrossMargin = useMemo(
        () => {
            if (data.length === 0) {
                return 0;
            }
            const totalGrossMargin = data.reduce((acc, mEU) => acc + mEU.calculationGrossMargin, 0);
            return totalGrossMargin / data.length;
        },
        [data]
    );

    const sumCalculationCostJobAmountBasePrice = useMemo(
        () => data.reduce((acc, mEU) => {
            if (mEU.calculationCostBaseJobPrice !== null) {
                return acc + mEU.calculationCostBaseJobPrice.price;
            } else {
                return acc;
            }
        }, 0),
        [data]
    );

    const sumCalculationCostTotalAmountBasePrice = useMemo(
        () => data.reduce((acc, mEU) => {
            if (mEU.calculationTotalCostBasePrice !== null) {
                return acc + mEU.calculationTotalCostBasePrice.price;
            } else {
                return acc;
            }
        }, 0),
        [data]
    );

    const sumCalculationAmountAlternativePrice = useMemo(
        () => data.reduce((acc, mEU) => acc + mEU.calculationAmountAlternativePrice, 0),
        [data]
    );

    const handleDeleteRow = useCallback(
        (row) => {
            const id = row.original.id;
            const materialId = row.original.id
            MaterialEstimateUnitService.delete(id)
                .then(function () {
                    data.splice(row.index, 1);
                    setData([...data]);
                    materialPriceUnits.delete(materialId);
                })
                .catch(function (error) {
                    console.log(error);
                })
        },
        [data],
    );

    const handleSubmitSetPurchase = async () => {
        EstimateService.setPurchase(estimateId)
            .then(function () {
                handleCloseSetPurchase()
                setCurrentInWork(false);
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    const handleCloseSetPurchase = () => {
        setSetPurchaseModalOpen(false);
    };

    const openSearchSimilarMaterials = useCallback(
        (row) => {
            setCurrentEditRowData(row.original);
            setSearchSimilarMaterials(true)
        },
        [],
    );

    const materialReplacement = () => {
        setSetPurchaseModalOpen(false);
    };

    const selectInMaterialOOId = async (row) => {
        const id = currentEditRowData.id
        MaterialEstimateUnitService.replacementByMaterialOrganizationOptionId(id, row.original.id)
            .then(function () {
                setChange(!change)
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    const selectInMaterialId = async (row) => {
        const id = currentEditRowData.id
        MaterialEstimateUnitService.replacementByMaterialId(id, row.original.id)
            .then(function () {
                setChange(!change)
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    const sumAmountAlternativePrice2 = useMemo(
        () => data.reduce((acc, mEU) => {
            if (mEU.alternativeTotalPrice && mEU.alternativeTotalPrice.price !== 0) {
                return acc + mEU.alternativeTotalPrice.price;
            } else {
                return acc;
            }
        }, 0),
        [data]
    );

    const sumCalculationAmountBasePrice2 = useMemo(
        () => data.reduce((acc, mEU) => {
            if (mEU.baseTotalPrice && mEU.baseTotalPrice.price !== 0) {
                return acc + mEU.baseTotalPrice.price;
            } else {
                return acc;
            }
        }, 0),
        [data]
    );

    const sumBasePrice = useMemo(
        () => data.reduce((acc, mEU) => {
            if (mEU.basePrice && mEU.basePrice.price !== 0) {
                return acc + mEU.basePrice.price;
            } else {
                return acc;
            }
        }, 0),
        [data]
    );

    const sumJobBasePrice = useMemo(
        () => data.reduce((acc, mEU) => {
            if (mEU.baseJobPrice && mEU.baseJobPrice.price !== 0) {
                return acc + mEU.baseJobPrice.price;
            } else {
                return acc;
            }
        }, 0),
        [data]
    );

    const sumJobAlternativePrice = useMemo(
        () => data.reduce((acc, mEU) => {
            if (mEU.alternativeJobPrice && mEU.alternativeJobPrice.price !== 0) {
                return acc + mEU.alternativeJobPrice.price;
            } else {
                return acc;
            }
        }, 0),
        [data]
    );

    const sumAlternativePrice = useMemo(
        () => data.reduce((acc, mEU) => {
            if (mEU.alternativePrice && mEU.alternativePrice.price !== 0) {
                return acc + mEU.alternativePrice.price;
            } else {
                return acc;
            }
        }, 0),
        [data]
    );

    const columns = useMemo(() => {
        const baseColumns = [

            {
                id: 'articleNumber',
                header: 'Артикул',
                accessorKey: 'materialOrganizationOption.articleNumber',
                minSize: 15,
                maxSize: 25,
                Cell: ({cell, row}) => {
                    return (row.original.materialOrganizationOption !== null) ? row.original.materialOrganizationOption.articleNumber : '';
                }
            },
            {
                id: 'name',
                header: 'Найменование',
                accessorKey: 'materialOrganizationOption.name',
                minSize: 50,
                maxSize: 50,
                enableEditing: false,
                Cell: ({cell, row}) => {
                    const temp = row.original.materialOrganizationOption === null;
                    return (
                        <span
                            style={{
                                color: temp ? 'red' : 'black'
                            }}>
                               {temp ? row.original.tempName : row.original.materialOrganizationOption.name}
                            </span>
                    );
                }
            },
            {
                id: 'unit',
                header: 'Ед. изм.',
                accessorKey: 'materialOrganizationOption.unit.name',
                minSize: 5,
                maxSize: 5,
                Cell: ({cell, row}) => {
                    return (row.original.materialOrganizationOption !== null) ? row.original.materialOrganizationOption.unit.name : row.original.tempUnit.name;
                }
            },
            {
                id: 'quantity',
                header: 'Кол.',
                accessorKey: 'quantity',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => {
                    const value = cell.getValue();
                    return (row.original.materialOrganizationOption === null) ?
                        '' : row.original.materialOrganizationOption.typeMaterial === 'SLAB' ?
                            (row.original.optimizationQuantity === null || row.original.optimizationQuantity === undefined
                                ? row.original.quantity : row.original.optimizationQuantity.toFixed(3)) : value.toFixed(3);
                },
            },
            {
                id: 'basePrice',
                header: `Материал`,
                accessorKey: 'basePrice',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => {
                    const value = cell.getValue();
                    return value === null || value === undefined ? 'Ожидание' : value.price.toFixed(2)
                },
                Footer: () => (
                    <Stack>
                        <Box color="warning.main">
                            {sumBasePrice?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2,
                            })} {baseCurrency}
                        </Box>
                    </Stack>
                ),
            },
            {
                id: 'baseJobPrice',
                header: `Работа`,
                accessorKey: 'baseJobPrice',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => {
                    const value = cell.getValue();
                    return value === null || value === undefined ? 'Ожидание' : value.price.toFixed(2)
                },
                Footer: () => (
                    <Stack>
                        <Box color="warning.main">
                            {sumJobBasePrice?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2,
                            })} {baseCurrency}
                        </Box>
                    </Stack>
                ),
            },
            {
                id: 'baseTotalPrice',
                header: `Итого:`,
                accessorKey: 'baseTotalPrice',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => {
                    const value = cell.getValue();
                    return value === null || value === undefined ? 'Ожидание' : value.price.toFixed(2)
                },
                Footer: () => (
                    <Stack>
                        <Box color="warning.main">
                            {sumAmountBaseTotalPrice?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2,
                            })} {baseCurrency}
                        </Box>
                    </Stack>
                ),
            },
            {
                id: 'alternativePrice',
                header: `Материал`,
                accessorKey: 'alternativePrice',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => {
                    const value = cell.getValue();
                    return value === null || value === undefined ? 'Ожидание' : value.price.toFixed(2)
                },
                Footer: () => (
                    <Stack>
                        <Box color="warning.main">
                            {sumAlternativePrice?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2,
                            })} {alternativeCurrency}
                        </Box>
                    </Stack>
                ),
            },
            {
                id: 'alternativeJobPrice',
                header: `Работа`,
                accessorKey: 'alternativeJobPrice',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => {
                    const value = cell.getValue();
                    return value === null || value === undefined ? 'Ожидание' : value.price.toFixed(2)
                },
                Footer: () => (
                    <Stack>
                        <Box color="warning.main">
                            {sumJobAlternativePrice?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2,
                            })} {alternativeCurrency}
                        </Box>
                    </Stack>
                ),
            },
            {
                id: 'alternativeTotalPrice',
                header: `Итого:`,
                accessorKey: 'alternativeTotalPrice',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => {
                    const value = cell.getValue();
                    return value === null || value === undefined ? 'Ожидание' : value.price.toFixed(2)
                },
                Footer: () => (
                    <Stack>
                        <Box color="warning.main">
                            {sumAmountAlternativeTotalPrice?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2,
                            })} {alternativeCurrency}
                        </Box>
                    </Stack>
                ),
            },
            {
                header: 'id',
                accessorKey: 'id',
                enableColumnActions: false,
                enableGrouping: false,
                enableEditing: false,
                size: 2,
            },
        ];

        if (!isFact) {
            baseColumns.splice(4, 0, {
                id: 'calculationCostBasePrice',
                header: `СБ мат.`,
                accessorKey: 'calculationCostBasePrice',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => {
                    const value = cell.getValue();
                    return value === null || value === undefined ? 'Ожидание' : value.price.toFixed(2)
                },
                Footer: () => (
                    <Stack>
                        <Box color="warning.main">
                            {sumCalculationAmountBasePrice?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2,
                            })} {baseCurrency}
                        </Box>
                    </Stack>
                ),
            });
        }

        if (isFact) {
            baseColumns.splice(4, 0, {
                id: 'costBasePrice',
                header: `СБ мат.`,
                accessorKey: 'costBasePrice',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => {
                    const value = cell.getValue();
                    return value === null || value === undefined ? 'Ожидание' : value.price.toFixed(2)
                },
                Footer: () => (
                    <Stack>
                        <Box color="warning.main">
                            {sumCalculationAmountBasePrice?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2,
                            })} {baseCurrency}
                        </Box>
                    </Stack>
                ),
            });
        }

        if (!isFact) {
            baseColumns.splice(5, 0, {
                id: 'calculationCostBaseJobPrice',
                header: `СБ раб.`,
                accessorKey: 'calculationCostBaseJobPrice',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => {
                    const value = cell.getValue();
                    return value === null || value === undefined ? 'Ожидание' : value.price.toFixed(2)
                },
                Footer: () => (
                    <Stack>
                        <Box color="warning.main">
                            {sumCalculationCostJobAmountBasePrice?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2,
                            })} {baseCurrency}
                        </Box>
                    </Stack>
                ),
            });
        }

        if (isFact) {
            baseColumns.splice(5, 0, {
                id: 'costBaseJobPrice',
                header: `СБ раб.`,
                accessorKey: 'costBaseJobPrice',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => {
                    const value = cell.getValue();
                    return value === null || value === undefined ? 'Ожидание' : value.price.toFixed(2)
                },
                Footer: () => (
                    <Stack>
                        <Box color="warning.main">
                            {sumCalculationCostJobAmountBasePrice?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2,
                            })} {baseCurrency}
                        </Box>
                    </Stack>
                ),
            });
        }

        if (!isFact) {
            baseColumns.splice(6, 0, {
                id: 'calculationTotalCostBasePrice',
                header: `СБ итого:`,
                accessorKey: 'calculationTotalCostBasePrice',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => {
                    const value = cell.getValue();
                    return value === null || value === undefined ? 'Ожидание' : value.price.toFixed(2)
                },
                Footer: () => (
                    <Stack>
                        <Box color="warning.main">
                            {sumCalculationCostTotalAmountBasePrice?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2,
                            })} {baseCurrency}
                        </Box>
                    </Stack>
                ),
            });
        }

        if (isFact) {
            baseColumns.splice(6, 0, {
                id: 'totalCostBasePrice',
                header: `СБ итого:`,
                accessorKey: 'totalCostBasePrice',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => {
                    const value = cell.getValue();
                    return value === null || value === undefined ? 'Ожидание' : value.price.toFixed(2)
                },
                Footer: () => (
                    <Stack>
                        <Box color="warning.main">
                            {sumCalculationCostTotalAmountBasePrice?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2,
                            })} {baseCurrency}
                        </Box>
                    </Stack>
                ),
            });
        }

        if (!isFact) {
            baseColumns.splice(15, 0, {
                id: 'profitCalculationBaseMaterialPrice',
                header: `Прибыль мат.`,
                accessorKey: 'profitCalculationBaseMaterialPrice',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => {
                    const value = cell.getValue();
                    return value === null || value === undefined ? 'Ожидание' : value.price.toFixed(2)
                },
                Footer: () => (
                    <Stack>
                        <Box color="warning.main">
                            {sumProfitCalculationBaseMaterialPrice?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2,
                            })} {baseCurrency}
                        </Box>
                    </Stack>
                ),
            });
        }

        if (isFact) {
            baseColumns.splice(15, 0, {
                id: 'profitBaseMaterialPrice',
                header: `Прибыль мат.`,
                accessorKey: 'profitBaseMaterialPrice',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => {
                    const value = cell.getValue();
                    return value === null || value === undefined ? 'Ожидание' : value.price.toFixed(2)
                },
                Footer: () => (
                    <Stack>
                        <Box color="warning.main">
                            {sumProfitCalculationBaseMaterialPrice?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2,
                            })} {baseCurrency}
                        </Box>
                    </Stack>
                ),
            });
        }

        if (!isFact) {
            baseColumns.splice(16, 0, {
                id: 'profitCalculationBaseJobPrice',
                header: `Прибыль раб.`,
                accessorKey: 'profitCalculationBaseJobPrice',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => {
                    const value = cell.getValue();
                    return value === null || value === undefined ? 'Ожидание' : value.price.toFixed(2)
                },
                Footer: () => (
                    <Stack>
                        <Box color="warning.main">
                            {sumProfitCalculationBaseJobPrice?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2,
                            })} {baseCurrency}
                        </Box>
                    </Stack>
                ),
            });
        }

        if (isFact) {
            baseColumns.splice(16, 0, {
                id: 'profitBaseJobPrice',
                header: `Прибыль раб.`,
                accessorKey: 'profitBaseJobPrice',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => {
                    const value = cell.getValue();
                    return value === null || value === undefined ? 'Ожидание' : value.price.toFixed(2)
                },
                Footer: () => (
                    <Stack>
                        <Box color="warning.main">
                            {sumProfitCalculationBaseJobPrice?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2,
                            })} {baseCurrency}
                        </Box>
                    </Stack>
                ),
            });
        }

        if (!isFact) {
            baseColumns.splice(17, 0, {
                id: 'profitCalculationBaseTotalPrice',
                header: `Прибыль итого:`,
                accessorKey: 'profitCalculationBaseTotalPrice',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => {
                    const value = cell.getValue();
                    return value === null || value === undefined ? 'Ожидание' : value.price.toFixed(2)
                },
                Footer: () => (
                    <Stack>
                        <Box color="warning.main">
                            {sumProfitCalculationBaseTotalPrice?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2,
                            })} {baseCurrency}
                        </Box>
                    </Stack>
                ),
            });
        }

        if (isFact) {
            baseColumns.splice(17, 0, {
                id: 'profitBaseTotalPrice',
                header: `Прибыль итого:`,
                accessorKey: 'profitCalculationBaseTotalPrice',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => {
                    const value = cell.getValue();
                    return value === null || value === undefined ? 'Ожидание' : value.price.toFixed(2)
                },
                Footer: () => (
                    <Stack>
                        <Box color="warning.main">
                            {sumProfitCalculationBaseTotalPrice?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2,
                            })} {baseCurrency}
                        </Box>
                    </Stack>
                ),
            });
        }

        //Alternative
        if (!isFact) {
            baseColumns.splice(4, 0, {
                id: 'calculationCostAlternativePrice',
                header: `СБ мат.`,
                accessorKey: 'calculationCostAlternativePrice',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => {
                    const value = cell.getValue();
                    return value === null || value === undefined ? 'Ожидание' : value.price.toFixed(2)
                },
                Footer: () => (
                    <Stack>
                        <Box color="warning.main">
                            {sumCalculationAmountBasePrice?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2,
                            })} {baseCurrency}
                        </Box>
                    </Stack>
                ),
            });
        }

        if (isFact) {
            baseColumns.splice(4, 0, {
                id: 'costAlternativePrice',
                header: `СБ мат.`,
                accessorKey: 'costAlternativePrice',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => {
                    const value = cell.getValue();
                    return value === null || value === undefined ? 'Ожидание' : value.price.toFixed(2)
                },
                Footer: () => (
                    <Stack>
                        <Box color="warning.main">
                            {sumCalculationAmountBasePrice?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2,
                            })} {baseCurrency}
                        </Box>
                    </Stack>
                ),
            });
        }

        if (!isFact) {
            baseColumns.splice(5, 0, {
                id: 'calculationCostAlternativeJobPrice',
                header: `СБ раб.`,
                accessorKey: 'calculationCostAlternativeJobPrice',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => {
                    const value = cell.getValue();
                    return value === null || value === undefined ? 'Ожидание' : value.price.toFixed(2)
                },
                Footer: () => (
                    <Stack>
                        <Box color="warning.main">
                            {sumCalculationCostJobAmountBasePrice?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2,
                            })} {baseCurrency}
                        </Box>
                    </Stack>
                ),
            });
        }

        if (isFact) {
            baseColumns.splice(5, 0, {
                id: 'costAlternativeJobPrice',
                header: `СБ раб.`,
                accessorKey: 'costAlternativeJobPrice',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => {
                    const value = cell.getValue();
                    return value === null || value === undefined ? 'Ожидание' : value.price.toFixed(2)
                },
                Footer: () => (
                    <Stack>
                        <Box color="warning.main">
                            {sumCalculationCostJobAmountBasePrice?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2,
                            })} {baseCurrency}
                        </Box>
                    </Stack>
                ),
            });
        }

        if (!isFact) {
            baseColumns.splice(6, 0, {
                id: 'calculationTotalCostAlternativePrice',
                header: `СБ итого:`,
                accessorKey: 'calculationTotalCostAlternativePrice',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => {
                    const value = cell.getValue();
                    return value === null || value === undefined ? 'Ожидание' : value.price.toFixed(2)
                },
                Footer: () => (
                    <Stack>
                        <Box color="warning.main">
                            {sumCalculationCostTotalAmountBasePrice?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2,
                            })} {baseCurrency}
                        </Box>
                    </Stack>
                ),
            });
        }

        if (isFact) {
            baseColumns.splice(6, 0, {
                id: 'totalCostAlternativePrice',
                header: `СБ итого:`,
                accessorKey: 'totalCostAlternativePrice',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => {
                    const value = cell.getValue();
                    return value === null || value === undefined ? 'Ожидание' : value.price.toFixed(2)
                },
                Footer: () => (
                    <Stack>
                        <Box color="warning.main">
                            {sumCalculationCostTotalAmountBasePrice?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2,
                            })} {baseCurrency}
                        </Box>
                    </Stack>
                ),
            });
        }

        if (!isFact) {
            baseColumns.splice(20, 0, {
                id: 'profitCalculationAlternativeMaterialPrice',
                header: `Прибыль мат.`,
                accessorKey: 'profitCalculationAlternativeMaterialPrice',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => {
                    const value = cell.getValue();
                    return value === null || value === undefined ? 'Ожидание' : value.price.toFixed(2)
                },
                Footer: () => (
                    <Stack>
                        <Box color="warning.main">
                            {sumProfitCalculationBaseMaterialPrice?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2,
                            })} {baseCurrency}
                        </Box>
                    </Stack>
                ),
            });
        }

        if (isFact) {
            baseColumns.splice(20, 0, {
                id: 'profitAlternativeMaterialPrice',
                header: `Прибыль мат.`,
                accessorKey: 'profitAlternativeMaterialPrice',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => {
                    const value = cell.getValue();
                    return value === null || value === undefined ? 'Ожидание' : value.price.toFixed(2)
                },
                Footer: () => (
                    <Stack>
                        <Box color="warning.main">
                            {sumProfitCalculationBaseMaterialPrice?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2,
                            })} {baseCurrency}
                        </Box>
                    </Stack>
                ),
            });
        }

        if (!isFact) {
            baseColumns.splice(21, 0, {
                id: 'profitCalculationAlternativeJobPrice',
                header: `Прибыль раб.`,
                accessorKey: 'profitCalculationAlternativeJobPrice',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => {
                    const value = cell.getValue();
                    return value === null || value === undefined ? 'Ожидание' : value.price.toFixed(2)
                },
                Footer: () => (
                    <Stack>
                        <Box color="warning.main">
                            {sumProfitCalculationBaseJobPrice?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2,
                            })} {baseCurrency}
                        </Box>
                    </Stack>
                ),
            });
        }

        if (isFact) {
            baseColumns.splice(22, 0, {
                id: 'profitAlternativeJobPrice',
                header: `Прибыль раб.`,
                accessorKey: 'profitBaseJobPrice',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => {
                    const value = cell.getValue();
                    return value === null || value === undefined ? 'Ожидание' : value.price.toFixed(2)
                },
                Footer: () => (
                    <Stack>
                        <Box color="warning.main">
                            {sumProfitCalculationBaseJobPrice?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2,
                            })} {baseCurrency}
                        </Box>
                    </Stack>
                ),
            });
        }

        if (!isFact) {
            baseColumns.splice(23, 0, {
                id: 'profitCalculationAlternativeTotalPrice',
                header: `Прибыль итого:`,
                accessorKey: 'profitCalculationAlternativeTotalPrice',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => {
                    const value = cell.getValue();
                    return value === null || value === undefined ? 'Ожидание' : value.price.toFixed(2)
                },
                Footer: () => (
                    <Stack>
                        <Box color="warning.main">
                            {sumProfitCalculationBaseTotalPrice?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2,
                            })} {baseCurrency}
                        </Box>
                    </Stack>
                ),
            });
        }

        if (isFact) {
            baseColumns.splice(24, 0, {
                id: 'profitAlternativeTotalPrice',
                header: `Прибыль итого:`,
                accessorKey: 'profitAlternativeTotalPrice',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => {
                    const value = cell.getValue();
                    return value === null || value === undefined ? 'Ожидание' : value.price.toFixed(2)
                },
                Footer: () => (
                    <Stack>
                        <Box color="warning.main">
                            {sumProfitCalculationBaseTotalPrice?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2,
                            })} {baseCurrency}
                        </Box>
                    </Stack>
                ),
            });
        }

        if (!isFact) {
            baseColumns.splice(31, 0, {
                id: 'calculationGrossMargin',
                header: `Валовая маржа`,
                accessorKey: 'calculationGrossMargin',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => {
                    const value = cell.getValue();
                    return value === null || value === undefined ? 'Ожидание' : value.toFixed(2)
                },
                Footer: () => (
                    <Stack>
                        <Box color="warning.main">
                            {"СР"} {averageCalculationGrossMargin?.toLocaleString?.('en-US', {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 2,
                        })}
                        </Box>
                    </Stack>
                ),
            });
        }

        if (isFact) {
            baseColumns.splice(30, 0, {
                id: 'calculationGrossMargin',
                header: `Валовая маржа`,
                accessorKey: 'calculationGrossMargin',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => {
                    const value = cell.getValue();
                    return value === null || value === undefined ? 'Ожидание' : value.toFixed(2)
                },
                Footer: () => (
                    <Stack>
                        <Box color="warning.main">
                            {"СР"} {averageCalculationGrossMargin?.toLocaleString?.('en-US', {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 2,
                        })}
                        </Box>
                    </Stack>
                ),
            });
        }

        return baseColumns;
    }, [data, setData]);

    return (
        <div>
            <MaterialReactTable
                muiTableBodyProps={{
                    sx: () => ({
                        '& tr:nth-of-type(odd)': {
                            backgroundColor: 'lightgray',
                        },
                    }),
                }}
                muiTableBodyCellProps={{
                    sx: {
                        p: '0px 0px 0px 10px',
                        m: '0px 0px 0px 0px',
                    },
                }}
                muiTableBodyRowProps={{
                    sx: {
                        height: '10px',
                    },
                }}
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        size: 15,
                        minSize: 15,
                    },
                }}
                columns={columns}
                data={data}
                rowHeight={13}
                columnSpacing={1}
                initialState={{
                    columnVisibility: initColumn,
                    isFullScreen: false,
                    density: "compact",
                    pagination: {pageIndex: 0, pageSize: 50},
                    sorting: [{id: 'unit', desc: false}],
                }}
                enableEditing={false}
                state={{columnVisibility}}
                getRowId={(originalRow) => originalRow.id}
                selectionPadding={1}
                selectionCss={{
                    backgroundColor: 'lightblue',
                    borderWidth: '2px',
                    borderStyle: 'solid',
                    borderColor: 'blue',
                    borderRadius: '4px',
                }}
                renderRowActions={({row, table}) => (
                    <Box sx={{
                        display: 'flex',
                        gap: '0.1rem',
                        justifyContent: 'flex-start',
                        maxWidth: '50px'
                    }}>
                        {!row.original.isBlocked ?
                            <Tooltip arrow placement="left" title="Заменить">
                                <Button size="small" sx={{minWidth: 'auto', marginLeft: 'auto'}} color="pencil"
                                        onClick={() => openSearchSimilarMaterials(row)}>
                                    <Cached sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                                </Button>
                            </Tooltip> : <Button size="small" sx={{minWidth: 'auto', marginLeft: 'auto'}} color="pencil"
                                                 onClick={() => openSearchSimilarMaterials(row)}>
                                <Cached sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                            </Button>
                        }
                        {!row.original.isBlocked ? <Tooltip arrow placement="right" title="Удалить">
                            <Button
                                size="small"
                                sx={{minWidth: 'auto', marginLeft: 'auto'}}
                                color="error"
                                onClick={() => handleDeleteRow(row)}
                            >
                                <Delete sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                            </Button>
                        </Tooltip> : <Button
                            size="small"
                            sx={{minWidth: 'auto', marginLeft: 'auto'}}
                            color="error"
                            onClick={() => handleDeleteRow(row)}
                            disabled={true}
                        >
                            <Delete sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                        </Button>}
                    </Box>
                )}
                localization={MRT_Localization_RU}
                renderTopToolbarCustomActions={({table}) => {
                    return (
                        <div style={{display: "flex"}}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '0.5rem',
                                    p: '0.5rem',
                                    flexWrap: 'wrap'
                                }}
                            >
                                <Button
                                    size="small"
                                    variant="outlined"
                                    style={{color: "black", borderColor: "black", fontWeight: "bold", fontSize: 14}}
                                    onClick={() => handleColumnVisibilityChange()}
                                >
                                    {currencyIsBase ? baseCurrency : alternativeCurrency}
                                </Button>
                                {detailed && <Button
                                    size="small"
                                    variant="outlined"
                                    style={{color: "black", borderColor: "black", fontWeight: "bold", fontSize: 14}}
                                    onClick={() => redirectEstimateItem()}
                                >
                                    Подробно
                                </Button>}
                            </Box>
                        </div>
                    );
                }}
            />
            <ModalConfirm
                title={"Подтвердить создание заявки на закупку?"}
                modalOpen={setPurchaseModalOpen}
                handleClose={handleCloseSetPurchase}
                handleSubmit={handleSubmitSetPurchase}
                action={"Подтвердить"}
            />
        </div>
    );
};

export default CalculationTable;
