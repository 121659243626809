import React from 'react';

const CellPriceWithCurrency = ({price}) => {

    return (
        <div>
            {price != null && price.price != null && price.currency != null ? (
                <span>
                  {price.price} {price.currency.curAbbreviation}
                </span>) : ('')}
        </div>
    );
};

export default CellPriceWithCurrency;
