import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {TextField} from "@mui/material";
import React from "react";

const DateInput = ({value, setValue, helperText, variant}) => {

    const handleChange = newValue => {
        setValue(newValue);
    };

    return (
        <div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    onChange={handleChange}
                    ampm={false}
                    renderInput={params => (
                        <TextField
                            {...params}
                            helperText={helperText ? helperText : null}
                            sx={{maxWidth: '150px'}}
                            variant={variant ? variant : 'standard'}
                            size={"small"}
                        />
                    )}
                    value={value}
                    inputFormat="DD/MM/YYYY"
                />
            </LocalizationProvider>
        </div>
    );
};

export default DateInput;