import Box from "@mui/material/Box";
import UploadGroupOfMaterialFileForm from "../../../../components/UI/cloud/UploadGroupOfMaterialFileForm";
import FileService from "../../../../API/orders/orders/organization/FileService";

const SettingsLoadGroupOrganization = () => {
    return (
        <div>
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '3px'
            }}>
                <UploadGroupOfMaterialFileForm
                    label={'Отправить'}
                    downloadFile={FileService.downloadSupplierMaterialsFile}
                />
            </Box>
        </div>
    );
};

export default SettingsLoadGroupOrganization;