import React from 'react';
import {useParams} from "react-router-dom";
import ModulIssuingEdge from "../../../components/UI/table/prodaction/issuingEdge/ModulIssuingEdge";

const IssuingEdge = () => {

    const params = useParams();

    return (
        <div>
            <ModulIssuingEdge
                id={params.id}
            />
        </div>
    );
};

export default IssuingEdge;