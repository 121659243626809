import axios from "axios";
import {apiVersionOrders, gatewayUrl, nameOrders} from "../../../../Constants";

const issuanceRestUrl = gatewayUrl + nameOrders + apiVersionOrders + '/organizations/issuance'

export default class IssuanceService {

    static async getAllByMaterialWarehouseIdWithIssuingEdgeItem(materialWarehouseId) {
        return axios.get(issuanceRestUrl + '/by-material-warehouse-id' + '?materialWarehouseId=' + materialWarehouseId);
    }

    static async getAllWithFilter() {
        return axios.get(issuanceRestUrl + '/all-with-filter');
    }

    static async getAllByMaterialWarehouseIdByIssuingEdgeItem(issuingEdgeId, materialWarehouseId) {
        return axios.get(issuanceRestUrl + '/' + issuingEdgeId + '/by-material-warehouse-id' + '?materialWarehouseId=' + materialWarehouseId);
    }

    static
    async getAllByMaterialReserveIdWithIssuingEdgeItem(materialReserveId) {
        return axios.get(issuanceRestUrl + '/by-material-reserve-id' + '?materialReserveId=' + materialReserveId);
    }

    static
    async getAllByIssuingEdgeItemId(issuingEdgeItemId) {
        return axios.get(issuanceRestUrl + '/by-issuing-edge-item-id' + '?issuingEdgeItemId=' + issuingEdgeItemId);
    }

    static async getInWork(localDateTime) {
        return axios.get(issuanceRestUrl + '/in-work' + '?localDateTime=' + localDateTime);
    }

    static async getInStock(localDateTime) {
        return axios.get(issuanceRestUrl + '/in-stock' + '?localDateTime=' + localDateTime);
    }
}