import {apiVersionMaterialCloud, gatewayUrl, nameMaterialCloud} from "../../../Constants";
import axios from "axios";

const materialRestUrl = gatewayUrl + nameMaterialCloud + apiVersionMaterialCloud + '/user/materials'

export default class UserMaterialMaterialCloudService {

    static async getAllWithGroupMaterialAndUnit(nameMethodGetMaterial, supplierId) {
        return axios.get(materialRestUrl + '/' + nameMethodGetMaterial + "?supplierId="
            + supplierId);
    }

    static async getWithAllPrices(id) {
        return axios.get(materialRestUrl + '/with-all-prices/' + id);
    }

    static async setBaseTrimming(trimmings, materialName) {
        await axios.patch(materialRestUrl + '/set-base-trimming' + "?materialName=" + materialName, trimmings);
    }

    static async getBaseTrimming(materialName) {
        return axios.get(materialRestUrl + '/get-base-trimming' + "?materialName="
            + materialName);
    }

    static async getWithFilter(typeMaterial, unitId, groupMaterialId, manufacturerId) {
        return axios.get(materialRestUrl + '/with-filter'
            + "?typeMaterial=" + typeMaterial + "&unitId=" + unitId
            + "&groupMaterialId=" + groupMaterialId + "&manufacturerId=" + manufacturerId
        );
    }

    static async getForMaterialOrganizationOption(id) {
        return axios.get(materialRestUrl + '/' + id + '/get-for-material-organization-option');
    }

    static async getAllForCatalog() {
        return axios.get(materialRestUrl + '/for-catalog');
    }

    static async getAllFilter(group, page, pageSize, sort,
                              typeMaterial, subTypeMaterial, search, archive) {
        const groupId = (group && typeof group.id === 'number') ? group.id : null;

        const params = {
            groupMaterialId : groupId,
            page: page,
            pageSize: pageSize,
            sort: sort,
            typeMaterial: typeMaterial,
            subTypeMaterial: subTypeMaterial,
            search: search,
            archive: archive,
        };

        const queryString = Object.keys(params)
            .filter(key => params[key] !== undefined && params[key] !== null)
            .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
            .join('&');

        const url = `${materialRestUrl}/filter?${queryString}`;

        return axios.get(url);
    }
}
