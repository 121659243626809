import React, {useState} from 'react';
import {Checkbox} from "@mui/material";
import OrganizationCustomerService from "../../../../../../API/orders/orders/organization/OrganizationCustomerService";

const CellCheckboxCTUnable = ({cell, id, change, setChange}) => {

    const [status, setStatus] = useState(cell.getValue);

    const handle = () => {
        OrganizationCustomerService.unable(id, !status)
            .then(function () {
                setStatus(!status)
                setChange(!change)
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    return (<div>
        <Checkbox onChange={handle}
                  checked={status}
                  sx={{'& .MuiSvgIcon-root': {fontSize: 28}}}
        />
    </div>);
};

export default CellCheckboxCTUnable;