import React from 'react';
import RequestsPurchaseTableItem from "../../../../components/UI/table/warehouse/purchase/RequestsPurchaseTableItem";
import {useParams} from "react-router-dom";

const RequestsPurchaseItem = () => {

    const params = useParams();

    const estimateId = params.estimateId;
    const orderId = params.orderId;
    const orderNumber = params.orderNumber;
    const status = params.status

    return (
        <div>
            <h3>Заявка на заказ: {orderNumber}</h3>
            <RequestsPurchaseTableItem
                estimateId={estimateId}
                orderId={orderId}
                orderNumber={orderNumber}
                status={status}
            />
        </div>
    );
};

export default RequestsPurchaseItem;