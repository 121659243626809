import {COLORS} from "../../../../../../Constants";
import Layer from "./Layer";
import React, {useState} from "react";

const Point = ({point, editableElement, scale, position, openPopoverActives, isRound}) => {
    const [hover, setHover] = useState(null)

    const typesToSelect = ["notch", "addEdge", "addHole", "editHole"]
    const active = editableElement.pointId === point.id && !(typesToSelect.includes(editableElement.type))

    const onclick = (event) => {
        event.stopPropagation()
        !isRound && openPopoverActives({pointId: point.id, x: event.x, y: event.y, arc: point.arcParams});
    }

    const onPointerOver = (event) => {
        event.stopPropagation()
        !isRound && setHover(true)
    }
    const onPointerOut = (event) => {
        event.stopPropagation()
        !isRound && setHover(false)
    }

    return <Layer layer={2}>
        <mesh
            position={[point.x * scale + position[0], point.y * scale + position[1], position[2] + scale]}
            onClick={onclick}
            onPointerOver={onPointerOver}
            onPointerOut={onPointerOut}
        >
            {active && <circleGeometry args={[0.2]}/>}
            <meshBasicMaterial color={COLORS[6][500]} opacity={0.7} transparent/>
            <Layer layer={3}>
                <mesh>
                    <boxGeometry args={[0.09, 0.09, scale]}/>
                    <meshBasicMaterial color={"black"}/>
                    <mesh>
                        <boxGeometry args={[0.07, 0.07, scale]}/>
                        <meshBasicMaterial color={hover || active ? COLORS[5][600] : COLORS[1][600]}/>
                    </mesh>
                </mesh>
            </Layer>
        </mesh>
    </Layer>
}
export default Point
