import axios from "axios";
import {apiVersionOrders, gatewayUrl, nameOrders} from "../../../../Constants";

const pathRestUrl = gatewayUrl + nameOrders + apiVersionOrders + '/organizations/general-paths'

export default class GeneralPathService {

    static async getAll() {
        return axios.get(pathRestUrl);
    }

    static async get(id) {
        return axios.get(pathRestUrl + '/' + id);
    }

    static async update(data) {
        await axios.post(pathRestUrl + '/' + data.id, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
}