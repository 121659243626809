export default class ObjectUtil {

    static createUpdateRequestObjectIds(ids, newValue) {
        return {
            ids: ids,
            newValue: newValue
        }
    }

    static createRequestObjectIds(ids) {
        return {
            ids: ids,
        }
    }

    static createRequestTwoObjectIds(firstIds, secondIds) {
        return {
            firstIds: firstIds,
            secondIds: secondIds
        }
    }

    static createRequestObjectOptimizationIds(ids, optimization) {
        return {
            ids: ids,
            optimization: optimization,
        }
    }

    static createArrivalMaterialRequestObjectIds(ids, arrivalMaterial) {
        return {
            ids: ids,
            arrivalMaterial: arrivalMaterial,
        }
    }
}