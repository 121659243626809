import {Button} from "@mui/material";

const ButtonAdd = ({onClick, text, disabled = false}) => {
    return <Button
        onClick={onClick}
        variant="contained"
        disabled={disabled}
        sx={{
            width: 117,
            height: 56,
            backgroundColor: (theme) => theme.palette.secondary.add,
            borderRadius: "100px",
            boxShadow: "none",
            textTransform:'none',
            "&:hover": {
                backgroundColor: (theme) => theme.palette.secondary.add,
                boxShadow: "none",
            }
        }}>
        {text}
    </Button>
}
export default ButtonAdd