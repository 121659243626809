import React, {useEffect, useState} from 'react';
import ModelDetailTable from "../../../../components/UI/table/model-estimate/details/ModelDetailTable";
import ModelDetailService from "../../../../API/model-estimate/details/ModelDetailService";
import {useParams} from "react-router-dom";

const ModelDetails = ({estimateId}) => {

        const [data, setData] = useState([]);
        const params = useParams();

        const basicModelId = params.basicModelId;
        const [unableCut, setUnableCut] = useState({});
        const [unableTurn, setUnableTurn] = useState({});
        const [selectedIds, setSelectedIds] = useState([]);
        const [modalStatusOpen, setModalStatusOpen] = useState(false);
        const [status, setStatus] = useState(true);
        const [typeStatus, setTypeStatus] = useState(null);
        const [change, setChange] = useState(false);
        const [isError, setIsError] = useState(false);

        useEffect(() => {
                ModelDetailService.getAllModelEstimateId(estimateId).then(function (response) {
                    setData(response.data);
                    data.forEach((row) => {
                        unableCut[row.id] = row.cut;
                        unableTurn[row.id] = row.turn;
                    });
                })
                    .catch(function (error) {
                        console.log(error);
                    });
            }, [change]
        )
        ;

        return (
            <div>
                <ModelDetailTable
                    data={data}
                    setData={setData}
                    unableCut={unableCut}
                    unableTurn={unableTurn}
                    setUnableCut={setUnableCut}
                    setUnableTurn={setUnableTurn}
                    selectedIds={selectedIds}
                    setSelectedIds={setSelectedIds}
                    modalStatusOpen={modalStatusOpen}
                    setModalStatusOpen={setModalStatusOpen}
                    status={status}
                    setStatus={setStatus}
                    typeStatus={typeStatus}
                    setTypeStatus={setTypeStatus}
                    isColumnOrder={true}
                    warehouse={'WarehouseFinishedDetail'}
                    expanded={true}
                    pageSize={100}
                    change={change}
                    setChange={setChange}
                    isError={isError}
                    estimateId={estimateId}
                    enableEditing={true}
                    isColumnCut={false}
                    isColumnTurn={false}
                />
            </div>
        );
    }
;

export default ModelDetails;