import React, {useEffect, useState} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import ModelsTable from "./ModelsTable";
import ModelService from "../../../../../API/model-estimate/model/ModelService";

export const UpdateOrAddModelsModule = ({
                                            open,
                                            onClose,
                                            setModelsOpen,
                                            setBasicModalModelsOpen,
                                            orderId,
                                            basicModelId,
                                            change,
                                            setChange
                                        }) => {

    const [data, setData] = useState([]);
    const [unable, setUnable] = useState({});

    useEffect(() => {
        if (basicModelId != null) {
            ModelService.getAllUnableByBasicModelId(basicModelId).then(function (response) {
                let data = response.data;
                setData(data);
                data.forEach((row) => {
                    unable[row.id] = row.enabled;
                });
            })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }, [basicModelId]);

    return (
        <div>
            <Dialog open={open} fullWidth={true} maxWidth='lg'>
                <DialogTitle textAlign="center">Выбрать модель</DialogTitle>
                <DialogContent>
                    <ModelsTable
                        data={data}
                        setData={setData}
                        unable={unable}
                        setUnable={setUnable}
                        isButtonChoice={true}
                        setModelsOpen={setModelsOpen}
                        setBasicModalModelsOpen={setBasicModalModelsOpen}
                        orderId={orderId}
                        isRedirect={false}
                        change={change}
                        setChange={setChange}
                    />
                </DialogContent>
                <DialogActions sx={{p: '1.25rem'}}>
                    <Button onClick={onClose}>Выйти</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};