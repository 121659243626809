import axios from "axios";
import {apiVersionOrders, gatewayUrl, nameOrders} from "../../../../Constants";

const restUrl = gatewayUrl + nameOrders + apiVersionOrders + '/organizations/job-metric-order-organizations'

export default class JobMetricOrderOrganizationService {

    static async getAllByOrderIdForOrganization(orderId) {
        return axios.get(restUrl + '/by-order-for-organization?orderId=' + orderId);
    }
}