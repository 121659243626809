import React, {useState} from 'react';
import Box from "@mui/material/Box";
import {Button, TextField} from "@mui/material";
import DateTimeInput from "../../../../components/UI/filter/DateTimeInput";
import DateTimeUtil from "../../../../utils/common/DateTimeUtil";
import IssuanceService from "../../../../API/orders/prodaction.issueedge/issuance/IssuanceService";

const InWork = () => {
        const [startInWork, setStartInWork] = useState(null);
        const [startInStock, setStartInStock] = useState(null);
        const [dataInWork, setDataInWork] = useState(null);
        const [dataInStock, setDataInStock] = useState(null);

        const applyInWork = () => {
            IssuanceService.getInWork(DateTimeUtil.convertToJavaDateTime(startInWork)).then(function (response) {
                const data = response.data;
                setDataInWork(data);
            })
                .catch(function (error) {
                    console.log(error);
                });
        }

        const applyInStock = () => {

            IssuanceService.getInStock(DateTimeUtil.convertToJavaDateTime(startInStock)).then(function (response) {
                const data = response.data;
                setDataInStock(data);
            })
                .catch(function (error) {
                    console.log(error);
                });
        }

        return (
            <div>
                <Box
                    sx={{
                        display: 'flex',
                        gap: '0.5rem',
                        paddingLeft: '0.5rem',
                        paddingTop: '0.5rem',
                        flexWrap: 'wrap',
                    }}
                >

                    <DateTimeInput
                        value={startInWork}
                        setValue={setStartInWork}
                        variant={"outlined"}
                    />
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            gap: '0.5rem',
                            paddingLeft: '0.5rem',
                            flexWrap: 'wrap',
                        }}
                    >

                        <Button
                            size="medium"
                            color="secondary"
                            onClick={applyInWork}
                            variant="contained"
                        >
                            Вычислить в работе
                        </Button>
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: "column",
                        gap: '0.5rem',
                        paddingLeft: '0.5rem',
                        paddingBottom: '0.2rem',
                    }}
                >
                    <TextField
                        size={"small"}
                        margin="none"
                        key={"base"}
                        label={"В базовой валюте"}
                        variant="standard"
                        name={"base"}
                        disabled={true}
                        value={dataInWork === null ? "" : dataInWork.first.toFixed(2)}
                    />
                    <TextField
                        size={"small"}
                        margin="none"
                        key={"alternative"}
                        label={"В Альтернативной валюте"}
                        variant="standard"
                        name={"base"}
                        disabled={true}
                        value={dataInWork === null ? "" : dataInWork.second.toFixed(2)}
                    />
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        gap: '0.5rem',
                        paddingLeft: '0.5rem',
                        paddingTop: '0.5rem',
                        flexWrap: 'wrap',
                    }}
                >

                    <DateTimeInput
                        value={startInStock}
                        setValue={setStartInStock}
                        variant={"outlined"}
                    />
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            gap: '0.5rem',
                            paddingLeft: '0.5rem',
                            flexWrap: 'wrap',
                        }}
                    >

                        <Button
                            size="medium"
                            color="secondary"
                            onClick={applyInStock}
                            variant="contained"
                        >
                            Вычислить на складе
                        </Button>
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: "column",
                        gap: '0.5rem',
                        paddingLeft: '0.5rem',
                        paddingBottom: '0.2rem',
                    }}
                >
                    <TextField
                        size={"small"}
                        margin="none"
                        key={"base"}
                        label={"В базовой валюте"}
                        variant="standard"
                        name={"base"}
                        disabled={true}
                        value={dataInStock === null ? "" : dataInStock.first.toFixed(2)}
                    />
                    <TextField
                        size={"small"}
                        margin="none"
                        key={"alternative"}
                        label={"В Альтернативной валюте"}
                        variant="standard"
                        name={"base"}
                        disabled={true}
                        value={dataInStock === null ? "" : dataInStock.second.toFixed(2)}
                    />
                </Box>
            </div>
        );
    }
;

export default InWork;