import {Button, Popover, Tooltip, Typography} from "@mui/material";
import {ReactComponent as ArcIcon} from "../../../../../../components/Icons/arc.svg";
import {ReactComponent as RoundIcon} from "../../../../../../components/Icons/round.svg";
import {ReactComponent as CutIcon} from "../../../../../../components/Icons/cut.svg";
import {ReactComponent as QuarterIcon} from "../../../../../../components/Icons/quarter.svg"
import {Edit} from "@mui/icons-material";
import Delete from "@mui/icons-material/DeleteOutlined";

const PopoverProcessing = ({
                               popoverActives,
                               onClose,
                               processingHandle,
                               confirmModal,
                               processingModal,
                               openConfirmModal,
                               cutData
                           }) => {
    const open = Boolean(popoverActives.pointId) && !confirmModal && !processingModal;

    const cutPossible = cutData.possible.find(point => {
        const allPointsLieLine = point.next?.x * (point.curr?.y - point.prev?.y) - point.next?.y * (point.curr?.x - point.prev?.x) === point.prev?.x * point.curr?.y - point.curr?.x * point.prev?.y
        return point.curr.id === popoverActives.pointId && !allPointsLieLine
    })?.curr?.id

    const quarterPossible = cutData.possible.find(point => {
        return point.curr.id === popoverActives.pointId && ((point.curr.x === point.next.x || point.curr.y === point.next.y) && point.mainContour)
    })?.curr?.id

    return <Popover
        open={open}
        onClose={onClose}
        anchorReference="anchorPosition"
        anchorPosition={{top: popoverActives?.y + 12, left: popoverActives?.x - 94}}
    >
        <Typography sx={{padding: 0.5, display: "flex", gap: '5px'}}>
            <Tooltip arrow placement="bottom" title={popoverActives.arc ? "Изменить дугу" : "Создать дугу"}>
                <span>
                     <Button
                         size="small"
                         sx={{
                             minWidth: 'auto', margin: '5px auto 0', border: "1px black solid"
                         }}
                         onClick={() => processingHandle("arc", popoverActives.pointId, popoverActives.pointId)}>
                        <ArcIcon style={{width: "30px", height: "30px"}}/>
                     </Button>
                </span>
            </Tooltip>
            <Tooltip arrow placement="bottom" title="Скруглить угол">
                  <span>
                    <Button
                        size="small"
                        disabled={!cutPossible}
                        sx={{
                            minWidth: 'auto',
                            margin: '5px auto 0',
                            border: "1px black solid",
                            opacity: `${cutPossible ? 1 : 0.2}`
                        }}
                        onClick={() => processingHandle("round", popoverActives.pointId, popoverActives.pointId)}>
                        <RoundIcon style={{width: "30px", height: "30px"}}/>
                    </Button>
                  </span>
            </Tooltip>
            <Tooltip arrow placement="bottom" title="Срез">
                  <span>
                    <Button
                        size="small"
                        disabled={!cutPossible}
                        sx={{
                            minWidth: 'auto',
                            margin: '5px auto 0',
                            border: "1px black solid",
                            opacity: `${cutPossible ? 1 : 0.2}`
                        }}
                        onClick={() => processingHandle("cut", popoverActives.pointId, popoverActives.pointId)}>
                        <CutIcon style={{width: "30px", height: "30px"}}/>
                    </Button>
                  </span>
            </Tooltip>
            <Tooltip arrow placement="bottom" title="Четверь">
                  <span>
                    <Button
                        size="small"
                        disabled={!quarterPossible}
                        sx={{
                            minWidth: 'auto',
                            margin: '5px auto 0',
                            border: "1px black solid",
                            opacity: `${quarterPossible ? 1 : 0.2}`
                        }}
                        onClick={() => processingHandle("quarter", popoverActives.pointId, popoverActives.pointId)}>
                        <QuarterIcon style={{width: "30px", height: "30px"}}/>
                    </Button>
                  </span>
            </Tooltip>
            <Tooltip arrow placement="bottom" title="Редактировать">
                  <span>
                    <Button
                        size="small"
                        sx={{minWidth: 'auto', margin: '5px auto 0', border: "1px black solid"}}
                        onClick={() => processingHandle("editPoint", null, popoverActives.pointId)}>
                        <Edit sx={{width: "30px", height: "30px"}}
                              color="pencil"
                        />
                    </Button>
                  </span>
            </Tooltip>
            <Tooltip arrow placement="bottom" title="Удалить">
                  <span>
                    <Button
                        size="small" sx={{minWidth: 'auto', margin: '5px auto 0', border: "1px black solid"}}
                        onClick={() => openConfirmModal("deletePoint", popoverActives.pointId)}>
                        <Delete sx={{width: "30px", height: "30px"}}
                                color="error"
                        />
                    </Button>
                  </span>
            </Tooltip>
        </Typography>
    </Popover>
}
export default PopoverProcessing
