export const calcPrevQuarterXY = (currPoint, distToStartPoint, width, length, detailData) => {
    if (currPoint.curr.x === detailData.length && currPoint.curr.y !== 0) {
        return {
            x: currPoint.curr.x - +width,
            y: currPoint.curr.y - +distToStartPoint - +length,
            horizontal: true
        }
    } else if (currPoint.curr.y === detailData.width) {
        return {x: currPoint.curr.x + +distToStartPoint, y: currPoint.curr.y - +width, horizontal: false}
    } else if (currPoint.curr.x === 0) {
        return {x: currPoint.curr.x, y: currPoint.curr.y + +distToStartPoint, horizontal: true}
    } else {
        return {x: currPoint.curr.x - +distToStartPoint - +length, y: currPoint.curr.y, horizontal: false}
    }
}
