import React, {useEffect, useState} from 'react';
import {Button, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import TextFieldValidation from "../../../../../../components/UI/table/customer-detales/TextFieldValidation";
import {SIDE} from "../../../../../../Constants";
import {calcValueOfStringWithVar} from "../../Canvas/CanvasCalcPositions/calcValueOfStringWithVar";
import {noExponents} from "../../../../../../utils/processing/noExponents";
import { hasOnlyDigitsAndSymbols} from "../../../../../../utils/processing/hasOnlyDigits";

const NotchRoundModalContent = ({
                                    type,
                                    detailData,
                                    onClose,
                                    onSubmit,
                                    editableElementId,
                                    openConfirmModal,
                                    setActivePointId,
                                    calcPrevInnerContour,
                                    variables
                                }) => {

    const initData = detailData.roundNotches.find(el => el.id === editableElementId)

    const initFormState = editableElementId ? {
        "position": initData.contour.position,
        "radius": initData.variables.find(el => el.name === "RADIUS")?.pattern || initData.radius,
        "depth": initData.variables.find(el => el.name === "DEPTH")?.pattern || initData.depth,
        "x": initData.center.variables.find(el => el.name === "x")?.pattern || initData.center.x,
        "y": initData.center.variables.find(el => el.name === "y")?.pattern || initData.center.y,
    } : {
        "position": SIDE[0].value,
        "radius": "",
        "depth": "",
        "x": "",
        "y": "",
    }

    const [formState, setFormState] = useState(initFormState)
    const [validationState, setValidationState] = useState({
        "position": "",
        "radius": "",
        "depth": '',
        "x": "",
        "y": ""
    })

    const handleValidationData = () => {
        const newErrors = {...validationState}

        const calcValues = Object.fromEntries(Object.entries(formState).map(([name, value]) => {
            return name === "position" || !hasOnlyDigitsAndSymbols(value) ? [name, value] : [name, calcValueOfStringWithVar(variables, value.toString())]
        }))

        Object.entries(calcValues).forEach(([name, value]) => {
            if (name === "depth" && value > detailData.height) {
                newErrors.depth = `от 0.1 до ${detailData.height}`
            } else if (name === "radius" && value <= 30) {
                newErrors.radius = `введите > 30`
            }

            if (value === "") {
                newErrors[name] = "пустое поле"
            }
        })

        setValidationState(newErrors)

        if (Object.values(newErrors).every(el => el === "")) {
            onSubmit({
                "center": {x: formState.x, y: formState.y},
                "radius": formState.radius,
                "depth": formState.depth,
                "position": formState.position
            })
        }
    }

    const inputHandler = (getData) => {
        const data = getData()
        const currState = {...formState, ...data}

        setFormState(currState)
        data.pointId && setActivePointId(data.pointId)

        if ([currState.x, currState.y, currState.radius].every(str => str.toString().trim() !== '')) {
            calcPrevInnerContour({
                "radius": currState.radius,
                "x": currState.x,
                "y": currState.y,
            }, true)
        }
    }

    return (<>
        <DialogTitle
            textAlign="center">{type === "notchRound" ? "Добавить круглый контур" : "Изменить круглый контур"}</DialogTitle>
        <DialogContent style={{paddingBottom: 7}}>
            <FormControl sx={{minWidth: 300}}>
                <TextFieldValidation
                    select
                    name="position"
                    label="Позиция"
                    value={formState.position}
                    setCurrValues={inputHandler}
                    setValidationState={setValidationState}
                >
                    {[SIDE[0], SIDE[1]].map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextFieldValidation>
                <TextFieldValidation
                    type={"radius"}
                    name={"radius"}
                    value={formState?.radius}
                    label="Радиус"
                    setCurrValues={inputHandler}
                    validationError={validationState?.radius}
                    setValidationState={setValidationState}
                    adornment={editableElementId && +parseFloat(noExponents(initData.radius)).toFixed(2)}
                />
                <TextFieldValidation
                    name={"depth"}
                    value={formState?.depth}
                    label="Глубина"
                    setCurrValues={inputHandler}
                    validationError={validationState?.depth}
                    setValidationState={setValidationState}
                    adornment={editableElementId && +parseFloat(noExponents(initData.depth)).toFixed(2)}
                />
                <TextFieldValidation
                    name={"x"}
                    value={formState?.x}
                    label="X"
                    setCurrValues={inputHandler}
                    validationError={validationState?.x}
                    setValidationState={setValidationState}
                    adornment={editableElementId && +parseFloat(noExponents(initData.center.x)).toFixed(2)}
                />
                <TextFieldValidation
                    name={"y"}
                    value={formState?.y}
                    label="Y"
                    setCurrValues={inputHandler}
                    validationError={validationState?.y}
                    setValidationState={setValidationState}
                    adornment={editableElementId && +parseFloat(noExponents(initData.center.y)).toFixed(2)}
                />
                {editableElementId && <Button
                    size="small"
                    color="secondary"
                    onClick={() => openConfirmModal("deleteNotchRound", editableElementId)}
                    variant="outlined"
                >
                    Удалить контур
                </Button>}
            </FormControl>
        </DialogContent>
        <DialogActions sx={{p: '1.25rem'}}>
            <Button size="small" onClick={onClose}> отменить </Button>
            <Button size="small" color="secondary" onClick={handleValidationData} variant="contained">
                выбрать
            </Button>
        </DialogActions>
    </>)
}
export default NotchRoundModalContent
