export default class OrderUtil {

    static getListField(data, filed) {
        let set = new Set();
        for (const row of data) {
            set.add(row[filed])
        }
        return Array.from(new Set(set));
    }

    static getListFieldObject(data, filed) {
        const set = new Set();
        for (const row of data) {
            const value = row[filed]
            if (value !== null) {
                set.add(row[filed].name)
            }
        }
        return Array.from(new Set(set));
    }

    static getListOrderNumbers(data) {
        let set = new Set();
        for (const row of data) {
            for (const order of row['orders']) {
                set.add(order.number)
            }
        }
        return Array.from(new Set(set));
    }

    static getStatuses(data, filed) {
        let set = new Set();
        for (const row of data) {
            for (const status of row[filed]) {
                set.add(status.name)
            }
        }
        return Array.from(new Set(set));
    }

    static checkStatus(statuses, filterStatuses) {
        let check = false;
        for (const status of statuses) {
            for (const filterStatus of filterStatuses) {
                if (status.name === filterStatus) {
                    check = true;
                }
            }
        }
        return check;
    }

    static checkField(column) {
        let accessorKey = column.id;
        return !(accessorKey === 'id' || accessorKey === 'model' ||
            accessorKey === 'manufactured' || accessorKey === 'plannedDateOfShipment'
            || accessorKey === 'customer' || accessorKey === 'header'
            || accessorKey === 'state' || accessorKey === 'year'
            || accessorKey === 'attributes' || accessorKey === 'shipped' || accessorKey === 'status'
        );
    }

    static convertNumberOrders(orders) {

        let count = orders.length;
        let i = 0;
        let resultNumberOrders = "";

        for (const order of orders) {
            i++;
            resultNumberOrders += order.number;
            if (i !== count) {
                resultNumberOrders += ", "
            }
        }
        return resultNumberOrders;
    }

    static checkNumberOrder(statuses, filterStatuses) {
        let check = false;
        for (const status of statuses) {
            for (const filterStatus of filterStatuses) {
                if (status.number === filterStatus) {
                    check = true;
                }
            }
        }
        return check;
    }
}