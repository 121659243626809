import React, {useCallback, useMemo, useState} from 'react';
import MaterialReactTable from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import Box from "@mui/material/Box";
import {Button, Tooltip} from "@mui/material";
import Delete from "@mui/icons-material/DeleteOutlined";
import {Edit} from "@mui/icons-material";
import {constantsErrorMessages} from "../../../../../Constants";
import ModalAlert from "../ModalAlert";
import ModalAddVariable from "./ModalAddVariable";
import VariableControllerService from "../../../../../API/details/VariableControllerService";
import {materialReserveState} from "../../../../../data/MaterialReserveState";

const immutableVariables = ["W", "H", "L"]

const VariablesTable = ({
                            data, isError, setData, enableEditing, enableRowSelection, orderId, detailId
                        }) => {
    const rowInitValue = {name: "", pattern: "", comment: ""}

    const [variableModalOpen, setVariableModalOpen] = useState(false);
    const [alertData, setAlertData] = useState({});

    const [rowSelection, setRowSelection] = useState({});
    const [currentEditRowData, setCurrentEditRowData] = useState(rowInitValue);
    const [currentEditRowId, setCurrentEditRowId] = useState(null);
    const [isNew, setIsNew] = useState(true);

    const handleCloseAlert = () => {
        setAlertData((alert) => {
            return {...alert, open: false}
        })
    }
    const handleError = (error) => {
        setAlertData({
            type: 'error', open: true, message: constantsErrorMessages[error.response.status] || error.message
        })
    }
    const handleClose = () => {
        setVariableModalOpen(false)
        setCurrentEditRowData(rowInitValue)
        setIsNew(true)
    }

    const handleUpdate = (data) => {
        VariableControllerService.edit(orderId, detailId, currentEditRowId, data)
            .then(function (response) {
                setData(prev => prev.map(el => el.id === response.data.id ? response.data : el))
            })
            .catch(function (error) {
                handleError(error)
            })
        handleClose()
    }

    const handleDeleteVariable = (row) => {
        VariableControllerService.delete(orderId, detailId, row.original.id)
            .then(function () {
                setData(prev => prev.filter(el => el.id !== row.original.id))
            })
            .catch(function (error) {
                handleError(error)
            })
    };

    const handleSubmit = (data) => {
        VariableControllerService.save(orderId, detailId, data)
            .then(function (response) {
                setData(prev => [...prev, response.data])
            })
            .catch(function (error) {
                handleError(error)
            })
        handleClose()
    }

    const handleAddVariable = () => {
        setVariableModalOpen(true)
    };

    const handleEditVariable = (row) => {
        setCurrentEditRowData((prev) => {
            return Object.keys(prev).reduce((acc, it) => ({...acc, [it]: row[it]}), {})
        });
        setVariableModalOpen(true)
        setCurrentEditRowId(row.id);
        setIsNew(false);
    };

    const columns = useMemo(() => {
        const baseColumns = [{
            id: 'name', header: 'Наименование', accessorKey: 'name', minSize: 60,
        }, {
            id: 'pattern', header: 'Значение', accessorKey: 'pattern', Cell: ({row}) => {
                const numberPattern = Number(row.original.pattern)
                return isNaN(numberPattern) ? row.original.pattern : numberPattern.toFixed(2)
            }
        }, {
            id: 'value',
            header: 'Текущее значение',
            accessorKey: 'value',
            Cell: ({row}) => row.original.value.toFixed(2),
        }, {
            id: 'comment', header: 'Комментарий', accessorKey: 'comment',
        },]

        return baseColumns;

    }, [data]);

    return (<div>
        <MaterialReactTable
            muiTableBodyProps={{
                sx: () => ({
                    '& tr:nth-of-type(odd)': {
                        backgroundColor: 'lightgray',
                    },
                }),
            }}
            muiTableBodyCellProps={{
                sx: {
                    p: '0px 0px 0px 10px', m: '0px 0px 0px 0px',
                },
            }}
            muiTableBodyRowProps={{
                sx: {
                    height: '10px',
                },
            }}

            displayColumnDefOptions={{
                'mrt-row-actions': {
                    muiTableHeadCellProps: {
                        align: 'center',

                    }, size: 15, minSize: 50,
                },
            }}
            columns={columns}
            data={isError ? [] : data}
            rowHeight={13}
            columnSpacing={1}
            enableEditing={enableEditing}
            onRowSelectionChange={setRowSelection}
            state={{rowSelection}}
            enableRowSelection={enableRowSelection}
            getRowId={(originalRow) => originalRow.id}
            selectionPadding={1}
            selectionCss={{
                backgroundColor: 'lightblue',
                borderWidth: '2px',
                borderStyle: 'solid',
                borderColor: 'blue',
                borderRadius: '4px',
            }}
            renderRowActions={({row, table}) => {
                const disabled = immutableVariables.includes(row.original.name)
                return (<Box sx={{
                    display: 'flex', gap: '0.1rem', justifyContent: 'flex-start', maxWidth: '50px'
                }}>
                    <Tooltip arrow placement="left" title="Редактировать">
                        <Button size="small"
                                disabled={disabled}
                                sx={{
                                    minWidth: 'auto', marginLeft: 'auto',
                                }} color="pencil"
                                onClick={() => handleEditVariable(row.original)}>
                            <Edit sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                        </Button>
                    </Tooltip>
                    <Tooltip arrow placement="right" title="Удалить">
                        <Button size="small" disabled={disabled}
                                sx={{minWidth: 'auto', marginLeft: 'auto'}}
                                color="error"
                                onClick={() => handleDeleteVariable(row)}>
                            <Delete sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                        </Button>
                    </Tooltip>
                </Box>)
            }}
            localization={MRT_Localization_RU}
            renderTopToolbarCustomActions={({table}) => {
                return (<div>
                    <Box
                        sx={{display: 'flex', gap: '0.5rem', p: '0.5rem', flexWrap: 'wrap'}}
                    >
                        <Button
                            size="small"
                            color="success"
                            onClick={handleAddVariable}
                            variant="contained"
                            disabled={isError}
                        >
                            Добавить переменную
                        </Button>
                    </Box>
                </div>);
            }}
        />
        <ModalAddVariable title={isNew ? "Добавить переменную" : "Изменить переменную"}
                          initValue={currentEditRowData}
                          open={variableModalOpen}
                          onClose={handleClose}
                          handleSubmit={isNew ? handleSubmit : handleUpdate}
                          labelButtonSubmit={isNew ? 'Создать' : 'Обновить'}
        />
        <ModalAlert data={alertData} onClose={handleCloseAlert}/>
    </div>);
};

export default VariablesTable;
