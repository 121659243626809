import React, {useEffect, useMemo, useState} from 'react';
import MaterialReactTable from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import {Link} from 'react-router-dom';
import OrderService from "../../../../../../API/orders/orders/organization/OrderService";
import AttributeUtil from "../../../../../../utils/orders/AttributeUtil";
import OrderUtil from "../../../../../../utils/orders/OrderUtil";
import HeaderOPT from "../production/HeaderOPT";
import MenuItem from "@mui/material/MenuItem";
import DataUtil from "../../../../../../utils/common/DataUtil";
import FilterUtil from "../../../../../../utils/common/FilterUtil";
import DateTimeUtil from "../../../../../../utils/common/DateTimeUtil";
import DateFilter from "../../../../filter/DateRangeFilter";

const ArchiveTable = () => {

    const [data, setData] = useState([]);
    const [customerList, setCustomerList] = useState([]);
    const [nameList, setNameList] = useState([]);
    const [attributeList, setAttributeList] = useState([]);
    const [planningList, setPlanningList] = useState([]);
    const [constructorList, setConstructorList] = useState([]);
    const [technologistList, setTechnologistList] = useState([]);
    const [isFullScreen, setIsFullScreen] = useState(false);

    const handleCreateNewRow = (values) => {
        data.push(values);
        setData([...data]);
    };

    useEffect(() => {
        OrderService.getOrdersArchive().then(function (response) {
            setData(response.data);
            setCustomerList(DataUtil.getListChildField(response.data, 'customerOrder', 'name'));
            setNameList(DataUtil.getListChildField(response.data, 'header', 'name'));
            setConstructorList(DataUtil.getListChildField(response.data, 'constructor', 'name'));
            setTechnologistList(DataUtil.getListChildField(response.data, 'technologist', 'name'));
            setAttributeList(OrderUtil.getStatuses(response.data, 'attributes'));
            setPlanningList(DataUtil.getListField(response.data, 'state'));
        })
            .catch(function (error) {
                console.log(error);
            });
    }, []);

    const columns = useMemo(
        () => [
            {
                id: 'number',
                header: 'Номер заказа                                                   ',
                accessorKey: 'number',
                filterFn: 'contains',
                maxSize: 23,
                minSize: 35,
                Header: ({column}) => <HeaderOPT column={column}></HeaderOPT>,
                Cell: ({cell, row}) => (
                    <li key={cell.getValue()}>
                        <Link
                            to={'/organization/orders/' + data[row.index].id + '/' + cell.getValue()}>{cell.getValue()}</Link>
                    </li>
                ),
            },
            {
                id: 'numberCustomer',
                header: 'Номер заказчика                                             ',
                accessorKey: 'numberCustomer',
                filterFn: 'contains',
                maxSize: 23,
                minSize: 35,
                Header: ({column}) => <HeaderOPT column={column}></HeaderOPT>,
            },
            {
                id: 'customer',
                header: 'Заказчик                                                           ',
                accessorKey: 'customerOrder.name',
                enableStickyHeader: true,
                enableStickyFooter: true,
                enableGrouping: true,
                filterVariant: 'multi-select',
                filterSelectOptions: customerList,
                muiTableBodyCellEditTextFieldProps: {
                    select: true,
                    children: customerList.map((customer) => (
                        <MenuItem key={customer} value={customer}>
                            {customer}
                        </MenuItem>
                    )),
                },
                minSize: 15,
                Header: ({column}) => <HeaderOPT column={column}></HeaderOPT>,
            },
            {
                id: 'header',
                header: 'Найменование                                                 ',
                accessorKey: 'header.name',
                filterVariant: 'multi-select',
                filterSelectOptions: nameList,
                muiTableBodyCellEditTextFieldProps: {
                    select: true,
                    children: nameList.map((header) => (
                        <MenuItem key={header} value={header}>
                            {header}
                        </MenuItem>
                    )),
                },
                enableGrouping: true,
                minSize: 15,
                Header: ({column}) => <HeaderOPT column={column}></HeaderOPT>,
            },
            {
                id: 'model',
                header: 'Модель                                                             ',
                accessorKey: 'modelOrder.name',
                filterVariant: 'multi-select',
                filterSelectOptions: nameList,
                enableGrouping: true,
                minSize: 150,
                Header: ({column}) => <HeaderOPT column={column}></HeaderOPT>,
            },
            {
                accessorFn: (row) => {
                    if (row.dateOfReceipt === null) {
                    } else {
                        return new Date(row.dateOfReceipt)
                    }
                },
                id: 'dateOfReceipt',
                header: 'Дата поступления                                           ',
                sortingFn: 'datetime',
                size: 10,
                filterFn: (row, id, filterValue) => {
                    return FilterUtil.checkDate(row.getValue(id), filterValue)
                },
                Cell: ({cell}) => {
                    if (cell.getValue() !== undefined) {
                        return cell.getValue().toLocaleDateString();
                    }
                },
                Header: ({column}) => <HeaderOPT column={column}></HeaderOPT>,
                Filter: ({column}) => <DateFilter column={column}/>,
            },
            {
                header: 'Констуктор                                                       ',
                accessorKey: 'constructor.name',
                enableStickyHeader: true,
                enableStickyFooter: true,
                enableGrouping: true,
                filterVariant: 'multi-select',
                filterSelectOptions: constructorList,
                muiTableBodyCellEditTextFieldProps: {
                    select: true,
                    children: constructorList.map((constructor) => (
                        <MenuItem key={constructor} value={constructor}>
                            {constructor}
                        </MenuItem>
                    )),
                },
                maxSize: 100,
                minSize: 100,
                Header: ({column}) => <HeaderOPT column={column}></HeaderOPT>,
            },
            {
                accessorFn: (row) => {
                    if (row.dateOfConstructorDocumentation === null) {
                    } else {
                        return new Date(row.dateOfConstructorDocumentation)
                    }
                },
                id: 'dateOfConstructorDocumentation',
                header: 'Дата тех. документации конструктор           ',
                sortingFn: 'datetime',
                maxSize: 35,
                minSize: 30,
                filterFn: (row, id, filterValue) => {
                    return FilterUtil.checkDate(row.getValue(id), filterValue)
                },
                Cell: ({cell}) => {
                    if (cell.getValue() !== undefined) {
                        return cell.getValue().toLocaleDateString();
                    }
                },
                Header: ({column}) => <HeaderOPT column={column}></HeaderOPT>,
                Filter: ({column}) => <DateFilter column={column}/>,
            },
            {
                header: 'Технолог                                                           ',
                accessorKey: 'technologist.name',
                enableStickyHeader: true,
                enableStickyFooter: true,
                enableGrouping: true,
                filterVariant: 'multi-select',
                filterSelectOptions: technologistList,
                muiTableBodyCellEditTextFieldProps: {
                    select: true,
                    children: technologistList.map((technologist) => (
                        <MenuItem key={technologist} value={technologist}>
                            {technologist}
                        </MenuItem>
                    )),
                },
                maxSize: 20,
                Header: ({column}) => <HeaderOPT column={column}></HeaderOPT>,
            },
            {
                accessorFn: (row) => {
                    if (row.dateOfTechnicalDocumentation === null) {
                    } else {
                        return new Date(row.dateOfTechnicalDocumentation)
                    }
                },
                id: 'dateOfTechnicalDocumentation',
                header: 'Дата тех. документации технолог                ',
                sortingFn: 'datetime',
                size: 10,
                filterFn: (row, id, filterValue) => {
                    return DateTimeUtil.checkDate(row.getValue(id), filterValue)
                },
                Cell: ({cell}) => {
                    if (cell.getValue() !== undefined) {
                        return cell.getValue().toLocaleDateString();
                    }
                },
                Header: ({column}) => <HeaderOPT column={column}></HeaderOPT>,
                Filter: ({column}) => <DateFilter column={column}/>,
            },
            {
                id: 'attributes',
                header: 'Атрибуты                                                         ',
                accessorKey: 'attributes',
                filterVariant: 'multi-select',
                filterSelectOptions: attributeList,
                filterFn: (row, id, filterValue) => {
                    return OrderUtil.checkStatus(row.getValue(id), filterValue)
                },
                minSize: 20,
                Cell: ({cell}) => (<div>{AttributeUtil.convertAttributes(cell.getValue())}</div>),
                Header: ({column}) => <HeaderOPT column={column}></HeaderOPT>,

            },
            {
                accessorFn: (row) => {
                    if (row.manufactured === null) {
                    } else {
                        return new Date(row.manufactured)
                    }
                },
                id: 'manufactured',
                header: 'Произведено                                                   ',
                filterFn: (row, id, filterValue) => {
                    return FilterUtil.checkDate(row.getValue(id), filterValue)
                },
                sortingFn: 'datetime',
                size: 10,
                Cell: ({cell}) => {
                    if (cell.getValue() !== undefined) {
                        return cell.getValue().toLocaleDateString();
                    }
                },
                Header: ({column}) => <HeaderOPT column={column}></HeaderOPT>,
                Filter: ({column}) => <DateFilter column={column}/>,
            },
            {
                accessorFn: (row) => {
                    if (row.shipped === null) {
                    } else {
                        return new Date(row.shipped)
                    }
                },
                id: 'shipped',
                header: 'Отгружено                                                        ',
                sortingFn: 'datetime',
                size: 10,
                filterFn: (row, id, filterValue) => {
                    return DateTimeUtil.checkDate(row.getValue(id), filterValue)
                },
                Cell: ({cell}) => {
                    if (cell.getValue() !== undefined) {
                        return cell.getValue().toLocaleDateString();
                    }
                },
                Header: ({column}) => <HeaderOPT column={column}></HeaderOPT>,
                Filter: ({column}) => <DateFilter column={column}/>,
            },
            {
                id: 'state',
                header: 'Планирование                                                 ',
                accessorKey: 'state',
                filterVariant: 'multi-select',
                filterSelectOptions: planningList,
                minSize: 6,
                maxSize: 6,
                Header: ({column}) => <HeaderOPT column={column}></HeaderOPT>,
            },
            {
                id: 'year',
                header: 'год                                                                     ',
                accessorKey: 'year',
                enableColumnActions: false,
                enableGrouping: false,
                enableColumnFilter: false,
                enableEditing: false,
                Header: ({column}) => <HeaderOPT column={column}></HeaderOPT>,
            },
            {
                header: 'id',
                accessorKey: 'id',
                enableHiding: false,
                enableColumnActions: false,
                enableGrouping: false,
                enableColumnFilter: false,
                enableEditing: false,
            },
        ],
        [data, setData],
    );

    return (
        <>
            <MaterialReactTable
                isFullScreen={isFullScreen}
                enableColumnFilterModes
                enableGrouping
                enableStickyHeader
                enablePinning
                muiTableBodyProps={{
                    sx: () => ({
                        '& tr:nth-of-type(odd)': {
                            backgroundColor: 'lightgray',
                        },
                    }),
                }}
                muiTableBodyCellProps={{
                    sx: {
                        p: '0px 0px 0px 0px',
                        m: '0px',
                    },
                }}
                muiTableBodyRowProps={{
                    sx: {
                        height: '10px',
                    },
                }}
                columns={columns}
                data={data}
                initialState={{
                    columnVisibility: {
                        id: false,
                        state: false,
                        model: false,
                        year: false,
                    },
                    isFullScreen: false,
                    density: "compact",
                    pagination: {pageIndex: 0, pageSize: 100},
                    columnPinning: {left: ['number']}
                }}
                muiToolbarAlertBannerChipProps={{color: 'primary'}}
                localization={MRT_Localization_RU}
            />
        </>
    );
};

export default ArchiveTable;