import {Button} from "@mui/material";

const ButtonExit = ({onClick, text}) => {
    return <Button
        onClick={onClick}
        sx={{
            width: 117,
            height: 56,
            textTransform: 'none',
            fontSize: '16px',
            color: (theme) => theme.palette.text.main,
            "&:hover": {
                backgroundColor: 'transparent',
            }
        }}>
        {text}
    </Button>
}
export default ButtonExit