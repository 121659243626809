import React from 'react';
import {TextField} from "@mui/material";
import {statuses} from "../../../data/ArrivalState";

const ArrivalStateTextFieldObjectWithEntityList = ({label, values, setValues, field, disabled, excludeStatus}) => {

    return (
        <div>
            <TextField
                size={"small"}
                disabled={disabled}
                key={field}
                sx={{
                    width: '100%',
                    minWidth: {xs: '300px', sm: '360px', md: '400px'},
                    gap: '1.5rem',
                }}
                margin="normal"
                select
                label={label}
                value={values === null ? "" : (values[field] === null ? "" : values[field])}
                name={field}
                onChange={(e) =>
                    setValues({...values, [e.target.name]: e.target.value})
                }
                SelectProps={{
                    native: true,
                }}
            >
                {Object.keys(statuses).map((statusKey) => (
                    excludeStatus !== statusKey && <option key={statusKey} value={statusKey}>
                        {statuses[statusKey]}
                    </option>
                ))}
            </TextField>
        </div>
    );
};

export default ArrivalStateTextFieldObjectWithEntityList;