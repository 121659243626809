import React, {useEffect, useState} from 'react';
import ReservationMaterialTable from "../../../../components/UI/table/warehouse/reservation/ReservationMaterialTable";
import OrderUtil from "../../../../utils/orders/OrderUtil";
import MaterialReserveService from "../../../../API/orders/reservation/MaterialReserveService";

const ReservationSlabMaterial = () => {

    const [data, setData] = useState([]);
    const [change, setChange] = useState(false);
    const [orderList, setOrderList] = useState([]);

    useEffect(() => {
        MaterialReserveService.getAllNotArchiveSlabMaterial().then(function (response) {
            const data = response.data;
            setData(data);
            setOrderList(OrderUtil.getListOrderNumbers(data))
        })
            .catch(function (error) {
                console.log(error);
            });
    }, [change]);

    return (
        <div>
            <ReservationMaterialTable
                data={data}
                setData={setData}
                pageSize={100}
                enableGrouping={true}
                columnGrouping={["materialName", "size"]}
                expanded={false}
                isColumnMaterialWarehouse={true}
                isColumnOptimization={true}
                orderList={orderList}
                change={change}
                setChange={setChange}
                titleModalUpdateOrAddMaterialArrival={"Создать приход плитных материалов"}
                typeMaterial={'SLAB'}
            />
        </div>
    );
};

export default ReservationSlabMaterial;