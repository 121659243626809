import React from 'react';
import {Button, Dialog, DialogContent, DialogTitle, TextField} from "@mui/material";
import {statusesYesOrNo} from "../../../data/StatusesYesOrNo";

const ModalOpenSetValuesCutOrTurnDetail = ({title, label, modalOpen, handleClose, setValue, handleSubmit}) => {

    const options = Object.entries(statusesYesOrNo).map(([key, value]) => ({id: key, value}));

    return (
        <div>
            <Dialog open={modalOpen} onClose={handleClose}>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <form onSubmit={handleSubmit}>
                        <TextField
                            sx={{
                                width: '100%',
                                minWidth: {xs: '300px', sm: '360px', md: '400px'},
                                gap: '1.5rem',
                            }}
                            margin="normal"
                            key={label}
                            label={label}
                            name={label}
                            value={statusesYesOrNo[setValue]}
                            onChange={(e) => setValue(e.target.value)}
                            select
                            SelectProps={{
                                native: true,
                            }}
                        >
                            {options.map((option) => (
                                <option
                                    key={option.id}
                                    value={option.id}
                                >
                                    {option.value}
                                </option>
                            ))}
                        </TextField>
                        <Button type="submit" variant="contained" color="secondary">
                            Применить
                        </Button>
                    </form>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default ModalOpenSetValuesCutOrTurnDetail;