import React, {useCallback, useEffect, useMemo, useState} from 'react';
import MaterialReactTable from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import WorkerService from "../../../../../../API/orders/orders/organization/job/WorkerService";
import {Tooltip} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {Delete, Edit} from "@mui/icons-material";
import Box from "@mui/material/Box";
import FilterUtil from "../../../../../../utils/common/FilterUtil";
import ButtonSettingsOrders from "../../../../button/ButtonSettingsOrders";
import CellCheckboxWithUnable from "../../../../сheckbox/CellCheckboxWithUnable";
import {Link} from "react-router-dom";

const WorkerTable = () => {

    const [data, setData] = useState([]);
    const [isFullScreen, setIsFullScreen] = useState(false);

    const [change, setChange] = useState(null);
    const [unable, setUnable] = useState({});
    const [unableProduction, setUnableProduction] = useState({});
    const [unableConstructor, setUnableConstructor] = useState({});

    const handleSaveRowEdits = async ({exitEditingMode, row, values}) => {
        WorkerService.update(values)
            .then(function () {
                data[row.index] = values;
                setData([...data]);
            })
            .catch(function (error) {
                console.log(error);
            })
        exitEditingMode();
    };

    const handleDeleteRow = useCallback(
        (row) => {
            WorkerService.delete(row.original.id)
                .then(function () {
                    data.splice(row.index, 1);
                    setData([...data]);
                })
                .catch(function (error) {
                    console.log(error);
                })
        },
        [data],
    );

    useEffect(() => {

        WorkerService.getAll().then(function (response) {
            let data = response.data;
            setData(response.data);
            data.forEach((row) => {
                unable[row.id] = row.enabled;
                unableProduction[row.id] = row.production;
                unableConstructor[row.id] = row.constructor;
            });
        })
            .catch(function (error) {
                console.log(error);
            });
    }, [change]);

    const handleUnableCheckboxConstructor = (id, status) => {
        WorkerService.unableConstructor(id, status)
            .then(function () {
                handleUnableConstructor(id, status)
            })
            .catch(function (error) {
                console.log(error);
            })
    };

    const handleUnableConstructor = (id, status) => {
        unableConstructor[id] = status
        setUnableConstructor(prevState => ({
            ...prevState,
            [id]: status
        }));
    };

    const handleUnableCheckbox = (id, status) => {
        WorkerService.unable(id, status)
            .then(function () {
                handleUnable(id, status)
            })
            .catch(function (error) {
                console.log(error);
            })
    };

    const handleUnable = (id, status) => {
        unable[id] = status
        setUnable(prevState => ({
            ...prevState,
            [id]: status
        }));
    };

    const handleUnableCheckboxProduction = (id, status) => {
        WorkerService.unableProduction(id, status)
            .then(function () {
                handleUnableProduction(id, status)
            })
            .catch(function (error) {
                console.log(error);
            })
    };

    const handleUnableProduction = (id, status) => {
        unableProduction[id] = status
        setUnableProduction(prevState => ({
            ...prevState,
            [id]: status
        }));
    };


    const columns = useMemo(
        () => [
            {
                header: 'Фамилия',
                accessorKey: 'lastName',
                minSize: 25,
            },
            {
                header: 'Имя',
                accessorKey: 'firstName',
                minSize: 25,
            },
            {
                header: 'Отчество',
                accessorKey: 'middleName',
                minSize: 25,
            },
            {
                header: 'Должность',
                accessorKey: 'position',
                minSize: 50,
                Cell: ({cell, row}) => (
                    <li key={cell.getValue()}>
                        <Link
                            to={'/organization/workers/' + data[row.index].id + '/ratings/' + row.original.lastName + ' ' + row.original.firstName + ' ' + row.original.middleName}>
                            {data[row.index].position === null ? 'Не определена' : cell.getValue()}</Link>
                    </li>
                ),
            },
            {
                header: 'Статус',
                accessorKey: 'enabled',
                size: 8,
                enableEditing: false,
                filterVariant: 'multi-select',
                filterSelectOptions: FilterUtil.statusList,
                filterFn: (row, id, filterValue) => {
                    return FilterUtil.checkStatus(row.getValue(id), filterValue)
                },
                Cell: ({cell, row}) => <CellCheckboxWithUnable
                    id={row.id}
                    unable={unable}
                    setUnable={handleUnableCheckbox}
                >
                </CellCheckboxWithUnable>,
            },
            {
                header: 'Производство',
                accessorKey: 'production',
                size: 8,
                enableEditing: false,
                filterVariant: 'multi-select',
                filterSelectOptions: FilterUtil.statusList,
                filterFn: (row, id, filterValue) => {
                    return FilterUtil.checkStatus(row.getValue(id), filterValue)
                },
                Cell: ({cell, row}) => <CellCheckboxWithUnable
                    id={row.id}
                    unable={unableProduction}
                    setUnable={handleUnableCheckboxProduction}
                >
                </CellCheckboxWithUnable>,
            },
            {
                header: 'Проектирование',
                accessorKey: 'constructor',
                size: 8,
                enableEditing: false,
                filterVariant: 'multi-select',
                filterSelectOptions: FilterUtil.statusList,
                filterFn: (row, id, filterValue) => {
                    return FilterUtil.checkStatus(row.getValue(id), filterValue)
                },
                Cell: ({cell, row}) => <CellCheckboxWithUnable
                    id={row.id}
                    unable={unableConstructor}
                    setUnable={handleUnableCheckboxConstructor}
                >
                </CellCheckboxWithUnable>,
            },
            {
                header: 'Email',
                accessorKey: 'email',
                minSize: 50
            },
            {
                header: 'Номер телефона',
                accessorKey: 'phoneNumber',
                size: 8,
            },
            {
                header: 'id',
                accessorKey: 'id',
                enableHiding: false,
                enableColumnActions: false,
                enableGrouping: false,
                enableEditing: false,
            },
        ],
        [data, setData],
    );

    return <MaterialReactTable
        muiTableBodyProps={{
            sx: () => ({
                '& tr:nth-of-type(odd)': {
                    backgroundColor: 'lightgray',
                },
            }),
        }}
        muiTableBodyCellProps={{
            sx: {
                p: '0px 0px 0px 0px',
                m: '0px 0px 0px 0px',
            },
        }}
        muiTableBodyRowProps={{
            sx: {
                height: '10px',
            },
        }}
        isFullScreen={isFullScreen}
        displayColumnDefOptions={{
            'mrt-row-actions': {
                muiTableHeadCellProps: {
                    align: 'center',
                },
                size: 30,
            },
        }}
        columns={columns}
        data={data}
        editingMode="modal"
        enableEditing
        getRowId={(originalRow) => originalRow.id}
        onEditingRowSave={handleSaveRowEdits}
        renderRowActions={({row, table}) => (
            <Box sx={{
                display: 'flex',
                gap: '0.1rem',
                justifyContent: 'flex-start',
                maxWidth: '30px'
            }}>
                <Tooltip arrow placement="left" title="Редактировать">
                    <IconButton onClick={() => table.setEditingRow(row)}>
                        <Edit sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                    </IconButton>
                </Tooltip>
                <Tooltip arrow placement="right" title="Удалить">
                    <IconButton color="error" onClick={() => handleDeleteRow(row)}>
                        <Delete sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                    </IconButton>
                </Tooltip>
            </Box>
        )}
        initialState={{
            sorting: [{id: 'firstName', desc: false}],
            columnVisibility: {
                id: false,
            },
            isFullScreen: false,
            density: "compact",
            pagination: {pageIndex: 0, pageSize: 20},
            columnPinning: {left: ['number']}
        }}
        localization={MRT_Localization_RU}
        renderTopToolbarCustomActions={() => (
            <Box
                sx={{display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap'}}
            >
                <ButtonSettingsOrders/>
            </Box>
        )}
    />;
};

export default WorkerTable;