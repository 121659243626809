export const estimateStatuses = {
    "WAIT_OFFERS": "Ожидание предложений",
    "CALCULATED_OFFERS": "Расчитаны предложения",
    "PERFORMER_SELECTED": "Выбран исполнитель",
    "PERFORMER_CONFIRMED": "Исполнитель подтвержден",
    "CALCULATION": "Расчетная",
    "IN_WORK": "В работе",
    "PURCHASE": "Заказать",
    "FORMATION_PURCHASE": "Формирование закупки",
    "PURCHASED": "Заказана",
    "COLLECTED": "Собрана",
    "PRODUCTION": "В производстве",
    "SHIPMENT": "Отгружена",
    "ARCHIVE": "Архив"
}