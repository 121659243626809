import React, {useEffect, useState} from 'react';
import ReservationMaterialEstimateUnitTable
    from "../../../../components/UI/table/warehouse/reservation/ReservationMaterialEstimateUnitTable";
import {useParams} from "react-router-dom";
import MaterialReserveService from "../../../../API/orders/reservation/MaterialReserveService";

const IssuanceReservationMaterialEstimateUnit = () => {

    const [data, setData] = useState([]);
    const [change, setChange] = useState(false);
    const params = useParams();
    const materialEstimateUnitId = params.eId;
    const orderId = params.orderId;
    const orderNumber = params.orderNumber;
    const type = params.type

    const [unablePackaging, setUnablePackaging] = useState({});

    useEffect(() => {
        MaterialReserveService.getAllByMaterialEstimateUnitId(type, materialEstimateUnitId).then(function (response) {
            const data = response.data;
            data.forEach((row) => {
                unablePackaging[row.id] = row.packaging;
            });
            setData(data);
        })
            .catch(function (error) {
                console.log(error);
            });
    }, [change]);

    const handleUnableCheckboxPackaging = (id, status) => {
        MaterialReserveService.setPackaging(id, status).then(function () {
            handleUnableTurn(id, status);
        })
            .catch(function (error) {
                console.log(error);
            });
    };

    const handleUnableTurn = (id, status) => {
        unablePackaging[id] = status
        setUnablePackaging(prevState => ({
            ...prevState,
            [id]: status
        }));
    };

    return (
        <div>
            <ReservationMaterialEstimateUnitTable
                data={data}
                setData={setData}
                orderId={orderId}
                nameOrder={orderNumber}
                isButtonPurchase={false}
                isSlab={false}
                isColumnOrder={false}
                isIssue={true}
                unablePackaging={unablePackaging}
                setUnablePackaging={handleUnableCheckboxPackaging}
            />
        </div>
    );
};

export default IssuanceReservationMaterialEstimateUnit;