import axios from "axios";
import {apiVersionModelEstimate, gatewayUrl, modelEstimate} from "../../../Constants";
import ObjectUtil from "../../../utils/common/ObjectUtil";
import {
    warehouseFinishedDetailRestUrl
} from "../../orders/cutting/warehousefinisheddetail/WarehouseFinishedDetailService";

const detailOrderRestUrl = gatewayUrl + modelEstimate  + apiVersionModelEstimate +'/details'

export default class ModelDetailService {

    static async getAllModelEstimateId(estimateId) {
        return axios.get(detailOrderRestUrl + '/estimate?estimateId=' + estimateId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async unableCutOrTurn(id, status, typeStatus, warehouse) {
        let url = null;
        if (warehouse === 'WarehouseFinishedDetail') {
            url = warehouseFinishedDetailRestUrl
        } else if (warehouse === 'orders') {
            url = detailOrderRestUrl
        }
        return axios.patch(url + '/' + id + '/unable-' + typeStatus + '?status=' + status, [], {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async updateCutOrTurnByIds(ids, status, typeStatus, warehouse) {
        let url = null;
        if (warehouse === 'WarehouseFinishedDetail') {
            url = warehouseFinishedDetailRestUrl
        } else if (warehouse === 'orders') {
            url = detailOrderRestUrl
        }
        let data = ObjectUtil.createUpdateRequestObjectIds(ids, status);
        await axios.post(url + '/update-cut-and-turn-by-ids'
            + '?status=' + status + '&typeStatus=' + typeStatus,
            JSON.stringify(data), {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
    }

    static async update(data, warehouse) {
        let url = null;
        if (warehouse === 'WarehouseFinishedDetail') {
            url = warehouseFinishedDetailRestUrl
        } else if (warehouse === 'orders') {
            url = detailOrderRestUrl
        }
        return await axios.post(url + '/' + data.id, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async save(data, requestsCuttingId, warehouse) {
        let url = null;
        if (warehouse === 'WarehouseFinishedDetail') {
            url = warehouseFinishedDetailRestUrl
        } else if (warehouse === 'orders') {
            url = detailOrderRestUrl
        }
        return await axios.post(url + '?requestsCuttingId=' + requestsCuttingId, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async delete(id, warehouse) {
        await axios.delete(detailOrderRestUrl + '/' + +id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
}