import axios from "axios";
import {apiVersionOrders, gatewayUrl, nameOrders} from "../../../../Constants";


const estimateRestUrl = gatewayUrl + nameOrders + apiVersionOrders + '/customers/orders/'

export default class CustomerEstimateService {

    static async getAllByOrderId(orderId) {
        return axios.get(estimateRestUrl + orderId + '/estimates/by-order');
    }

    static async createOffers(orderId) {
        return await axios.post(estimateRestUrl + orderId + '/estimates/create-offers', [], {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
}