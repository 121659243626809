import React, {useEffect, useMemo, useState} from 'react';
import MaterialReactTable from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import {Button, Tooltip} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {AddCircle} from "@mui/icons-material";
import Box from "@mui/material/Box";
import PackagingUnitService from "../../../../../API/model-estimate/packaging/PackagingUnitService";
import PackagingSetService from "../../../../../API/model-estimate/packaging/PackagingSetService";
import InfoModal from "../../../modal/InfoModal";
import DateTimeUtil from "../../../../../utils/common/DateTimeUtil";
import ErrorModal from "../../../modal/ErrorModal";
import {useNavigate} from "react-router-dom";

const PackagingUnitTableAdd = ({id}) => {

    const [data, setData] = useState([]);
    const [isFullScreen, setIsFullScreen] = useState(false);

    const [modalOpen, setModalOpen] = useState(false);
    const [modalErrorOpen, setModalErrorOpen] = useState(false);
    const navigate = useNavigate();

    const handleAddPackagingUnit = (row) => {
        PackagingSetService.addPackagingUnit(id, row.original.id)
            .then(async function () {
                setModalOpen(true);
                await DateTimeUtil.timeout(1000);
                setModalOpen(false);
                setData([...data]);
            })
            .catch(async function (error) {
                setModalErrorOpen(true);
                await DateTimeUtil.timeout(1000);
                setModalErrorOpen(false);
                console.log(error);
            })
    };

    const redirectPackagingSets = () => {
        navigate('/packaging-sets/');
    };

    useEffect(() => {
        PackagingUnitService.getAll().then(function (response) {
            setData(response.data);
        })
            .catch(function (error) {
                console.log(error);
            });
    }, []);


    const columns = useMemo(
        () => [
            {
                id: 'name',
                header: 'Наименование',
                accessorKey: 'name',
                minSize: 70,
            },
            {
                id: 'weight',
                header: 'Вес',
                accessorKey: 'weight',
                minSize: 15,
            },
            {
                header: 'id',
                id: 'id',
                accessorKey: 'id',
                enableHiding: false,
                enableColumnActions: false,
                enableGrouping: false,
                enableEditing: false,
            },
        ],
        [data, setData],
    );

    return (
        <>
            <MaterialReactTable
                muiTableBodyProps={{
                    sx: () => ({
                        '& tr:nth-of-type(odd)': {
                            backgroundColor: 'lightgray',
                        },
                    }),
                }}
                muiTableBodyCellProps={{
                    sx: {
                        p: '0px 0px 0px 0px',
                        m: '0px',
                    },
                }}
                muiTableBodyRowProps={{
                    sx: {
                        height: '10px',
                    },
                }}
                isFullScreen={isFullScreen}
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        size: 120,
                    },
                }}
                columns={columns}
                data={data}
                editingMode="modal"
                enableEditing
                renderRowActions={({row, table}) => (
                    <Box sx={{display: 'flex', gap: '1rem'}}>
                        <Tooltip arrow placement="left" title="Добавить">
                            <IconButton onClick={() => handleAddPackagingUnit(row)}>
                                <AddCircle color="success"/>
                            </IconButton>
                        </Tooltip>
                    </Box>
                )}
                initialState={{
                    columnVisibility: {
                        id: false,
                    },
                    isFullScreen: false,
                    density: "compact",
                    pagination: {pageIndex: 0, pageSize: 20},
                    columnPinning: {left: ['number']}
                }}
                localization={MRT_Localization_RU}
                renderTopToolbarCustomActions={() => (
                    <Box
                        sx={{display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap'}}
                    >
                        <Button
                            color="secondary"
                            onClick={redirectPackagingSets}
                            variant="contained"
                        >
                            Вернуться в комплекты упаковки
                        </Button>
                    </Box>
                )}
            />
            <InfoModal
                setOpen={setModalOpen}
                open={modalOpen}
                message={'Единица успешно добавлена'}
            />
            <ErrorModal
                setOpen={setModalErrorOpen}
                open={modalErrorOpen}
                message={'Единица не добавлена'}
            />
        </>
    );
};

export default PackagingUnitTableAdd;