import React, {useEffect, useState} from 'react';
import WarehouseMaterialItemTable from "../../../../../components/UI/table/warehouse/material/materialwarehouse/WarehouseMaterialItemTable";
import {useParams} from "react-router-dom";
import {Tabs} from "@mui/material";
import Box from "@mui/material/Box";
import {TabContext, TabPanel} from "@mui/lab";
import Tab from "@mui/material/Tab";
import CloudOptionsMaterialModule from "../../../../../components/UI/modul/mainoptions/CloudOptionsMaterialModule";
import SupplierService from "../../../../../API/orders/warehouse/supplier/SupplierService";
import GroupMaterialService from "../../../../../API/orders/orders/organization/GroupMaterialService";
import ManufacturerService from "../../../../../API/orders/warehouse/manufacturer/ManufacturerService";
import SupplierMaterialItemModule from "../../../../../components/UI/modul/mainoptions/SupplierMaterialItemModule";
import MaterialOrganizationOptionService
    from "../../../../../API/orders/warehouse/material/MaterialOrganizationOptionService";
import CurrentOptionsMaterialModule from "../../../../../components/UI/modul/mainoptions/CurrentOptionsMaterialModule";
import UserSupplierMaterialService from "../../../../../API/orders/warehouse/material/UserSupplierMaterialService";
import UserManufacturerMaterialCloudService
    from "../../../../../API/material-cloud/user/UserManufacturerMaterialCloudService";
import UserGroupMaterialMaterialCloudService
    from "../../../../../API/material-cloud/user/UserGroupMaterialMaterialCloudService";
import UserMaterialMaterialCloudService
    from "../../../../../API/material-cloud/user/UserMaterialMaterialCloudService";
import AdminMaterialMaterialCloudService
    from "../../../../../API/material-cloud/admin/AdminMaterialMaterialCloudService";
import SignJobTable from "../../../../../components/UI/table/prodaction/productioncosts/SignJobTable";
import JobMetricOrganizationTable
    from "../../../../../components/UI/table/prodaction/jobmetricorganization/JobMetricOrganizationTable";

const MaterialItem = () => {
        const params = useParams();

        const [data, setData] = useState(null);
        const [dataMaterial, setDataMaterial] = useState(null);

        const [supplierList, setSupplierList] = useState(null);
        const [groupMaterialList, setGroupMaterialList] = useState(null);
        const [manufacturerList, setManufacturerList] = useState(null);

        const [supplierMaterialCloudList, setSupplierMaterialCloudList] = useState(null);
        const [groupMaterialMaterialCloudList, setGroupMaterialMaterialCloudList] = useState(null);
        const [manufacturerMaterialCloudList, setManufacturerMaterialCloudList] = useState(null);

        const [supplierId, setSupplierId] = useState(null);
        const [manufacturerId, setManufacturerId] = useState(null);
        const [groupMaterialId, setGroupMaterialId] = useState(null);

        const id = params.id;
        const baseUnit = params.baseUnit;
        const typeMaterial = params.typeMaterial;
        const materialId = params.materialId;

        useEffect(() => {

            SupplierService.getAllByOrganization().then(function (response) {
                setSupplierList(response.data);

            })
                .catch(function (error) {
                    console.log(error);
                });

            /*  UserSupplierMaterialService.getAll().then(function (response) {
                  setGroupMaterialMaterialCloudList(response.data);
              })
                  .catch(function (error) {
                      console.log(error);
                  });
  */

            GroupMaterialService.getAllByOrganization().then(function (response) {
                setGroupMaterialList(response.data);

            })
                .catch(function (error) {
                    console.log(error);
                });

            UserGroupMaterialMaterialCloudService.getAll().then(function (response) {
                setSupplierMaterialCloudList(response.data);

            })
                .catch(function (error) {
                    console.log(error);
                });

            ManufacturerService.getAllByOrganization().then(function (response) {
                setManufacturerList(response.data);

            })
                .catch(function (error) {
                    console.log(error);
                });
            UserManufacturerMaterialCloudService.getAll().then(function (response) {
                setManufacturerMaterialCloudList(response.data);
            })
                .catch(function (error) {
                    console.log(error);
                });

            UserMaterialMaterialCloudService.getForMaterialOrganizationOption(materialId).then(function (response) {
                let data = response.data;
                setDataMaterial(data)
            })

            MaterialOrganizationOptionService.getWithGroupMaterialAndDefaultSupplierById(id).then(function (response) {
                let data = response.data;
                setDataJobMetricOrganization(data.jobMetricOrganizationByMaterials);
                setData(data)
            })
                .catch(function (error) {
                    console.log(error);
                });
        }, []);

        const [dataMaterialWholeData, setDataMaterialWholeData] = useState([]);
        const [dataMaterialMinStripeData, setDataMaterialMinStripeData] = useState([]);
        const [dataMaterialFixedStripeData, setDataMaterialFixedStripeData] = useState([]);

        useEffect(() => {

            UserSupplierMaterialService.getAllCurrentWholeByMaterialOrganizationOptionId(id)
                .then(function (response) {
                    setDataMaterialWholeData(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });
            UserSupplierMaterialService.getAllCurrentByMaterialOrganizationOptionIdMinStripe(id)
                .then(function (response) {
                    setDataMaterialMinStripeData(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });
            UserSupplierMaterialService.getAllCurrentByMaterialOrganizationOptionIdFixedStripe(id)
                .then(function (response) {
                    setDataMaterialFixedStripeData(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });
        }, []);

        const handleSubmitUpdateMainOptions = () => {


            const currentSupplierId = supplierId === null ? data.defaultSupplier.id : supplierId;
            const currentManufacturerId = manufacturerId == null ? data.manufacturer.id : manufacturerId;
            const currentGroupMaterialId = groupMaterialId == null ? data.groupMaterial.id : groupMaterialId;

            AdminMaterialMaterialCloudService.updateMainOptions(data, currentSupplierId, currentManufacturerId, currentGroupMaterialId,
            ).then(function (response) {
                let data = response.data;
                handleUpdate(data)
            })
                .catch(function (error) {
                    console.log(error);
                });
        }

        const handleSubmitUpdateCurrentOptions = () => {


            const currentSupplierId = supplierId === null ? data.defaultSupplier.id : supplierId;
            const currentManufacturerId = manufacturerId == null ? data.manufacturer.id : manufacturerId;
            const currentGroupMaterialId = groupMaterialId == null ? data.groupMaterial.id : groupMaterialId;

            MaterialOrganizationOptionService.updateCurrentOptions(data, currentSupplierId, currentManufacturerId, currentGroupMaterialId,
            ).then(function (response) {
                let data = response.data;
                handleUpdate(data)
            })
                .catch(function (error) {
                    console.log(error);
                });
        }

        const handleUpdate = (values) => {
            setData(values);
        };

        const handleChange = (event, newValue) => {
            setValue(newValue);
        };

        const [value, setValue] = useState('1');

        const [change, setChange] = useState(false);
        const [dataJobMetricOrganization, setDataJobMetricOrganization] = useState([]);
        const [rowSelection, setRowSelection] = useState({});
        const [rowIndexSelection, setRowIndexSelection] = useState([])
        const [selectedIds, setSelectedIds] = useState([]);

        return (
            <div>
                {<Box sx={{display: 'flex', flexDirection: 'column', height: '100%'}}>
                    <Box sx={{flexGrow: 1}}>
                        <TabContext value={value}>
                            <Box sx={{
                                borderBottom: 1, borderColor: 'divider'
                            }}>
                                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                    <Tab label="Основные" value='1'/>
                                    <Tab label="Поставщики" value='2'/>
                                    <Tab label="Склад" value='3'/>
                                    <Tab label="Перечень работ" value='4'/>
                                    <Tab label="Облако" value='0'/>
                                </Tabs>
                            </Box>
                            <TabPanel value='0'>
                                <CloudOptionsMaterialModule
                                    data={dataMaterial}
                                    setData={setDataMaterial}
                                    supplierList={supplierMaterialCloudList}
                                    groupMaterialList={groupMaterialMaterialCloudList}
                                    manufacturerList={manufacturerMaterialCloudList}
                                    handleSubmit={handleSubmitUpdateMainOptions}
                                    setSupplierId={setSupplierId}
                                    setManufacturerId={setManufacturerId}
                                    setGroupMaterialId={setGroupMaterialId}
                                    baseUnit={baseUnit}
                                    isMaterialOrganizationOption={true}
                                    changeOptionsDisabled={true}
                                />
                            </TabPanel>
                            <TabPanel value='1'>
                                <CurrentOptionsMaterialModule
                                    data={data}
                                    setData={setData}
                                    supplierList={supplierList}
                                    groupMaterialList={groupMaterialList}
                                    manufacturerList={manufacturerList}
                                    handleSubmit={handleSubmitUpdateCurrentOptions}
                                    setSupplierId={setSupplierId}
                                    setManufacturerId={setManufacturerId}
                                    setGroupMaterialId={setGroupMaterialId}
                                    baseUnit={baseUnit}
                                    isMaterialOrganizationOption={true}
                                    changeOptionsDisabled={true}
                                    isDataMOO={true}
                                />
                            </TabPanel>
                            <TabPanel value='2'>
                                <SupplierMaterialItemModule
                                    materialId={id}
                                    baseUnit={baseUnit}
                                    typeMaterial={typeMaterial}
                                    dataMaterialWholeData={dataMaterialWholeData}
                                    dataMaterialMinStripeData={dataMaterialMinStripeData}
                                    dataMaterialFixedStripeData={dataMaterialFixedStripeData}
                                    setDataMaterialWholeData={setDataMaterialWholeData}
                                    setDataMaterialMinStripeData={setDataMaterialMinStripeData}
                                    setDataMaterialFixedStripeData={setDataMaterialFixedStripeData}
                                    allPrice={true}
                                    create={UserSupplierMaterialService.createByMaterialOrganizationOption}
                                    update={UserSupplierMaterialService.update}
                                    urlArchiveSM={'/organization/supplier-material-archive-item'}
                                    urlArchiveMS={'organization/material-min-archive-item'}
                                    urlArchiveFS={'organization/material-fixed-archive-item'}
                                    addArchive={UserSupplierMaterialService.sendToArchive}
                                    isFixPrice={true}
                                />
                            </TabPanel>
                            <TabPanel value='3'>
                                <WarehouseMaterialItemTable
                                    id={id}
                                    baseUnit={baseUnit}
                                    typeMaterial={typeMaterial}
                                    material={data}
                                />
                            </TabPanel>
                            <TabPanel value='4'>
                                <JobMetricOrganizationTable
                                    data={dataJobMetricOrganization}
                                    setData={setDataJobMetricOrganization}
                                    change={change}
                                    setChange={setChange}
                                    rowSelection={rowSelection}
                                    setRowSelection={setRowSelection}
                                    rowIndexSelection={rowIndexSelection}
                                    setRowIndexSelection={setRowIndexSelection}
                                    selectedIds={selectedIds}
                                    setSelectedIds={setSelectedIds}
                                    isChooseSignJob={false}
                                />
                            </TabPanel>
                        </TabContext>
                    </Box>
                </Box>}
            </div>
        );
    };

export default MaterialItem;