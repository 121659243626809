import axios from "axios";
import ObjectUtil from "../../../../utils/common/ObjectUtil";
import {apiVersionOrders, gatewayUrl, nameOrders} from "../../../../Constants";

const requestsCuttingServiceRestUrl = gatewayUrl + nameOrders + apiVersionOrders + '/organizations/requests-cutting'

export default class RequestsCuttingService {

    static async getAllWithMaterialNotArchive() {
        return axios.get(requestsCuttingServiceRestUrl + '/not-archive', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async getAllWithMaterialNotArchiveTypeCalculation() {
        return axios.get(requestsCuttingServiceRestUrl + '/not-archive-calculation', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async getAllWithMaterialByOptimizationId(optimizationId) {
        return axios.get(requestsCuttingServiceRestUrl + '/by-optimization-id?optimizationId=' + optimizationId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async getAllWithMaterialByPlanId(planId) {
        return axios.get(requestsCuttingServiceRestUrl + '/by-plan-id?planId=' + planId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async delete(id) {
        await axios.delete(requestsCuttingServiceRestUrl + '/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async unableStatus(id, requestsCuttingState) {
        return axios.patch(requestsCuttingServiceRestUrl + '/' + id + '/unable-status?requestsCuttingState=' + requestsCuttingState, [], {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async setOptimizationByIds(ids, optimizationId) {
        let data = ObjectUtil.createRequestObjectIds(ids);
        await axios.patch(requestsCuttingServiceRestUrl + '/set-optimization-by-ids' + '?optimizationId=' + optimizationId, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async deleteOptimizationByIds(ids) {
        let data = ObjectUtil.createRequestObjectIds(ids);
        await axios.patch(requestsCuttingServiceRestUrl + '/delete-optimization-by-ids', JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async getAllByOrderIdWithMaterial(orderId) {
        return axios.get(requestsCuttingServiceRestUrl + '/by-order-id?orderId=' + orderId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async setForOptimizationByIds(ids) {
        let data = ObjectUtil.createRequestObjectIds(ids);
        await axios.patch(requestsCuttingServiceRestUrl + '/add-for-optimization-by-ids', JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async setForCalculationByIds(ids) {
        let data = ObjectUtil.createRequestObjectIds(ids);
        await axios.patch(requestsCuttingServiceRestUrl + '/add-for-calculation-by-ids', JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
}