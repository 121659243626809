import axios from "axios";
import {apiVersionOrders, gatewayUrl, nameOrders} from "../../../../Constants";

const planServiceRestUrl = gatewayUrl + nameOrders + apiVersionOrders + '/organizations/plans'

export default class PlanService {

    static async getAllNotArchive() {
        return axios.get(planServiceRestUrl + '/not-archive', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async delete(id) {
        await axios.delete(planServiceRestUrl + '/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async createPlan(data) {
        return await axios.post(planServiceRestUrl,
            JSON.stringify(data), {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
    }

    static async unableStatus(id, planState) {
        return axios.patch(planServiceRestUrl + '/' + id + '/unable-status?planState=' + planState, [], {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
}