import {calcDistance} from "../../../../../../utils/processing/calcDistance";

export const calcPositionCut = (prev, start, next, cutData, distance) => {
    const lengthPrev = calcDistance(prev, start)
    const lengthNext = calcDistance(start, next)

    const distancePrev = (lengthPrev - (distance || cutData.distancePrev)) / lengthPrev
    const distanceNext = (distance || cutData.distanceNext) / lengthNext

    const xPrev = prev.x + (start.x - prev.x) * distancePrev
    const yPrev = prev.y + (start.y - prev.y) * distancePrev
    const xNext = start.x + (next.x - start.x) * distanceNext
    const yNext = start.y + (next.y - start.y) * distanceNext

    const res = [{x: xPrev, y: yPrev, distance: cutData.distancePrev}, {
        x: xNext,
        y: yNext,
        distance: cutData.distanceNext
    }]
    return res
}
