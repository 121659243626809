import React, {useEffect, useMemo, useState} from 'react';
import MaterialReactTable from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import CoefficientExchangeRateService from "../../../../../API/orders/warehouse/CoefficientExchangeRateService";
import DateTimeUtil from "../../../../../utils/common/DateTimeUtil";
import DateRangeFilter from "../../../filter/DateRangeFilter";

const SupplierCoefficientExchangeRate = ({supplierId}) => {

    const [isFullScreen, setIsFullScreen] = useState(false);
    const [data, setData] = useState([]);

    useEffect(() => {

        CoefficientExchangeRateService.getAllBySupplierId(supplierId)
            .then(function (response) {
                setData(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }, []);

    const columns = useMemo(
        () => [
            {
                header: 'Производитель',
                accessorKey: 'manufacturer.name',
                minSize: 30,
            },
            {
                accessorFn: (row) => {
                    if (row.arrivalDate === null) {
                    } else {
                        return new Date(row.arrivalDate)
                    }
                },
                header: 'Дата прихода',
                accessorKey: 'arrivalDate',
                minSize: 30,
                maxSize: 30,
                Cell: ({cell}) => {
                    if (cell.getValue() !== undefined) {
                        return cell.getValue().toLocaleDateString();
                    }
                },
                filterFn: (row, id, filterValue) => {
                    const startDate = filterValue?.startDate;
                    const endDate = filterValue?.endDate;
                    if (startDate && endDate) {
                        return (
                            DateTimeUtil.checkDateBefore(row.getValue(id), startDate) &&
                            DateTimeUtil.checkDateAfter(row.getValue(id), endDate)
                        );
                    }
                    return true;
                },
                Filter: ({column}) => <DateRangeFilter
                    column={column}
                />,
            },
            {
                id: 'coefficient',
                header: 'Кф. курса базовой цены',
                accessorKey: 'coefficient',
                size: 8,
                minSize: 10,
                maxSize: 10,
            },
        ],
        [data],
    );


    return <MaterialReactTable
        muiTableBodyProps={{
            sx: () => ({
                '& tr:nth-of-type(odd)': {
                    backgroundColor: 'lightgray',
                },
            }),
        }}
        muiTableBodyCellProps={{
            sx: {
                p: '0px 0px 0px 10px',
                m: '0px 0px 0px 0px',
            },
        }}
        muiTableBodyRowProps={{
            sx: {
                height: '10px',
            },
        }}
        isFullScreen={isFullScreen}
        displayColumnDefOptions={{
            'mrt-row-actions': {
                muiTableHeadCellProps: {
                    align: 'center',
                },
                size: 120,
            },
        }}
        columns={columns}
        data={data}
        initialState={{
            columnVisibility: {
                id: false,
            },
            isFullScreen: false,
            density: "compact",
            pagination: {pageIndex: 0, pageSize: 10},
        }}
        localization={MRT_Localization_RU}
    />;
};

export default SupplierCoefficientExchangeRate;