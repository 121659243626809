import {calcPositionCut} from "../CanvasCalcPositions/calcPositionCut";
import Layer from "./Layer";
import {Text} from "@react-three/drei";
import LineElement from "./LineElement";
import {COLORS} from "../../../../../../Constants";

const Cut = ({scale, data, cutData}) => {
    const textDistance = 0.4/ scale
    const textPosition = calcPositionCut(data.prev, data.start, data.next, cutData, textDistance)
    const linePosition = calcPositionCut(data.prev, data.start, data.next, cutData)

    return (
        <>
            {cutData.distancePrev && cutData.distanceNext &&
                <LineElement
                    width={4} dashSize={0.08 / scale} gapSize={0.08 / scale}
                    points={[[linePosition[0].x, linePosition[0].y], [linePosition[1].x, linePosition[1].y]]}
                    color={COLORS[6][800]}/>
            }
            {textPosition.map((el, index) => {
                return el.distance &&
                    <Layer layer={2} key={index}>
                        <mesh position={[el.x, el.y, 0]}>
                            <Layer layer={3}>
                                <Text
                                    color={"black"}
                                    fontSize={0.15 / scale}
                                >
                                    {el.distance}
                                </Text>
                            </Layer>
                            <boxGeometry args={[el.distance.length * 0.09 / scale, 0.15 / scale, 0]}/>
                            <meshBasicMaterial opacity={0.5} transparent toneMapped={false} color={"white"}/>
                        </mesh>
                    </Layer>
            })}
        </>
    )
}

export default Cut
