import React, {useCallback, useEffect, useMemo, useState} from 'react';
import MaterialReactTable from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import Box from "@mui/material/Box";
import {Button, Tooltip} from "@mui/material";
import {Delete} from "@mui/icons-material";

import {Link, useNavigate} from "react-router-dom";
import RequestsCuttingService from "../../../../../API/orders/optimization/organization/RequestsCuttingService";
import ModalUpdateStatus from "../ModalUpdateStatus";
import OptimizationService from "../../../../../API/orders/optimization/organization/OptimizationService";
import {requestsCuttingStatuses} from "../../../../../data/RequestsCuttingState";
import DateRangeFilter from "../../../filter/DateRangeFilter";
import DateTimeUtil from "../../../../../utils/common/DateTimeUtil";
import FileService from "../../../../../API/orders/orders/organization/FileService";
import ModalAddDetailOrRequestsCuttingForFiles from "../../cutting/details/ModalAddDetailOrRequestsCuttingForFiles";
import ModalOpenCreatePlanOrOptimization from "../plans/ModalOpenCreatePlanOrOptimization";
import ModalSetOptimization from "./ModalSetOptimization";
import ModalConfirm from "../../../modal/ModalConfirm";

const RequestsCuttingTable = ({
                                  getAll,
                                  optimizationId,
                                  isOptimization,
                                  isRedirectRequestsCutting,
                                  orderId,
                                  requestsCuttingPlan,
                                  planId,
                                  isOrder,
                                  isButtonAddRequestsCutting,
                                  isPlan,
                                  isRedirectOrder,
                                  nameOrder,
                                  idHeader,
                                  year,
                                  isRedirectOrders,
                                  isRedirectPlans,
                                  isRedirectOptimizations,
                                  isCreateOptimization,
                                  isCreateOptimizationCalculation,
                                  isAddOptimization,
                                  isSetForOptimization,
                                  page,
                                  hideOrder,
                                  hideWorkingCuttingMap,
                                  customer,
                                  isRedirectCalculationOptimizations,
                                  isRedirectCalculationCalculationWorkingCuttingMaps,
                                  isRedirectCalculationRequestsCuttings
                              }) => {

    const [isFullScreen, setIsFullScreen] = useState(false);
    const [data, setData] = useState([]);
    const [change, setChange] = useState(false);

    const [selectedIds, setSelectedIds] = useState([]);

    const [updateModalOpen, setUpdateModalOpen] = useState(false);

    const [modalAddRequestsCuttingOpen, setModalAddRequestsCuttingOpen] = useState(false);
    const [calculationRequestsCutting, setCalculationRequestsCutting] = useState(false);

    const [currentEditRowData, setCurrentEditRowData] = useState(null);
    const [currentAllowedStatuses, setCurrentAllowedStatuses] = useState(['ARCHIVE']);

    const [currentEditRowId, setCurrentEditRowId] = useState(null);

    const [rowSelection, setRowSelection] = useState({});
    const [modalOpenCreateOptimization, setModalOpenCreateOptimization] = useState(false);
    const [newRowDataOptimization, setNewRowDataOptimization] = useState(null);
    const [setOptimizationModalOpen, setSetOptimizationModalOpen] = useState(false);
    const [isCalculationOptimization, setIsCalculationOptimization] = useState(null);
    const [showConfirmationModalDeleteOptimization, setShowConfirmationModalDeleteOptimization] = useState(false);
    const [showConfirmationModalSetForOptimization, setShowConfirmationModalSetForOptimization] = useState(false);
    const [showConfirmationModalSetForCalculation, setShowConfirmationModalSetForCalculation] = useState(false);

    useEffect(() => {
            const id = isOrder ? orderId : (requestsCuttingPlan ? planId : optimizationId);
            getAll(id).then(function (response) {
                let data = response.data;
                setData(data);
            })
                .catch(function (error) {
                    console.log(error);
                });
        }, [change]
    );

    const handleDeleteRow = useCallback(
        (row) => {
            const id = row.original.id;
            RequestsCuttingService.delete(id)
                .then(function () {
                    data.splice(row.index, 1);
                    setData([...data]);
                })
                .catch(function (error) {
                    console.log(error);
                })
        },
        [data],
    );

    const handleUpdateModel = useCallback(
        (row) => {
            setCurrentAllowedStatuses([...currentAllowedStatuses, row.original.requestsCuttingState]);
            setCurrentEditRowData(row.original);
            setCurrentEditRowId(row.index);
            setUpdateModalOpen(true);
        },
        [data, currentEditRowData, setCurrentEditRowData],
    );

    const handleClose = () => {
        currentAllowedStatuses.splice(1, 1);
        setUpdateModalOpen(false)
    };

    const navigate = useNavigate();

    const createOptimizationByIds = () => {
        const requestsCuttingState = isCalculationOptimization ? 'FORMED_CALCULATION_OPTIMIZATION' : 'FORMED_OPTIMIZATION';
        OptimizationService.createOptimizationByIds(selectedIds, newRowDataOptimization)
            .then(function (response) {
                const newData = data.map(item => {
                    if (selectedIds.includes(item.id)) {
                        return {
                            ...item,
                            optimization: response.data,
                            requestsCuttingState: requestsCuttingState
                        };
                    } else {
                        return item;
                    }
                });
                setData(newData);
                handleCloseModalOpenCreateOptimization();
                setChange(!change)
            })
            .catch(function (error) {
                console.log(error);
            })
    };

    const setForOptimizationByIds = () => {
        RequestsCuttingService.setForOptimizationByIds(selectedIds)
            .then(function () {
                let newData;
                if (page === 'RequestsCuttingCalculation') {
                    newData = data.filter(item => !selectedIds.includes(item.id));
                } else {
                    newData = data.map(item => {
                        if (selectedIds.includes(item.id)) {
                            return {
                                ...item,
                                requestsCuttingState: 'EXPECTATION'
                            };
                        } else {
                            return item;
                        }
                    });
                }
                setData(newData);
                handleCloseSetForOptimization();
            })
            .catch(function (error) {
                console.log(error);
            })
    };

    const setForCalculationByIds = () => {
        RequestsCuttingService.setForCalculationByIds(selectedIds)
            .then(function () {
                let newData;
                if (page === 'RequestsCuttingsAll') {
                    newData = data.filter(item => !selectedIds.includes(item.id));
                } else {
                    newData = data.map(item => {
                        if (selectedIds.includes(item.id)) {
                            return {
                                ...item,
                                requestsCuttingState: 'CALCULATION'
                            };
                        } else {
                            return item;
                        }
                    });
                }
                setData(newData);
                handleCloseSetForCalculation();
            })
            .catch(function (error) {
                console.log(error);
            })
    };

    const deleteOptimizationByIds = () => {
        const requestsCuttingState = isCalculationOptimization ? 'CALCULATION' : 'EXPECTATION';
        RequestsCuttingService.deleteOptimizationByIds(selectedIds)
            .then(function () {
                const newData = data.map(item => {
                    if (selectedIds.includes(item.id)) {
                        return {
                            ...item,
                            optimization: null,
                            requestsCuttingState: requestsCuttingState
                        };
                    } else {
                        return item;
                    }
                });
                setData(newData);
                handleCloseDeleteOptimization();
            })
            .catch(function (error) {
                console.log(error);
            })
    };

    const handleCloseModalOpenCreateOptimization = () => {
        setModalOpenCreateOptimization(false);
        setSelectedIds([]);
    };

    const handleCloseDeleteOptimization = () => {
        setShowConfirmationModalDeleteOptimization(false);
        setSelectedIds([]);
    };

    const handleCloseSetForOptimization = () => {
        setShowConfirmationModalSetForOptimization(false);
        setSelectedIds([]);
    };

    const handleCloseSetForCalculation = () => {
        setShowConfirmationModalSetForCalculation(false);
        setSelectedIds([]);
    };

    const redirectRequestsCutting = () => {
        navigate('/organization/requests-cutting');
    };

    const redirectOrders = () => {
        navigate('/organization/orders');
    };

    const redirectPlans = () => {
        navigate('/organization/plans');
    };

    const redirectOptimizations = () => {
        navigate('/organization/optimizations');
    };

    const redirectOrder = () => {
        navigate('/organization/orders/' + orderId + '/' + nameOrder + '/' + idHeader + '/' + year);
    };

    const redirectCalculationWorkingCuttingMaps = () => {
        navigate('/organization/working-cutting-maps-calculation');
    };

    const redirectCalculationOptimizations = () => {
        navigate('/organization/optimizations-calculation');
    };


    const redirectCalculationRequestsCutting = () =>  {
        navigate('/organization/requests-cutting/Calculation');
    }

    const addAndCalculateRequestsCutting = () => {
        setCalculationRequestsCutting(true);
        setModalAddRequestsCuttingOpen(true)
    };

    const columns = useMemo(() => {
        const baseColumns = [
            {
                id: 'number',
                header: 'Номер',
                accessorKey: 'number',
                minSize: 8,
                maxSize: 8,
                Cell: ({cell, row}) => (
                    <li key={cell.getValue()}>
                        {customer ? <Link
                            to={'/customer/orders/' + orderId + '/requests-cutting/' + row.original.id + '/details'}>
                            {cell.getValue() === null ? '' : cell.getValue()}
                        </Link> : <Link
                            to={'/organization/requests-cutting/detail/' + row.original.id + '/' + row.original.number}>
                            {cell.getValue() === null ? '' : cell.getValue()}
                        </Link>}
                    </li>
                ),
            },
            {
                id: 'nominalQuantity',
                header: 'Ном. кв.м.',
                accessorKey: 'nominalQuantity',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell}) => cell.getValue() === null ? null : cell.getValue().toFixed(3),
            },
            {
                accessorFn: (row) => {
                    if (row.dateOfReceipt === null) {
                    } else {
                        return new Date(row.dateOfReceipt)
                    }
                },
                id: 'date',
                header: 'Дата поспупления',
                sortingFn: 'date',
                size: 10,
                Cell: ({cell}) => {
                    if (cell.getValue() !== undefined) {
                        return cell.getValue().toLocaleDateString();
                    }
                },
                filterFn: (row, id, filterValue) => {
                    const startDate = filterValue?.startDate;
                    const endDate = filterValue?.endDate;
                    if (startDate && endDate) {
                        return (
                            DateTimeUtil.checkDateBefore(row.getValue(id), startDate) &&
                            DateTimeUtil.checkDateAfter(row.getValue(id), endDate)
                        );
                    }
                    return true;
                },
                Filter: ({column}) => <DateRangeFilter
                    column={column}
                />,
            },
            {
                id: 'requestsCuttingState',
                header: 'Статус',
                accessorKey: 'requestsCuttingState',
                minSize: 55,
                maxSize: 55,
                Cell: ({cell}) => requestsCuttingStatuses[cell.getValue()],
            },
            {
                header: 'id',
                accessorKey: 'id',
                enableColumnActions: false,
                enableGrouping: false,
                enableEditing: false,
                size: 2,
                maxSize: 2,
            },
        ];

        if (customer) {
            baseColumns.splice(1, 0, {
                id: 'materialCustomer',
                header: 'материал',
                accessorKey: 'materialName',
                minSize: 150,
                maxSize: 150,
            });
        }

        if (!customer) {
            baseColumns.splice(1, 0, {
                id: 'material',
                header: 'материал',
                accessorKey: 'materialOrganizationOption.name',
                minSize: 150,
                maxSize: 300,
            });
        }

        if (!hideOrder) {
            baseColumns.splice(3, 0, {
                id: 'orderNumber',
                header: 'Номер заказа',
                accessorKey: 'orderNumber',
                minSize: 8,
                maxSize: 8,
            });
        }

        if (!hideWorkingCuttingMap) {
            baseColumns.splice(2, 0, {
                id: 'workingCuttingMap.number',
                header: 'К. раскроя',
                accessorKey: 'workingCuttingMap.number',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => (
                    cell.getValue() ? (
                        <li key={cell.getValue()}>
                            <Link
                                to={'/organization/working-cutting-map/' + row.original.workingCuttingMap.id + '/' + row.original.materialOrganizationOption.name}>
                                {cell.getValue()}
                            </Link>
                        </li>
                    ) : ''
                ),
            });
        }

        if (isOptimization) {
            baseColumns.splice(3, 0, {
                id: 'optimization',
                header: 'Оптимизация',
                accessorKey: 'optimization.number',
                minSize: 150,
                maxSize: 150,
                Cell: ({cell, row}) => (
                    cell.getValue() ? (
                        <li key={cell.getValue()}>
                            <Link
                                to={'/organization/requests-cutting/optimization/' + row.original.optimization.id + '/' + row.original.optimization.number}>
                                {cell.getValue()}
                            </Link>
                        </li>
                    ) : ''
                ),
            });
        }

        if (isPlan) {
            baseColumns.splice(4, 0, {
                id: 'plan',
                header: 'План',
                accessorKey: 'plan.name',
                minSize: 8,
                maxSize: 8,
                Cell: ({cell, row}) => (
                    cell.getValue() ? (
                        <li key={cell.getValue()}>
                            <Link to={'/organization/requests-cutting/plan/' + row.original.plan.id + '/' + row.original.plan.name}>
                                {cell.getValue()}
                            </Link>
                        </li>
                    ) : ''
                ),
            });
        }

        return baseColumns;
    }, [data, setData, isOptimization]);

    return (
        <div>
            <MaterialReactTable
                muiTableBodyProps={{
                    sx: () => ({
                        '& tr:nth-of-type(odd)': {
                            backgroundColor: 'lightgray',
                        },
                    }),
                }}
                muiTableBodyCellProps={{
                    sx: {
                        p: '0px 0px 0px 10px',
                        m: '0px 0px 0px 0px',
                    },
                }}
                muiTableBodyRowProps={{
                    sx: {
                        height: '10px',
                    },
                }}
                isFullScreen={isFullScreen}
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        size: 15,
                        minSize: 15,
                    },
                }}
                columns={columns}
                data={data}
                rowHeight={13}
                columnSpacing={1}
                initialState={{
                    columnVisibility: {
                        id: false,
                    },
                    isFullScreen: false,
                    density: "compact",
                    pagination: {pageIndex: 0, pageSize: 50},
                }}
                enableEditing
                onRowSelectionChange={setRowSelection}
                state={{rowSelection}}
                enableRowSelection
                getRowId={(originalRow) => originalRow.id}
                selectionPadding={1}
                selectionCss={{
                    backgroundColor: 'lightblue',
                    borderWidth: '2px',
                    borderStyle: 'solid',
                    borderColor: 'blue',
                    borderRadius: '4px',
                }}
                renderRowActions={({row, table}) => (
                    <Box sx={{
                        display: 'flex',
                        gap: '0.1rem',
                        justifyContent: 'flex-start',
                        maxWidth: '50px'
                    }}>
                        {row.original.requestsCuttingState === 'CALCULATION' ?
                            <Tooltip arrow placement="right" title="Удалить">
                                <Button
                                    size="small"
                                    sx={{minWidth: 'auto', marginLeft: 'auto'}} color="error"
                                    onClick={() => handleDeleteRow(row)}>
                                    <Delete sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                                </Button>
                            </Tooltip> :
                            <Button
                                size="small"
                                disabled={true}
                                sx={{minWidth: 'auto', marginLeft: 'auto'}} color="error"
                                onClick={() => handleDeleteRow(row)}>
                                <Delete sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                            </Button>
                        }
                    </Box>
                )}
                localization={MRT_Localization_RU}
                renderTopToolbarCustomActions={({table}) => {
                    const setOptimization = () => {
                        setSetOptimizationModalOpen(true)
                        const selectedRows = table.getSelectedRowModel().flatRows;
                        const selectedIds = selectedRows.map((row) => row.getValue('id'));
                        setSelectedIds(selectedIds);
                    };

                    const setForOptimization = () => {
                        const selectedRows = table.getSelectedRowModel().flatRows;
                        const selectedIds = selectedRows.map((row) => row.getValue('id'));
                        setSelectedIds(selectedIds);
                        setShowConfirmationModalSetForOptimization(true)
                    };

                    const setForCalculation = () => {
                        const selectedRows = table.getSelectedRowModel().flatRows;
                        const selectedIds = selectedRows.map((row) => row.getValue('id'));
                        setSelectedIds(selectedIds);
                        setShowConfirmationModalSetForCalculation(true)
                    };

                    const deleteOptimization = () => {
                        setShowConfirmationModalDeleteOptimization(true)
                        const selectedRows = table.getSelectedRowModel().flatRows;
                        const selectedIds = selectedRows.map((row) => row.getValue('id'));
                        setSelectedIds(selectedIds);
                    };

                    const createOptimization = (optimizationState) => {
                        const number = optimizationState === 'CALCULATION' ? "СOP" : "OP";
                        const optimization = {
                            id: null,
                            number: number,
                            optimizationState: optimizationState,
                            optimized: null,
                        }
                        setNewRowDataOptimization(optimization);
                        setIsCalculationOptimization(optimizationState === 'CALCULATION');
                        setModalOpenCreateOptimization(true)
                        const selectedRows = table.getSelectedRowModel().flatRows;
                        const selectedIds = selectedRows.map((row) => row.getValue('id'));
                        setSelectedIds(selectedIds);
                    };

                    return (
                        <div>
                            <Box
                                sx={{
                                    display: 'flex',
                                    gap: '0.5rem',
                                    paddingLeft: '0.5rem',
                                    paddingTop: '0.5rem',
                                    paddingBottom: '0.2rem',
                                    flexWrap: 'wrap',
                                }}
                            >
                                {isRedirectOrders && <Button
                                    size="small"
                                    color="secondary"
                                    onClick={redirectOrders}
                                    variant="contained"
                                >
                                    Заказы
                                </Button>}
                                {isRedirectPlans && <Button
                                    size="small"
                                    color="secondary"
                                    onClick={redirectPlans}
                                    variant="contained"
                                >
                                    Планы
                                </Button>}
                                {isRedirectRequestsCutting && <Button
                                    size="small"
                                    color="secondary"
                                    onClick={redirectRequestsCutting}
                                    variant="contained"
                                >
                                    Заявки на раскрой
                                </Button>}
                                {isRedirectOptimizations && <Button
                                    size="small"
                                    color="secondary"
                                    onClick={redirectOptimizations}
                                    variant="contained"
                                >
                                    Оптимизации
                                </Button>}
                                {isRedirectCalculationRequestsCuttings && <Button
                                    size="small"
                                    color="secondary"
                                    onClick={redirectCalculationRequestsCutting}
                                    variant="contained"
                                >
                                    Рас. заявки на раскрой
                                </Button>}
                                {isRedirectCalculationOptimizations && <Button
                                    size="small"
                                    color="secondary"
                                    onClick={redirectCalculationOptimizations}
                                    variant="contained"
                                >
                                    Рас. оптимизации
                                </Button>}
                                {isRedirectCalculationCalculationWorkingCuttingMaps && <Button
                                    size="small"
                                    color="secondary"
                                    onClick={redirectCalculationWorkingCuttingMaps}
                                    variant="contained"
                                >
                                    Рас. карты раскроя
                                </Button>}
                            </Box>
                            <Box
                                sx={{display: 'flex', gap: '0.5rem', p: '0.5rem', flexWrap: 'wrap'}}
                            >
                                {isCreateOptimization && <Button
                                    size="small"
                                    color="success"
                                    disabled={table.getIsSomeRowsSelected() ? false : !table.getIsAllRowsSelected()}
                                    onClick={() => createOptimization('EXPECTATION')}
                                    variant="contained"
                                >
                                    Создать оптимизацию
                                </Button>}
                                {isCreateOptimizationCalculation && <Button
                                    size="small"
                                    color="success"
                                    disabled={table.getIsSomeRowsSelected() ? false : !table.getIsAllRowsSelected()}
                                    onClick={() => createOptimization('CALCULATION')}
                                    variant="contained"
                                >
                                    Создать расчетную оптимизацию
                                </Button>}
                                {isAddOptimization && <Button
                                    size="small"
                                    color="success"
                                    disabled={table.getIsSomeRowsSelected() ? false : !table.getIsAllRowsSelected()}
                                    onClick={setOptimization}
                                    variant="contained"
                                >
                                    Добавить в оптимизацию
                                </Button>}
                                {isAddOptimization && <Button
                                    size="small"
                                    color="success"
                                    disabled={table.getIsSomeRowsSelected() ? false : !table.getIsAllRowsSelected()}
                                    onClick={deleteOptimization}
                                    variant="contained"
                                >
                                    Удалить оптимизацию
                                </Button>}
                                {isSetForOptimization && <Button
                                    size="small"
                                    color="success"
                                    disabled={table.getIsSomeRowsSelected() ? false : !table.getIsAllRowsSelected()}
                                    onClick={setForOptimization}
                                    variant="contained"
                                >
                                    Отправить на оптимизацию
                                </Button>}
                                {isAddOptimization && <Button
                                    size="small"
                                    color="success"
                                    disabled={table.getIsSomeRowsSelected() ? false : !table.getIsAllRowsSelected()}
                                    onClick={setForCalculation}
                                    variant="contained"
                                >
                                    Вернуть с оптимизации
                                </Button>}
                            </Box>
                        </div>
                    );
                }}
            />
            <ModalUpdateStatus
                title={'Обновить статус'}
                data={data}
                setData={setData}
                open={updateModalOpen}
                handleClose={handleClose}
                currentEditRowId={currentEditRowId}
                values={currentEditRowData}
                setValues={setCurrentEditRowData}
                currentAllowedStatuses={currentAllowedStatuses}
                statusesList={requestsCuttingStatuses}
                field={'requestsCuttingState'}
                unableStatus={RequestsCuttingService.unableStatus}
            />
            <ModalOpenCreatePlanOrOptimization
                label={'Название оптимизации'}
                field={'number'}
                values={newRowDataOptimization}
                setValues={setNewRowDataOptimization}
                title={'Создать оптимизацию'}
                modalOpen={modalOpenCreateOptimization}
                handleClose={() => setModalOpenCreateOptimization(false)}
                handleSubmit={createOptimizationByIds}
                change={change}
                setChange={setChange}
                isCalculationOptimization={isCalculationOptimization}
            />
            <ModalAddDetailOrRequestsCuttingForFiles
                title={'Добавить заявки на раскрой из файлов'}
                labelForm={'Загрузить заявки на раскрой'}
                modalOpen={modalAddRequestsCuttingOpen}
                handleClose={() => setModalAddRequestsCuttingOpen(false)}
                requestsCuttingId={null}
                change={change}
                setChange={setChange}
                upload={FileService.uploadRequestsCuttingFiles}
                orderId={orderId}
                requestsCutting={false}
                calculationRequestsCutting={calculationRequestsCutting}
            />
            <ModalSetOptimization
                open={setOptimizationModalOpen}
                handleClose={() => setSetOptimizationModalOpen(false)}
                selectedIds={selectedIds}
                data={data}
                setData={setData}
                change={change}
            />
            <ModalConfirm
                title={"Вы действительно хотите удалить оптимизацию?"}
                modalOpen={showConfirmationModalDeleteOptimization}
                handleClose={handleCloseDeleteOptimization}
                handleSubmit={deleteOptimizationByIds}
                action={'Удалить'}
            />
            <ModalConfirm
                title={"Вы действительно хотите отправить заявки оптимизацию?"}
                modalOpen={showConfirmationModalSetForOptimization}
                handleClose={handleCloseSetForOptimization}
                handleSubmit={setForOptimizationByIds}
                action={'Отправить'}
            />
            <ModalConfirm
                title={"Вы действительно хотите вернтуть заявки с оптимизации?"}
                modalOpen={showConfirmationModalSetForCalculation}
                handleClose={handleCloseSetForCalculation}
                handleSubmit={setForCalculationByIds}
                action={'Вернуть'}
            />
        </div>
    );
};

export default RequestsCuttingTable;
