import React, {useState} from 'react';
import {Box, Checkbox, Stack, Tooltip} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import CuttingImageGallery from "../../../pages/organization/cutting/CuttingImageGallery";
import ModalImageGallery from "../../../pages/organization/cutting/ModalImageGallery";
import CuttingTextField from "../textfield/CuttingTextField";

const CuttingModule = ({
                           images,
                           currentPage,
                           setCurrentPage,
                           isLoading,
                           fitToStripFirst,
                           setFitToStripFirst,
                           fitToStripSecond,
                           setFitToStripSecond,
                           dimensions,
                           setDimensions,
                           combiningDrops,
                           setCombiningDrops,
                           cutDropDetail,
                           setCutDropDetail,
                           cutFitToStripFirstDropDetail,
                           setCutFitToStripFirstDropDetail,
                           cutFitToStripSecondDropDetail,
                           setCutFitToStripSecondDropDetail,
                           cutCombiningDrops,
                           setCutCombiningDrops,
                           takeAccountDimensions,
                           setTakeAccountDimensions,
                           cutWastes,
                           setCutWastes,
                           cutCutOffs,
                           setCutCutOffs,
                           cutOnlyCutOffs,
                           setCutOnlyCutOffs,
                           cutOnlyWarehouse,
                           setCutOnlyWarehouse,
                       }) => {

    const [openModalImageGallery, setOpenModalImageGallery] = useState(false);

    const handleCheckbox = (checkbox) => {
        switch (checkbox) {
            case 'fitToStripFirst':
                setFitToStripFirst(!fitToStripFirst);
                break;
            case 'fitToStripSecond':
                setFitToStripSecond(!fitToStripSecond);
                break;
            case 'combiningDrops':
                setCombiningDrops(!combiningDrops);
                break;
            case 'cutDropDetail':
                setCutDropDetail(!cutDropDetail);
                break;
            case 'cutFitToStripFirstDropDetail':
                setCutFitToStripFirstDropDetail(!cutFitToStripFirstDropDetail);
                break;
            case 'cutFitToStripSecondDropDetail':
                setCutFitToStripSecondDropDetail(!cutFitToStripSecondDropDetail);
                break;
            case 'cutCombiningDrops':
                setCutCombiningDrops(!cutCombiningDrops);
                break;
            case 'takeAccountDimensions':
                setTakeAccountDimensions(!takeAccountDimensions);
                break;
            case 'cutWastes':
                setCutWastes(!cutWastes);
                break;
            case 'cutCutOffs':
                setCutCutOffs(!cutCutOffs);
                break;
            case 'сutOnlyCutOffs':
                setCutOnlyCutOffs(!cutOnlyCutOffs);
                break;
            case 'cutOnlyWarehouse':
                setCutOnlyWarehouse(!cutOnlyWarehouse);
                break;
            default:
                break;
        }
    }

    return (

        <Box sx={{
            display: 'flex',
            alignItems: 'flex-start',
            marginTop: '10px'
        }}>
            <Box sx={{
                position: 'relative',
                display: 'flex',
                border: '2px solid #3B8BEB',
                height: '682px',
                width: '900px',
            }}>
                <CuttingImageGallery
                    images={images}
                    isLoading={isLoading}
                    setOpenModalImageGallery={setOpenModalImageGallery}
                    weight='97%'
                    height='95%'
                    buttonClose={false}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                />
                <ModalImageGallery
                    weight='64%'
                    height='91%'
                    images={images}
                    open={openModalImageGallery}
                    onClose={() => setOpenModalImageGallery(false)}
                    setOpenModalImageGallery={setOpenModalImageGallery}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                />
            </Box>
            <Box sx={{
                marginLeft: '20px',
                paddingRight: '3px',
            }}>
                <Stack direction="column" spacing={0.1}>
                    <Box sx={{width: '400px', marginRight: '20px'}}>
                        <Box sx={{width: '400px', marginRight: '20px'}}>
                            <Tooltip
                                title={'Включает опцию при которой не объединяются обрезки и отходы меньше минимального обрезка, а также обрезки и отходы менее заданного размера не участвуют в раскрое обрезков и отходов с резерва'}>
                                <FormControlLabel
                                    control={<Checkbox
                                        disabled={!combiningDrops}
                                        onChange={() => handleCheckbox('takeAccountDimensions')}
                                        checked={takeAccountDimensions}
                                    />}
                                    label="Учет минимального обрезка"
                                />
                            </Tooltip>
                        </Box>
                        <Box sx={{width: '400px', marginRight: '20px'}}>
                            <Tooltip title={'Разрешает кроить первичные обрезки на карте со склада резервов'}>
                                <FormControlLabel
                                    control={<Checkbox
                                        onChange={() => handleCheckbox('cutCutOffs')}
                                        checked={cutCutOffs}
                                    />}
                                    label="Кроить первичные обрезки"
                                />
                            </Tooltip>
                        </Box>
                        <Box sx={{width: '400px', marginRight: '20px'}}>
                            <Tooltip title={'Разрешает кроить отходы на карте со склада резервов'}>
                                <FormControlLabel
                                    control={<Checkbox
                                        onChange={() => handleCheckbox('cutWastes')}
                                        checked={cutWastes}
                                    />}
                                    label="Кроить отходы на карте"
                                />
                            </Tooltip>
                        </Box>
                        <Tooltip title={'Подгоняет под полосу на 1 листе'}>
                            <FormControlLabel
                                control={<Checkbox
                                    onChange={() => handleCheckbox('fitToStripFirst')}
                                    checked={fitToStripFirst}
                                />}
                                label="Подгонка полосы на 1 листе"
                            />
                        </Tooltip>
                    </Box>
                    <Box sx={{width: '400px', marginRight: '20px'}}>
                        <Tooltip title={'Подгоняет под полосу на всех листах начиная со 2-го'}>
                            <FormControlLabel
                                control={<Checkbox
                                    disabled={!fitToStripFirst}
                                    onChange={() => handleCheckbox('fitToStripSecond')}
                                    checked={fitToStripSecond}
                                />}
                                label="Подгонка полосы со 2 листа"
                            />
                        </Tooltip>
                    </Box>
                    <CuttingTextField
                        label="Направление"
                        value={dimensions}
                        setValue={setDimensions}
                        field={'dimension'}
                        disabled={!fitToStripFirst}
                    />
                    <Box sx={{width: '400px', marginRight: '20px'}}>
                        <Tooltip title={'Включает опцию при которой объединяются обрезки и отходы'}>
                            <FormControlLabel
                                control={<Checkbox
                                    onChange={() => handleCheckbox('combiningDrops')}
                                    checked={combiningDrops}
                                />}
                                label="Объединение обрезков и отходов"
                            />
                        </Tooltip>
                    </Box>


                    <Box sx={{width: '400px', marginRight: '20px'}}>
                        <Tooltip title={'Разрешить кроить вторичные обрезки деталями со склада резервов'}>
                            <FormControlLabel
                                control={<Checkbox
                                    onChange={() => handleCheckbox('cutDropDetail')}
                                    checked={cutDropDetail}
                                />}
                                label="Кроить вторичные обрезки"
                            />
                        </Tooltip>
                    </Box>
                    <Box sx={{width: '400px', marginRight: '20px'}}>
                        <Tooltip title={'Разрешает кроить вториный обрезок на 1 листе со склада резервов'}>
                            <FormControlLabel
                                control={<Checkbox
                                    disabled={!cutDropDetail ? true : !fitToStripFirst}
                                    onChange={() => handleCheckbox('cutFitToStripFirstDropDetail')}
                                    checked={cutFitToStripFirstDropDetail}
                                />}
                                label="Кроить вториный обрезок на 1 листе"
                            />
                        </Tooltip>
                    </Box>
                    <Box sx={{width: '400px', marginRight: '20px'}}>
                        <Tooltip title={'Разрешает кроить вториные обрезоки на со 2-го листа со склада резервов'}>
                            <FormControlLabel
                                control={<Checkbox
                                    disabled={!cutDropDetail ? true : !fitToStripFirst ? true : !fitToStripSecond}
                                    onChange={() => handleCheckbox('cutFitToStripSecondDropDetail')}
                                    checked={cutFitToStripSecondDropDetail}
                                />}
                                label="Кроить вториные обрезоки со 2-го листа"
                            />
                        </Tooltip>
                    </Box>
                    <Box sx={{width: '400px', marginRight: '20px'}}>
                        <Tooltip
                            title={'Разрешает кроить вторичные обрезки комбинированных обрезков со склада резервов'}>
                            <FormControlLabel
                                control={<Checkbox
                                    disabled={!cutDropDetail ? true : !combiningDrops}
                                    onChange={() => handleCheckbox('cutCombiningDrops')}
                                    checked={cutCombiningDrops}
                                />}
                                label="Кроить вторичные обрезки комбинированных обрезков"
                            />
                        </Tooltip>
                    </Box>

                    <Box sx={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        '& > *': {
                            marginRight: '10px',
                        }
                    }}>
                        <Tooltip title={'Кроить только склад'}>
                            <FormControlLabel
                                control={<Checkbox
                                    onChange={() => handleCheckbox('cutOnlyWarehouse')}
                                    checked={cutOnlyWarehouse}
                                />}
                                label="Кроить только склад"
                            />
                        </Tooltip>
                        <Tooltip title={'Кроить только обрезки'}>
                            <FormControlLabel
                                control={<Checkbox
                                    onChange={() => handleCheckbox('сutOnlyCutOffs')}
                                    checked={cutOnlyCutOffs}
                                />}
                                label="Кроить только обрезки"
                            />
                        </Tooltip>
                    </Box>
                </Stack>
            </Box>
        </Box>
    );
};

export default CuttingModule;