import React from 'react';
import {useParams} from "react-router-dom";
import SupplierCoefficientExchangeRate from "../../../../components/UI/table/warehouse/supplier/SupplierCoefficientExchangeRate";

const SupplierItem = () => {

    const params = useParams();

    let supplierId = params.supplierId;

    return (
        <div>
            <SupplierCoefficientExchangeRate
                supplierId={supplierId}
            />
        </div>
    );
};

export default SupplierItem;