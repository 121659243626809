import React, {useEffect, useState} from 'react';
import OrderUtil from "../../../../../../utils/orders/OrderUtil";
import OrganizationCustomerService from "../../../../../../API/orders/orders/organization/OrganizationCustomerService";
import HeaderService from "../../../../../../API/orders/orders/organization/HeaderService";
import AttributesService from "../../../../../../API/orders/orders/organization/AttributesService";
import AttributeUtil from "../../../../../../utils/orders/AttributeUtil";
import StatusService from "../../../../../../API/orders/orders/organization/StatusService";
import OrderService from "../../../../../../API/orders/orders/organization/OrderService";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack} from "@mui/material";
import CreateOrderTextFieldObject from "./CreateOrderTextFieldObject";
import TextFieldWithSetValue from "../../../../textfield/TextFieldWithSetValue";
import OrderDatePicker from "../../../../datepicker/OrderDatePicker";
import MaterialTextFieldObjectWithEntityList from "../../../../modul/mainoptions/MaterialTextFieldObjectWithEntityList";
import {orderStatus} from "../../../../../../data/OrderStatus";
import CreateOrderTextFieldCustomer from "./CreateOrderTextFieldCustomer";

const CreateNewOrderModal = ({open, columns, onClose, onSubmit, defaultAttributeId, setDefaultAttributeId}) => {

    const [values, setValues] = useState(() =>
        columns.reduce((acc, column) => {
            if (OrderUtil.checkField(column)) {
                acc[column.id ?? ''] = '';
            }
            return acc;
        }, {}),
    );

    const [customerList, setCustomerList] = useState([]);
    const [modelHeaderList, setModelHeaderList] = useState([]);
    const [attributeList, setAttributeList] = useState([]);

    const [customerId, setCustomerId] = useState(null);
    const [customerName, setCustomerName] = useState(null);

    const [modelHeaderId, setModelHeaderId] = useState(null);
    const [attributeId, setAttributeId] = useState(null);

    const [defaultCustomerId, setDefaultCustomerId] = useState(null);
    const [defaultCustomerName, setDefaultCustomerName] = useState(null);

    const [defaultModelHeaderId, setDefaultModelHeaderId] = useState(null);

    const [dateOfReceipt, setDateOfReceipt] = useState(new Date());
    const [dateOfPlannedProduction, setDateOfPlannedProduction] = useState(new Date());

    useEffect(() => {

        OrganizationCustomerService.getAllUnable().then(function (response) {
            let data = response.data;
            setCustomerList(data);
            setDefaultCustomerId(data[0].id);
            setDefaultCustomerName(data[0].name)
        })
            .catch(function (error) {
                console.log(error);
            });
        HeaderService.getAllUnable().then(function (response) {
            const data = response.data;
            setModelHeaderList(data);
            setDefaultModelHeaderId(data[0].id)
        })
            .catch(function (error) {
                console.log(error);
            });

        AttributesService.getAll().then(function (response) {
            const data = response.data;
            setAttributeList(data);
            let id = AttributeUtil.getDefaultAttribute(data).id;
            console.log(id)
            setDefaultAttributeId(id);
        })
            .catch(function (error) {
                console.log(error);
            });
    }, []);

    const handleSubmit = () => {

        values.dateOfPlannedProduction = dateOfPlannedProduction;
        values.dateOfReceipt = dateOfReceipt;
        values.plan = null;

        if (values.number === '') {
            values.number = null;
        }

        OrderService.save(values, customerId === null ? defaultCustomerId : customerId,
            customerName === null ? defaultCustomerName : customerName,
            modelHeaderId === null ? defaultModelHeaderId : modelHeaderId,
            attributeId === null ? defaultAttributeId : attributeId
        ).then(function (response) {
            onSubmit(response.data);
            onClose();
        })
            .catch(function (error) {
                console.log(error);
            });
        setNull();
    };

    const setNull = () => {
        setCustomerId(null)
        setCustomerName(null)
        setModelHeaderId(null)
        setAttributeId(null)
        values.number = null;
        values.numberCustomer = null;
        values.dateOfPlannedProduction = null;
        values.dateOfReceipt = null;
    }

    const exit = () => {
        setNull()
        onClose()
    }

    return (
        <Dialog open={open}>
            <DialogTitle textAlign="center">Создать заказ</DialogTitle>
            <DialogContent>
                <form onSubmit={(e) => e.preventDefault()}>
                    <Stack spacing={2}>
                    </Stack>
                    <Stack
                        sx={{
                            width: '100%',
                            minWidth: {xs: '300px', sm: '360px', md: '400px'},
                            gap: '1.5rem',
                        }}
                    >
                        <CreateOrderTextFieldCustomer
                            label="Заказчик"
                            defaultValue={defaultCustomerId}
                            setId={setCustomerId}
                            field={'customer'}
                            entityList={customerList}
                        />
                        <CreateOrderTextFieldObject
                            label="Найменование"
                            defaultValue={defaultModelHeaderId}
                            setId={setModelHeaderId}
                            field={'header'}
                            entityList={modelHeaderList}
                        />
                        <TextFieldWithSetValue
                            label="Номер заказа"
                            values={values}
                            setValues={setValues}
                            field={'number'}
                        />
                        <TextFieldWithSetValue
                            label="Номер заказчика"
                            values={values}
                            setValues={setValues}
                            field={'numberCustomer'}
                        />
                        <MaterialTextFieldObjectWithEntityList
                            label="Статус"
                            values={values}
                            setValues={setValues}
                            field={'status'}
                            entityList={orderStatus}
                        />
                        <CreateOrderTextFieldObject
                            label="Атрибут"
                            defaultValue={defaultAttributeId}
                            value={defaultAttributeId}
                            setId={setAttributeId}
                            field={'attribute'}
                            entityList={attributeList}
                        />
                        <OrderDatePicker
                            label="Дата поступления"
                            date={dateOfReceipt}
                            setDate={setDateOfReceipt}
                        />
                        <OrderDatePicker
                            label="Плановая дата произодства"
                            date={dateOfPlannedProduction}
                            setDate={setDateOfPlannedProduction}
                        />
                    </Stack>
                </form>
            </DialogContent>
            <DialogActions sx={{p: '1.25rem'}}>
                <Button onClick={exit}>Выход</Button>
                <Button color="secondary" onClick={handleSubmit} variant="contained">
                    Создать
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CreateNewOrderModal;