import React, {useEffect, useState} from 'react';
import Box from "@mui/material/Box";
import MaterialTree from "../tree/MaterialTree";
import MaterialTable from "../table/warehouse/material/material/MaterialTable";
import MaterialsUtil from "../../../utils/warehouse/MaterialsUtil";
import MaterialOrganizationOptionService
    from "../../../API/orders/warehouse/material/MaterialOrganizationOptionService";
import GroupMaterialService from "../../../API/orders/orders/organization/GroupMaterialService";
import GroupMaterialUtil from "../../../utils/orders/GroupMaterialUtil";
import SignJobService from "../../../API/orders/orders/organization/job/SignJobService";
import {useLocation, useParams} from "react-router-dom";
import UserMaterialMaterialCloudService from "../../../API/material-cloud/user/UserMaterialMaterialCloudService";
import UserGroupMaterialMaterialCloudService
    from "../../../API/material-cloud/user/UserGroupMaterialMaterialCloudService";
import {EditLocalStoragePagesData} from "../../../utils/EditLocalStoragePagesData";

const MaterialPageItemModule = ({
                                    refMaterial,
                                    pageSize,
                                    addMaterialInArrival,
                                    enableEditing,
                                    isAddMaterial,
                                    isSelectInArrival,
                                    isSelectInEstimate,
                                    selectMaterialId,
                                    selectMaterial,
                                    isOrganization = true,
                                    subTypeMaterial
                                }) => {
    const pathname = useLocation().pathname

    const [filterGroup, setFilterGroup] = useState(new Map());
    const [data, setData] = useState([]);
    const [currentData, setCurrentData] = useState([]);
    const [changeFilterGroup, setChangeFilterGroup] = useState(false);
    const [dataTree, setDataTree] = useState([]);
    const [groupMap, setGroupMap] = useState(null);

    const [rowSelection, setRowSelection] = useState({});
    const [rowIndexSelection, setRowIndexSelection] = useState([])
    const [selectedIds, setSelectedIds] = useState([]);
    const [openModalAddSignJob, setOpenModalAddSignJob] = useState(false);
    const [groupNames, setGroupNames] = useState("");
    const [groupIds, setGroupIds] = useState([]);
    const [disabledAddMaterial, setDisabledAddMaterial] = useState(true);
    const [currentGroup, setCurrentGroup] = useState(null);

    const [tableOptions, setTableOptions] = useState({pageIndex: 0, pageSize: 20, totalElements: 0, searchData: ""})

    const tableOptionsHandle = (nextState) => {
        setTableOptions(nextState)
        if (nextState.pageSize !== tableOptions.pageSize) {
            EditLocalStoragePagesData(pathname, "pageSize", nextState.pageSize)
        }
    }

    const getStartPageSize = () => {
        const pagesData = JSON.parse(localStorage.getItem('pagesData')) || {}
        const pageSize = pagesData[pathname]?.pageSize
        pageSize && setTableOptions((prev) => ({...prev, pageSize: pageSize}))
    }

    const handleCloseModalAddSignJob = () => {
        setOpenModalAddSignJob(false)
        setRowSelection({});
        setRowIndexSelection([]);
        setSelectedIds([]);
    };

    const handleSubmitAddSignJobInMaterial = () => {
        MaterialOrganizationOptionService.addSigJobInMaterialsByMaterialGroup(selectedIds, groupIds).then(function () {
            handleCloseModalAddSignJob();
        })
            .catch(function (error) {
                console.log(error);
            });
    }

    const [change, setChange] = useState(false);
    const [dataSignJob, setDataSignJob] = useState([]);


    useEffect(() => {
        isOrganization && SignJobService.getAll().then(function (response) {
            let data = response.data;
            setDataSignJob(data);
        })
            .catch(function (error) {
                console.log(error);
            });
    }, [change]);

    useEffect(() => {
        if (groupIds.length === 0) {
            setDisabledAddMaterial(true)
        } else {
            setDisabledAddMaterial(false)
        }
    }, [groupIds]);

    useEffect(() => {
        const api = isOrganization ? MaterialOrganizationOptionService.getAllFilter : UserMaterialMaterialCloudService.getAllFilter

        if (currentGroup) {
            api(currentGroup, tableOptions.pageIndex, tableOptions.pageSize,
                null, null, subTypeMaterial, tableOptions.searchData, null).then(function (response) {
                let data = response.data;
                setCurrentData(data.content)
                setTableOptions((prev) => ({...prev, totalElements: data.total}))
            })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }, [currentGroup, tableOptions.searchData, tableOptions.pageIndex, tableOptions.pageSize]);

    useEffect(() => {
        getStartPageSize()
        const api = isOrganization ? GroupMaterialService.getAllByOrganization : UserGroupMaterialMaterialCloudService.getAll

        api().then((response) => {
            const buildTreeGroups = GroupMaterialUtil.buildTreeGroups(response.data);
            setDataTree(buildTreeGroups);
            const groupMap = new Map();
            GroupMaterialUtil.addToMap(buildTreeGroups, groupMap)
            setGroupMap(groupMap);
            setCurrentGroup({})
        }).catch(function (error) {
            console.log(error);
        });
    }, []);


    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'flex-start',
            marginTop: '3px',
            // "& > *:not(:last-child)": {marginRight: '16px'}
        }}>
            <Box sx={{flexBasis: '50%', maxWidth: '225px', paddingTop: "17px"}}>
                <MaterialTree
                    filterGroup={filterGroup}
                    setFilterGroup={setFilterGroup}
                    changeFilterGroup={changeFilterGroup}
                    setChangeFilterGroup={setChangeFilterGroup}
                    data={dataTree}
                    groupMap={groupMap}
                    currentGroup={currentGroup}
                    setCurrentGroup={setCurrentGroup}
                />
            </Box>
            <Box sx={{
                flexBasis: 'auto',
                marginLeft: '16px',
                overflow: 'auto',
                borderTopLeftRadius: '3px',
                borderTopRightRadius: '3px'
            }}>
                <MaterialTable
                    currentData={currentData}
                    setCurrentData={setCurrentData}
                    refMaterial={refMaterial}
                    pageSize={pageSize}
                    addMaterialInArrival={addMaterialInArrival}
                    enableEditing={enableEditing}
                    isAddMaterial={isAddMaterial}
                    isSelectInArrival={isSelectInArrival}
                    isSelectInEstimate={isSelectInEstimate}
                    openModalAddSignJob={openModalAddSignJob}
                    setOpenModalAddSignJob={setOpenModalAddSignJob}
                    handleCloseModalAddSignJob={handleCloseModalAddSignJob}
                    handleSubmitAddSignJobInMaterial={handleSubmitAddSignJobInMaterial}
                    groupNames={groupNames}
                    rowSelection={rowSelection}
                    setRowSelection={setRowSelection}
                    rowIndexSelection={rowIndexSelection}
                    setRowIndexSelection={setRowIndexSelection}
                    selectedIds={selectedIds}
                    setSelectedIds={setSelectedIds}
                    disabledAddMaterial={disabledAddMaterial}
                    dataSignJob={dataSignJob}
                    setDataSignJob={setDataSignJob}
                    change={change}
                    setChange={setChange}
                    selectMaterialId={selectMaterialId}
                    selectMaterial={selectMaterial}
                    tableOptionsHandle={tableOptionsHandle}
                    tableOptions={tableOptions}
                    sx={{
                        borderBottomLeftRadius: '0px',
                        borderBottomRightRadius: '0px',
                        maxWidth: '100%',
                    }}
                />
            </Box>
        </Box>
    );
};

export default MaterialPageItemModule;
