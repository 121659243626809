import React, {useMemo, useState} from 'react';
import MaterialReactTable from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import DateTimeUtil from "../../../../../utils/common/DateTimeUtil";
import {Button, Stack} from "@mui/material";
import Box from "@mui/material/Box";
import DateRangeFilter from "../../../filter/DateRangeFilter";
import DateInput from "../../../filter/DateInput";
import FilterUtil from "../../../../../utils/common/FilterUtil";
import CellCheckboxWithUnable from "../../../сheckbox/CellCheckboxWithUnable";


const IssuanceWarehouseMaterialTable = ({
                                            data,
                                            setData,
                                            baseCurrency,
                                            alternativeCurrency,
                                            unableAfterCut,
                                            setUnableAfterCut,
                                            reserve,
                                            afterCut
                                        }) => {

    const [start, setStart] = useState(null);
    const [end, setEnd] = useState(null);
    const [startWrittenOff, setStartWrittenOff] = useState(null);
    const [endWrittenOff, setEndWrittenOff] = useState(null);

    const sumAmountBasePrice = useMemo(
        () => data.reduce((acc, mEU) => acc + mEU.amountBasePrice, 0),
        [data]
    );

    const sumAmountAlternativePrice = useMemo(
        () => data.reduce((acc, mEU) => acc + mEU.amountAlternativePrice, 0),
        [data]
    );

    const sumQuantity = useMemo(
        () => data.reduce((acc, mEU) => acc + mEU.quantity, 0),
        [data]
    );

    const columns = useMemo(() => {

        const baseColumns = [
            {
                id: 'localDateTime',
                header: 'Дата выдачи',
                accessorKey: 'localDateTime',
                minSize: 10,
                maxSize: 10,
                Cell: ({cell}) => {
                    const value = cell.getValue();
                    return value === null || value === undefined ? '' : DateTimeUtil.dateTimeToString(value);
                },
                filterFn: (row, id, filterValue) => {
                    const startDate = filterValue?.startDate;
                    const endDate = filterValue?.endDate;
                    if (startDate && endDate) {
                        return (
                            DateTimeUtil.checkDateBefore(row.getValue(id), startDate) &&
                            DateTimeUtil.checkDateAfter(row.getValue(id), endDate)
                        );
                    }
                    return true;
                },
                Filter: ({column}) => <DateRangeFilter column={column}/>,
            },

            {
                id: 'quantity',
                header: 'Кол.',
                accessorKey: 'quantity',
                minSize: 10,
                maxSize: 10,
                Cell: ({cell}) => {
                    const value = cell.getValue();
                    return value === null || value === undefined ? 'Ожидание' : value.toFixed(3)
                },
                Footer: () => (
                    <Stack>
                        <Box color="warning.main">
                            {sumQuantity?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 3,
                            })}
                        </Box>
                    </Stack>
                ),
            },
            {
                id: 'amountBasePrice',
                header: `Факт ${baseCurrency}`,
                accessorKey: 'amountBasePrice',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => {
                    const value = cell.getValue();
                    return value === null || value === undefined ? 'Ожидание' : value.toFixed(2)
                },
                Footer: () => (
                    <Stack>
                        <Box color="warning.main">
                            {sumAmountBasePrice?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2,
                            })} {baseCurrency}
                        </Box>
                    </Stack>
                ),
            },
            {
                id: 'amountAlternativePrice',
                header: `Факт ${alternativeCurrency}`,
                accessorKey: 'amountAlternativePrice',
                minSize: 15,
                maxSize: 15,
                Cell: ({cell, row}) => {
                    const value = cell.getValue();
                    return value === null || value === undefined ? 'Ожидание' : value.toFixed(2)
                },
                Footer: () => (
                    <Stack>
                        <Box color="warning.main">
                            {sumAmountAlternativePrice?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2,
                            })} {alternativeCurrency}
                        </Box>
                    </Stack>
                ),
            },
            {
                id: 'orderNumber',
                header: '№ заказа',
                accessorKey: 'orderNumber',
                minSize: 100,
                maxSize: 150,
            },
            {
                id: 'writtenOff',
                header: 'Дата списания',
                accessorKey: 'writtenOff',
                minSize: 10,
                maxSize: 10,
                Cell: ({cell}) => {
                    const value = cell.getValue();
                    return value === null || value === undefined ? '' : DateTimeUtil.dateTimeToString(value);
                },
                filterFn: (row, id, filterValue) => {
                    const startDate = filterValue?.startDate;
                    const endDate = filterValue?.endDate;
                    if (startDate && endDate) {
                        return (
                            DateTimeUtil.checkDateBefore(row.getValue(id), startDate) &&
                            DateTimeUtil.checkDateAfter(row.getValue(id), endDate)
                        );
                    }
                    return true;
                },
                Filter: ({column}) => <DateRangeFilter
                    column={column}
                />,
            },
            {
                header: 'id',
                id: 'id',
                accessorKey: 'id',
                enableColumnActions: false,
                enableGrouping: false,
                enableEditing: false,
                enableHiding: false,
            },
        ];

        if (!reserve) {
            baseColumns.splice(0, 0, {
                id: 'articleNumber',
                header: 'Артикул',
                accessorKey: 'materialWarehouse.materialPriceUnit.materialOrganizationOption.articleNumber',
                minSize: 30,
                maxSize: 50,
            });
        }

        if (!reserve) {
            baseColumns.splice(1, 0, {
                id: 'name',
                header: 'Найменование',
                accessorKey: 'materialWarehouse.materialPriceUnit.materialOrganizationOption.name',
                minSize: 100,
                maxSize: 150,
            });
        }

        if (afterCut) {
            baseColumns.splice(1, 0, {
                id: 'afterCut',
                header: 'После раскроя',
                accessorKey: 'afterCut',
                size: 8,
                enableEditing: false,
                filterVariant: 'multi-select',
                filterSelectOptions: FilterUtil.statusList,
                filterFn: (row, id, filterValue) => {
                    return FilterUtil.checkStatus(row.getValue(id), filterValue)
                },
                Cell: ({cell, row}) => <CellCheckboxWithUnable
                    id={row.id}
                    unable={unableAfterCut}
                    setUnable={setUnableAfterCut}
                    disabled={true}
                >
                </CellCheckboxWithUnable>,
            });
        }

        return baseColumns;

    }, [data, setData]);


    return (
        <div>
            <MaterialReactTable
                muiTableBodyProps={{
                    sx: () => ({
                        '& tr:nth-of-type(odd)': {
                            backgroundColor: 'lightgray',
                        },
                    }),
                }}
                muiTableBodyCellProps={{
                    sx: {
                        p: '0px 0px 0px 10px',
                        m: '0px 0px 0px 0px',
                    },
                }}
                muiTableBodyRowProps={{
                    sx: {
                        height: '10px',
                    },
                }}
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        size: 15,
                        minSize: 15,
                    },
                }}
                columns={columns}
                data={data}
                rowHeight={13}
                columnSpacing={1}
                getRowId={(originalRow) => originalRow.id}
                initialState={{
                    columnVisibility: {
                        id: false,
                    },
                    isFullScreen: false,
                    density: "compact",
                    pagination: {pageIndex: 0, pageSize: 50},
                    sorting: [
                        {
                            id: 'localDateTime',
                            desc: false,
                        },]
                }}
                localization={MRT_Localization_RU}
                renderTopToolbarCustomActions={({table}) => {
                    const cancelFilter = () => {
                    };

                    const applyFilter = () => {

                    }

                    return (
                        <div>
                            <Box
                                sx={{
                                    display: 'flex',
                                    gap: '0.5rem',
                                    paddingLeft: '0.5rem',
                                    paddingTop: '0.5rem',
                                    paddingBottom: '0.2rem',
                                    flexWrap: 'wrap',
                                }}
                            >

                                <DateInput
                                    value={start}
                                    setValue={setStart}
                                    helperText={"Выдано от"}
                                />
                                <DateInput
                                    value={end}
                                    setValue={setEnd}
                                    helperText={"Выдано до"}
                                />
                                <DateInput
                                    value={startWrittenOff}
                                    setValue={setStartWrittenOff}
                                    helperText={"Списано от"}
                                />
                                <DateInput
                                    value={endWrittenOff}
                                    setValue={setEndWrittenOff}
                                    helperText={"Списано до"}
                                />
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'flex-start',
                                        gap: '0.5rem',
                                        paddingLeft: '0.5rem',
                                        paddingBottom: '0.2rem',
                                        flexWrap: 'wrap',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            gap: '0.5rem',
                                        }}
                                    >
                                        <Button
                                            size="small"
                                            color="secondary"
                                            onClick={applyFilter}
                                            variant="contained"
                                        >
                                            Применить фильтр
                                        </Button>
                                        <Button
                                            size="small"
                                            color="secondary"
                                            onClick={cancelFilter}
                                            variant="contained"
                                        >
                                            Отменить фильтр
                                        </Button>
                                    </Box>

                                </Box>

                            </Box>
                        </div>
                    );
                }}
            />
        </div>
    );
};


export default IssuanceWarehouseMaterialTable;