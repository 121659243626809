import React from 'react';
import BasicModelEstimateTable
    from "../../../../components/UI/table/model-estimate/estimate/BasicModelEstimateTable";
import {useParams} from "react-router-dom";

const BasicModelsEstimate = () => {

    const params = useParams();

    const id = params.id;

    return (
        <div>
            <BasicModelEstimateTable
                modelId={id}
            />
        </div>
    );
};

export default BasicModelsEstimate;