import React from 'react';

import {useParams} from "react-router-dom";
import Typography from "@mui/material/Typography";
import SetAttributeOrderTable from "../../../../components/UI/table/orders/organization/order/SetAttributeOrderTable";

const SetAttributeOrder = () => {

    const params = useParams();

    return (
        <div>
            <Typography variant="h6" component="h6">
                Установить атрибуты к заказу №: {params.number}
            </Typography>
            <SetAttributeOrderTable
                idOrder={params.id}
                number={params.number}
                idHeader={params.idHeader}
                year={params.year}
            />
        </div>
    );
};

export default SetAttributeOrder;