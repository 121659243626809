import React, {useState} from "react";
import UploadFilesForm from "../../../../components/form/UploadFilesForm";

const UploadFilesRequestsForm = ({orderId, change, setChange, calculationRequestsCutting, upload}) => {

    const [selectedFiles, setSelectedFiles] = useState([]);

    const handleUpload = async (event) => {
        event.preventDefault();
        upload(selectedFiles, calculationRequestsCutting, orderId)
            .then(function () {
                setSelectedFiles([]);
                setChange(!change)
                event.target.reset();
            })
            .catch(function (error) {
                console.log(error);
                event.target.reset();
            })
    }

    return (
        <div>
            <UploadFilesForm
                handleUpload={handleUpload}
                selectedFiles={selectedFiles}
                setSelectedFiles={setSelectedFiles}
                label={"Загрузить заявки на раскрой"}
            />
        </div>
    );
};

export default UploadFilesRequestsForm;