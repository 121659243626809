import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack} from "@mui/material";
import ArrivalMaterialTextField from "./ArrivalMaterialTextField";
import ArrivalMaterialTextFieldObject from "./ArrivalMaterialTextFieldObject";
import TextFieldArrivalMaterialModuleItem
    from "../../../../modul/arrivalmaterialmoduleitem/TextFieldArrivalMaterialModuleItem";
import {materialCutDefault} from "../../../../../../data/MaterialCutDefault";

const ModalUpdateOrAddMaterialPriceUnit = ({
                                               open,
                                               onClose,
                                               title,
                                               values,
                                               setValues,
                                               currentCurrency,
                                               baseCurrency,
                                               basePriceCurrency,
                                               alternativeCurrency,
                                               isBaseCurrency,
                                               isAlternativeCurrency,
                                               labelButtonSSubmit,
                                               handleSubmit,
                                               isSlabMaterial,
                                               typeSlabMaterial,
                                               isNew
                                           }) => {

    const currentBasePriceCurrency = basePriceCurrency === null ? values !== null ? values.materialPriceInRealBasePrice.currency.curAbbreviation : null : basePriceCurrency
    const isFixedStripe = typeSlabMaterial === null ? (values !== null ? values.supplierMaterial.typeSupplierMaterial === "BY_FIXED_STRIPE" : null) : typeSlabMaterial === "BY_FIXED_STRIPE";
    const currentIsSlabMaterial = isSlabMaterial === null ? (values !== null ? values.materialOrganizationOption.typeMaterial === "SLAB" : null) : isSlabMaterial;

    return (
        <Dialog open={open}>
            <DialogTitle textAlign="center">{title}</DialogTitle>
            <DialogContent>
                <form onSubmit={(e) => e.preventDefault()}>
                    <Stack
                        sx={{
                            width: '100%',
                            minWidth: {xs: '300px', sm: '360px', md: '400px'},
                            gap: '0.01rem',
                        }}
                    >
                        {currentIsSlabMaterial && <TextFieldArrivalMaterialModuleItem
                            label="Тип прихода"
                            value={isNew ? (typeSlabMaterial ? materialCutDefault[typeSlabMaterial] : Object.values(materialCutDefault)[0]) :
                                (values !== null ? materialCutDefault[values.supplierMaterial.typeSupplierMaterial] : null)
                            }
                            field={typeSlabMaterial}
                            maxWidth={'600px'}
                            disabled={true}
                        />}
                        <ArrivalMaterialTextFieldObject
                            label="Артикул"
                            values={values}
                            field1={'materialOrganizationOption'}
                            field2={'articleNumber'}
                            disabled={true}
                        />
                        <ArrivalMaterialTextFieldObject
                            label="Материал"
                            values={values}
                            field1={'materialOrganizationOption'}
                            field2={'name'}
                            disabled={true}
                        />
                        <ArrivalMaterialTextFieldObject
                            label="Ед. измерения"
                            values={values && values.materialOrganizationOption}
                            field1={'unit'}
                            field2={'name'}
                            disabled={true}
                        />
                        <ArrivalMaterialTextField
                            label="Количество"
                            values={values}
                            setValues={setValues}
                            field={'quantity'}
                            disabled={isSlabMaterial && isFixedStripe}
                        />
                        {currentIsSlabMaterial && <ArrivalMaterialTextField
                            label="Ширина"
                            values={values}
                            setValues={setValues}
                            field={'length'}
                            disabled={isFixedStripe}
                        />}
                        {currentIsSlabMaterial && <ArrivalMaterialTextField
                            label="Высота"
                            values={values}
                            setValues={setValues}
                            field={'width'}
                            disabled={isFixedStripe}
                        />}
                        <ArrivalMaterialTextField
                            label={`Сумма в ${currentCurrency}`}
                            values={values}
                            setValues={setValues}
                            field={'amountInCurrencyArrival'}
                        />
                        {isBaseCurrency === "true" && <ArrivalMaterialTextField
                            label={`Курс ${currentCurrency}\\${baseCurrency} (НБ РБ ${values === null ? null : values.officialExchangeRateToBaseCurrencyBaseMaterials})`}
                            values={values}
                            setValues={setValues}
                            field={'exchangeRateToBaseCurrencyBaseMaterials'}
                        />}
                        {isAlternativeCurrency === "true" && <ArrivalMaterialTextField
                            label={`Курс ${currentCurrency}\\${alternativeCurrency} (НБ РБ ${values === null ? null : values.officialExchangeRateToAlternativeCurrencyBaseMaterials})`}
                            values={values}
                            setValues={setValues}
                            field={'exchangeRateToAlternativeCurrencyBaseMaterials'}
                        />}
                        {!(currentCurrency === currentBasePriceCurrency || currentBasePriceCurrency === baseCurrency || currentBasePriceCurrency === alternativeCurrency) &&
                            <ArrivalMaterialTextField
                                label={`Курс ${currentCurrency}\\${currentBasePriceCurrency} базовой цены (НБ РБ ${values === null ? null : values.officialExchangeRateToBasePrice})`}
                                values={values}
                                setValues={setValues}
                                field={'exchangeRateToBasePrice'}
                            />}
                    </Stack>
                </form>
            </DialogContent>
            <DialogActions sx={{p: '1.25rem'}}>
                <Button onClick={onClose}>Выйти</Button>
                <Button color="secondary" onClick={handleSubmit} variant="contained">
                    {labelButtonSSubmit}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ModalUpdateOrAddMaterialPriceUnit;