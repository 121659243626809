import axios from "axios";
import {apiVersionOrders, gatewayUrl, nameOrders} from "../../../../Constants";

const restUrlCustomer = gatewayUrl + nameOrders + apiVersionOrders + '/customers/job-metric-order-organizations'

export default class CustomerJobMetricOrderOrganizationService {

    static async getAllByEstimateIdForCustomer(estimateId) {
        return axios.get(restUrlCustomer + '/by-estimate-for-customer?estimateId=' + estimateId);
    }
}