import React, {useEffect, useState} from 'react';
import CustomerOffersTable from "../../../components/UI/table/orders/customer/order/CustomerOffersTable";
import {useParams} from "react-router-dom";
import WarehouseMaterialSettingsService from "../../../API/orders/warehouse/WarehouseMaterialSettingsService";

const CustomerOrderOffers = () => {

    const [baseCurrency, setBaseCurrency] = useState("");
    const [alternativeCurrency, setAlternativeCurrency] = useState("");
    const params = useParams();
    const customerOrderNumber = params.customerOrderNumber;
    const orderId = params.orderId;

    useEffect(() => {
        WarehouseMaterialSettingsService.getWithBaseAndAlternativeCurrency().then(function (response) {
            let data = response.data;
            setBaseCurrency(data.baseCurrency.curAbbreviation);
            setAlternativeCurrency(data.alternativeCurrency.curAbbreviation);
        })
            .catch(function (error) {
                console.log(error);
            });
    }, []);

    return (
        <div>
            <CustomerOffersTable
                orderId={orderId}
                customerOrderNumber={customerOrderNumber}
                baseCurrency={baseCurrency}
                alternativeCurrency={alternativeCurrency}
            />
        </div>
    );
};

export default CustomerOrderOffers;