import axios from "axios";
import {apiVersionOrders, gatewayUrl, nameOrders} from "../../../Constants";

const warehouseMaterialSettingsRestUrl = gatewayUrl +  nameOrders + apiVersionOrders + '/organizations/warehouse-material-settings'

export default class WarehouseMaterialSettingsService {

    static async getWithBaseAndAlternativeCurrency() {
        return axios.get(warehouseMaterialSettingsRestUrl + '/with-base-and-alternative-currency');
    }
}