import React, {useCallback, useMemo, useState} from 'react';
import {Button, Tooltip} from '@mui/material';
import MaterialReactTable from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import {Link, useNavigate} from 'react-router-dom';
import Box from "@mui/material/Box";
import OrganizationCustomerService from "../../../../../../API/orders/orders/organization/OrganizationCustomerService";
import DateRangeFilter from "../../../../filter/DateRangeFilter";
import DateTimeUtil from "../../../../../../utils/common/DateTimeUtil";
import {Edit, Unarchive} from "@mui/icons-material";
import ModalConfirm from "../../../../modal/ModalConfirm";
import OrderService from "../../../../../../API/orders/orders/organization/OrderService";

const CustomerOrderTable = ({
                                data,
                                setData,
                                enableEditing
                            }) => {

    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [updateModalOpen, setUpdateModalOpen] = useState(false);
    const [sendArchiveModalOpen, setSendArchiveModalOpen] = useState(false);

    const [currentEditRowData, setCurrentEditRowData] = useState(null);
    const [dateOfReceipt, setDateOfReceipt] = useState(null);
    const [dateOfPlannedProduction, setDateOfPlannedProduction] = useState(null);
    const [plannedDateOfShipment, setPlannedDateOfShipment] = useState(null);
    const [manufactured, setManufactured] = useState(null);
    const [shipped, setShipped] = useState(null);
    const [currentEditRowId, setCurrentEditRowId] = useState(null);
    const [setPlanModalOpen, setSetPlanModalOpen] = useState(false);
    const [selectedIds, setSelectedIds] = useState([]);
    const [rowSelection, setRowSelection] = useState({});
    const [defaultAttributeId, setDefaultAttributeId] = useState(null);

    const navigate = useNavigate();

    const redirectPlans = () => {
        navigate('/organization/plans');
    };

    const handleCreateNewRow = (values) => {
        data.push(values);
        setData([...data]);
    };

    const handleUpdate = (row, values) => {
        data[row[currentEditRowId]] = values;
        setData([...data]);
    };

    const handleUpdateModel = useCallback(
        (row) => {
            setCurrentEditRowData(row.original);
            setDateOfReceipt(row.original.dateOfReceipt);
            setDateOfPlannedProduction(row.original.dateOfPlannedProduction)
            setPlannedDateOfShipment(row.original.plannedDateOfShipment)
            setManufactured(row.original.manufactured)
            setShipped(row.original.shipped)
            setCurrentEditRowId(row.index);
            setUpdateModalOpen(true);
        },
        [data, currentEditRowData, setCurrentEditRowData],
    );

    const sendToArchive = useCallback(
        (row) => {
            setCurrentEditRowData(row.original);
            setSendArchiveModalOpen(true);
        },
        [data, currentEditRowData, setCurrentEditRowData],
    );

    const handleSubmitSendToArchive = () => {
        OrderService.sendToArchive(currentEditRowData.id)
            .then(function () {
                data.splice(currentEditRowData.index, 1);
                setData([...data]);
                setCurrentEditRowData(null);
                setSendArchiveModalOpen(false);
            })
            .catch(function (error) {
                console.log(error);
            })
    };

    const handleCloseModalSendToArchive = () => {
        setSendArchiveModalOpen(false);
    };

    const handleDeleteRow = useCallback(
        (row) => {
            OrganizationCustomerService.delete(row.original.id)
                .then(function () {
                    data.splice(row.index, 1);
                    setData([...data]);
                })
                .catch(function (error) {
                    console.log(error);
                })
        },
        [data],
    );

    const columns = useMemo(() => {
        const baseColumns = [
            {
                id: 'numberCustomer',
                header: 'Номер заказа',
                accessorKey: 'numberCustomer',
                filterFn: 'contains',
                maxSize: 23,
                minSize: 35,
                Cell: ({cell, row}) => (
                    <li key={cell.getValue()}>
                        <Link
                            to={'/customer/orders/' + data[row.index].id + '/' + data[row.index].numberCustomer}>{cell.getValue() === null ? 'Не занумерован' : cell.getValue()}</Link>
                    </li>
                ),
            },
            {
                accessorFn: (row) => {
                    if (row.dateOfCreate === null) {
                    } else {
                        return new Date(row.dateOfCreate)
                    }
                },
                id: 'dateOfCreate',
                header: 'Дата',
                sortingFn: 'datetime',
                maxSize: 25,
                Cell: ({cell}) => {
                    if (cell.getValue() !== undefined) {
                        return cell.getValue().toLocaleDateString();
                    }
                },
                filterFn: (row, id, filterValue) => {
                    const startDate = filterValue?.startDate;
                    const endDate = filterValue?.endDate;
                    if (startDate && endDate) {
                        return (
                            DateTimeUtil.checkDateBefore(row.getValue(id), startDate) &&
                            DateTimeUtil.checkDateAfter(row.getValue(id), endDate)
                        );
                    }
                    return true;
                },
                Filter: ({column}) => <DateRangeFilter
                    column={column}
                />,
            },
            {
                id: 'year',
                header: 'год                                                                     ',
                accessorKey: 'year',
                enableColumnActions: false,
                enableGrouping: false,
                enableColumnFilter: false,
                enableEditing: false,
            },
            {
                header: 'id',
                accessorKey: 'id',
                enableHiding: false,
                enableColumnActions: false,
                enableGrouping: false,
                enableColumnFilter: false,
                enableEditing: false,
            },
        ];

        return baseColumns;

    }, [data, setData]);

    return (
        <>
            <MaterialReactTable
                enableEditing={enableEditing}
                enableColumnFilterModes
                enableGrouping
                enableStickyHeader
                enablePinning
                muiTableBodyProps={{
                    sx: () => ({
                        '& tr:nth-of-type(odd)': {
                            backgroundColor: 'lightgray',
                        },
                    }),
                }}
                muiTableBodyCellProps={{
                    sx: {
                        p: '0px 0px 0px 5px',
                        m: '0px 0px 0px 0px',
                    },
                }}
                muiTableBodyRowProps={{
                    sx: {
                        height: '10px',
                    },
                }}
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        size: 15,
                        minSize: 15,
                    },
                }}
                columns={columns}
                data={data}
                onRowSelectionChange={setRowSelection}
                state={{rowSelection}}
                enableRowSelection
                getRowId={(originalRow) => originalRow.id}
                selectionPadding={1}
                selectionCss={{
                    backgroundColor: 'lightblue',
                    borderWidth: '2px',
                    borderStyle: 'solid',
                    borderColor: 'blue',
                    borderRadius: '4px',
                }}
                initialState={{
                    columnVisibility: {
                        id: false,
                        model: false,
                        year: false,
                    },
                    isFullScreen: false,
                    density: "compact",
                    pagination: {pageIndex: 0, pageSize: 100},
                    columnPinning: {left: ['numberCustomer']}
                }}
                muiToolbarAlertBannerChipProps={{color: 'primary'}}
                localization={MRT_Localization_RU}
                renderTopToolbarCustomActions={({table}) => {


                    return (
                        <div>
                            <Box
                                sx={{
                                    display: 'flex',
                                    gap: '0.5rem',
                                    paddingLeft: '0.5rem',
                                    paddingTop: '0.5rem',
                                    paddingBottom: '0.2rem',
                                    flexWrap: 'wrap',
                                }}
                            >


                                {<Button
                                    size="small"
                                    color="success"
                                    onClick={() => setCreateModalOpen(true)}
                                    variant="contained"
                                >
                                    Создать заказ
                                </Button>}
                            </Box>
                        </div>
                    );
                }}
                renderRowActions={({row, table}) => (
                    <Box sx={{
                        display: 'flex',
                        gap: '0.1rem',
                        justifyContent: 'flex-start',
                        maxWidth: '50px'
                    }}>
                        {<Tooltip arrow placement="left" title="Редактировать">
                            <Button size="small" sx={{minWidth: 'auto', marginLeft: 'auto'}} color="pencil"
                                    onClick={() => handleUpdateModel(row)}>
                                <Edit sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                            </Button>
                        </Tooltip>}

                        {<Tooltip arrow placement="right" title="Поместить в архив">
                            <Button size="small" sx={{minWidth: 'auto', marginLeft: 'auto'}} color="error"
                                    onClick={() => sendToArchive(row)}>
                                <Unarchive sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                            </Button>
                        </Tooltip>}
                    </Box>
                )}
            />
            {/*  <CreateNewOrderModal
                columns={columns}
                open={createModalOpen}
                onClose={() => setCreateModalOpen(false)}
                onSubmit={handleCreateNewRow}
                customerList={customerList}
                defaultAttributeId={defaultAttributeId}
                setDefaultAttributeId={setDefaultAttributeId}
            />*/}
            {/*<UpdateOrderModal
                open={updateModalOpen}
                onClose={() => setUpdateModalOpen(false)}
                onSubmit={handleUpdate}
                values={currentEditRowData}
                currentEditRowId={currentEditRowId}
                change={change}
                setChange={setChange}
                setValues={setCurrentEditRowData}
                dateOfReceipt={dateOfReceipt}
                setDateOfReceipt={setDateOfReceipt}
                dateOfPlannedProduction={dateOfPlannedProduction}
                setDateOfPlannedProduction={setDateOfPlannedProduction}
                plannedDateOfShipment={plannedDateOfShipment}
                setPlannedDateOfShipment={setPlannedDateOfShipment}
                manufactured={manufactured}
                setManufactured={setManufactured}
                shipped={shipped}
                setShipped={setShipped}
            />*/}
            <ModalConfirm
                title={"Подтвердить поместить в архив"}
                modalOpen={sendArchiveModalOpen}
                handleClose={handleCloseModalSendToArchive}
                handleSubmit={handleSubmitSendToArchive}
                action={"Подтвердить"}
            />
        </>
    );
};

export default CustomerOrderTable;