import React from 'react';
import {TextField} from "@mui/material";

const MaterialTextFieldObjectWithEntityListCutDefault = ({
                                                   entityList,
                                                   label,
                                                   value,
                                                   setValue,
                                                   field,
                                                   disabled,
                                                   excludeStatus
                                               }) => {

    return (
        <div>
            <TextField
                size={"small"}
                disabled={disabled}
                key={field}
                sx={{
                    width: '100%',
                    minWidth: {xs: '300px', sm: '360px', md: '400px'},
                    gap: '1.5rem',
                }}
                margin="normal"
                select
                label={label}
                value={value === null ? "" : value}
                name={field}
                onChange={(e) =>
                    setValue(e.target.value)
                }
                SelectProps={{
                    native: true,
                }}
            >
                {Object.keys(entityList).map((statusKey) => (
                    excludeStatus !== statusKey && <option key={statusKey} value={statusKey}>
                        {entityList [statusKey]}
                    </option>
                ))}
            </TextField>
        </div>
    );
};

export default MaterialTextFieldObjectWithEntityListCutDefault;