import axios from "axios";
import {apiVersionOrders, gatewayUrl, nameOrders} from "../../../Constants";

const warehouseRestUrl = gatewayUrl + nameOrders + apiVersionOrders + '/organizations/warehouses'

export default class WarehouseService {

    static async getAll() {
        return axios.get(warehouseRestUrl);
    }

    static async update(data) {
        await axios.post(warehouseRestUrl + '/' + data.id, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async save(data) {
        return await axios.post(warehouseRestUrl, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async delete(id) {
        await axios.delete(warehouseRestUrl + '/' + id);
    }
}