import React from "react";
import {COLORS} from "../../../../../../Constants";
import Layer from "./Layer";

const Notch = ({
                   notch,
                   scale,
                   position,
                   texture,
                   isGroove,
                   isQuarter,
                   isRound,
                   setHoveredGrooveId,
                   setHoveredHoleId,
                   setSelectedHole,
                   processingHandle,
                   notThrough,
                   startPoint,
                   onEdge
               }) => {


    const onClickHandle = (event) => {
        event.stopPropagation()
        if (isGroove) {
            processingHandle("editGroove", notch.data.id)
        } else if (isQuarter) {
            processingHandle("editQuarter", notch.data.id, startPoint)
        } else if (isRound) {
            processingHandle("editNotchRound", notch.data.id)
            setSelectedHole(notch.data.id)
        }
    }
    const onPointerOver = (event) => {
        event.stopPropagation()
        if (isGroove || isQuarter) {
            setHoveredGrooveId(`${notch.data.id}${notch.data.name}`)
        } else if (isRound) {
            setHoveredHoleId(`${notch.data.id}${notch.data.name}`)
        }
    }
    const onPointerOut = (event) => {
        event.stopPropagation()
        if (isGroove || isQuarter) {
            setHoveredGrooveId(null)
        } else if (isRound) {
            setHoveredHoleId(null)
        }
    }

    return (
        <Layer layer={1}>
            <mesh
                scale={scale}
                position={[position[0], position[1], position[2] - scale/2]}
                onPointerOver={onPointerOver}
                onPointerOut={onPointerOut}
                onClick={onClickHandle}>

                <extrudeGeometry args={[notch.shape, {
                    bevelEnabled: false,
                }]}/>
                <meshBasicMaterial
                    toneMapped={notThrough}
                    map={notThrough && isGroove && texture}
                    color={!notThrough ? "white" : notch.data.contour.position === "BOTTOM" && notThrough ? COLORS[6][600] : !isGroove ? "grey" : "white"}
                />
            </mesh>
        </Layer>
    )
}
export default Notch
