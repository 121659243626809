import React, {useCallback, useMemo, useState} from 'react';
import MaterialReactTable from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import {Button, Tooltip} from "@mui/material";
import {Delete, Edit, PlaylistAdd} from "@mui/icons-material";
import Box from "@mui/material/Box";
import ModelService from "../../../../../API/model-estimate/model/ModelService";
import FilterUtil from "../../../../../utils/common/FilterUtil";
import ButtonSettingsOrders from "../../../button/ButtonSettingsOrders";
import CellCheckboxWithUnable from "../../../сheckbox/CellCheckboxWithUnable";
import {CreateNewModelModal, UpdateModelModal} from "./BasicModelsTable";
import {Link} from "react-router-dom";
import Common from "../../../../../utils/common/Common";
import ChecklistRtlIcon from "@mui/icons-material/ChecklistRtl";
import ModalConfirm from "../../../modal/ModalConfirm";
import OrderService from "../../../../../API/orders/orders/organization/OrderService";
import UploadModelFileForm from "./UploadModelFileForm";
import ReplacementModelMaterialFileForm from "./ReplacementModelMaterialFileForm";
import ModelFileService from "../../../../../API/model-estimate/file/ModelFileService";

const ModelsTable = ({
                         data,
                         setData,
                         unable,
                         setUnable,
                         isButtonDelete,
                         isButtonUpdate,
                         isButtonBasicModels,
                         isButtonCreateModel,
                         isButtonSettings,
                         isColumnUnable,
                         enableRowSelection,
                         isButtonChoice,
                         setModelsOpen,
                         setBasicModalModelsOpen,
                         orderId,
                         isRedirect,
                         isBasicModel,
                         change,
                         setChange,
                         disableEditing,
                         upLoad
                     }) => {

        const [isFullScreen, setIsFullScreen] = useState(false);

        const [createModalOpen, setCreateModalOpen] = useState(false);
        const [updateModalOpen, setUpdateModalOpen] = useState(false);
        const [modelHeaderList, setModelHeaderList] = useState([]);
        const [currentEditRowData, setCurrentEditRowData] = useState(null);
        const [currentEditRowId, setCurrentEditRowId] = useState(null);
        const [confirmIssueModalOpen, setConfirmIssueModalOpen] = useState(false);
        const [currentIdModel, setCurrentIdModel] = useState(null);
        const [rowSelection, setRowSelection] = useState({});

        const handleCreateNewRow = (values) => {
            data.push(values);
            setData([...data]);
        };

        const handleUpdate = (row, values) => {
            data[row[currentEditRowId]] = values;
            setData([...data]);
        };

        const handleSaveRowEdits = async ({exitEditingMode, row, values}) => {
            ModelService.update(values)
                .then(function () {
                    data[row.index] = values;
                    setData([...data]);
                })
                .catch(function (error) {
                    console.log(error);
                })
            exitEditingMode();
        };

        const handleDeleteRow = useCallback(
            (row) => {
                ModelService.delete(row.original.id)
                    .then(function () {
                        data.splice(row.index, 1);
                        setData([...data]);
                    })
                    .catch(function (error) {
                        console.log(error);
                    })
            },
            [data],
        );

        const handleUpdateModel = useCallback(
            (row) => {
                setCurrentEditRowData(row.original);
                setCurrentEditRowId(row.index);
                setUpdateModalOpen(true);

            },
            [data, currentEditRowData, setCurrentEditRowData],
        );

        const addModel = useCallback(
            (row) => {
                setCurrentIdModel(row.original.id)
                setConfirmIssueModalOpen(true);

            },
            [data, currentEditRowData, setCurrentEditRowData],
        );

        const handleCloseConfirmIssue = () => {
            setConfirmIssueModalOpen(false)
        };

        const handleUnableCheckbox = (id, status) => {
            ModelService.unable(id, status)
                .then(function () {
                    Common.handleUnable(id, status, unable, setUnable)
                })
                .catch(function (error) {
                    console.log(error);
                })
        };

        const handleSubmitConfirmIssue = () => {

            OrderService.addModel(orderId, currentIdModel).then(function () {
                setConfirmIssueModalOpen(false);
                setModelsOpen(false);
                setBasicModalModelsOpen(false);
                setCurrentIdModel(null);
                setChange(!change)
            })
                .catch(function (error) {
                    console.log(error);
                });
        }

        const columns = useMemo(() => {
            const baseColumns = [
                {
                    id: 'name',
                    header: 'Модель',
                    accessorKey: 'name',
                    minSize: 250,
                    Cell: ({cell, row}) => (
                        isRedirect ? <li key={cell.getValue()}>
                            <Link
                                to={'/models/replacements/' + data[row.index].id}>{cell.getValue()}</Link>
                        </li> : cell.getValue()
                    ),
                },

                {
                    id: 'articleNumber',
                    header: 'Артикул',
                    accessorKey: 'articleNumber',
                    minSize: 15,
                    maxSize: 15,
                },
                {
                    id: 'barcode',
                    header: 'Штрих-код',
                    accessorKey: 'barcode',
                    minSize: 50,
                },

                {
                    header: 'id',
                    id: 'id',
                    accessorKey: 'id',
                    enableHiding: false,
                    enableColumnActions: false,
                    enableGrouping: false,
                    enableEditing: false,
                },
            ];

            if (isBasicModel) {
                baseColumns.splice(2, 0, {
                    id: 'basicModel.nam',
                    header: 'Базовая модель',
                    accessorKey: 'basicModel.name',
                    minSize: 150,
                    Cell: ({cell, row}) => (
                        isRedirect ?
                            <li key={cell.getValue()}>
                                <Link
                                    to={'/basic-models/' + data[row.index].basicModel.id + '/estimates/'}>{cell.getValue() === undefined ? 'Не определено' : cell.getValue()}</Link>
                            </li> : cell.getValue()
                    ),
                });
            }

            if (isColumnUnable) {
                baseColumns.splice(3, 0, {
                    header: 'Статус',
                    accessorKey: 'enabled',
                    size: 8,
                    enableEditing: false,
                    filterVariant: 'multi-select',
                    filterSelectOptions: FilterUtil.statusList,
                    filterFn: (row, id, filterValue) => {
                        return FilterUtil.checkStatus(row.getValue(id), filterValue)
                    },
                    Cell: ({cell, row}) => <CellCheckboxWithUnable
                        id={row.id}
                        unable={unable}
                        setUnable={handleUnableCheckbox}
                    >
                    </CellCheckboxWithUnable>,
                });
            }

            return baseColumns;

        }, [data, setData, isBasicModel]);


        function redirectByArrivalMaterialId(row) {

        }

        return (
            <div>
                <MaterialReactTable
                    muiTableBodyProps={{
                        sx: () => ({
                            '& tr:nth-of-type(odd)': {
                                backgroundColor: 'lightgray',
                            },
                        }),
                    }}
                    muiTableBodyCellProps={{
                        sx: {
                            p: '0px 0px 0px 10px',
                            m: '0px 0px 0px 0px',
                        },
                    }}
                    muiTableBodyRowProps={{
                        sx: {
                            height: '10px',
                        },
                    }}
                    isFullScreen={isFullScreen}
                    displayColumnDefOptions={{
                        'mrt-row-actions': {
                            muiTableHeadCellProps: {
                                align: 'center',
                            },
                            size: 15,
                            minSize: 15,
                        },
                    }}
                    columns={columns}
                    data={data}
                    rowHeight={13}
                    columnSpacing={1}
                    initialState={{
                        sorting: [{id: 'name', desc: false}],
                        columnVisibility: {
                            id: false,
                        },
                        isFullScreen: false,
                        density: "compact",
                        pagination: {pageIndex: 0, pageSize: 100},
                        columnPinning: {left: ['number']}
                    }}
                    enableEditing={!disableEditing}
                    onRowSelectionChange={setRowSelection}
                    state={{rowSelection}}
                    enableRowSelection={enableRowSelection}
                    getRowId={(originalRow) => originalRow.id}
                    selectionPadding={1}
                    selectionCss={{
                        backgroundColor: 'lightblue',
                        borderWidth: '2px',
                        borderStyle: 'solid',
                        borderColor: 'blue',
                        borderRadius: '4px',
                    }}
                    renderRowActions={({row, table}) => (
                        <Box sx={{
                            display: 'flex',
                            gap: '0.1rem',
                            justifyContent: 'flex-start',
                            maxWidth: '50px'
                        }}>
                            {isButtonUpdate && <Tooltip arrow placement="left" title="Редактировать">
                                <Button size="small" sx={{minWidth: 'auto', marginLeft: 'auto'}} color="pencil"
                                        onClick={() => handleUpdateModel(row)}>
                                    <Edit sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                                </Button>
                            </Tooltip>}
                            {isButtonDelete && <Tooltip arrow placement="right" title="Удалить">
                                <Button size="small" sx={{minWidth: 'auto', marginLeft: 'auto'}} color="error"
                                        onClick={() => handleDeleteRow(row)}>
                                    <Delete sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                                </Button>
                            </Tooltip>}
                        {/*    {isButtonBasicModels && <Tooltip arrow placement="right" title="Базовая модель">
                                <Button size="small" sx={{minWidth: 'auto', marginLeft: 'auto'}} color="success"
                                        onClick={() => redirectByArrivalMaterialId(row)}>
                                    <PlaylistAdd sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                                </Button>
                            </Tooltip>}*/}
                            {isButtonChoice && <Tooltip arrow placement="right" title="Показать модели">
                                <Button size="small" sx={{minWidth: 'auto', marginLeft: 'auto'}} color="success"
                                        onClick={() => addModel(row)}>
                                    <ChecklistRtlIcon sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                                </Button>
                            </Tooltip>}
                        </Box>
                    )}
                    localization={MRT_Localization_RU}
                    renderTopToolbarCustomActions={({table}) => {
                        return (
                            <div>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        gap: '0.5rem',
                                        paddingLeft: '0.5rem',
                                        paddingTop: '0.5rem',
                                        paddingBottom: '0.2rem',
                                        flexWrap: 'wrap',
                                    }}
                                >
                                    {isButtonCreateModel && <Button
                                        size="small"
                                        color="secondary"
                                        onClick={() => setCreateModalOpen(true)}
                                        variant="contained"
                                    >
                                        Создать модель
                                    </Button>}
                                    {isButtonSettings && <ButtonSettingsOrders/>}
                                    {upLoad && <UploadModelFileForm
                                        label={'Загрузить модели'}
                                        change={change}
                                        setChange={setChange}
                                    />}
                                    {upLoad && <ReplacementModelMaterialFileForm
                                        labelForm ={'Загрузить замены материалов'}
                                        upload={ModelFileService.uploadReplacementModelMaterialsFile}
                                        change={change}
                                        setChange={setChange}
                                    />}
                                </Box>
                            </div>
                        );
                    }}
                />
                <CreateNewModelModal
                    columns={columns}
                    open={createModalOpen}
                    onClose={() => setCreateModalOpen(false)}
                    onSubmit={handleCreateNewRow}
                />
                <UpdateModelModal
                    columns={columns}
                    open={updateModalOpen}
                    onClose={() => setUpdateModalOpen(false)}
                    onSubmit={handleUpdate}
                    values={currentEditRowData}
                    currentEditRowId={currentEditRowId}
                    change={change}
                    setChange={setChange}
                />
                <ModalConfirm
                    title={"Подтвердить выбор модели?"}
                    modalOpen={confirmIssueModalOpen}
                    handleClose={handleCloseConfirmIssue}
                    handleSubmit={handleSubmitConfirmIssue}
                    action={"Подтвердить"}
                />
            </div>
        );
    }
;

export default ModelsTable;