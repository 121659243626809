import React from 'react';
import {TextField} from "@mui/material";

const ArrivalMaterialTextFieldObject = ({label, values, field1, field2, field3, disabled}) => {

        const getValue = (values, field1, field2, field3) => {

            if (values === null) {
                return "";
            }
            if (field2 === undefined && field3 === undefined) {
                return values[field1];
            }
            if (field3 === undefined) {
                return values[field1][field2];
            }
            return values[field1][field2][field3]
        };

    return (
        <div>
            <TextField
                size={"small"}
                key={field1}
                sx={{
                    width: '100%',
                    minWidth: {xs: '300px', sm: '360px', md: '400px'},
                    gap: '1.5rem',
                }}
                margin="normal"
                label={label}
                value={getValue (values, field1, field2, field3)}
                name={field1}
                disabled={disabled}
            >
            </TextField>
        </div>
    );
};

export default ArrivalMaterialTextFieldObject;