import axios from "axios";
import {apiVersionOrders, gatewayUrl, nameOrders} from "../../../../Constants";

const groupMaterialRestUrl = gatewayUrl + nameOrders + apiVersionOrders + '/organizations/group-materials'

export default class GroupMaterialService {

    static async getAll() {
        return axios.get(groupMaterialRestUrl);
    }

    static async getAllByOrganization() {
        return axios.get(groupMaterialRestUrl + '/get-all-by-organization');
    }

    static async update(data) {
        await axios.post(groupMaterialRestUrl + '/' + data.id, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async save(data) {
        return await axios.post(groupMaterialRestUrl, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async delete(id) {
        await axios.delete(groupMaterialRestUrl + '/' + id);
    }
}