import React from 'react';
import {useParams} from "react-router-dom";
import IssuanceEstimateUnitTable from "../../../../components/UI/table/prodaction/estimate/IssuanceEstimateUnitTable";

const IssuancePieceMaterialsItem = () => {

    const params = useParams();
    let orderId = params.id;
    let orderNumber = params.order;

    return (
        <div>
            <h3>Выдача штучных материалов заказа: {params.order}</h3>
            <IssuanceEstimateUnitTable
                orderId={orderId}
                orderNumber={orderNumber}
            />
        </div>
    );
};

export default IssuancePieceMaterialsItem;