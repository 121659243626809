import React from 'react';
import {useParams} from "react-router-dom";
import ArrivalMaterialPriceUnitTable
    from "../../../../../components/UI/table/warehouse/material/arrival/ArrivalMaterialPriceUnitTable";

const ArrivalMaterialPriceUnit = () => {

    const params = useParams();

    return (
        <div>
            <ArrivalMaterialPriceUnitTable
                arrivalMaterialId={params.arrivalMaterialId}
                currentCurrency={params.currentCurrency}
                isBaseCurrency={params.isBaseCurrency}
                isAlternativeCurrency={params.isAlternativeCurrency}
                baseCurrency={params.baseCurrency}
                alternativeCurrency={params.alternativeCurrency}
                supplierId={params.supplierId}
                warehouseId={params.warehouseId}
            />
        </div>
    );
};

export default ArrivalMaterialPriceUnit;