import React, {useCallback, useEffect, useMemo, useState} from 'react';
import MaterialReactTable from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import {Button, Tooltip} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {Delete} from "@mui/icons-material";
import Box from "@mui/material/Box";

import PackagingUnitService from "../../../../../API/model-estimate/packaging/PackagingUnitService";
import PackagingSetService from "../../../../../API/model-estimate/packaging/PackagingSetService";
import {useNavigate} from "react-router-dom";

const PackagingItemTable = ({id, article}) => {

    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [isFullScreen, setIsFullScreen] = useState(false);

    const redirectPackagingSetsUnits = () => {
        navigate('/packaging-sets-units/' + id + '/' + article);
    };

    const redirectPackagingUnits = () => {
        navigate('/packaging-units/');
    };

    const redirectPackagingSets = () => {
        navigate('/packaging-sets/');
    };

    useEffect(() => {
        PackagingUnitService.getAllByPackagingSetId(id).then(function (response) {
            setData(response.data);
        })
            .catch(function (error) {
                console.log(error);
            });
    }, []);

    const handleDeleteRow = useCallback(
        (row) => {
            PackagingSetService.deletePackagingUnitById(id, row.original.id)
                .then(function () {
                    data.splice(row.index, 1);
                    setData([...data]);
                })
                .catch(function (error) {
                    console.log(error);
                })
        },
        [data],
    );

    const columns = useMemo(
        () => [
            {
                id: 'name',
                header: 'Наименование',
                accessorKey: 'name',
                minSize: 70,
            },
            {
                id: 'weight',
                header: 'Вес',
                accessorKey: 'weight',
                minSize: 15,
            },
            {
                header: 'id',
                id: 'id',
                accessorKey: 'id',
                enableHiding: false,
                enableColumnActions: false,
                enableGrouping: false,
                enableEditing: false,
            },
        ],
        [data, setData],
    );

    return (
        <>
            <MaterialReactTable
                muiTableBodyProps={{
                    sx: () => ({
                        '& tr:nth-of-type(odd)': {
                            backgroundColor: 'lightgray',
                        },
                    }),
                }}
                muiTableBodyCellProps={{
                    sx: {
                        p: '0px 0px 0px 0px',
                        m: '0px',
                    },
                }}
                muiTableBodyRowProps={{
                    sx: {
                        height: '10px',
                    },
                }}
                isFullScreen={isFullScreen}
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        size: 120,
                    },
                }}
                columns={columns}
                data={data}
                enableEditing
                renderRowActions={({row, table}) => (
                    <Box sx={{display: 'flex', gap: '1rem'}}>
                        <Tooltip arrow placement="right" title="Удалить">
                            <IconButton color="error" onClick={() => handleDeleteRow(row)}>
                                <Delete/>
                            </IconButton>
                        </Tooltip>
                    </Box>
                )}
                initialState={{
                    columnVisibility: {
                        id: false,
                    },
                    isFullScreen: false,
                    density: "compact",
                    pagination: {pageIndex: 0, pageSize: 20},
                }}
                localization={MRT_Localization_RU}
                renderTopToolbarCustomActions={() => (
                    <Box
                        sx={{display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap'}}
                    >
                        <Button
                            color="secondary"
                            onClick={redirectPackagingSetsUnits}
                            variant="contained"
                        >
                            Добавить ед. упаковки
                        </Button>
                        <Button
                            color="secondary"
                            onClick={redirectPackagingSets}
                            variant="contained"
                        >
                            Вернуться в комплекты упаковки
                        </Button>
                        <Button
                            color="secondary"
                            onClick={redirectPackagingUnits}
                            variant="contained"
                        >
                            Редактировать ед. упаковки
                        </Button>
                    </Box>
                )}
            />
        </>
    );
};

export default PackagingItemTable;