import React, {useCallback, useMemo, useState} from 'react';
import MaterialReactTable from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import Box from "@mui/material/Box";
import CellPriceWithCurrency from "../../../../сell/CellPriceWithCurrency";
import {Link, useNavigate} from "react-router-dom";
import {Button, Tooltip} from "@mui/material";
import {Edit, Unarchive} from "@mui/icons-material";
import CellCheckboxAMPUTFixPrice from "../arrival/CellCheckboxAMPUTFixPrice";
import FilterUtil from "../../../../../../utils/common/FilterUtil";
import CellCheckbox from "../../../../сheckbox/CellCheckbox";
import DateTimeUtil from "../../../../../../utils/common/DateTimeUtil";
import DateRangeFilter from "../../../../filter/DateRangeFilter";

const SupplierMaterialTable = ({
                                   data,
                                   setData,
                                   handleAddSupplierMaterial,
                                   handleUpdateSupplierMaterial,
                                   isArchive,
                                   materialId,
                                   baseUnit,
                                   typeMaterial,
                                   allPrice,
                                   urlArchive,
                                   addArchive,
                                   isAll
                               }) => {

    const [isFullScreen, setIsFullScreen] = useState(false);
    const navigate = useNavigate();

    const redirectArchive = () => {
        navigate(urlArchive + '/' + materialId + '/' + baseUnit + '/' + typeMaterial);
    };

    const redirectMaterial = () => {
        navigate(isAll ? '/all-materials/' : '/materials/' + materialId + '/' + baseUnit + '/' + typeMaterial);
    };

    const sendToArchive = useCallback(
        (row) => {
            addArchive(row.original.id)
                .then(function () {
                    data.splice(row.index, 1);
                    setData([...data]);
                })
                .catch(function (error) {
                    console.log(error);
                })
        },
        [data],
    );

    const columns = useMemo(() => {
        const baseColumns = [
            {
                header: 'Поставщик',
                accessorKey: 'supplier.name',
                minSize: 150,
                Cell: ({cell, row}) => (
                    <li key={cell.getValue()}>
                        <Link
                            to={'/suppliers/' + data[row.index].supplier.id}>{cell.getValue()}</Link>
                    </li>
                ),
            },
            {
                header: 'Артикул поставщика',
                accessorKey: 'article',
                minSize: 150,
            },
            {
                id: 'basePrice',
                header: 'Баз. цена',
                accessorKey: 'basePrice.price',
                size: 8,
                Cell: ({row}) => {
                    const basePrice = row.original.basePrice || null;
                    return basePrice === null ? '' : (
                        <CellCheckboxAMPUTFixPrice
                            price={basePrice}
                            fixPrice={row.original.fixPrice}
                        />
                    );
                },
                minSize: 8,
                maxSize: 8,
            },
            {
                id: 'basePrice.dateTime',
                header: 'Дата',
                accessorKey: 'basePrice.dateTime',
                minSize: 10,
                maxSize: 10,
                Cell: ({cell}) => {
                    const value = cell.getValue();
                    return value === null || value === undefined ? '' : DateTimeUtil.dateTimeToString(value);
                },
                filterFn: (row, id, filterValue) => {
                    const startDate = filterValue?.startDate;
                    const endDate = filterValue?.endDate;
                    if (startDate && endDate) {
                        return (
                            DateTimeUtil.checkDateBefore(row.getValue(id), startDate) &&
                            DateTimeUtil.checkDateAfter(row.getValue(id), endDate)
                        );
                    }
                    return true;
                },
                Filter: ({column}) => <DateRangeFilter column={column}/>,
            },
        ];

        if (isArchive) {
            baseColumns.splice(0, 0, {
                id: 'current',
                header: 'Текущий',
                accessorKey: 'current',
                size: 8,
                enableEditing: false,
                filterVariant: 'multi-select',
                filterSelectOptions: FilterUtil.statusList,
                filterFn: (row, id, filterValue) => {
                    return FilterUtil.checkStatus(row.getValue(id), filterValue)
                },
                Cell: ({cell, row}) => <CellCheckbox
                    cell={cell}
                    disabled={true}
                />,
            });
        }

        if (allPrice) {
            baseColumns.splice(7, 0, {
                id: 'lastRealPrice',
                header: 'Посл. реальная цена',
                accessorKey: 'lastRealPrice.price',
                size: 8,
                Cell: ({row}) => <CellPriceWithCurrency
                    price={row.original.lastRealPrice}
                />,
                minSize: 10,
                maxSize: 10,
            });
        }

        if (allPrice) {
            baseColumns.splice(8, 0, {
                id: 'averageRealPrice',
                header: 'Ср. реальная цена',
                accessorKey: 'averageRealPrice.price',
                size: 8,
                Cell: ({row}) => <CellPriceWithCurrency
                    price={row.original.averageRealPrice}
                />,
                minSize: 10,
                maxSize: 10,
            });
        }

        if (allPrice) {
            baseColumns.splice(9, 0, {
                id: 'maxRealPrice',
                header: 'Макс. реальная цена',
                accessorKey: 'maxRealPrice.price',
                size: 8,
                Cell: ({row}) => <CellPriceWithCurrency
                    price={row.original.maxRealPrice}
                />,
                minSize: 10,
                maxSize: 10,
            });
        }

        return baseColumns;

    }, [data, isArchive]);

    return (
        <div>
            <MaterialReactTable
                muiTableBodyProps={{
                    sx: () => ({
                        '& tr:nth-of-type(odd)': {
                            backgroundColor: 'lightgray',
                        },
                    }),
                }}
                muiTableBodyCellProps={{
                    sx: {
                        p: '0px 0px 0px 10px',
                        m: '0px 0px 0px 0px',
                    },
                }}
                muiTableBodyRowProps={{
                    sx: {
                        height: '10px',
                    },
                }}
                isFullScreen={isFullScreen}
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        size: 15,
                        minSize: 15,
                    },
                }}
                columns={columns}
                data={data}
                rowHeight={13}
                columnSpacing={1}
                initialState={{
                    columnVisibility: {
                        id: false,
                    },
                    isFullScreen: false,
                    density: "compact",
                    pagination: {pageIndex: 0, pageSize: 20},
                }}
                enableEditing={!isArchive}
                renderRowActions={({row, table}) => (
                    <Box sx={{
                        display: 'flex',
                        gap: '0.1rem',
                        justifyContent: 'flex-start',
                        maxWidth: '50px'
                    }}>
                        <Tooltip arrow placement="left" title="Редактировать">
                            <Button size="small" sx={{minWidth: 'auto', marginLeft: 'auto'}} color="pencil"
                                    onClick={() => handleUpdateSupplierMaterial(row)}>
                                <Edit sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                            </Button>
                        </Tooltip>
                        <Tooltip arrow placement="right" title="Поместить в архив">
                            <Button size="small" sx={{minWidth: 'auto', marginLeft: 'auto'}} color="error"
                                    onClick={() => sendToArchive(row)}>
                                <Unarchive sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                            </Button>
                        </Tooltip>
                    </Box>
                )}
                localization={MRT_Localization_RU}
                renderTopToolbarCustomActions={({table}) => {
                    return (
                        <div>
                            <Box
                                sx={{display: 'flex', gap: '0.5rem', p: '0.5rem', flexWrap: 'wrap'}}
                            >
                                {!isArchive && <Button
                                    size="small"
                                    color="success"
                                    onClick={handleAddSupplierMaterial}
                                    variant="contained"
                                >
                                    Добавить базовую цену поставщика
                                </Button>}
                                {!isArchive && <Button
                                    size="small"
                                    color="secondary"
                                    onClick={redirectArchive}
                                    variant="contained"
                                >
                                    Архив цен
                                </Button>}
                                {isArchive && <Button
                                    size="small"
                                    color="secondary"
                                    onClick={redirectMaterial}
                                    variant="contained"
                                >
                                    Вернуться к материалу
                                </Button>}
                            </Box>
                        </div>
                    );
                }}

            />
        </div>
    );
};

export default SupplierMaterialTable;