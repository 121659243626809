export default class DetailUtil {

    static getMinAndMax(data) {
        let min_numberCutting = data[0].numberCutting;
        let max_numberCutting = data[0].numberCutting;

        for (let item of data) {

            let numberCutting = item.numberCutting;
            min_numberCutting = Math.min(min_numberCutting, numberCutting);
            max_numberCutting = Math.max(max_numberCutting, numberCutting);
        }

        return {
            min_numberCutting,
            max_numberCutting
        };
    }

    static checkStartCutting(data) {
        for (let item of data) {
            if (item.workingCuttingMapState === 'CUTTING_STARTED') {
               return true
            }
        }
    }
}