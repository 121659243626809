import axios from "axios";
import ObjectUtil from "../../../../utils/common/ObjectUtil";
import {apiVersionOrders, gatewayUrl, nameOrders} from "../../../../Constants";

const arrivalMaterialRestUrl = gatewayUrl + nameOrders + apiVersionOrders + '/organizations/arrival-materials'

export default class ArrivalMaterialService {

    static async getAll(method) {
        return axios.get(arrivalMaterialRestUrl + '/' + method, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async createOrUpdate(data, currencyId, supplierId, warehouseId) {
        if (data.id !== null) {
            return await ArrivalMaterialService.update(data, warehouseId);
        } else {
            return await ArrivalMaterialService.save(data, currencyId, supplierId, warehouseId)
        }
    }

    static async update(data, warehouseId) {
        return await axios.post(arrivalMaterialRestUrl + '/' + data.id + '?warehouseId=' + warehouseId,
            JSON.stringify(data), {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
    }

    static async save(data, currencyId, supplierId, warehouseId) {
        return await axios.post(arrivalMaterialRestUrl + "?currencyId=" + currencyId + '&supplierId=' + supplierId +
            '&warehouseId=' + warehouseId, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async delete(id) {
        await axios.delete(arrivalMaterialRestUrl + '/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async performMaterialReceipt(id, warehouseId) {
        await axios.post(arrivalMaterialRestUrl + '/perform-material-receipt/' + id + '?warehouseId=' + warehouseId, [], {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async createByReserves(ids, arrivalMaterial, currencyId, warehouseId) {
        let data = ObjectUtil.createArrivalMaterialRequestObjectIds(ids, arrivalMaterial);
        return await axios.post(arrivalMaterialRestUrl + '/create-by-reserves' + "?currencyId=" + currencyId +
            '&warehouseId=' + warehouseId, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async merge(ids) {
        let data = ObjectUtil.createRequestObjectIds(ids);
        return await axios.post(arrivalMaterialRestUrl + '/merge-arrival-material', JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
}