import React, {useCallback, useMemo, useState} from 'react';
import MaterialReactTable from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import Box from "@mui/material/Box";
import {Button, Tooltip} from "@mui/material";
import {Delete, Edit} from "@mui/icons-material";

import {useNavigate} from "react-router-dom";
import DateTimeUtil from "../../../../../utils/common/DateTimeUtil";
import DateRangeFilter from "../../../filter/DateRangeFilter";
import ModalUpdateStatus from "../ModalUpdateStatus";
import OptimizationService from "../../../../../API/orders/optimization/organization/OptimizationService";
import {optimizationStateStatuses} from "../../../../../data/OptizationState";
import NavigateButton from "../NavigateButton";
import ModalOpenCreatePlanOrOptimization from "../plans/ModalOpenCreatePlanOrOptimization";
import ModalConfirm from "../../../modal/ModalConfirm";
import WorkingCuttingMapService from "../../../../../API/orders/cutting/cuttingmap/WorkingCuttingMapService";

const OptimizationTable = ({
                               data,
                               setData,
                               selectedIds,
                               setSelectedIds,
                               isCreateCreateOptimization,
                               isRedirectPlans,
                               isCalculation
                           }) => {

        const [updateModalOpen, setUpdateModalOpen] = useState(false);
        const [createCuttingMapsModalOpen, setCreateCuttingMapsModalOpen] = useState(false);

        const [currentEditRowData, setCurrentEditRowData] = useState(null);
        const [currentAllowedStatuses, setCurrentAllowedStatuses] = useState(['ARCHIVE']);
        const [currentEditRowId, setCurrentEditRowId] = useState(null);
        const [rowSelection, setRowSelection] = useState({});
        const [addModalOpen, setAddModalOpen] = useState(false);
        const [newRowData, setNewRowData] = useState(null);
        const [isCalculationCuttingMaps, setIsCalculationCuttingMaps] = useState(null);

        const handleDeleteRow = useCallback(
            (row) => {
                const id = row.original.id;
                OptimizationService.delete(id)
                    .then(function () {
                        data.splice(row.index, 1);
                        setData([...data]);
                    })
                    .catch(function (error) {
                        console.log(error);
                    })
            },
            [data],
        );

        const handleUpdateModel = useCallback(
            (row) => {
                setCurrentAllowedStatuses([...currentAllowedStatuses, row.original.optimizationState]);
                setCurrentEditRowData(row.original);
                setCurrentEditRowId(row.index);
                setUpdateModalOpen(true);
            },
            [data, currentEditRowData, setCurrentEditRowData],
        );

        const handleClose = () => {
            currentAllowedStatuses.splice(1, 1);
            setUpdateModalOpen(false)
        };

        const navigate = useNavigate();

        const redirectOrders = () => {
            navigate('/organization/orders');
        };

        const redirectPlans = () => {
            navigate('/organization/plans');
        };

        const redirectRequestsCutting = () => {
            navigate('/organization/requests-cutting');
        };

        const redirectRequestsCuttingCalculation = () => {
            navigate('/organization/requests-cutting/Calculation');
        };

        const redirectWorkingCuttingMaps = () => {
            navigate('/organization/working-cutting-maps');
        };

        const redirectWorkingCuttingMapsCalculation = () => {
            navigate('/organization/working-cutting-maps-calculation');
        };

        const handleCreateOptimization = () => {
            const optimization = {
                id: null,
                number: "OP",
                optimizationState: 'EXPECTATION',
                optimized: null,
            }
            setNewRowData(optimization);
            setAddModalOpen(true)
        };

        const handleSubmitCreateCuttingMaps = async () => {
            const optimizationState = isCalculation ? 'CREATED_CUTTING_MAP_CALCULATION' : 'CREATED_CUTTING_MAP';
            WorkingCuttingMapService.createByIdsOptimization(selectedIds, isCalculation,)
                .then(function () {
                    const newData = data.map(item => {
                        if (selectedIds.includes(item.id)) {
                            return {
                                ...item,
                                optimizationState: optimizationState
                            };
                        } else {
                            return item;
                        }
                    });
                    setData(newData);
                })
                .catch(function (error) {
                    console.log(error);
                })
            handleCloseModalConfirmCreateCuttingMaps()
        }

        const handleCloseModalConfirmCreateCuttingMaps = () => {
            setCreateCuttingMapsModalOpen(false)
        };

        const createOptimization = () => {
            OptimizationService.createOptimization(newRowData,).then(function (response) {
                handleCreateNewRow(response.data);
                setAddModalOpen(false)
            })
                .catch(function (error) {
                    console.log(error);
                });
        };

        const handleCreateNewRow = (values) => {
            data.push(values);
            setData([...data]);
        };

        const columns = useMemo(
            () => [
                {
                    id: 'number',
                    header: 'Номер',
                    accessorKey: 'number',
                    minSize: 20,
                    maxSize: 20,
                },
                {
                    id: 'requests_cutting',
                    header: 'Заявки на раскрой',
                    accessorKey: 'id',
                    minSize: 55,
                    maxSize: 55,
                    Cell: ({row}) => (
                        <div
                            style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: '100px'}}>
                            <NavigateButton
                                row={row}
                                url={'/organization/requests-cutting/optimization/'}
                                suffix={row.original.number}
                            />
                        </div>
                    ),
                },
                {
                    id: 'details_cutting',
                    header: 'Детали на раскрой',
                    accessorKey: 'id',
                    minSize: 55,
                    maxSize: 55,
                    Cell: ({row}) => (
                        <div
                            style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: '100px'}}>
                            <NavigateButton
                                row={row}
                                url={'/organization/details/optimization/'}
                                suffix={row.original.number}
                            />
                        </div>
                    ),
                },
                {
                    accessorFn: (row) => {
                        if (row.optimized === null) {
                        } else {
                            return new Date(row.optimized)
                        }
                    },
                    id: 'optimized',
                    header: 'Дата оптимизации',
                    sortingFn: 'optimized',
                    size: 10,
                    Cell: ({cell}) => {
                        if (cell.getValue() !== undefined) {
                            return cell.getValue().toLocaleDateString();
                        }
                    },
                    filterFn: (row, id, filterValue) => {
                        const startDate = filterValue?.startDate;
                        const endDate = filterValue?.endDate;
                        if (startDate && endDate) {
                            return (
                                DateTimeUtil.checkDateBefore(row.getValue(id), startDate) &&
                                DateTimeUtil.checkDateAfter(row.getValue(id), endDate)
                            );
                        }
                        return true;
                    },
                    Filter: ({column}) => <DateRangeFilter
                        column={column}
                    />,
                },
                {
                    id: 'optimizationState',
                    header: 'Статус',
                    accessorKey: 'optimizationState',
                    minSize: 55,
                    maxSize: 55,
                    Cell: ({cell}) => optimizationStateStatuses[cell.getValue()],
                },
                {
                    header: 'id',
                    accessorKey: 'id',
                    enableColumnActions: false,
                    enableGrouping: false,
                    enableEditing: false,
                    size: 2,
                    maxSize: 2,
                },
            ],
            [data, setData],
        );

        return (
            <div>
                <MaterialReactTable
                    muiTableBodyProps={{
                        sx: () => ({
                            '& tr:nth-of-type(odd)': {
                                backgroundColor: 'lightgray',
                            },
                        }),
                    }}
                    muiTableBodyCellProps={{
                        sx: {
                            p: '0px 0px 0px 10px',
                            m: '0px 0px 0px 0px',
                        },
                    }}
                    muiTableBodyRowProps={{
                        sx: {
                            height: '10px',
                        },
                    }}
                    displayColumnDefOptions={{
                        'mrt-row-actions': {
                            muiTableHeadCellProps: {
                                align: 'center',
                            },
                            size: 15,
                            minSize: 15,
                        },
                    }}
                    columns={columns}
                    data={data}
                    rowHeight={13}
                    columnSpacing={1}
                    initialState={{
                        columnVisibility: {
                            id: false,
                        },
                        isFullScreen: false,
                        density: "compact",
                        pagination: {pageIndex: 0, pageSize: 50},
                    }}
                    enableEditing
                    onRowSelectionChange={setRowSelection}
                    state={{rowSelection}}
                    enableRowSelection
                    getRowId={(originalRow) => originalRow.id}
                    selectionPadding={1}
                    selectionCss={{
                        backgroundColor: 'lightblue',
                        borderWidth: '2px',
                        borderStyle: 'solid',
                        borderColor: 'blue',
                        borderRadius: '4px',
                    }}
                    renderRowActions={({row, table}) => (
                        <Box sx={{
                            display: 'flex',
                            gap: '0.1rem',
                            justifyContent: 'flex-start',
                            maxWidth: '50px'
                        }}>
                            <Tooltip arrow placement="left" title="Редактировать">
                                <Button size="small" sx={{minWidth: 'auto', marginLeft: 'auto'}} color="pencil"
                                        onClick={() => handleUpdateModel(row)}>
                                    <Edit sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                                </Button>
                            </Tooltip>
                            {(row.original.optimizationState === 'CALCULATION' || row.original.optimizationState === 'EXPECTATION') ?
                                <Tooltip arrow placement="right" title="Удалить">
                                    <Button
                                        size="small"
                                        sx={{minWidth: 'auto', marginLeft: 'auto'}} color="error"
                                        onClick={() => handleDeleteRow(row)}>
                                        <Delete sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                                    </Button>
                                </Tooltip> :
                                <Button
                                    size="small"
                                    disabled={true}
                                    sx={{minWidth: 'auto', marginLeft: 'auto'}} color="error"
                                    onClick={() => handleDeleteRow(row)}>
                                    <Delete sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                                </Button>
                            }
                        </Box>
                    )}
                    localization={MRT_Localization_RU}
                    renderTopToolbarCustomActions={({table}) => {
                        const createCuttingMaps = (cuttingMapState) => {
                            setIsCalculationCuttingMaps(cuttingMapState)
                            setCreateCuttingMapsModalOpen(true)
                            const selectedRows = table.getSelectedRowModel().flatRows;
                            const selectedIds = selectedRows.map((row) => row.getValue('id'));
                            setSelectedIds(selectedIds);
                        };

                        return (
                            <div>
                                <Box
                                    sx={{display: 'flex', gap: '0.5rem', p: '0.5rem', flexWrap: 'wrap'}}
                                >
                                    <Button
                                        size="small"
                                        color="secondary"
                                        onClick={redirectOrders}
                                        variant="contained"
                                    >
                                        Заказы
                                    </Button>
                                    {!isCalculation && <Button
                                        size="small"
                                        color="secondary"
                                        onClick={redirectRequestsCutting}
                                        variant="contained"
                                    >
                                        Заявки на раскрой
                                    </Button>}
                                    {isCalculation && <Button
                                        size="small"
                                        color="secondary"
                                        onClick={redirectRequestsCuttingCalculation}
                                        variant="contained"
                                    >
                                        Заявки на просчет
                                    </Button>}
                                    {isRedirectPlans && <Button
                                        size="small"
                                        color="secondary"
                                        onClick={redirectPlans}
                                        variant="contained"
                                    >
                                        Планы
                                    </Button>}
                                    {!isCalculation && <Button
                                        size="small"
                                        color="secondary"
                                        onClick={redirectWorkingCuttingMaps}
                                        variant="contained"
                                    >
                                        Карты раскроя
                                    </Button>}
                                    {isCalculation && <Button
                                        size="small"
                                        color="secondary"
                                        onClick={redirectWorkingCuttingMapsCalculation}
                                        variant="contained"
                                    >
                                        Расчетные карты раскроя
                                    </Button>}
                                    {isCreateCreateOptimization && <Button
                                        size="small"
                                        color="success"
                                        onClick={handleCreateOptimization}
                                        variant="contained"
                                    >
                                        Создать оптимизацию
                                    </Button>}
                                    {!isCalculation && <Button
                                        size="small"
                                        color="success"
                                        disabled={table.getIsSomeRowsSelected() ? false : !table.getIsAllRowsSelected()}
                                        onClick={() => createCuttingMaps('EXPECTATION')}
                                        variant="contained"
                                    >
                                        Создать карты раскроя
                                    </Button>}
                                    {isCalculation && <Button
                                        size="small"
                                        color="success"
                                        disabled={table.getIsSomeRowsSelected() ? false : !table.getIsAllRowsSelected()}
                                        onClick={() => createCuttingMaps('CALCULATION')}
                                        variant="contained"
                                    >
                                        Создать расчетные карты раскроя
                                    </Button>}
                                </Box>
                            </div>
                        );
                    }}
                />
                <ModalUpdateStatus
                    title={'Обновить статус'}
                    data={data}
                    setData={setData}
                    open={updateModalOpen}
                    handleClose={handleClose}
                    currentEditRowId={currentEditRowId}
                    values={currentEditRowData}
                    setValues={setCurrentEditRowData}
                    currentAllowedStatuses={currentAllowedStatuses}
                    statusesList={optimizationStateStatuses}
                    field={'optimizationState'}
                    unableStatus={OptimizationService.unableStatus}
                />
                <ModalOpenCreatePlanOrOptimization
                    label={'Название оптимизации'}
                    field={'number'}
                    values={newRowData}
                    setValues={setNewRowData}
                    title={'Создать оптимизацию'}
                    modalOpen={addModalOpen}
                    handleClose={() => setAddModalOpen(false)}
                    handleSubmit={createOptimization}
                />
                <ModalConfirm
                    title={"Подтвердить создание карт?"}
                    modalOpen={createCuttingMapsModalOpen}
                    handleClose={handleCloseModalConfirmCreateCuttingMaps}
                    handleSubmit={handleSubmitCreateCuttingMaps}
                    action={"Подтвердить"}/>
            </div>
        );
    }
;

export default OptimizationTable;