import React, {useEffect, useMemo, useState} from 'react';
import MaterialReactTable from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import Box from "@mui/material/Box";
import CustomerMaterialEstimateUnitService
    from "../../../API/orders/estimate/customer/CustomerMaterialEstimateUnitService";
import {useNavigate} from "react-router-dom";
import {Button} from "@mui/material";
import CustomerEstimateService from "../../../API/orders/estimate/customer/CustomerEstimateService";

const CustomerEstimateTableItem = ({
                                       estimateId, orderId, customerOrderNumber, orderHeaderId, year, isButtonPurchase,
                                       isActual, enableEditing, inWork
                                   }) => {

    const [data, setData] = useState([]);
    const [rowSelection, setRowSelection] = useState({});
    const [change, setChange] = useState(false);

    const navigate = useNavigate();

    const createOffers = async (orderId) => {

        CustomerEstimateService.createOffers(orderId)
            .then(function () {
            })
            .catch(function (error) {
                console.log(error);
            })
    };

    const redirectOffers = (orderId, customerOrderNumber) => {
        navigate('/customer/orders/' + orderId + '/' + customerOrderNumber + '/estimates');
    };

    useEffect(() => {

        CustomerMaterialEstimateUnitService.getAllByOrderId(orderId).then(function (response) {
            const data = response.data;
            setData(data);
        })
            .catch(function (error) {
                console.log(error);
            });
    }, [change]);

    const columns = useMemo(
        () => [
            {
                id: 'articleNumber',
                header: 'Артикул',
                accessorKey: 'materialArticleNumber',
                minSize: 15,
                maxSize: 25,
            },
            {
                id: 'materialName',
                header: 'Найменование',
                accessorKey: 'materialName',
                minSize: 50,
                maxSize: 50,
                enableEditing: false,
            },
            {
                id: 'quantity',
                header: 'Кол.',
                accessorKey: 'quantity',
                minSize: 5,
                maxSize: 5,
            },
            {
                id: 'unit.name',
                header: 'Ед. изм.',
                accessorKey: 'unitName',
                minSize: 5,
                maxSize: 5,
            },
            {
                header: 'id',
                accessorKey: 'id',
                enableColumnActions: false,
                enableGrouping: false,
                enableEditing: false,
                size: 2,
            },
        ],
        [data, setData],
    );

    return (
        <div>
            <MaterialReactTable
                muiTableBodyProps={{
                    sx: () => ({
                        '& tr:nth-of-type(odd)': {
                            backgroundColor: 'lightgray',
                        },
                    }),
                }}
                muiTableBodyCellProps={{
                    sx: {
                        p: '0px 0px 0px 10px',
                        m: '0px 0px 0px 0px',
                    },
                }}
                muiTableBodyRowProps={{
                    sx: {
                        height: '10px',
                    },
                }}
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        size: 15,
                        minSize: 15,
                    },
                }}
                columns={columns}
                data={data}
                rowHeight={13}
                columnSpacing={1}
                initialState={{
                    columnVisibility: {
                        id: false,
                    },
                    isFullScreen: false,
                    density: "compact",
                    pagination: {pageIndex: 0, pageSize: 50},
                }}
                enableEditing={false}
                onRowSelectionChange={setRowSelection}
                state={{rowSelection}}
                enableRowSelection={false}
                getRowId={(originalRow) => originalRow.id}
                selectionPadding={1}
                selectionCss={{
                    backgroundColor: 'lightblue',
                    borderWidth: '2px',
                    borderStyle: 'solid',
                    borderColor: 'blue',
                    borderRadius: '4px',
                }}
                localization={MRT_Localization_RU}
                renderTopToolbarCustomActions={({table}) => {
                    const setCurrency = (typeCurrentCurrency) => {
                    };

                    return (
                        <div>
                            <Box
                                sx={{
                                    display: 'flex',
                                    gap: '0.5rem',
                                    paddingLeft: '0.5rem',
                                    paddingTop: '0.5rem',
                                    paddingBottom: '0.2rem',
                                    flexWrap: 'wrap',
                                }}
                            >
                            </Box>
                            <Box
                                sx={{display: 'flex', gap: '0.5rem', p: '0.5rem', flexWrap: 'wrap'}}
                            >
                                {<Button
                                    size="small"
                                    color="success"
                                    onClick={() => createOffers(orderId)}
                                    variant="contained"
                                >
                                    Расчитать предложения
                                </Button>}
                                {<Button
                                    size="small"
                                    color="success"
                                    onClick={() => redirectOffers(orderId, customerOrderNumber)}
                                    variant="contained"
                                >
                                    Смотреть предложения
                                </Button>}
                            </Box>
                        </div>
                    );
                }}
            />
        </div>
    );
};

export default CustomerEstimateTableItem;

