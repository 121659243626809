import axios from "axios";
import {apiVersionOrders, gatewayUrl, nameOrders} from "../../../../Constants";

const materialEstimateUnitUrl = gatewayUrl + nameOrders + apiVersionOrders + '/customers/material-estimate-units'

export default class CustomerMaterialEstimateUnitService {

    static async getAllByOrderId(orderId) {
        return axios.get(materialEstimateUnitUrl + '/by-order-id?orderId=' + orderId);
    }

    static async getAllByEstimateOrganization(estimateId) {
        return axios.get(materialEstimateUnitUrl + '/by-estimate-id' + '?estimateId=' + estimateId);
    }
}