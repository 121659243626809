import axios from "axios";
import {apiVersionModelEstimate, gatewayUrl, modelEstimate} from "../../../Constants";

const modelRestUrl = gatewayUrl + modelEstimate + apiVersionModelEstimate + '/models'

export default class ModelService {

    static async getAll() {
        return axios.get(modelRestUrl);
    }

    static async getAllUnableByBasicModelId(basicModelId) {
        return axios.get(modelRestUrl + '/unable' + '?basicModelId=' + basicModelId);
    }

    static async getAllCheckForOrder(orderId, idHeader) {
        return axios.get(modelRestUrl + '/check-for-order/' + orderId + '?idHeader=' + idHeader);
    }

    static async update(data, modelHeaderId) {
        return await axios.post(modelRestUrl + '/' + data.id + '/with-model-headers' + '?modelHeaderId=' + modelHeaderId, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async save(data, modelHeaderId) {
        return await axios.post(modelRestUrl + '?modelHeaderId=' + modelHeaderId, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async delete(id) {
        await axios.delete(modelRestUrl + '/' + id);
    }

    static async unable(id, status) {
        return axios.patch(modelRestUrl + '/' + id + '/unable?status=' + status, [], {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async setPackagingSetById(id, packagingSetId) {
        return await axios.post(modelRestUrl + '/' + id + "/packaging-sets/" + packagingSetId, [], {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async deletePackagingSet(idModel) {
        return axios.patch(modelRestUrl + '/' + idModel + '/packaging-sets', [], {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
}