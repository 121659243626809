import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import WarehouseMaterialSettingsService from "../../../API/orders/warehouse/WarehouseMaterialSettingsService";
import IssuanceService from "../../../API/orders/prodaction.issueedge/issuance/IssuanceService";
import IssuanceWarehouseMaterialTable from "../../../components/UI/table/prodaction/issue/IssuanceWarehouseMaterialTable";

const IssuanceIssuingEdgesItem = () => {

    const params = useParams();
    const [data, setData] = useState([]);
    const [baseCurrency, setBaseCurrency] = useState("");
    const [alternativeCurrency, setAlternativeCurrency] = useState("");
    const id = params.id

    useEffect(() => {

        WarehouseMaterialSettingsService.getWithBaseAndAlternativeCurrency().then(function (response) {
            let data = response.data;
            setBaseCurrency(data.baseCurrency.curAbbreviation);
            setAlternativeCurrency(data.alternativeCurrency.curAbbreviation);
        })
            .catch(function (error) {
                console.log(error);
            });
        IssuanceService.getAllByIssuingEdgeItemId(id).then(function (response) {
            const data = response.data;
            setData(data);
        })
            .catch(function (error) {
                console.log(error);
            });
    }, []);

    return (
        <div>
            <IssuanceWarehouseMaterialTable
                data={data}
                setData={setData}
                baseCurrency={baseCurrency}
                alternativeCurrency={alternativeCurrency}
            />
        </div>
    );
};

export default IssuanceIssuingEdgesItem;