import React from 'react';
import ArrivalMaterialTable from "../../../../../components/UI/table/warehouse/material/arrival/ArrivalMaterialTable";

const ArrivalMaterial = () => {

    return (
        <div>
            <ArrivalMaterialTable
                isCreateOrUpdate={true}
                method={'not-archive'}
            />
        </div>
    );
};

export default ArrivalMaterial;