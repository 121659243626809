import React, {useState} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import UploadFilesForm from "../../../../form/UploadFilesForm";

const ModalAddDetailOrRequestsCuttingForFiles = ({
                                                     upload,
                                                     title,
                                                     labelForm,
                                                     modalOpen,
                                                     handleClose,
                                                     requestsCuttingId,
                                                     change,
                                                     setChange,
                                                     orderId,
                                                     requestsCutting,
                                                     calculationRequestsCutting
                                                 }) => {

        const [selectedFiles, setSelectedFiles] = useState([]);

        const handleUpload = async (event) => {
            event.preventDefault();
            upload(selectedFiles, calculationRequestsCutting, requestsCutting ? requestsCuttingId : orderId)
                .then(function () {
                    setSelectedFiles([]);
                    setChange(!change)
                    event.target.reset();
                    handleClose();
                })
                .catch(function (error) {
                    console.log(error);
                    event.target.reset();
                })
        }

        return (
            <div>
                <Dialog open={modalOpen} onClose={handleClose}>
                    <DialogTitle>{title}</DialogTitle>
                    <DialogContent>
                        <DialogActions sx={{display: "flex", flexDirection: "column", alignItems: "flex-end"}}>
                            <UploadFilesForm
                                handleUpload={handleUpload}
                                selectedFiles={selectedFiles}
                                setSelectedFiles={setSelectedFiles}
                                label={labelForm}
                            />
                            <Button size="small" color="secondary" variant="contained" onClick={handleClose} sx={{
                                marginTop: "7px",
                                marginRight: "10px",
                            }}>
                                Выйти
                            </Button>
                        </DialogActions>
                    </DialogContent>
                </Dialog>
            </div>
        );
    }
;

export default ModalAddDetailOrRequestsCuttingForFiles;