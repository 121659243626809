import React, {useState} from 'react';
import FileService from "../../API/orders/orders/organization/FileService";
import UploadFilesForm from "./UploadFilesForm";

const UploadOrderFilesForm = ({label, year, nameOrder, orderId, change, setChange, fileNameType}) => {

    const [selectedFiles, setSelectedFiles] = useState([]);

    const handleUpload = async (event) => {
        event.preventDefault();

        FileService.uploadFiles(year, nameOrder, orderId, fileNameType, selectedFiles)
            .then(function () {
                setChange(!change)
                setSelectedFiles([]);
                event.target.reset();
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    return (
        <div>
            <UploadFilesForm
                handleUpload={handleUpload}
                selectedFiles={selectedFiles}
                setSelectedFiles={setSelectedFiles}
                label={label}
            />
        </div>
    );
};

export default UploadOrderFilesForm;