import axios from "axios";
import {apiVersionModelEstimate, gatewayUrl, modelEstimate} from "../../../Constants";

const packagingSetRestUrl = gatewayUrl + modelEstimate + apiVersionModelEstimate + '/packaging-sets'

export default class PackagingSetService {

    static async getAll() {
        return axios.get(packagingSetRestUrl);
    }

    static async getAllPackagingSetCheckForModelId(modelId) {
        return axios.get(packagingSetRestUrl + '/models/' + modelId);
    }

    static async update(data) {
        await axios.post(packagingSetRestUrl + '/' + +data.id, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async save(data) {
        return await axios.post(packagingSetRestUrl, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async delete(id) {
        await axios.delete(packagingSetRestUrl + '/' + id);
    }

    static async deletePackagingUnitById(id, packagingUnitId) {
        await axios.delete(packagingSetRestUrl + '/' + id + '/units/' + packagingUnitId);
    }

    static async addPackagingUnit(id, packagingUnitId) {
        await axios.patch(packagingSetRestUrl + '/' + id + '/units/' + packagingUnitId, [], {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
}