import axios from "axios";
import {apiVersionOrders, gatewayUrl, nameOrders} from "../../../Constants";

const coefficientExchangeRateRestUrl = gatewayUrl + nameOrders + apiVersionOrders + '/organizations/coefficient-exchange-rates'

export default class CoefficientExchangeRateService {

    static async getAllBySupplierId(supplierId) {
        return axios.get(coefficientExchangeRateRestUrl + '/by-supplierId?supplierId=' + supplierId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
}