import React, {useCallback, useMemo, useState} from 'react';
import MaterialReactTable from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import Box from "@mui/material/Box";
import {Button, Tooltip} from "@mui/material";
import Delete from "@mui/icons-material/DeleteOutlined";
import {Edit} from "@mui/icons-material";
import {constantsErrorMessages} from "../../../../../Constants";
import ModalAddEdges from "./ModalAddEdges";
import OrderEdgeController from "../../../../../API/details/OrderEdgeController";
import CellShortenedName from "../../orders/organization/estimate/CellShortenedName";
import {gluing} from "../../../../../data/Gluing";
import ModalAlert from "../ModalAlert";
import {log} from "three/tsl";

const CustomerEdgesTable = ({
                                data,
                                isError,
                                setData,
                                enableEditing,
                                enableRowSelection,
                                orderId,
                            }) => {
    const rowInitValue = {material: {}, edgeType: Object.entries(gluing)[0][0]}

    const [addEdgeModalOpen, setAddEdgeModalOpen] = useState(false);
    const [alertData, setAlertData] = useState({});

    const [rowSelection, setRowSelection] = useState({});
    const [currentEditRowData, setCurrentEditRowData] = useState(rowInitValue);
    const [currentEditRowId, setCurrentEditRowId] = useState(null);
    const [isNew, setIsNew] = useState(true);

    const handleCloseAlert = () => {
        setAlertData((alert) => {
            return {...alert, open: false}
        })
    }
    const handleError = (error) => {
        setAlertData({
            type: 'error', open: true, message: constantsErrorMessages[error.response.status] || error.message
        })
    }
    const handleClose = () => {
        setAddEdgeModalOpen(false)
        setCurrentEditRowData(rowInitValue)
        setIsNew(true)
    }

    const handleUpdate = (data) => {
        OrderEdgeController.edit(currentEditRowId, data)
            .then(function (response) {
                setData(prev => prev.map(el => el.id === response.data.id ? response.data : el))
            })
            .catch(function (error) {
                handleError(error)
            })
        handleClose()
    }

    const handleDeleteDetail = (row) => {
        OrderEdgeController.delete(row.original.id)
            .then(function () {
                setData(prev => prev.filter(el => el.id !== row.original.id))
            })
            .catch(function (error) {
                handleError(error)
            })
    };

    const handleSubmit = (data) => {
        OrderEdgeController.save(orderId, data)
            .then(function (response) {
                setData(prev => [...prev, response.data])
            })
            .catch(function (error) {
                handleError(error)
            })
        handleClose()
    }

    const handleAddEdge = () => {
        setAddEdgeModalOpen(true)
    };

    const handleEditEdge = (row) => {
        setCurrentEditRowData({
            material: {
                articleNumber: row.materialArticleNumber,
                id: row.materialId,
                name: row.materialName
            },
            edgeType: row.edgeType
        })
        setAddEdgeModalOpen(true)
        setCurrentEditRowId(row.id);
        setIsNew(false);
    };

    const columns = useMemo(() => {
        const baseColumns = [
            {
                header: 'Артикул',
                accessorKey: 'materialArticleNumber',
                minSize: 50,
                maxSize: 75,
                Cell: ({cell}) => <CellShortenedName
                    value={cell.getValue()}
                    numberOfCharacters={15}
                />,
            },
            {
                header: 'Найменование', accessorKey: 'materialName', minSize: 600,
            },
            {
                header: 'Тип поклейки', accessorKey: 'edgeType', minSize: 80,
                Cell: ({cell}) => gluing[cell.getValue()],
            },
        ]

        return baseColumns;

    }, [data]);

    return (<div>
        <MaterialReactTable
            muiTableBodyProps={{
                sx: () => ({
                    '& tr:nth-of-type(odd)': {
                        backgroundColor: 'lightgray',
                    },
                }),
            }}
            muiTableBodyCellProps={{
                sx: {
                    p: '0px 0px 0px 10px', m: '0px 0px 0px 0px',
                },
            }}
            muiTableBodyRowProps={{
                sx: {
                    height: '10px',
                },
            }}

            displayColumnDefOptions={{
                'mrt-row-actions': {
                    muiTableHeadCellProps: {
                        align: 'center',

                    }, size: 15, minSize: 50,
                },
            }}
            columns={columns}
            data={isError ? [] : data}
            rowHeight={13}
            columnSpacing={1}
            enableEditing={enableEditing}
            onRowSelectionChange={setRowSelection}
            state={{rowSelection}}
            enableRowSelection={enableRowSelection}
            getRowId={(originalRow) => originalRow.id}
            selectionPadding={1}
            selectionCss={{
                backgroundColor: 'lightblue',
                borderWidth: '2px',
                borderStyle: 'solid',
                borderColor: 'blue',
                borderRadius: '4px',
            }}
            renderRowActions={({row, table}) => (<Box sx={{
                display: 'flex', gap: '0.1rem', justifyContent: 'flex-start', maxWidth: '50px'
            }}>
                <Tooltip arrow placement="left" title="Редактировать">
                    <Button size="small" sx={{minWidth: 'auto', marginLeft: 'auto'}} color="pencil"
                            onClick={() => handleEditEdge(row.original)}>
                        <Edit sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                    </Button>
                </Tooltip>
                <Tooltip arrow placement="right" title="Удалить">
                    <Button size="small" sx={{minWidth: 'auto', marginLeft: 'auto'}} color="error"
                            onClick={() => handleDeleteDetail(row)}>
                        <Delete sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                    </Button>
                </Tooltip>
            </Box>)}
            localization={MRT_Localization_RU}
            renderTopToolbarCustomActions={({table}) => {
                return (<div>
                    <Box
                        sx={{display: 'flex', gap: '0.5rem', p: '0.5rem', flexWrap: 'wrap'}}
                    >
                        <Button
                            size="small"
                            color="success"
                            onClick={handleAddEdge}
                            variant="contained"
                            disabled={isError}
                        >
                            Добавить кромку в заказ
                        </Button>
                    </Box>
                </div>);
            }}
        />
        <ModalAddEdges title={isNew ? "Добавить кромку" : "Изменить кромку"}
                       initValue={currentEditRowData}
                       open={addEdgeModalOpen}
                       onClose={handleClose}
                       handleSubmit={isNew ? handleSubmit : handleUpdate}
        />
        <ModalAlert data={alertData} onClose={handleCloseAlert}/>
    </div>);
};

export default CustomerEdgesTable;
