import axios from "axios";
import {apiVersionDetail, gatewayUrl, nameDetail} from "../../Constants";

const detailControllerRestUrl = gatewayUrl + nameDetail + apiVersionDetail + '/customers/orderEdges'

export default class OrderEdgeController {
    static async getAll(orderId) {
        return axios.get(`${detailControllerRestUrl}/by-order-id?orderId=${orderId}`);
    }

    static async get(orderEdgeId) {
        return axios.get(`${detailControllerRestUrl}/${orderEdgeId}`);
    }

    static async save(orderId, data) {
        return await axios.post(`${detailControllerRestUrl}?orderId=${orderId}`, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async delete(orderEdgeId) {

        return await axios.delete(`${detailControllerRestUrl}/${orderEdgeId}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async edit(orderEdgeId, data) {
        return await axios.put(`${detailControllerRestUrl}/${orderEdgeId}`, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
}
