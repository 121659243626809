import React, {useState} from 'react';
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import {Box, Button, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {COLORS} from "../../../../../../Constants";
import FormControl from "@mui/material/FormControl";
import {gluing} from "../../../../../../data/Gluing";
import {useNavigate} from "react-router-dom";
import {EditLocalStoragePagesData} from "../../../../../../utils/EditLocalStoragePagesData";

const EdgeModalContent = ({type, edgesData, detailData, onClose, onSubmit, editableElementId, openConfirmModal}) => {
    const navigate = useNavigate();
    const currEdgeId = type === "addEdge" ? edgesData[0]?.id : detailData.edges.find(el => el?.id === editableElementId).orderEdge?.id
    const [selectedValue, setSelectedValue] = useState(currEdgeId);

    const redirectEdges = () => {
        const pathname = `/customer/orders/${detailData.orderId}/N${detailData.orderId}`
        EditLocalStoragePagesData(pathname, "tab", "3")
        navigate(pathname);
    }

    return (
        <>
            <DialogTitle
                textAlign="center"> {edgesData.length === 0 ? "В заказе нет кромок" : type === "addEdge" ? "Добавить кромку" : "Изменить кромку"} </DialogTitle>
            <DialogContent style={{paddingBottom: 0}}>
                <FormControl component="fieldset">
                    <RadioGroup
                        aria-label="edge"
                        name="radio-buttons-group"
                    >
                        {edgesData.map((el, i) => (
                            <FormControlLabel
                                style={{borderBottom: "1px solid black", margin: 0}}
                                key={i} checked={selectedValue === el.id}
                                control={<Radio onChange={() => setSelectedValue(el.id)} sx={{
                                    padding: 0,
                                    '& .MuiSvgIcon-root': {
                                        fontSize: 15,
                                    },
                                }}/>}
                                label={<Box style={{display: "flex", alignItems: "center",}}>
                                    <Box style={{
                                        width: "13px",
                                        height: "13px",
                                        borderRadius: "4px",
                                        margin: "5px",
                                        backgroundColor: COLORS[el.color][400],

                                    }}/>
                                    <span style={{
                                        fontSize: "14px",
                                        width: "130px"
                                    }}>{el.materialArticleNumber}</span>
                                    <span style={{fontSize: "14px", width: "580px"}}>{el.materialName}</span>
                                    <span style={{fontSize: "14px", width: "70px"}}>{gluing[el.edgeType]}</span>
                                </Box>}>
                            </FormControlLabel>
                        ))}
                    </RadioGroup>
                    <Box style={{display: "flex", justifyContent: "flex-end", paddingTop: 20}}>
                        {type === "editEdge" && <Button
                            size="small"
                            color="secondary"
                            onClick={() => openConfirmModal("deleteEdge", editableElementId)}
                            variant="outlined"
                        >
                            Удалить кромку
                        </Button>}
                    </Box>
                </FormControl>
            </DialogContent>
            <DialogActions sx={{p: '1.25rem'}}>
                <Button size="small" onClick={onClose}> отменить </Button>
                <Button size="small" color="secondary"
                        onClick={() => edgesData.length === 0 ? redirectEdges() : onSubmit(selectedValue)}
                        variant="contained">
                    {edgesData.length === 0 ? "К добавлению кромок" : "выбрать"}
                </Button>
            </DialogActions>
        </>
    )
}
export default EdgeModalContent
