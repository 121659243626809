import React from 'react';
import CutFileTable from "../../../../components/UI/table/cut-file/CutFileTable";
import {useParams} from "react-router-dom";

const CutFile = () => {

    const params = useParams();

    return (
        <div>
            <CutFileTable
                cuttingMapId={params.workingCuttingMapId}
            />
        </div>
    );
};

export default CutFile;