import React, {useEffect, useMemo, useState} from 'react';
import MaterialReactTable from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import ModelProductionCostsService from "../../../../../API/model-estimate/estimate/ModelProductionCostsService";

const ModelProductionCosts = ({estimateId}) => {


    const [data, setData] = useState([]);

    useEffect(() => {

        ModelProductionCostsService.getAllByEstimateId(estimateId).then(function (response) {
            let data = response.data;
            setData(data);
        })
            .catch(function (error) {
                console.log(error);
            });
    }, []);

    const columns = useMemo(
        () => [
            {
                id: "nameMaterial",
                header: 'Материал',
                accessorKey: 'nameMaterial',
                enableEditing: false,
                enableGrouping: true,
                minSize: 35,
            },
            {
                header: 'Найменование операции',
                accessorKey: 'nameOperations',
                enableEditing: false,
                enableGrouping: true,
                minSize: 35,
            },
            {
                header: 'Ед.изм.',
                accessorKey: 'unit',
                enableColumnActions: false,
                enableEditing: false,
                size: 8,
            },
            {
                header: 'Объем в заказе',
                accessorKey: 'orderQuantity',
                enableColumnActions: false,
                enableEditing: false,
                minSize: 50,
            },
        ],
        [data, setData],
    );

    return (
        <div>
            <MaterialReactTable
                muiTableBodyProps={{
                    sx: () => ({
                        '& tr:nth-of-type(odd)': {
                            backgroundColor: 'lightgray',
                        },
                    }),
                }}
                muiTableBodyCellProps={{
                    sx: {
                        p: '0px 0px 0px 10px',
                        m: '0px 0px 0px 0px',
                    },
                }}
                muiTableBodyRowProps={{
                    sx: {
                        height: '10px',
                    },
                }}
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        size: 15,
                        minSize: 15,
                    },
                }}
                columns={columns}
                data={data}
                rowHeight={13}
                columnSpacing={1}
                enableExpanding={true}
                initialState={{
                    expanded: true,
                    columnVisibility: {
                        id: false,
                    },
                    isFullScreen: false,
                    density: "compact",
                    pagination: {pageIndex: 0, pageSize: 50},
                    grouping: ['nameMaterial'],
                }}
                getRowId={(originalRow) => originalRow.id}
                localization={MRT_Localization_RU}
            />
        </div>
    );
};

export default ModelProductionCosts;