import React from 'react';
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import TextFieldSimple from "../../../textfield/TextFieldSimple";

const ModalAddMaterialRetailIssuingEdge = ({
                                               open,
                                               onClose,
                                               titleModal,
                                               availability,
                                               issue,
                                               setIssue,
                                               submit
                                           }) => {

    return (
        <div>
            <Dialog open={open}>
                <DialogTitle textAlign="center">{titleModal}</DialogTitle>
                <DialogContent>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        '& > *': {
                            marginRight: '10px',
                        }
                    }}>
                        <TextFieldSimple
                            label="Наличие"
                            value={availability}
                            field={'availability'}
                            disabled={true}
                        />
                        <TextFieldSimple
                            label="Выдать"
                            value={issue}
                            setValue={setIssue}
                            field={'issue'}
                        />
                    </Box>
                </DialogContent>
                <DialogActions sx={{p: '1.25rem'}}>
                    <Button onClick={submit}>Выдать</Button>
                    <Button onClick={onClose}>Выйти</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ModalAddMaterialRetailIssuingEdge;