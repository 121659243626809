import {Box} from "@mui/material";
import {COLORS} from "../../../../../Constants";
import {gluing} from "../../../../../data/Gluing";

const EdgeContent = ({edgesData}) => {
    return <Box style={{
         display: "flex", flexDirection: "column", margin: "0 auto"
    }}>
        {edgesData.map((el, i) => (<Box style={{display: "flex", alignItems: "center",}} key={i}>
            <Box style={{
                width: "13px",
                height: "13px",
                borderRadius: "4px",
                margin: "4px",
                backgroundColor: COLORS[el.color][400],

            }}/>

            <span style={{fontSize: "16px", width: "130px"}}>{el.materialArticleNumber}</span>
            <span style={{fontSize: "16px", width: "600px"}}>{el.materialName}</span>
            <span style={{fontSize: "16px", width: "70px"}}>{gluing[el.edgeType]}</span>

        </Box>))}
    </Box>
}
export default EdgeContent
