import axios from "axios";
import {apiVersionOrders, gatewayUrl, nameOrders} from "../../../../Constants";

const restManufacturerUrl = gatewayUrl + nameOrders + apiVersionOrders + '/organizations/manufacturers'

export default class ManufacturerService {

    static async getAll() {
        return axios.get(restManufacturerUrl, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async getAllByOrganization() {
        return axios.get(restManufacturerUrl + '/organization', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
}