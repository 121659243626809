import React from 'react';
import ModelHeadersTable from "../../../components/UI/table/orders/organization/header/ModelHeadersTable";

const ModelHeaders = () => {
    return (
        <div>
            <ModelHeadersTable/>
        </div>
    );
};

export default ModelHeaders;