import React, {useEffect, useState} from 'react';
import OrderProductionTable from "../../../components/UI/table/orders/organization/production/OrderProductionTable";
import OrderService from "../../../API/orders/orders/organization/OrderService";
import OrderUtil from "../../../utils/orders/OrderUtil";
import TypeJobService from "../../../API/orders/orders/organization/TypeJobService";

const OrdersProduction = () => {

    const [data, setData] = useState([]);
    const [additionallyColumn, setAdditionallyColumn] = useState([]);
    const [customerList, setCustomerList] = useState([]);
    const [nameList, setNameList] = useState([]);
    const [attributeList, setAttributeList] = useState([]);
    const [planningList, setPlanningList] = useState([]);

    useEffect(() => {
        TypeJobService.getAllByOrganizationId().then(function (response) {
            const data = response.data
            data.sort((a, b) => b.outputPriority - a.outputPriority);
            setAdditionallyColumn(data);
        })
            .catch(function (error) {
                console.log(error);
            });

        OrderService.getOrdersProduction().then(function (response) {
            const data = response.data;
            setData(data);
            setCustomerList(OrderUtil.getListField(data, 'customerName'));
            setNameList(OrderUtil.getListField(data, 'header'));
            setAttributeList(OrderUtil.getStatuses(data, 'attributes'));
            setPlanningList(OrderUtil.getListField(data, 'state'));
        })
            .catch(function (error) {
                console.log(error);
            });
    }, []);

    return (
        <div>
            <OrderProductionTable
                data={data}
                setData={setData}
                customerList={customerList}
                nameList={nameList}
                attributeList={attributeList}
                planningList={planningList}
                isColumnPlan={true}
                additionallyColumn={additionallyColumn}
            />
        </div>
    );
};

export default OrdersProduction;