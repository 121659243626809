import React from 'react';
import RequestsCuttingTable from "../../../../components/UI/table/optimization/requestscutting/RequestsCuttingTable";
import RequestsCuttingService from "../../../../API/orders/optimization/organization/RequestsCuttingService";
import {useParams} from "react-router-dom";
import Typography from "@mui/material/Typography";

const RequestsCuttingPlan = () => {

    const params = useParams();

    const planId = params.planId;
    const name = params.name;

    return (
        <div>
            <Typography variant="h6" component="h6">
                Заявки на раскрой плана {name}
            </Typography>
            <RequestsCuttingTable
                getAll={RequestsCuttingService.getAllWithMaterialByPlanId}
                isOptimization={true}
                isRedirectRequestsCutting={true}
                requestsCuttingPlan={true}
                planId={planId}
                isOrder={false}
                isButtonAddRequestsCutting={false}
                isSetForOptimization={true}
            />
        </div>
    );
};

export default RequestsCuttingPlan;