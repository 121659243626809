import axios from "axios";
import {apiVersionOrders, gatewayUrl, nameOrders} from "../../../../Constants";

const productionCostsUrl = gatewayUrl + nameOrders + apiVersionOrders + '/organizations/production-costs'

export default class ProductionCostsService {

    static async getAllByEstimateId(estimateId) {
        return axios.get(productionCostsUrl + '/estimate/' + estimateId);
    }

    static async getAllByOrderId(orderId, calculation) {
        return axios.get(productionCostsUrl + '/order/' + orderId + '?calculation=' + calculation);
    }
}