import React, {useEffect, useState} from 'react';
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import OrderTable from "../../orders/organization/order/OrderTable";
import OrderService from "../../../../../API/orders/orders/organization/OrderService";
import OrderUtil from "../../../../../utils/orders/OrderUtil";

const ModalCreateIssuingEdge = ({
                                    open,
                                    onClose,
                                    titleModal,
                                    createIssuingEdgeOpen,
                                    change,
                                    setChange
                                }) => {

        const [data, setData] = useState([]);
        const [additionallyColumn, setAdditionallyColumn] = useState([]);

        const [customerList, setCustomerList] = useState([]);
        const [nameList, setNameList] = useState([]);
        const [attributeList, setAttributeList] = useState([]);
        const [planningList, setPlanningList] = useState([]);

        useEffect(() => {

                OrderService.getAllIssuingEdges().then(function (response) {
                    const data = response.data;
                    setData(data);
                    setCustomerList(OrderUtil.getListField(data, 'customerName'));
                    setNameList(OrderUtil.getListField(data, 'header'));
                    setPlanningList(OrderUtil.getListField(data, 'state'));
                })
                    .catch(function (error) {
                        console.log(error);
                    });
            }, [change]
        )
        ;

        return (
            <div>
                <Dialog open={open} fullWidth={true} maxWidth='lg'>
                    <DialogTitle textAlign="center">{titleModal}</DialogTitle>
                    <DialogContent>
                        <Box sx={{mt: '0.2rem'}}>
                            <OrderTable
                                data={data}
                                setData={setData}
                                customerList={customerList}
                                nameList={nameList}
                                planningList={planningList}
                                enableEditing={false}
                                isIssuingEdge={true}
                                createIssuingEdgeOpen={createIssuingEdgeOpen}
                            />
                        </Box>
                    </DialogContent>
                    <DialogActions sx={{p: '1.25rem'}}>
                        <Button onClick={onClose}>Выйти</Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
;

export default ModalCreateIssuingEdge;