import React, {useState} from 'react';
import UploadFileForm from "../../../../form/UploadFileForm";
import ModelFileService from "../../../../../API/model-estimate/file/ModelFileService";
import UploadFilesForm from "../../../../form/UploadFilesForm";

const ReplacementModelMaterialFileForm = ({
                                              labelForm,
                                              upload, change, setChange
                                          }) => {

    const [selectedFiles, setSelectedFiles] = useState([]);

    const handleUpload = async (event) => {

        event.preventDefault();
        upload(selectedFiles)
            .then(function () {
                setSelectedFiles([]);
                event.target.reset();
                setChange(!change)
            })
            .catch(function (error) {
                console.log(error);
                event.target.reset();
            })
    }

    return (
        <div>
            <UploadFilesForm
                handleUpload={handleUpload}
                selectedFiles={selectedFiles}
                setSelectedFiles={setSelectedFiles}
                label={labelForm}
            />
        </div>
    );
};

export default ReplacementModelMaterialFileForm;