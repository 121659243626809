import {TextField} from "@mui/material";
import React from "react";

export const TextFieldWithEntityList = ({label, values, setId, field, entityList, disabled}) => {
    return (
        <div>
            <TextField
                size={"small"}
                disabled={disabled}
                key={field}
                sx={{
                    width: '100%',
                    minWidth: {xs: '300px', sm: '360px', md: '400px'},
                    gap: '1.5rem',
                }}
                margin="normal"
                select
                label={label}
                defaultValue={values === null ? "" : (values[field] === null ? null : values[field])}
                name={field}
                onChange={(e) =>
                    setId(e.target.value)
                }
                SelectProps={{
                    native: true,
                }}
            >
                {entityList != null && entityList.map((entity) => (
                    <option
                        key={entity.id}
                        value={entity.id}>
                        {entity.name}
                    </option>
                ))}
            </TextField>
        </div>
    );
};