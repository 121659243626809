import React from 'react';
import {TextField} from "@mui/material";

const TextFieldWithSetValueMainOptions = ({
                                              label,
                                              values,
                                              setValues,
                                              field,
                                              disabled,
                                              minWidthXS,
                                              minWidthSM,
                                              minWidthPX,
                                              marginRight
                                          }) => {
    return (
        <div>
            <TextField
                sx={{
                    width: '100%',
                    minWidth: {xs: minWidthXS, sm: minWidthSM, md: minWidthPX},
                    gap: '1.5rem',
                    '& > *': {
                        marginRight: marginRight,
                    }
                }}
                size={"small"}
                margin="normal"
                key={field}
                label={label}
                name={field}
                disabled={disabled == null ? false : disabled}
                value={values === null ? "" : values[field]}
                onChange={(e) =>
                    setValues({...values, [e.target.name]: e.target.value})
                }
            />
        </div>
    );
};

export default TextFieldWithSetValueMainOptions;