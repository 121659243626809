import React from 'react';
import {Box, Stack} from "@mui/material";
import TextFieldSimple from "../../textfield/TextFieldSimple";

const SettingsCutOffCuttingModule = ({
                                         trimmingLeftCutOff,
                                         setTrimmingLeftCutOff,
                                         trimmingRightCutOff,
                                         setTrimmingRightCutOff,
                                         trimmingTopCutOff,
                                         setTrimmingTopCutOff,
                                         trimmingBottomCutOff,
                                         setTrimmingBottomCutOff,
                                     }) => {

    return (
        <div>
            <Stack
                sx={{
                    width: '100%',
                    minWidth: {xs: '300px', sm: '360px', md: '400px'},
                    maxWidth: '500px',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        gap: '0.5rem',
                        p: '0.5rem',
                        flexWrap: 'wrap',
                        flexDirection: 'column'
                    }}
                >
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        '& > *': {
                            marginRight: '10px',
                        }
                    }}>
                        <TextFieldSimple
                            label="Отсуп слева"
                            value={trimmingLeftCutOff}
                            setValue={setTrimmingLeftCutOff}
                            field={'trimmingLeft'}
                        />
                        <TextFieldSimple
                            label="Отсуп справа"
                            value={trimmingRightCutOff}
                            setValue={setTrimmingRightCutOff}
                            field={'trimmingRight'}
                        />
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        '& > *': {
                            marginRight: '10px',
                        }
                    }}>
                        <TextFieldSimple
                            label="Отсуп сверху"
                            value={trimmingTopCutOff}
                            setValue={setTrimmingTopCutOff}
                            field={'trimmingTop'}
                        />
                        <TextFieldSimple
                            label="Отсуп снизу"
                            value={trimmingBottomCutOff}
                            setValue={setTrimmingBottomCutOff}
                            field={'trimmingBottom'}
                        />
                    </Box>
                </Box>
            </Stack>
        </div>
    );
};

export default SettingsCutOffCuttingModule;