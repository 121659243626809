import React from 'react';
import OrderProductionUtil from "../../../../../../utils/OrderProductionUtil";

const CellOPTm = ({cell2, row}) => {
    const header = cell2.column.id
    const cell = OrderProductionUtil.getValueByHeader(row.original.packageJobs, header)

    return (
        <span style={{
            padding: cell >= 100 ? '0.82rem' : cell < 10 ? '0.83rem' : '0.58rem',
            backgroundColor: cell >= 100 ? 'rgba(22, 184, 44, 0.5)'
                : cell < 0 ? undefined : 'rgba(240, 130, 12, 1)',
        }}>
                        {cell >= 100 ? "+" : cell < 0 ? "n" : cell.toFixed(0)}</span>
    );
};

export default CellOPTm;