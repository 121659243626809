import React, {useCallback} from 'react';
import {PlaylistAdd} from "@mui/icons-material";
import {Button} from "@mui/material";
import {useNavigate} from "react-router-dom";

const NavigateButton = ({row, url, suffix}) => {

    const navigate = useNavigate();

    const redirectById = useCallback(
        (row) => {
            navigate(url + row.original.id + (suffix ? '/' + suffix : ''));
        },
        [row],
    );

    return (
        <div>
            <Button size="small" sx={{minWidth: 'auto', marginLeft: 'auto'}} color="success"
                    onClick={() => redirectById(row)}>
                <PlaylistAdd sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
            </Button>
        </div>
    );
};

export default NavigateButton;