import React from 'react';
import {TextField} from "@mui/material";
import {dimensions} from "../../../data/Dimensions";

const CuttingTextField = ({label, value, setValue, field, disabled}) => {

    return (
        <div>
            <TextField
                size={"small"}
                disabled={disabled}
                key={field}
                sx={{
                    minWidth: {xs: '150px', sm: '150px', md: '150px'},
                    maxWidth: {xs: '150px', sm: '150px', md: '150px'},
                    gap: '1.5rem',
                }}
                margin="normal"
                select
                label={label}
                value={value === null ? "" : value}
                name={field}
                onChange={(e) =>
                    setValue(e.target.value)}
                SelectProps={{
                    native: true,
                }}
            >
                {Object.keys(dimensions).map((dimension) => (
                    <option key={dimension} value={dimension}>
                        {dimensions[dimension]}
                    </option>
                ))}
            </TextField>
        </div>
    );
};

export default CuttingTextField;