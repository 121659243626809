import axios from "axios";
import ObjectUtil from "../../../../utils/common/ObjectUtil";
import {apiVersionOrders, gatewayUrl, nameOrders} from "../../../../Constants";

const workingCuttingMapRestUrl = gatewayUrl + nameOrders + apiVersionOrders + '/organizations/working-cutting-maps'

export default class WorkingCuttingMapService {

    static async getAll() {
        return axios.get(workingCuttingMapRestUrl, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async getAllCalculation() {
        return axios.get(workingCuttingMapRestUrl + '/calculation', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async getAllArchive() {
        return axios.get(workingCuttingMapRestUrl + '/archive', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async getAllCalculationArchive() {
        return axios.get(workingCuttingMapRestUrl + '/calculation-archive', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async getState(id) {
        return axios.get(workingCuttingMapRestUrl + '/' + id + '/get-working-cutting-map-state', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async delete(id) {
        await axios.delete(workingCuttingMapRestUrl + '/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async createByIdsOptimization(ids, isCalculation) {
        let data = ObjectUtil.createRequestObjectIds(ids);
        return await axios.post(workingCuttingMapRestUrl + '/create-by-ids-optimization' + "?isCalculation=" + isCalculation, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async approveCuttingMap(workingCuttingMapId) {
        return await axios.post(workingCuttingMapRestUrl + '/' + workingCuttingMapId + '/approve', [], {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async cancelCuttingMap(workingCuttingMapId) {
        return await axios.post(workingCuttingMapRestUrl + '/' + workingCuttingMapId + '/cancel', [], {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async cancelReserve(workingCuttingMapId) {
        return await axios.post(workingCuttingMapRestUrl + '/' + workingCuttingMapId + '/cancel-reserve', [], {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async cut(workingCuttingMapId, cutWeight, minACutOff, minBCutOff, rotatePartInsideStrip, fitToStripFirst, fitToStripSecond, dimensions, combiningDrops, cutDropDetail, cutFitToStripFirstDropDetail, cutFitToStripSecondDropDetail, cutCombiningDrops, takeAccountDimensions, cutWastes, cutCutOffs, limitNumberTurns, cutOnlyCutOffs, cutOnlyWarehouse, supplierId, minBalanceUpToWholeSheet, cutDefault, trimmingLeft, trimmingRight, trimmingBottom, trimmingTop, trimmingLeftCutOff, trimmingRightCutOff, trimmingBottomCutOff, trimmingTopCutOff) {

        return await axios.post(workingCuttingMapRestUrl + '/cut' + "?workingCuttingMapId=" + workingCuttingMapId + "&cutWeight=" + cutWeight + "&minACutOff=" + minACutOff + "&minBCutOff=" + minBCutOff + "&rotatePartInsideStrip=" + rotatePartInsideStrip + "&fitToStripFirst=" + fitToStripFirst + '&fitToStripSecond=' + fitToStripSecond + '&dimensions=' + dimensions + '&combiningDrops=' + combiningDrops + '&cutDropDetail=' + cutDropDetail + '&cutFitToStripFirstDropDetail=' + cutFitToStripFirstDropDetail + '&cutFitToStripSecondDropDetail=' + cutFitToStripSecondDropDetail + '&cutCombiningDrops=' + cutCombiningDrops + '&takeAccountDimensions=' + takeAccountDimensions + '&cutWastes=' + cutWastes + '&cutCutOffs=' + cutCutOffs + '&limitNumberTurns=' + limitNumberTurns + '&cutOnlyCutOffs=' + cutOnlyCutOffs + '&cutOnlyWarehouse=' + cutOnlyWarehouse + '&supplierId=' + supplierId + '&minBalanceUpToWholeSheet=' + minBalanceUpToWholeSheet + '&cutDefault=' + cutDefault + '&trimmingLeft=' + trimmingLeft + '&trimmingRight=' + trimmingRight + '&trimmingBottom=' + trimmingBottom + '&trimmingTop=' + trimmingTop + '&trimmingLeftCutOff=' + trimmingLeftCutOff + '&trimmingRightCutOff=' + trimmingRightCutOff + '&trimmingBottomCutOff=' + trimmingBottomCutOff + '&trimmingTopCutOff=' + trimmingTopCutOff, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async getAllWorkingCuttingMapId(workingCuttingMapId, drawEdge) {
        return axios.get(workingCuttingMapRestUrl + '/by-working-cutting-map-id' + "?workingCuttingMapId=" + workingCuttingMapId + '&drawEdge=' + drawEdge, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async setForCutting(currentIdRow) {
        return await axios.post(workingCuttingMapRestUrl + '/' + currentIdRow + '/set-for-cutting', [], {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async setStartCutting(currentIdRow) {
        return await axios.post(workingCuttingMapRestUrl + '/' + currentIdRow + '/set-start-cutting', [], {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async raiseCuttingPriority(currentIdRow) {
        return await axios.post(workingCuttingMapRestUrl + '/' + currentIdRow + '/raise-cutting-priority', [], {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async lowerCuttingPriority(currentIdRow) {
        return await axios.post(workingCuttingMapRestUrl + '/' + currentIdRow + '/lower-cutting-priority', [], {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async getAllForCuttingWithOptimization() {
        return axios.get(workingCuttingMapRestUrl + '/all-for-cutting-with-optimization', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async setFinishCut(id) {
        return await axios.put(workingCuttingMapRestUrl + '/' + id + '/finish-cut', [], {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async sendToArchive(id) {
        return await axios.patch(workingCuttingMapRestUrl + '/' + id + '/send-to-archive', [], {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async returnForRevision(id) {
        return await axios.patch(workingCuttingMapRestUrl + '/' + id + '/return-for-revision', [], {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
}