import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack} from "@mui/material";
import TextFieldWithSetValue from "../../../textfield/TextFieldWithSetValue";

const ModalUpdateCutOff = ({
                               open,
                               handleClose,
                               title,
                               values,
                               setValues,
                               handleSubmit,
                               materialPriceUnit,
                               setMaterialPriceUnit,
                               unableCheckbox
                           }) => {

    //const edit_MWCO = checkRoleOne(token, 'ROLE_edit_MWCO');
    const edit_MWCO = true;

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle textAlign="center">{title}</DialogTitle>
            <DialogContent>
                <form onSubmit={(e) => e.preventDefault()}>
                    <Stack
                        sx={{
                            width: '100%',
                            minWidth: {xs: '300px', sm: '360px', md: '400px'},
                            gap: '0.01rem',
                        }}
                    >
                        <TextFieldWithSetValue
                            label="Коментарий"
                            values={values}
                            setValues={setValues}
                            field={'note'}
                        />
                        {edit_MWCO && !unableCheckbox &&
                            <TextFieldWithSetValue
                                label="Ширина"
                                values={materialPriceUnit}
                                setValues={setMaterialPriceUnit}
                                field={'length'}
                            />
                        }
                        {edit_MWCO && !unableCheckbox &&
                            <TextFieldWithSetValue
                                label="Высота"
                                values={materialPriceUnit}
                                setValues={setMaterialPriceUnit}
                                field={'width'}
                            />
                        }
                    </Stack>
                </form>
            </DialogContent>
            <DialogActions sx={{p: '1.25rem'}}>
                <Button onClick={handleClose}>Выйти</Button>
                <Button color="secondary" onClick={() => handleSubmit()} variant="contained">
                    {'Обновить'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ModalUpdateCutOff;