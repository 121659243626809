import React, {useEffect, useMemo, useRef, useState} from 'react';
import {Box, Button} from "@mui/material";
import HoleControllerService from "../../../../API/details/HoleControllerService";
import ProcessingTable from "../../../../components/UI/table/customer-detales/processing/ProcessingTable";
import {Canvas} from "@react-three/fiber";
import {Text, PerspectiveCamera, OrbitControls} from "@react-three/drei";
import ModalProcessing from "./Modals/ModalProcessing";
import DetailCanvas from "./Canvas/DetailCanvas";
import PointControllerService from "../../../../API/details/PointController";
import ModalConfirm from "./Modals/ModalConfirm";
import Layer from "./Canvas/CanvasComponents/Layer";
import SideBar from "./SideBar/SideBar";
import EdgeContent from "./EdgeContent/EdgeContent";
import DetailControllerService from "../../../../API/details/DetailControllerService";
import NotchControllerService from "../../../../API/details/NotchController";
import {calcCutPossible} from "../../../../utils/processing/calcCutPossible";
import PopoverProcessing from "./Canvas/CanvasComponents/PopoverProcessing";
import GrooveControllerService from "../../../../API/details/GrooveControllerService";
import ModalAddDetail from "../../../../components/UI/table/customer-detales/ModalAddDetail";
import * as THREE from "three";
import {calcNewScale, INIT_SCALE} from "../../../../utils/processing/calcNewScale";
import OrderEdgeController from "../../../../API/details/OrderEdgeController";
import EdgesControllerService from "../../../../API/details/EdgesControllerService";
import QuarterControllerService from "../../../../API/details/QuarterControllerService";
import {calcValueOfStringWithVar} from "./Canvas/CanvasCalcPositions/calcValueOfStringWithVar";


const MainDimension = React.memo(({detailData, scale}) => {
    // const {detailData, scale} = useProcessingContext()

    return (
        <Layer layer={2}>
            <Text
                color={"black"}
                fontSize={.2}
                position={[0, (detailData.width / 2 * scale) + 0.45, 0]}
                maxWidth={6}
            >
                ← {detailData.length} →
            </Text>
            <Text
                rotation={[0, 0, 20.4]}
                color={"black"}
                fontSize={.2}
                position={[(detailData.length / 2 * scale) + 0.45, 0, 0]}
                maxWidth={6}
            >
                ← {detailData.width} →
            </Text>
        </Layer>
    )
})

const DetailProcessing = ({
                              setIsError,
                              detailId,
                              setChange,
                              handleError,
                              orderId,
                              detailData,
                              redirect,
                              variables
                          }) => {
    // console.log("DetailProcessing render")
    // const {detailData, startX, startY} = useProcessingContext()
    const refCanvas = useRef()

    const [scale, setScale] = useState(INIT_SCALE);
    const [edgesData, setEdgesData] = useState([])

    useEffect(() => {
        OrderEdgeController.getAll(orderId)
            .then(function (response) {
                const data = response?.data
                setEdgesData(data);
                setIsError(false)
            })
            .catch(function (error) {
                handleError(error)
            })
    }, []);

    useEffect(() => {
        setScale(calcNewScale(refCanvas.current || {}, detailData))
        const resizeHandler = () => setScale(calcNewScale(refCanvas.current || {}, detailData));

        window.addEventListener("resize", resizeHandler);
        return () => window.removeEventListener("resize", resizeHandler)
    }, [detailData])

    const startX = -(detailData.length / 2 * scale)
    const startY = -(detailData.width / 2 * scale)
    const corePosition = [startX, startY, 0];

    const [processingModal, setProcessingModal] = useState("")
    const [confirmModal, setConfirmModal] = useState("");
    const [popoverActives, setPopoverActives] = useState({pointId: null, x: 0, y: 0});
    const [editDetailModal, setEditDetailModal] = useState(false)

    const [editableElementId, setEditableElementId] = useState(null);
    const [activePointId, setActivePointId] = useState(null)
    const [selectedHole, setSelectedHole] = useState(null)
    const [hoveredHole, setHoveredHole] = useState(null)
    const [hoveredGroove, setHoveredGroove] = useState(null)
    const [prevArc, setPrevArc] = useState(null)
    const [prevInnerContour, setPrevInnerContour] = useState({contour: {}, type: ""})

    const cutPossible = useMemo(() => {
        const possibleArr = []

        detailData.detailContour.points.forEach((el, i, arr) => {
            const possible = calcCutPossible(el, i, arr)
            possible && possibleArr.push({...possible, mainContour: true})
        })
        detailData.notches.forEach(el => el.contour.points.forEach((el, i, arr) => {
            const possible = calcCutPossible(el, i, arr)
            possible && possibleArr.push({...possible, mainContour: false})
        }))
        return possibleArr
    }, [detailData])

    const [cutData, setCutData] = useState({
        pointId: null,
        possible: cutPossible,
        distancePrev: "",
        distanceNext: ""
    })


    const handleCloseDetailModal = (data) => {
        setEditDetailModal(false)
    }

    const calcPrevInnerContour = (enteredValues, round) => {
        try {
            const calcValues = Object.fromEntries(Object.entries(enteredValues).map(([name, value]) => {
                return name === "position" || name === "direction" ? [name, value] : [name, calcValueOfStringWithVar(variables, value.toString())]
            }))
            setPrevInnerContour({contour: calcValues, type: round ? "round" : "groove"})
        } catch (error) {
            setPrevInnerContour({contour: {}, type: ""})
            console.log("error:", "запросить контур с бека")
        }
    }

    const handleSubmitDetailModal = (data) => {
        DetailControllerService.update(orderId, detailData.id, data).then(function (response) {
            setChange((change) => !change)
        })
            .catch(function (error) {
                handleError(error)
            })
            .then(() => setEditDetailModal(false))
    }

    const processingHandle = (type, editId, pointId) => {
        type === "cut" && setCutData((value) => {
            return {...value, pointId: editId}
        })
        setEditableElementId(editId)
        setActivePointId(pointId)
        setProcessingModal(type)
    }

    const openConfirmModal = (type, deleteId) => {
        setConfirmModal(type)
        setEditableElementId(deleteId)
    }

    const openPopoverActives = (data) => {
        setPopoverActives(data)
        setActivePointId(data.pointId)
    }
    const closePopoverActives = () => {
        setPopoverActives({pointId: null, x: 0, y: 0})
        setActivePointId(null)
    }

    const onCloseModal = () => {
        setProcessingModal("")
        setEditableElementId(null)
        setPrevArc(null)
        setCutData(values => ({...values, distancePrev: "", distanceNext: ""}))
        setPrevInnerContour({contour: {}, type: ""})
        setActivePointId(null)
    }

    const onCloseConfirmModal = () => {
        setConfirmModal("")
    }

    const onConfirm = () => {
        if (confirmModal === "reset") {
            DetailControllerService.resetContour(orderId, detailData.id)
                .then(function () {
                    setChange((change) => !change)
                })
                .catch(function (error) {
                    handleError(error)
                })
                .finally(() => onCloseConfirmModal())
        } else if (confirmModal === "deletePoint") {
            PointControllerService.delete(orderId, detailData.id, editableElementId)
                .then(function () {
                    setChange((change) => !change)
                })
                .catch(function (error) {
                    handleError(error)
                })
                .finally(() => onCloseConfirmModal())
        } else if (confirmModal === "deleteHole") {
            HoleControllerService.delete(orderId, detailData.id, editableElementId)
                .then(function () {
                    setChange((change) => !change)
                })
                .catch(function (error) {
                    handleError(error)
                })
                .finally(() => onCloseConfirmModal())
        } else if (confirmModal === "deleteNotchRound") {
            NotchControllerService.deleteRound(orderId, detailData.id, editableElementId)
                .then(function () {
                    setChange((change) => !change)
                })
                .catch(function (error) {
                    handleError(error)
                })
                .finally(() => onCloseConfirmModal())
        } else if (confirmModal === "deleteGroove") {
            GrooveControllerService.delete(orderId, detailData.id, editableElementId)
                .then(function () {
                    setChange((change) => !change)
                })
                .catch(function (error) {
                    handleError(error)
                })
                .finally(() => onCloseConfirmModal())
        } else if (confirmModal === "deleteEdge") {
            EdgesControllerService.delete(orderId, editableElementId)
                .then(function () {
                    setChange((change) => !change)
                })
                .catch(function (error) {
                    handleError(error)
                })
                .finally(() => onCloseConfirmModal())
        } else if (confirmModal === "deleteQuarter") {
            QuarterControllerService.delete(orderId, detailId, editableElementId)
                .then(function () {
                    setChange((change) => !change)
                })
                .catch(function (error) {
                    handleError(error)
                })
                .finally(() => onCloseConfirmModal())
        }
    }

    const onSubmitHandle = (data) => {
        if (processingModal === "addPoint") {
            PointControllerService.save(orderId, detailId, data.afterPointId, data.point).then(function (response) {
                setChange((change) => !change)
            })
                .catch(function (error) {
                    handleError(error)
                })
                .finally(() => onCloseModal())
        } else if (processingModal === "editPoint") {
            PointControllerService.patch(orderId, detailId, activePointId, data.point).then(function (response) {
                setChange((change) => !change)
            })
                .catch(function (error) {
                    handleError(error)
                })
                .finally(() => onCloseModal())
        } else if (processingModal === "arc") {
            PointControllerService.patchArc(orderId, detailId, data.pointId, data.point).then(function (response) {
                setChange((change) => !change)
            })
                .catch(function (error) {
                    handleError(error)
                })
                .finally(() => onCloseModal())
        } else if (processingModal === "round") {
            PointControllerService.patchRound(orderId, detailId, data.pointId, data.point).then(function (response) {
                setChange((change) => !change)
            })
                .catch(function (error) {
                    handleError(error)
                })
                .finally(() => onCloseModal())
        } else if (processingModal === "cut") {
            PointControllerService.patchCut(orderId, detailId, data.pointId, data.params).then(function (response) {
                setChange((change) => !change)
            })
                .catch(function (error) {
                    handleError(error)
                })
                .finally(() => onCloseModal())
        } else if (processingModal === "notch") {
            NotchControllerService.add(orderId, detailId, data).then(function (response) {
                setChange((change) => !change)
            })
                .catch(function (error) {
                    handleError(error)
                })
                .finally(() => onCloseModal())
        } else if (processingModal === "notchRound") {
            NotchControllerService.addRound(orderId, detailId, data).then(function (response) {
                setChange((change) => !change)
            })
                .catch(function (error) {
                    handleError(error)
                })
                .finally(() => onCloseModal())
        } else if (processingModal === "editNotchRound") {
            NotchControllerService.putRound(orderId, detailId, editableElementId, data).then(function (response) {
                setChange((change) => !change)
            })
                .catch(function (error) {
                    handleError(error)
                })
                .finally(() => onCloseModal())
        } else if (processingModal === "addHole") {
            HoleControllerService.save(orderId, detailId, data).then(function (response) {
                setChange((change) => !change)
            })
                .catch(function (error) {
                    handleError(error)
                })
                .finally(() => onCloseModal())
        } else if (processingModal === "editHole") {
            HoleControllerService.put(orderId, detailId, editableElementId, data).then(function (response) {
                setChange((change) => !change)
            })
                .catch(function (error) {
                    handleError(error)
                })
                .finally(() => onCloseModal())
        } else if (processingModal === "addEdge") {
            EdgesControllerService.save(orderId, {
                startPointId: editableElementId,
                orderEdgeId: data,
                edgeEnd: "STANDARD"
            }).then(function (response) {
                setChange((change) => !change)
            })
                .catch(function (error) {
                    handleError(error)
                })
                .finally(() => onCloseModal())
        } else if (processingModal === "editEdge") {
            EdgesControllerService.edit(orderId, editableElementId, {
                orderEdgeId: data,
                edgeEnd: "STANDARD"
            }).then(function (response) {
                setChange((change) => !change)
            })
                .catch(function (error) {
                    handleError(error)
                })
                .finally(() => onCloseModal())
        } else if (processingModal === "groove") {
            GrooveControllerService.add(orderId, detailId, data).then(function (response) {
                setChange((change) => !change)
            })
                .catch(function (error) {
                    handleError(error)
                })
                .finally(() => onCloseModal())
        } else if (processingModal === "editGroove") {
            GrooveControllerService.put(orderId, detailId, editableElementId, data).then(function (response) {
                setChange((change) => !change)
            })
                .catch(function (error) {
                    handleError(error)
                })
                .finally(() => onCloseModal())
        } else if (processingModal === "quarter") {
            QuarterControllerService.add(orderId, detailId, data.pointId, data.params).then(function (response) {
                setChange((change) => !change)
            })
                .catch(function (error) {
                    handleError(error)
                })
                .finally(() => onCloseModal())
        } else if (processingModal === "editQuarter") {
            QuarterControllerService.put(orderId, detailId, editableElementId, data.pointId, data.params).then(function (response) {
                setChange((change) => !change)
            })
                .catch(function (error) {
                    handleError(error)
                })
                .finally(() => onCloseModal())
        }
    }

    const getPrevContour = (data) => {
        if (!data.params.radius) {
            setPrevArc(null)
        } else if (processingModal === "arc") {
            PointControllerService.previewArc(orderId, detailId, data.pointId, data.params)
                .then((response) => setPrevArc(response.data))
                .catch((err) => {
                    setPrevArc(null)
                    handleError(err)
                })
        } else {
            PointControllerService.previewRound(orderId, detailId, data.pointId, data.params)
                .then((response) => setPrevArc(response.data))
                .catch((err) => {
                    setPrevArc(null)
                    handleError(err)
                })
        }
    }

    return (<Box>
        <ModalAddDetail
            open={editDetailModal}
            onClose={handleCloseDetailModal}
            title={'Редактировать деталь'}
            labelButtonSubmit={'Обновить'}
            handleSubmit={handleSubmitDetailModal}
            initValue={{
                position: detailData.position,
                name: detailData.name,
                length: detailData.length,
                height: "18",
                width: detailData.width
            }}
            uniqueNames={[]}

        />
        <ModalProcessing
            type={processingModal}
            onClose={onCloseModal}
            onSubmit={onSubmitHandle}
            edgesData={edgesData}
            detailData={detailData}
            editableElementId={editableElementId}
            activePointId={activePointId}
            setActivePointId={setActivePointId}
            openConfirmModal={openConfirmModal}
            cutData={cutData}
            setCutData={setCutData}
            calcPrevInnerContour={calcPrevInnerContour}
            getPrevContour={getPrevContour}
            refCanvas={refCanvas}
            variables={variables}
        />
        <ModalConfirm
            confirmModal={confirmModal}
            onClose={onCloseConfirmModal}
            onSubmit={onConfirm}
        />
        {popoverActives.pointId &&
            <PopoverProcessing popoverActives={popoverActives}
                               processingHandle={processingHandle}
                               openConfirmModal={openConfirmModal}
                               confirmModal={confirmModal}
                               processingModal={processingModal}
                               cutData={cutData}
                               onClose={closePopoverActives}>
            </PopoverProcessing>
        }

        {detailData.id ? <Box>
            <p style={{margin: 10}}> Деталь {detailData.name} [{detailData.length}x{detailData.width}x{detailData.height}] </p>
            <Box style={{marginBottom: 10}}>
                <Button
                    style={{marginRight: 10}}
                    size="small"
                    color="secondary"
                    onClick={redirect.orders}
                    variant="contained"
                >
                    Вернуться в заказы
                </Button>
                <Button style={{marginRight: 10}} size="small" color="secondary" variant="contained"
                        onClick={redirect.details}>
                    вернуться к деталям
                </Button>
                <Button style={{marginRight: 10}} size="small" color="success" variant="contained"
                        onClick={() => openConfirmModal("reset")}>
                    К начальному контуру
                </Button>
                <Button size="small" color="success" variant="contained"
                        onClick={() => setEditDetailModal(true)}
                >
                    Изменить размеры
                </Button>
            </Box>
            <Box style={{display: "flex", justifyContent: "space-between", height: "calc(100vh - 220px)",}}>
                <SideBar detailData={detailData} processingHandle={processingHandle} cutPossible={cutPossible}/>
                <Box style={{
                    minWidth: "500px",
                    width: "calc(100% - 840px)",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between"
                }}>
                    <Box
                        ref={refCanvas}
                        style={{
                            minWidth: "500px",
                            height: "100%",
                            display: "flex",
                        }}>
                        <Canvas>
                            <MainDimension detailData={detailData} scale={scale}/>

                            <DetailCanvas
                                editableElement={{
                                    type: processingModal,
                                    pointId: activePointId,
                                    id: editableElementId
                                }}
                                processingHandle={processingHandle}
                                hoveredHoleId={hoveredHole}
                                setHoveredHoleId={setHoveredHole}
                                selectedHole={selectedHole}
                                setSelectedHole={setSelectedHole}
                                hoveredGrooveId={hoveredGroove}
                                setHoveredGrooveId={setHoveredGroove}
                                cutData={cutData}
                                prevInnerContour={prevInnerContour}
                                prevArc={prevArc}
                                openPopoverActives={openPopoverActives}
                                openConfirmModal={openConfirmModal}
                                position={corePosition}
                                detailData={detailData}
                                scale={scale}
                            />
                            <Layer layer={1}>
                                <arrowHelper
                                    args={[new THREE.Vector3(0, 0, 0), new THREE.Vector3(startX - 0.55, startY - 0.55, 0), 1, "#FF0000", 0.2, 0.06]}
                                />
                                <arrowHelper
                                    args={[new THREE.Vector3(90, 0, 0), new THREE.Vector3(startX - 0.55, startY - 0.55, 0), 1, "#FF0000", 0.2, 0.06]}
                                />
                                <Text
                                    color={"black"}
                                    fontSize={.15}
                                    position={[startX + 0.55, startY - 0.55, 0]}>X
                                </Text>
                                <Text
                                    color={"black"}
                                    fontSize={.15}
                                    position={[startX - 0.55, startY + 0.55, 0]}>Y
                                </Text>
                            </Layer>
                            <ambientLight intensity={Math.PI / 2}/>
                            <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} decay={0}
                                       intensity={Math.PI}/>
                            <pointLight position={[-10, -10, -10]} decay={0} intensity={Math.PI}/>
                        </Canvas>
                    </Box>
                    <EdgeContent edgesData={edgesData}/>
                </Box>
                <Box>
                    <ProcessingTable
                        data={detailData}
                        processingHandle={processingHandle}
                        openConfirmModal={openConfirmModal}
                        hoveredHole={hoveredHole}
                        setHoveredHole={setHoveredHole}
                        selectedHole={selectedHole}
                        setSelectedHole={setSelectedHole}/>
                </Box>
            </Box>
        </Box> : <p style={{color: "red"}}> the detail does not exist </p>}
    </Box>)
}
export default DetailProcessing
