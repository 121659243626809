import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import BasicModelsTable from "./BasicModelsTable";
import BasicModelService from "../../../../../API/model-estimate/model/BasicModelService";

export const UpdateOrAddBasicModelsModule = ({
                                                 open,
                                                 onClose,
                                                 setBasicModelId,
                                                 setModalModelsOpen,
                                                 orderIdHeader
                                             }) => {


    return (<div>
        <Dialog open={open} fullWidth={true} maxWidth='lg'>
            <DialogTitle textAlign="center">Выбрать базовую модель</DialogTitle>
            <DialogContent>
                <BasicModelsTable
                    isButtonModels={true}
                    setBasicModelId={setBasicModelId}
                    setModalModelsOpen={setModalModelsOpen}
                    orderIdHeader={orderIdHeader}
                    getAll={BasicModelService.getAllUnableByHeader}
                />
            </DialogContent>
            <DialogActions sx={{p: '1.25rem'}}>
                <Button onClick={onClose}>Выйти</Button>
            </DialogActions>
        </Dialog>
    </div>);
};