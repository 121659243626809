import React, {useCallback, useContext, useMemo, useState} from 'react';
import MaterialReactTable from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import Box from "@mui/material/Box";
import {Button, Tooltip} from "@mui/material";
import {Edit} from "@mui/icons-material";
import ModalReplacementWorker from "./ModalReplacementWorker";
import CheckIcon from '@mui/icons-material/Check';
import WorkerUtil from "../../../../../../utils/orders/WorkerUtil";
import ModalExecuteJob from "./ModalExecuteJob";
import JobService from "../../../../../../API/orders/orders/organization/job/JobService";
import DateTimeUtil from "../../../../../../utils/common/DateTimeUtil";
import DateRangeFilter from "../../../../filter/DateRangeFilter";

const TechnicalMapTable = ({data, setData, estimateIsCurrent}) => {

    const [openModalSimilarWorker, setOpenModalSimilarWorker] = useState(false);
    const [openModalExecuteJob, setOpenModalExecuteJob] = useState(false);
    const [currentDate, setCurrentDate] = useState(new Date());
    const columns = useMemo(
        () => [
            {
                header: 'Тип работы',
                accessorKey: 'typeJobName',
                enableEditing: false,
                enableGrouping: true,
                minSize: 35,
                maxSize: 50,
            },
            {
                header: 'Материал',
                accessorKey: 'materialName',
                enableColumnActions: false,
                enableEditing: false,
                minSize: 50,
            },
            {
                header: 'Количество',
                accessorKey: 'count',
                enableColumnActions: false,
                enableEditing: false,
                minSize: 10,
            },
            {
                id: 'unitName',
                header: 'Ед.изм.',
                accessorKey: 'unitName',
                enableColumnActions: false,
                enableEditing: false,
                size: 8,
            },
            {
                header: 'НВ/ч',
                accessorKey: 'normOfTime',
                enableColumnActions: false,
                enableEditing: false,
                size: 8,
            },
            {
                header: 'Отметки',
                accessorKey: 'jobWorkers',
                id: 'jobWorkers',
                enableColumnActions: false,
                enableEditing: false,
                size: 8,
                Cell: ({cell, row}) => (<div>
                        {WorkerUtil.convertWorkers(cell.getValue())}
                    </div>
                )
            },
            {
                accessorFn: (row) => {
                    if (row.completionDateTime === null) {
                    } else {
                        return new Date(row.completionDateTime)
                    }
                },
                header: 'Дата выполнения',
                id: 'completionDateTime',
                maxSize: 8,
                Cell: ({cell}) => {
                    if (cell.getValue() !== undefined) {
                        return cell.getValue().toLocaleDateString();
                    }
                },
                filterFn: (row, id, filterValue) => {
                    const startDate = filterValue?.startDate;
                    const endDate = filterValue?.endDate;
                    if (startDate && endDate) {
                        return (
                            DateTimeUtil.checkDateBefore(row.getValue(id), startDate) &&
                            DateTimeUtil.checkDateAfter(row.getValue(id), endDate)
                        );
                    }
                    return true;
                },
                Filter: ({column}) => <DateRangeFilter
                    column={column}
                />,
            },
            {
                header: 'Описание',
                accessorKey: 'metricName',
                id: 'metricName',
                enableColumnActions: false,
                enableEditing: false,
                size: 8,
            },
        ],
        [data, setData],
    );

    const [currentIdRow, setCurrentIdRow] = useState(null);
    const [currentRow, setCurrentRow] = useState(null);

    const similarWorker = useCallback(
        (row) => {
            setCurrentIdRow(row.original.id);
            setCurrentRow(row);
            setOpenModalSimilarWorker(true)
        },
        [data],
    );

    const executeJob = useCallback(
        (row) => {
            setCurrentIdRow(row.original.id);
            setCurrentRow(row);
            setOpenModalExecuteJob(true)
        },
        [data],
    );

    const closeExecuteJob = () => {
        setCurrentIdRow(null);
        setCurrentRow(null);
        setOpenModalExecuteJob(false)
    };

    const closeSimilarWorker = () => {
        setCurrentIdRow(null);
        setCurrentRow(null);
        setOpenModalSimilarWorker(false)
    };

    const onExecute = () => {
        JobService.executeJob(currentIdRow, DateTimeUtil.dateConvert(currentDate))
            .then(function () {
                handleUpdate();
                closeExecuteJob();
            })
            .catch(function (error) {
                console.log(error);
            })
    };

    const handleUpdate = () => {
        data[currentRow.index].completed = true;
        data[currentRow.index].completionDateTime = currentDate;
        setData([...data]);
    };

    return (
        <div>
            <MaterialReactTable
                muiTableBodyProps={{
                    sx: () => ({
                        '& tr:nth-of-type(odd)': {
                            backgroundColor: 'lightgray',
                        },
                    }),
                }}
                muiTableBodyCellProps={{
                    sx: {
                        p: '0px 0px 0px 10px',
                        m: '0px 0px 0px 0px',
                    },
                }}
                muiTableBodyRowProps={{
                    sx: {
                        height: '10px',
                    },
                }}
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        size: 15,
                        minSize: 15,
                    },
                }}
                columns={columns}
                data={data}
                rowHeight={13}
                columnSpacing={1}
                enableEditing={estimateIsCurrent}
                initialState={{
                    expanded: true,
                    grouping: ['typeJobName'],
                    columnVisibility: {
                        id: false,
                        customerId: false,
                    },
                    isFullScreen: false,
                    density: "compact",
                    pagination: {pageIndex: 0, pageSize: 50},
                }}
                getRowId={(originalRow) => originalRow.id}
                renderRowActions={({row, table}) => (
                    <Box sx={{
                        display: 'flex',
                        gap: '0.1rem',
                        justifyContent: 'flex-start',
                        maxWidth: '50px'
                    }}>
                        {!row.original.completed ?
                            <Tooltip arrow placement="right" title="Изменить">
                                <Button
                                    size="small"
                                    sx={{minWidth: 'auto', marginLeft: 'auto'}} color="pencil"
                                    onClick={() => similarWorker(row)}>
                                    <Edit sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                                </Button>
                            </Tooltip> :
                            <Button
                                size="small"
                                disabled={true}
                                sx={{minWidth: 'auto', marginLeft: 'auto'}} color="pencil"
                            >
                                <Edit sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                            </Button>
                        }
                        {estimateIsCurrent && !row.original.completed ?
                            <Tooltip arrow placement="right" title="Выполнить работу">
                                <Button
                                    size="small"
                                    sx={{minWidth: 'auto', marginLeft: 'auto'}} color="success"
                                    onClick={() => executeJob(row)}>
                                    <CheckIcon sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                                </Button>
                            </Tooltip> :
                            <Button
                                size="small"
                                disabled={true}
                                sx={{minWidth: 'auto', marginLeft: 'auto'}} color="success"
                            >
                                <CheckIcon sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                            </Button>
                        }
                    </Box>
                )}
                localization={MRT_Localization_RU}
            />
            <ModalReplacementWorker
                open={openModalSimilarWorker}
                onClose={closeSimilarWorker}
                selectId={currentIdRow}
                data={data}
                setData={setData}
                currentRow={currentRow}
            />
            <ModalExecuteJob
                open={openModalExecuteJob}
                onClose={closeExecuteJob}
                date={currentDate}
                setDate={setCurrentDate}
                onExecute={onExecute}
            />
        </div>
    );
};

export default TechnicalMapTable;
