import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {TabContext, TabPanel} from "@mui/lab";
import {Box, Button, Tabs} from "@mui/material";
import Tab from "@mui/material/Tab";
import IssuingEdgeMaterialMaterialReserveTable from "./IssuingEdgeMaterialMaterialReserveTable";
import ModulAddMaterialIssuingEdge from "./ModulAddMaterialIssuingEdge";
import OrderUtil from "../../../../../utils/orders/OrderUtil";
import IssuingEdgesItemTable from "../issuingedgeitem/IssuingEdgesItemTable";
import MaterialReserveService from "../../../../../API/orders/reservation/MaterialReserveService";

const ModulIssuingEdge = ({id}) => {

    const [value, setValue] = useState('1');
    const [data, setData] = useState([]);
    const [change, setChange] = useState(false);
    const [orderList, setOrderList] = useState([]);
    const [addMaterialModalOpen, setAddMaterialModalOpen] = useState(false);

    const closeAddMaterialModal = () => {
        setAddMaterialModalOpen(false)
    };

    const openAddMaterialModal = () => {
        setAddMaterialModalOpen(true)
    };

    useEffect(() => {
        MaterialReserveService.getAllByIssuingEdgeId(id).then(function (response) {
            const data = response.data;
            setData(data);
            setOrderList(OrderUtil.getListOrderNumbers(data))
        })
            .catch(function (error) {
                console.log(error);
            });
    }, [change]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {

        /*JobService.getJobsByEstimateId(estimateId, ).then(function (response) {
            setTechnicalMapData(response.data);
        })
            .catch(function (error) {
                console.log(error);
            });*/
    }, []);

    const navigate = useNavigate();

    const redirectOrders = () => {
        navigate('/organization/orders/');
    };


    return (
        <div>
            <Box sx={{
                '& > *': {
                    marginTop: '0px',
                }
            }}>
                <Box
                    sx={{
                        display: 'flex',
                        gap: '0.5rem',
                        paddingLeft: '0.1rem',
                        paddingTop: '0.5rem',
                        paddingBottom: '0.1rem',
                        flexWrap: 'wrap'
                    }}
                >
                    <Button
                        size="small"
                        color="secondary"
                        onClick={redirectOrders}
                        variant="contained"
                    >
                        Вернуться в заказы
                    </Button>

                </Box>
                <TabContext value={value}>
                    <Box sx={{
                        borderBottom: 1, borderColor: 'divider'
                    }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="В заказах" value='1'/>
                            <Tab label="Склад" value='4'/>
                            <Tab label="Выдано" value='2'/>
                        </Tabs>
                    </Box>
                    <TabPanel value='1'>
                        <IssuingEdgeMaterialMaterialReserveTable
                            data={data}
                            setData={setData}
                            pageSize={100}
                            enableGrouping={true}
                            columnGrouping={["materialName"]}
                            expanded={false}
                            isColumnMaterialWarehouse={true}
                            isColumnOptimization={false}
                            orderList={orderList}
                            change={change}
                            setChange={setChange}
                            titleModalUpdateOrAddMaterialArrival={"Создать приход плитных материалов"}
                        />
                    </TabPanel>
                    <TabPanel value='2'>
                        <IssuingEdgesItemTable
                            id={id}
                            change={change}
                            setChange={setChange}
                        />
                    </TabPanel>
                    <TabPanel value='4'>
                        <ModulAddMaterialIssuingEdge
                            open={addMaterialModalOpen}
                            onClose={closeAddMaterialModal}
                            titleModal={"Выдать материал"}
                            id={id}
                            change={change}
                            setChange={setChange}
                        />
                    </TabPanel>
                </TabContext>
            </Box>
        </div>
    );
};

export default ModulIssuingEdge;