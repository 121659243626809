import dayjs from "dayjs";

export default class DateTimeUtil {

    static timeout(delay) {
        return new Promise(res => setTimeout(res, delay));
    }

    static convertToJavaDateTime(value) {
        const dateTime = dayjs(value);
        return dateTime.format('YYYY-MM-DDTHH:mm:ss');
    };

    static dateConvert(date) {

        if (date === null) {
            return null;
        }

        const d = new Date(date);

        let day = d.getDate().toString();
        let month = (d.getMonth() + 1).toString();
        let year = (d.getFullYear()).toString();

        return year + '-' + DateTimeUtil.checkWithZero(month) + '-' + DateTimeUtil.checkWithZero(day)
    }

    static checkWithZero(data) {
        if (data.length === 1) {
            return '0' + data;
        } else return data;
    }

    static checkDateBefore(date, filterDate) {
        return DateTimeUtil.dateCompare(date, filterDate) === 1 || DateTimeUtil.dateCompare(date, filterDate) === 0;
    }

    static checkDateAfter(date, filterDate) {
        return DateTimeUtil.dateCompare(date, filterDate) === -1 || DateTimeUtil.dateCompare(date, filterDate) === 0;
    }

    static dateCompare(DateA, DateB) {
        const a = new Date(DateA);
        const b = new Date(DateB);

        const msDateA = Date.UTC(a.getFullYear(), a.getMonth() + 1, a.getDate());
        const msDateB = Date.UTC(b.getFullYear(), b.getMonth() + 1, b.getDate());

        if (parseFloat(msDateA) < parseFloat(msDateB))
            return -1;
        else if (parseFloat(msDateA) === parseFloat(msDateB))
            return 0;
        else if (parseFloat(msDateA) > parseFloat(msDateB))
            return 1;
        else
            return null;
    }

    static dateToString(date) {
        const newDate = new Date(date);
        return newDate.toLocaleDateString() + ' ' + newDate.toLocaleTimeString();
    }

    static dateTimeToString(inDate) {
        const date = new Date(inDate);
        return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`;
    }
}