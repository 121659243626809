import axios from "axios";
import {apiVersionOrders, gatewayUrl, nameOrders} from "../../../../Constants";

const headerRestUrl = gatewayUrl + nameOrders + apiVersionOrders + '/organizations/headers'

export default class HeaderService {

    static async getAll() {
        return axios.get(headerRestUrl);
    }

    static async getAllUnable() {
        return axios.get(headerRestUrl + '/unable');
    }

    static async getAllUnableForModels() {
        return axios.get(headerRestUrl + '/unable-models');
    }

    static async update(data) {
        await axios.post(headerRestUrl + '/' + +data.id, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async save(data) {
        return await axios.post(headerRestUrl, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async delete(id) {
        await axios.delete(headerRestUrl + '/' + id);
    }

    static async unable(id, status) {
        return axios.patch(headerRestUrl + '/' + id + '/unable?status=' + status, [], {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static unableForModels(id, status) {
        return axios.patch(headerRestUrl + '/' + id + '/unable-for-models?status=' + status, [], {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
}