export default class FilterUtil {

    static statusList = [
        'активный', 'неактивный'
    ]

    static checkStatus(value, filterStatus) {
        let check = false;
        if (filterStatus[0] === (FilterUtil.statusList[0])) {
            check = value;
        }
        if (filterStatus[0] === (FilterUtil.statusList[1])) {
            check = !value;
        }
        return check;
    }
}