import React from 'react';
import {ModelReplacementsTable} from "../../../../components/UI/table/model-estimate/model/ModelReplacementsTable";
import {useParams} from "react-router-dom";

const ModelReplacement = () => {

    const params = useParams();

    return (
        <div>
            <ModelReplacementsTable
                materialId={params.id}
            />
        </div>
    );
};

export default ModelReplacement;