import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack} from "@mui/material";
import React, {useEffect, useState} from "react";
import MaterialReserveService from "../../../../../API/orders/reservation/MaterialReserveService";
import MaterialWarehouseTableRMEU from "../material/materialwarehouse/MaterialWarehouseTableRMEU";
import MaterialWarehouseService from "../../../../../API/orders/warehouse/material/MaterialWarehouseService";

export const CreateSlabMaterialModal = ({
                                            open,
                                            onClose,
                                            handleCreateNewRow,
                                            id
                                        }) => {
    const [data, setData] = useState([]);
    const [selectedMaterial, setSelectedMaterial] = useState([]);

    const selectMaterial = (material) => {
        setSelectedMaterial(material)
    }

    useEffect(() => {

            MaterialWarehouseService.getAllByMaterialEstimateUnitId(id)
                .then(function (response) {
                    const data = response.data;
                    setData(data);
                })
                .catch(function (error) {
                    console.log(error);
                });
        }, []
    );

    const handleSubmit = () => {
        const materialWarehouseId = selectedMaterial.id
        MaterialReserveService.save(id, materialWarehouseId, true, true, true, true).then(function (response) {
            handleCreateNewRow(response.data);
            onClose();
        })
            .catch(function (error) {
                console.log(error);
            });
    };

    const exit = () => {
        onClose()
    }

    return (
        <Dialog open={open}>
            <DialogTitle textAlign="center">Добавить резерв</DialogTitle>
            <DialogContent>
                <form onSubmit={(e) => e.preventDefault()}>
                    <Stack spacing={2}>
                    </Stack>
                    <Stack
                        sx={{
                            width: '100%',
                            minWidth: {xs: '300px', sm: '360px', md: '400px'},
                            gap: '1.5rem',
                        }}
                    >
                        <MaterialWarehouseTableRMEU
                            data={data}
                            selectMaterial={selectMaterial}
                        />
                    </Stack>
                </form>
            </DialogContent>
            <DialogActions sx={{p: '1.25rem'}}>
                <Button onClick={exit}>Выход</Button>
                <Button color="secondary" onClick={handleSubmit} variant="contained">
                    Добавить
                </Button>
            </DialogActions>
        </Dialog>
    );
};
export default CreateSlabMaterialModal;