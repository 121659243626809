import React from 'react';
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import TextFieldSimple from "../../../textfield/TextFieldSimple";

const ModalPerformRefund = ({
                                open,
                                onClose,
                                titleModal,
                                issue,
                                refund,
                                setRefund,
                                submit
                            }) => {

    return (
        <div>
            <Dialog open={open}>
                <DialogTitle textAlign="center">{titleModal}</DialogTitle>
                <DialogContent>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        '& > *': {
                            marginRight: '10px',
                        }
                    }}>
                        <TextFieldSimple
                            label="Выдано"
                            value={issue}
                            field={'issue'}
                            disabled={true}
                        />
                        <TextFieldSimple
                            label="Вернуть"
                            value={refund}
                            setValue={setRefund}
                            field={'refund'}
                        />
                    </Box>
                </DialogContent>
                <DialogActions sx={{p: '1.25rem'}}>
                    <Button onClick={submit}>Вернуть</Button>
                    <Button onClick={onClose}>Выйти</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ModalPerformRefund;