import React, {useEffect, useState} from 'react';
import ModelsTable from "../../../../components/UI/table/model-estimate/model/ModelsTable";
import ModelService from "../../../../API/model-estimate/model/ModelService";

const Models = () => {

    const [data, setData] = useState([]);
    const [unable, setUnable] = useState({});
    const [change, setChange] = useState(null);

    useEffect(() => {
        ModelService.getAll().then(function (response) {
            let data = response.data;
            setData(data);
            /*  setModelHeaderList(DataUtil.getListChildField(response.data, 'header', 'name'));*/
            data.forEach((row) => {
                unable[row.id] = row.enabled;
            });
        })
            .catch(function (error) {
                console.log(error);
            });
    }, [change]);

    return (
        <div>
            <ModelsTable
                data={data}
                setData={setData}
                unable={unable}
                setUnable={setUnable}
                isButtonDelete={true}
                isButtonBasicModels={true}
                isButtonCreateModel={true}
                isButtonSettings={true}
                isButtonUpdate={true}
                enableRowSelection={true}
                isColumnUnable={true}
                isRedirect={true}
                isBasicModel={true}
                upLoad={true}
                change={change}
                setChange={setChange}
            />
        </div>
    );
};

export default Models;