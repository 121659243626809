import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack} from "@mui/material";
import TextFieldWithSetValue from "../../../textfield/TextFieldWithSetValue";
import MaterialOptimizationTextFieldObjectWithEntityList
    from "../../../textfield/MaterialOptimizationTextFieldObjectWithEntityList";

const ModalCreateOrUpdateDetailModel = ({
                                            open,
                                            onClose,
                                            title,
                                            values,
                                            setValues,
                                            labelButtonSSubmit,
                                            handleSubmit,
                                            isOptimization,
                                            materialList,
                                            setMaterialId
                                        }) => {

    return (
        <Dialog open={open}>
            <DialogTitle textAlign="center">{title}</DialogTitle>
            <DialogContent>
                <form onSubmit={(e) => e.preventDefault()}>
                    <Stack
                        sx={{
                            width: '100%',
                            minWidth: {xs: '300px', sm: '360px', md: '400px'},
                            gap: '1.5rem',
                        }}
                    >
                        {isOptimization && <MaterialOptimizationTextFieldObjectWithEntityList
                            label="Материал"
                            field={'material'}
                            setId={setMaterialId}
                            entityList={materialList}
                        />}
                        <TextFieldWithSetValue
                            label="Позиция"
                            values={values}
                            setValues={setValues}
                            field={'position'}
                        />
                        <TextFieldWithSetValue
                            label="Найменование"
                            values={values}
                            setValues={setValues}
                            field={'name'}
                        />
                        <TextFieldWithSetValue
                            label="Ширина"
                            values={values}
                            setValues={setValues}
                            field={'length'}
                        />
                        <TextFieldWithSetValue
                            label="Высота"
                            values={values}
                            setValues={setValues}
                            field={'height'}
                        />
                        <TextFieldWithSetValue
                            label="количество"
                            values={values}
                            setValues={setValues}
                            field={'quantity'}
                        />
                        <TextFieldWithSetValue
                            label="l1"
                            values={values}
                            setValues={setValues}
                            field={'l1'}
                        />
                        <TextFieldWithSetValue
                            label="l2"
                            values={values}
                            setValues={setValues}
                            field={'l2'}
                        />
                        <TextFieldWithSetValue
                            label="w1"
                            values={values}
                            setValues={setValues}
                            field={'w1'}
                        />
                        <TextFieldWithSetValue
                            label="w2"
                            values={values}
                            setValues={setValues}
                            field={'w2'}
                        />
                        <TextFieldWithSetValue
                            label="Паз"
                            values={values}
                            setValues={setValues}
                            field={'groove'}
                        />
                        <TextFieldWithSetValue
                            label="Материал"
                            values={values}
                            setValues={setValues}
                            field={'nameMaterial'}
                        />
                    </Stack>
                </form>
            </DialogContent>
            <DialogActions sx={{p: '1.25rem'}}>
                <Button onClick={onClose}>Выйти</Button>
                <Button color="secondary" onClick={handleSubmit} variant="contained">
                    {labelButtonSSubmit}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ModalCreateOrUpdateDetailModel;