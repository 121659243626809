import axios from "axios";
import {apiVersionOrders, gatewayUrl, nameOrders} from "../../../../Constants";

const supplierMaterialRestUrl = gatewayUrl + nameOrders + apiVersionOrders + '/organizations/supplier-materials'

export default class UserSupplierMaterialService {

    static async getAllByMaterialId(materialId) {
        return axios.get(supplierMaterialRestUrl + '/material/' + materialId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async getAllByMaterialIdMinStripe(materialId) {
        return axios.get(supplierMaterialRestUrl + '/material/' + materialId + '/min-stripe', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async getAllByMaterialIdFixedStripe(materialId) {
        return axios.get(supplierMaterialRestUrl + '/material/' + materialId + '/fixed-stripe', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async getCurrentByMaterialOrganizationOptionIdAndSupplierId(materialOrganizationOptionId, supplierId) {
        return axios.get(supplierMaterialRestUrl + '/current-by-material-id-and-supplier-id'
            + "?materialOrganizationOptionId=" + materialOrganizationOptionId + "&supplierId=" + supplierId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async getCurrentByMaterialOrganizationOptionIdAndSupplierIdMinStripe(materialOrganizationOptionId, supplierId) {
        return axios.get(supplierMaterialRestUrl + '/current-min-stripe-by-material-id-and-supplier-id'
            + "?materialOrganizationOptionId=" + materialOrganizationOptionId + "&supplierId=" + supplierId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async getAllCurrentByMaterialOrganizationOptionIdAndSupplierIdFixedStripe(materialOrganizationOptionId, supplierId) {
        return axios.get(supplierMaterialRestUrl + '/current-fixed-stripes-by-material-id-and-supplier-id'
            + "?materialOrganizationOptionId=" + materialOrganizationOptionId + "&supplierId=" + supplierId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static
    async getAllBySupplierId(supplierId) {
        return axios.get(supplierMaterialRestUrl + '/supplier/' + supplierId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async update(data) {
        return await axios.post(supplierMaterialRestUrl + '/' + data.id, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async createByMaterialOrganizationOption(data, materialOrganizationOptionId, supplierId, currencyId, price) {
        return await axios.post(supplierMaterialRestUrl + '/materialOrganizationOption' + "?materialOrganizationOptionId="
            + materialOrganizationOptionId + "&supplierId=" + supplierId + "&currencyId=" + currencyId + "&price=" + price, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async sendToArchive(id) {
        await axios.patch(supplierMaterialRestUrl + '/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        }, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async getAllCurrentWholeByMaterialOrganizationOptionId(materialOrganizationOptionId) {
        return axios.get(supplierMaterialRestUrl + '/get-all-whole-current-material-organization-option'
            + "?materialOrganizationOptionId=" + materialOrganizationOptionId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async getAllCurrentByMaterialOrganizationOptionIdFixedStripe(materialOrganizationOptionId) {
        return axios.get(supplierMaterialRestUrl + '/get-all-fixed-stripe-current-material-organization-option'
            + "?materialOrganizationOptionId=" + materialOrganizationOptionId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async getAllCurrentByMaterialOrganizationOptionIdMinStripe(materialOrganizationOptionId) {
        return axios.get(supplierMaterialRestUrl + '/get-all-min-stripe-current-material-organization-option'
            + "?materialOrganizationOptionId=" + materialOrganizationOptionId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async getAllByMaterialOrganizationOption(materialOrganizationOptionId) {
        return axios.get(supplierMaterialRestUrl + '/by-material-organization-option'
            + "?materialOrganizationOptionId=" + materialOrganizationOptionId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
}