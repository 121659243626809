import React from 'react';
import {TextField} from "@mui/material";

const TextFieldSimple = ({label, value, setValue, field, disabled}) => {
    return (
        <div>
            <TextField
                size={"small"}
                sx={{
                    minWidth: {xs: '150px', sm: '150px', md: '150px'},
                    maxWidth: {xs: '150px', sm: '150px', md: '150px'},
                    gap: '1.5rem',
                }}
                margin="normal"
                key={field}
                label={label}
                name={field}
                disabled={disabled == null ? false : disabled}
                defaultValue={value === null ? "" : value}
                onChange={(e) =>
                    setValue(e.target.value)
                }
            />
        </div>
    );
};

export default TextFieldSimple;