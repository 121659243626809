import { useFrame } from "@react-three/fiber";
import {  useRef } from "react";

const Layer = ({ layer, children }) => {
    const sceneRef = useRef(null);

    useFrame(({ gl, camera }) => {
        gl.autoClear = false;
        gl.clearDepth();
        gl.render(sceneRef.current, camera);
    }, layer);

    return <scene ref={sceneRef}>{children}</scene>;
};
export default Layer
