import React, {useCallback, useMemo, useState} from 'react';
import MaterialReactTable from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import Box from "@mui/material/Box";
import {Button, Tooltip} from "@mui/material";
import Delete from "@mui/icons-material/DeleteOutlined";
import FilterUtil from "../../../../utils/common/FilterUtil";
import CellCheckboxWithUnable from "../../сheckbox/CellCheckboxWithUnable";
import ModalAddDetail from "./ModalAddDetail";
import {useNavigate} from "react-router-dom";
import DetailControllerService from "../../../../API/details/DetailControllerService";
import ModalAlert from "./ModalAlert";
import {ReactComponent as ProcessingIcon} from "../../../Icons/processing.svg";
import {Edit} from "@mui/icons-material";
import {constantsErrorMessages} from "../../../../Constants";

const CustomerDetailsTable = ({
                                  data,
                                  isError,
                                  unableTurn,
                                  unablePackage,
                                  isColumnMaterial,
                                  isColumnOrder,
                                  enableGrouping,
                                  columnGrouping,
                                  expanded,
                                  pageSize,
                                  setData,
                                  enableEditing,
                                  enableRowSelection,
                                  orderId,
                                  requestsCuttingId
                              }) => {

    const navigate = useNavigate();
    const redirectProcessing = (el) => {
        navigate(`/customer/orders/${orderId}/requests-cutting/${requestsCuttingId}/details/${el.id}`);
    };

    const [createOrUpdateModalOpen, setCreateOrUpdateModalOpen] = useState(false);
    const [alertData, setAlertData] = useState({});

    const rowInitValue = {position: "", name: "", length: "", height: "18", width: ""}

    const [rowSelection, setRowSelection] = useState({});
    const [currentEditRowData, setCurrentEditRowData] = useState(rowInitValue);
    const [currentEditRowId, setCurrentEditRowId] = useState(null);
    const [isNew, setIsNew] = useState(null);

    const uniqueNames = data.map(el => el.position)

    const handleCloseAlert = () => {
        setAlertData((alert) => {
            return {...alert, open: false}
        })
    }
    const handleError = (error) => {
        setAlertData({
            type: 'error',
            open: true,
            message: constantsErrorMessages[error.response.status] || error.message
        })
    }

    const handleUpdateDetail = (row) => {
        setCurrentEditRowData((prev) => {
            return Object.keys(prev).reduce((acc, it) => ({...acc, [it]: row[it]}), {})
        });
        setCurrentEditRowId(row.id);
        setIsNew(false);
        setCreateOrUpdateModalOpen(true);
    }

    const handleDeleteDetail = (row) => {
        DetailControllerService.delete(orderId, row.original)
            .then(function () {
                setData(prev => prev.filter(el => el.id !== row.original.id))
            })
            .catch(function (error) {
                handleError(error)
            })
    };

    const handleAddDetail = () => {
        setIsNew(true);
        setCurrentEditRowData(rowInitValue);
        setCreateOrUpdateModalOpen(true);
    };

    const handleSubmitCreateDetail = (values) => {
        DetailControllerService.save(orderId, values).then(function (response) {
            setData(value => [...value, response.data])
        })
            .catch(function (error) {
                handleError(error)
            })
            .then(handleCloseCreateOrUpdate)

    }
    const handleSubmitUpdateDetail = (values) => {
        DetailControllerService.update(orderId, currentEditRowId, values).then(function (response) {
            setData(prev => prev.map(el => el.id === response.data.id ? response.data : el))
        })
            .catch(function (error) {
                handleError(error)
            })
            .then(handleCloseCreateOrUpdate)
    }

    const handleCloseCreateOrUpdate = () => {
        setIsNew(null);
        setCurrentEditRowId(null);
        setCreateOrUpdateModalOpen(false);
    };

    const columns = useMemo(() => {
        const baseColumns = [{
            id: 'position',
            header: 'Позиция',
            accessorKey: 'position',
            minSize: 70,
            Cell: ({cell, row}) => <span style={{
                color: row.original.modified ? 'green' : (row.original.excluded ? 'red' : (row.original.ignored ? 'orange' : 'black'))
            }}>{cell.getValue()}</span>,
        }, {
            id: 'name', header: 'Наименование', accessorKey: 'name', minSize: 60, Cell: ({cell, row}) => <span style={{
                color: row.original.modified ? 'green' : (row.original.excluded ? 'red' : (row.original.ignored ? 'orange' : 'black'))
            }}>{cell.getValue()}</span>,
        }, {
            id: 'length',
            header: 'L',
            accessorKey: 'length',
            minSize: 15,
            maxSize: 15,
            Cell: ({cell, row}) => <span style={{
                color: row.original.modified ? 'green' : (row.original.excluded ? 'red' : (row.original.ignored ? 'orange' : 'black'))
            }}>{cell.getValue()}</span>,
        }, {
            id: 'width',
            header: 'W',
            accessorKey: 'width',
            minSize: 15,
            maxSize: 15,
            Cell: ({cell, row}) => <span style={{
                color: row.original.modified ? 'green' : (row.original.excluded ? 'red' : (row.original.ignored ? 'orange' : 'black'))
            }}>{cell.getValue()}</span>,
        }, {
            id: 'height',
            header: 'H',
            accessorKey: 'height',
            minSize: 15,
            maxSize: 15,
            Cell: ({cell, row}) => <span style={{
                color: row.original.modified ? 'green' : (row.original.excluded ? 'red' : (row.original.ignored ? 'orange' : 'black'))
            }}>{cell.getValue()}</span>,
        }, {
            id: 'quantity',
            header: 'кол.',
            accessorKey: 'quantity',
            minSize: 15,
            maxSize: 15,
            Cell: ({cell, row}) => <span style={{
                color: row.original.modified ? 'green' : (row.original.excluded ? 'red' : (row.original.ignored ? 'orange' : 'black'))
            }}>{cell.getValue()}</span>,
        },];


        baseColumns.splice(2, 0, {
            id: 'package',
            header: 'Упаковка',
            accessorKey: 'Упаковка',
            size: 8,
            enableEditing: false,
            filterVariant: 'multi-select',
            filterSelectOptions: FilterUtil.statusList,
            filterFn: (row, id, filterValue) => {
                return FilterUtil.checkStatus(row.getValue(id), filterValue)
            },
            Cell: ({cell, row}) => <CellCheckboxWithUnable
                id={row.id}
                unable={unablePackage}
                disabled={row.original.excluded}
            >
            </CellCheckboxWithUnable>,
        });
        baseColumns.splice(3, 0, {
            id: 'turn',
            header: 'Поворот',
            accessorKey: 'turn',
            size: 8,
            enableEditing: false,
            filterVariant: 'multi-select',
            filterSelectOptions: FilterUtil.statusList,
            filterFn: (row, id, filterValue) => {
                return FilterUtil.checkStatus(row.getValue(id), filterValue)
            },
            Cell: ({cell, row}) => <CellCheckboxWithUnable
                id={row.id}
                unable={unableTurn}
                disabled={row.original.excluded}
            >
            </CellCheckboxWithUnable>,
        });

        return baseColumns;

    }, [data, isColumnMaterial, isColumnOrder]);

    return (<div>
        <MaterialReactTable
            muiTableBodyProps={{
                sx: () => ({
                    '& tr:nth-of-type(odd)': {
                        backgroundColor: 'lightgray',
                    },
                }),
            }}
            muiTableBodyCellProps={{
                sx: {
                    p: '0px 0px 0px 10px', m: '0px 0px 0px 0px',
                },
            }}
            muiTableBodyRowProps={{
                sx: {
                    height: '10px',
                },
            }}

            displayColumnDefOptions={{
                'mrt-row-actions': {
                    muiTableHeadCellProps: {
                        align: 'center',
                    }, size: 15, minSize: 100,
                },
            }}
            columns={columns}
            data={isError ? [] : data}
            rowHeight={13}
            columnSpacing={1}
            initialState={{
                columnVisibility: {
                    id: false, material: isColumnMaterial, order: isColumnOrder,
                },
                density: "compact",
                grouping: columnGrouping,
                expanded: expanded,
                pagination: {pageIndex: 0, pageSize: pageSize},
                sorting: [{id: 'position', desc: false}],
            }}

            enableEditing={enableEditing}
            enableGrouping={enableGrouping}
            onRowSelectionChange={setRowSelection}
            state={{rowSelection}}
            enableRowSelection={enableRowSelection}
            getRowId={(originalRow) => originalRow.id}
            selectionPadding={1}
            selectionCss={{
                backgroundColor: 'lightblue',
                borderWidth: '2px',
                borderStyle: 'solid',
                borderColor: 'blue',
                borderRadius: '4px',
            }}
            renderRowActions={({row, table}) => (<Box sx={{
                display: 'flex', gap: '0.1rem', justifyContent: 'flex-start', maxWidth: '50px'
            }}>
                <Tooltip arrow placement="left" title="Редактировать">
                    <Button size="small" sx={{minWidth: 'auto', marginLeft: 'auto'}} color="pencil"
                            onClick={() => handleUpdateDetail(row.original)}>
                        <Edit sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                    </Button>
                </Tooltip>
                <Tooltip arrow placement="right" title="Удалить">
                    <Button size="small" sx={{minWidth: 'auto', marginLeft: 'auto'}} color="error"
                            onClick={() => handleDeleteDetail(row)}>
                        <Delete sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                    </Button>
                </Tooltip>
                <Tooltip arrow placement="right" title="обработка">
                    <Button size="small" sx={{minWidth: 'auto', marginLeft: 'auto',}}
                            onClick={() => redirectProcessing(row.original)}>
                        <ProcessingIcon style={{width: "15px", height: "15px"}}/>
                    </Button>
                </Tooltip>
            </Box>)}
            localization={MRT_Localization_RU}
            renderTopToolbarCustomActions={({table}) => {
                return (<div>
                    <Box
                        sx={{display: 'flex', gap: '0.5rem', p: '0.5rem', flexWrap: 'wrap'}}
                    >
                        <Button
                            size="small"
                            color="success"
                            onClick={handleAddDetail}
                            variant="contained"
                            disabled={isError}
                        >
                            Добавить деталь
                        </Button>
                    </Box>
                </div>);
            }}
        />
        <ModalAddDetail
            open={createOrUpdateModalOpen}
            onClose={handleCloseCreateOrUpdate}
            title={isNew ? 'Создать деталь' : 'Редактировать деталь'}
            labelButtonSubmit={isNew ? 'Создать' : 'Обновить'}
            handleSubmit={isNew ? handleSubmitCreateDetail : handleSubmitUpdateDetail}
            initValue={currentEditRowData}
            uniqueNames={isNew ? uniqueNames : uniqueNames.filter(el => el !== currentEditRowData.position)}
        />
        <ModalAlert data={alertData} onClose={handleCloseAlert}/>
    </div>);
};

export default CustomerDetailsTable;
