import React from 'react';
import {useParams} from "react-router-dom";
import ArrivalMaterialModuleItem
    from "../../../../../components/UI/modul/arrivalmaterialmoduleitem/ArrivalMaterialModuleItem";

const ArrivalMaterialItem = () => {

    const params = useParams();

    return (
        <div>
            <ArrivalMaterialModuleItem
                id={params.id}
                isSlabMaterial={params.isSlabMaterial}
                baseUnit={params.baseUnit}
            />
        </div>
    );
};

export default ArrivalMaterialItem;