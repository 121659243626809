import React from 'react';
import {Box} from "@mui/material";

const HeaderOPT = ({column, height}) => {
    return (
        <Box sx={{
            whiteSpace: 'pre-wrap',
            writingMode: 'vertical-rl',
            transform: 'rotate(180deg)',
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'flex-start',
            height: height,
            padding: 0,
        }}>
            {column.columnDef.header}
        </Box>
    );
};

export default HeaderOPT;
