import axios from "axios";
import ObjectUtil from "../../../../utils/common/ObjectUtil";
import {apiVersionOrders, gatewayUrl, nameOrders} from "../../../../Constants";

const estimateRestUrl = gatewayUrl + nameOrders + apiVersionOrders + '/organizations/estimates'

export default class EstimateService {

    static async getAllByOrderId(id) {
        return axios.get(estimateRestUrl + '/order/' + id);
    }

    static async getAllNotCalculationAndNotArchive() {
        return axios.get(estimateRestUrl + '/get-all-not-calculation-not-archive');
    }

    static async save(data) {
        return await axios.post(estimateRestUrl, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async delete(id) {
        await axios.delete(estimateRestUrl + '/' + id);
    }

    static async unableCurrent(id, orderId) {
        return axios.patch(estimateRestUrl + '/' + id + '/unable-current' + '?orderId=' + orderId, [], {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async setPurchase(orderId) {
        return axios.patch(estimateRestUrl + '/set-purchase' + '?orderId=' + orderId, [], {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async setInWork(orderId) {
        return axios.patch(estimateRestUrl + '/set-in-work' + '?orderId=' + orderId, [], {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async returnInCalculation(id) {
        return axios.patch(estimateRestUrl + '/' + id + '/return-in-calculation', [], {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async setStatus(ids, estimateState) {
        let data = ObjectUtil.createRequestObjectIds(ids);
        return axios.patch(estimateRestUrl + '/set-estimate-state' + '?estimateState=' + estimateState, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async getAllIdsModelByEstimateId(estimateId) {
        return axios.get(estimateRestUrl + '/ids-model-by-estimate' + '?estimateId=' + estimateId);
    }
}