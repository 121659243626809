export default class SupplierMaterialUtil {

    static getById(supplierMaterials, supplierId) {
        for (const sM of supplierMaterials) {
            if (sM.supplier.id === Number.parseInt(supplierId)) {
                return sM;
            }
        }
        return null;
    }

    static deleteSupplierId(materialsWithSupplier, supplierId) {
        for (let i = 0; i < materialsWithSupplier.length; i++) {
            if (materialsWithSupplier[i].supplier.id === Number.parseInt(supplierId)) {
                materialsWithSupplier.splice(i, 1);
                break;
            }
        }
    }

    static deleteSupplierIdWithWeightAndHeight(materialsWithSupplier, supplierId, length, width) {
        for (let i = 0; i < materialsWithSupplier.length; i++) {
            if (materialsWithSupplier[i].supplier.id === Number.parseInt(supplierId) &&
                materialsWithSupplier[i].length === length && materialsWithSupplier[i].width === width
            ) {
                materialsWithSupplier.splice(i, 1);
                break;
            }
        }
    }

    static getSupplierMaterialFixedStripe (id, data) {
        for (let i = 0; i < data.length; i++) {
            if (data[i].id.toString() === id.toString()) {
                return data[i]
            }
        }
        return null;
    }
}