import React, {useEffect, useState} from 'react';
import WorkingCuttingMapTable from "../../../../components/UI/table/cutting/workingcuttingmap/WorkingCuttingMapTable";
import WorkingCuttingMapService from "../../../../API/orders/cutting/cuttingmap/WorkingCuttingMapService";
import WorkingCuttingMapUtil from "../../../../utils/cutting/WorkingCuttingMapUtil";

const QueueCuttingMaps = () => {

    const [data, setData] = useState([]);

    const [minNumberCutting, setMinNumberCutting] = useState(null);
    const [maxNumberCutting, setMaxNumberCutting] = useState(null);
    const [unableCut, setUnableCut] = useState({});

    useEffect(() => {

        WorkingCuttingMapService.getAllForCuttingWithOptimization().then(function (response) {
            const data = response.data;
            setData(data);
            const minAndMax = WorkingCuttingMapUtil.getMinAndMax(data);
            setMinNumberCutting(minAndMax.min_numberCutting)
            setMaxNumberCutting(minAndMax.max_numberCutting)
        })
            .catch(function (error) {
                console.log(error);
            });
    }, []);

    return (
        <div>
            <WorkingCuttingMapTable
                data={data}
                setData={setData}
                queue={true}
                minNumberCutting={minNumberCutting}
                maxNumberCutting={maxNumberCutting}
                unableCut={unableCut}
                setUnableCut={setUnableCut}
            />
        </div>
    );
};

export default QueueCuttingMaps;