import {subMenuData, menuData} from "./menuData";

export const determineAccessibility = (userRoles) => {
    const menu = []
    const subMenu = []

    userRoles.forEach(roles => {
            subMenuData.forEach((el) => el.roles.includes(roles.name) && subMenu.push(el))
            menuData.forEach((el) => el.roles.includes(roles.name) && menu.push(el))
        }
    )
    return {menu: menu, subMenu: subMenu}
}
