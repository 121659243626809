import React from 'react';
import Typography from "@mui/material/Typography";
import {useParams} from "react-router-dom";
import PackagingUnitTableAdd from "../../../../components/UI/table/model-estimate/packaging/PackagingUnitTableAdd";

const PackagingSetItemAddUnit = () => {

    const params = useParams();

    return (
        <div>
            <Typography variant="h6" component="h6">
                Добавить в комплект упаковки артикул: {params.article}
            </Typography>
            <PackagingUnitTableAdd
            id ={params.id}
            />
        </div>
    );
};

export default PackagingSetItemAddUnit;