import axios from "axios";
import {apiVersionModelEstimate, gatewayUrl, modelEstimate} from "../../../Constants";

const estimateRestUrl = gatewayUrl + modelEstimate + apiVersionModelEstimate + '/model-estimates'

export default class ModelEstimateService {

    static async getAllByModelId(id) {
        return axios.get(estimateRestUrl + '/model/' + id);
    }

    static async unableCurrent(id, modelId) {
        return axios.patch(estimateRestUrl + '/' + id + '/unable-current' + '?modelId=' + modelId, [], {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async delete(id) {
        await axios.delete(estimateRestUrl + '/' + id);
    }
}