import axios from "axios";
import {apiVersionMaterialCloud, gatewayUrl, nameMaterialCloud} from "../../../Constants";

const materialRestUrl = gatewayUrl + nameMaterialCloud + apiVersionMaterialCloud + '/admin/materials'

export default class MaterialService {

    static async getAllWithGroupMaterialAndUnit(nameMethodGetMaterial, supplierId) {
        return axios.get(materialRestUrl + '/' + nameMethodGetMaterial + "?supplierId="
            + supplierId);
    }

    static async getWithAllPrices(id) {
        return axios.get(materialRestUrl + '/with-all-prices/' + id);
    }

    static async updateMainOptions(data, supplierId, manufacturerId, groupMaterialId) {
        return await axios.post(materialRestUrl + '/' + data.id + "?supplierId="
            + supplierId + "&manufacturerId=" + manufacturerId + "&groupMaterialId=" + groupMaterialId, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async save(data) {
        return await axios.post(materialRestUrl, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async delete(id) {
        await axios.delete(materialRestUrl + '/' + id);
    }

    static async setBaseTrimming(trimmings, materialName) {
        await axios.patch(materialRestUrl + '/set-base-trimming' + "?materialName=" + materialName, trimmings);
    }
}