export default class MaterialsUtil {
    static applyFilter(dataMaterials, filterGroup, setFilterGroup) {

        let materials = [];

        dataMaterials.forEach((material) => {
            let idGroupMaterial = material.groupMaterial.id;
            if (filterGroup.has(idGroupMaterial)) {
                materials.push(material);
            }
        });

        setFilterGroup(new Map())
        return materials;
    }

    static addMaterial(materials, material) {
        if (!materials.has(material.id)) {
            materials.set(material.id, material);
        }
    }

    static equalsTrimming(trimming, tempTrimming) {

        if (trimming.trimmingLeft !== tempTrimming.trimmingLeft) {
            return false;
        }
        if (trimming.trimmingRight !== tempTrimming.trimmingRight) {
            return false;
        }
        if (trimming.trimmingTop !== tempTrimming.trimmingTop) {
            return false;
        }
        return !(trimming.trimmingBottom !== tempTrimming.trimmingBottom);
    }
}