import axios from "axios";
import {apiVersionMaterialCloud, gatewayUrl, nameMaterialCloud} from "../../../Constants";

const groupMaterialRestUrl = gatewayUrl +nameMaterialCloud + apiVersionMaterialCloud + '/user/group-materials'

export default class UserGroupMaterialMaterialCloudService {

    static async getAll() {
        return axios.get(groupMaterialRestUrl);
    }
}