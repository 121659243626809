import axios from "axios";
import {apiVersionOrders, gatewayUrl, nameOrders} from "../../../../Constants";

export const warehouseFinishedDetailRestUrl = gatewayUrl + nameOrders + apiVersionOrders + '/organizations/details-warehouse'

export default class WarehouseFinishedDetailService {

    static async getAll() {
        return axios.get(warehouseFinishedDetailRestUrl);
    }
}