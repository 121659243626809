import React, {useState} from 'react';
import UploadFilesForm from "../../../../form/UploadFilesForm";
import ModelFileService from "../../../../../API/model-estimate/file/ModelFileService";

const UploadModelDetailFileForm = ({
                                       labelForm,
                                       change,
                                       setChange,
                                       estimateId
                                   }) => {

    const [selectedFiles, setSelectedFiles] = useState([]);

    const handleUpload = async (event) => {

        event.preventDefault();

        ModelFileService.uploadModelDetailsFile(selectedFiles, estimateId)
            .then(function () {
                setSelectedFiles([]);
                setChange(!change)
                event.target.reset();
            })
            .catch(function (error) {
                console.log(error);
                event.target.reset();
            })

    }

    return (
        <div>
            <UploadFilesForm
                handleUpload={handleUpload}
                selectedFiles={selectedFiles}
                setSelectedFiles={setSelectedFiles}
                label={labelForm}
            />
        </div>
    );
};

export default UploadModelDetailFileForm;