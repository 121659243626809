import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import MaterialStripeTable
    from "../../../../../../components/UI/table/warehouse/material/materialStripe/MaterialStripeTable";
import UserSupplierMaterialService from "../../../../../../API/orders/warehouse/material/UserSupplierMaterialService";


const OrganizationMaterialFixedStripeArchiveItem = () => {

    const [data, setData] = useState([]);
    const params = useParams();
    const materialId = params.materialId

    useEffect(() => {

        UserSupplierMaterialService.getAllByMaterialIdFixedStripe(materialId)
            .then(function (response) {
                setData(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }, []);

    return (
        <div>
            <MaterialStripeTable
                data={data}
                setData={setData}
                materialId={materialId}
                baseUnit={params.baseUnit}
                typeMaterial={params.typeMaterial}
                isArchive={true}
            />
        </div>
    );
};

export default OrganizationMaterialFixedStripeArchiveItem;