import {Shape} from "three";

const calcShapePos = (el, position, height, side) => {
    if (position === "RIGHT") {
        return {x: side.x - (el.z * side.x / height), y: el.y}
    } else if (position === "LEFT") {
        return {x: el.z * side.x / height, y: el.y}
    } else if (position === "BACK") {
        return {x: el.x, y: side.y - (el.z * side.y / height)}
    } else if (position === "FRONT") {
        return {x: el.x, y: el.z * side.y / height}
    } else {
        return {x: el.x, y: el.y}
    }
}

export const createShape = (data, position, height, side) => {
    const shape = new Shape();

    data.forEach((el, i) => {
        const point = calcShapePos(el, position, height, side)
        if (i === 0) shape.moveTo(point.x, point.y)
        if (el.arcParams) {
            shape.absarc(el.arcParams.x, el.arcParams.y, el.arcParams.radius, el.arcParams.startAngle, el.arcParams.endAngle, el.arcParams.clockwise)
        } else {
            shape.lineTo(point.x, point.y)
        }
    })

    return shape
}
