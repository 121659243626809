import axios from "axios";
import ObjectUtil from "../../../utils/common/ObjectUtil";
import {apiVersionOrders, gatewayUrl, nameOrders} from "../../../Constants";

const materialPriceUnitRestUrl = gatewayUrl + nameOrders + apiVersionOrders + '/organizations/material-price-units'
export default class MaterialPriceUnitService {

    static async getAllByArrivalMaterialId(arrivalMaterialId) {
        return axios.get(materialPriceUnitRestUrl + '/arrival-material?arrivalMaterialId=' + arrivalMaterialId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async getByIdWithPrices(id) {
        return axios.get(materialPriceUnitRestUrl + '/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async updateCurrencyByIds(ids, newValue, typeCurrentCurrency, supplierId) {
        let data = ObjectUtil.createUpdateRequestObjectIds(ids, newValue);
        await axios.post(materialPriceUnitRestUrl + '/update-currency-by-ids'
            + '?typeCurrentCurrency=' + typeCurrentCurrency + '&supplierId=' + supplierId,
            JSON.stringify(data), {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
    }

    static async update(arrivalCurAbbreviation, supplierId, data) {
        return await axios.post(materialPriceUnitRestUrl + '/' + data.id + "?arrivalCurAbbreviation="
            + arrivalCurAbbreviation + '&supplierId=' + supplierId, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async create(arrivalCurAbbreviation, supplierId, arrivalMaterialId, data) {
        return await axios.post(materialPriceUnitRestUrl + "?arrivalCurAbbreviation="
            + arrivalCurAbbreviation + '&supplierId=' + supplierId + '&arrivalMaterialId=' + arrivalMaterialId,
            JSON.stringify(data), {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
    }

    static async delete(id) {
        await axios.delete(materialPriceUnitRestUrl + '/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async unableCut(id, status) {
        return axios.patch(materialPriceUnitRestUrl + '/' + id + '/unable-cut' + '?status=' + status, [], {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async updateTrimmingByIds(selectedIds, trimmingLeft, trimmingRight, trimmingTop, trimmingBottom) {
        let data = ObjectUtil.createRequestObjectIds(selectedIds);
        await axios.post(materialPriceUnitRestUrl + "/update-trimming-by-ids" + "?trimmingLeft="
            + trimmingLeft + '&trimmingRight=' + trimmingRight + '&trimmingTop=' + trimmingTop + '&trimmingBottom=' + trimmingBottom,
            JSON.stringify(data), {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
    }
}