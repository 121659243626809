const listEnabled = [
    'активен', 'не активен'
]

export default class WorkerUtil {

    static convertStatus(status) {
        return status === true ? listEnabled[0] : listEnabled[1];
    }

    static convertWorkers(workers) {
        let resultWorkers = "";
        if (workers) {
            let count = workers.length;
            let i = 0;
            for (const worker of workers) {
                i++;
                resultWorkers += worker.lastName + ' ' + worker.firstName[0] + '. ' + worker.middleName[0];
                if (i !== count) {
                    resultWorkers += ", "
                }
            }
        } else {
            resultWorkers = "No workers provided"; // Or handle the case as needed
        }
        return resultWorkers;
    }

    static convertWorker(worker) {
        return worker.firstName + ' ' + worker.lastName + ' ' + worker.middleName;
    }
}
