import React from 'react';
import {TextField} from "@mui/material";
import {materialCut} from "../../../../../../data/MaterialCutDefault";

const TextFieldObjectWithSupplierMaterialList = ({
                                                     label,
                                                     values,
                                                     setId,
                                                     field,
                                                     entityList,
                                                     disabled,
                                                     name,
                                                     nameValue,
                                                     isValueObject
                                                 }) => {

    const currentName = name ? name : 'name';
    const currentNameValue = nameValue ? nameValue : 'id';
    return (
        <div>
            <TextField
                size={"small"}
                disabled={disabled}
                key={field}
                sx={{
                    width: '100%',
                    minWidth: {xs: '300px', sm: '360px', md: '400px'},
                    gap: '1.5rem',
                }}
                margin="normal"
                select
                label={label}
                defaultValue={values === null ? "" : (values[field] === null ? null : values[field].id)}
                name={field}
                onChange={(e) =>
                    setId(e.target.value)
                }
                SelectProps={{
                    native: true,
                }}
            >
                {entityList != null && entityList.map((entity) => (
                    <option
                        key={entity.id}
                        value={isValueObject ? entity.valueOf() : entity[currentNameValue]}>
                        {materialCut[entity[currentName]] + " [" + entity.length + "x" + entity.width + "]"}
                    </option>
                ))}
            </TextField>
        </div>
    );
};

export default TextFieldObjectWithSupplierMaterialList;