export default class MaterialReserveUtil {
    static checkOrAllCalculation(data) {
        if (!data || data.length === 0) {
            return false;
        }
        for (const mr of data) {
            if (mr && mr.materialReserveState === 'Calculation') {
                return false;
            }
        }
        return true;
    }
}