import React from 'react';
import MaterialPageItemModule from "../../../../../components/UI/modul/MaterialPageItemModule";

const Materials = () => {

    return <div>
        <MaterialPageItemModule
            refMaterial={true}
            isAddMaterial={true}
            pageSize={30}
            nameMethodGetMaterial={'with-group-material-unit'}
        />
    </div>
};

export default Materials;