import React, {useEffect, useState} from 'react';
import SignJobTable from "../../../../components/UI/table/prodaction/productioncosts/SignJobTable";
import SignJobService from "../../../../API/orders/orders/organization/job/SignJobService";

const SignJob = () => {

    const [change, setChange] = useState(false);
    const [data, setData] = useState([]);
    const [rowSelection, setRowSelection] = useState({});
    const [rowIndexSelection, setRowIndexSelection] = useState([])
    const [selectedIds, setSelectedIds] = useState([]);

    useEffect(() => {
        SignJobService.getAll().then(function (response) {
            let data = response.data;
            setData(data);
        })
            .catch(function (error) {
                console.log(error);
            });
    }, [change]);

    return (
        <div>
            <SignJobTable
                data={data}
                setData={setData}
                change={change}
                setChange={setChange}
                rowSelection={rowSelection}
                setRowSelection={setRowSelection}
                rowIndexSelection={rowIndexSelection}
                setRowIndexSelection={setRowIndexSelection}
                selectedIds={selectedIds}
                setSelectedIds={setSelectedIds}
                enableEditing={true}
                uploadSignJob={true}
                isRedirectSettings={true}
            />
        </div>
    );
};

export default SignJob;