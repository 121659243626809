import React, {useEffect, useState} from 'react';
import RatingJobWorkerService from "../../../../API/orders/orders/organization/job/RatingJobWorkerService";
import {useParams} from "react-router-dom";
import RatingWorker from "../../../../components/UI/table/orders/organization/workerTable/RatingWorker";
import Typography from "@mui/material/Typography";

const RatingsWorker = () => {

    const params = useParams();
    const [data, setData] = useState([]);

    useEffect(() => {

        RatingJobWorkerService.getAllByWorkerId(params.id).then(function (response) {
            const data = response.data
            setData(data);
        })
            .catch(function (error) {
                console.log(error);
            });
    }, []);


    return (
        <div>
            <Typography
                sx={{
                    marginTop: '20px',
                    marginLeft: '15px',
                }}
                component="legend">Работник: {params.nameWorker}</Typography>
            {data.map((item, index) => (
                <div key={index}>
                    <RatingWorker
                        val={item.level}
                        name={item.typeJob.name}
                    />
                </div>
            ))}
        </div>
    );
};

export default RatingsWorker;