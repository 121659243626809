import React, {useEffect, useState} from 'react';
import IssuingEdgeService from "../../../API/orders/prodaction.issueedge/issueedge/IssuingEdgeService";
import IssuingEdgesTable from "../../../components/UI/table/prodaction/issuingEdges/IssuingEdgesTable";

const IssuingEdges = () => {

    const [data, setData] = useState([]);

    useEffect(() => {
        IssuingEdgeService.getAllByOrganizationNotClose().then(function (response) {
            const data = response.data;
            setData(data);
        })
            .catch(function (error) {
                console.log(error);
            });
    }, []);

    return (
        <div>
            <IssuingEdgesTable
                data={data}
                setData={setData}
            />
        </div>
    );
};

export default IssuingEdges;