export default class Common {

    static handleCreateNewRow = (values, data, setData) => {
        data.push(values);
        setData([...data]);
    };

    static handleUpdate = (currentEditRow, values, data, setData) => {
        data[currentEditRow.index] = values;
        setData([...data]);
    };

    static handleUnable = (id, status, unable, setUnable) => {
        unable[id] = status
        setUnable(prevState => ({
            ...prevState,
            [id]: status
        }));
    };
}