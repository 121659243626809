import axios from "axios";
import {apiVersionOrders, gatewayUrl, nameOrders} from "../../../../Constants";

const restSupplierUrl = gatewayUrl + nameOrders + apiVersionOrders + '/organizations/suppliers'

export default class SupplierService {

    static async getAllByOrganization() {
        return axios.get(restSupplierUrl + '/all-by-organization');
    }
}