import React from 'react';
import {Button, Dialog, DialogContent, DialogTitle} from "@mui/material";

const ModalConfirm = ({title, modalOpen, handleClose, handleSubmit, action}) => {
    return (
        <div>
            <Dialog open={modalOpen} onClose={handleClose}>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <Button type="submit" variant="contained" color="secondary" onClick={handleClose}
                                style={{marginRight: '10px'}}>
                            Отменить
                        </Button>
                        <Button type="submit" variant="contained" color="secondary" onClick={handleSubmit}>
                            {action}
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default ModalConfirm;

