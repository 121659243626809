import React from 'react';
import WorkerTable from "../../../../components/UI/table/orders/organization/workerTable/WorkerTable";

const Workers = () => {
    return (
        <div>
            <WorkerTable/>
        </div>
    );
};

export default Workers;