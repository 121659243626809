export const calcGroovesOnSidePos = (position, x, y, point, pointNext, height) => {
    if (position === "FRONT") {
        return {
            start: {
                x: point.x,
                y: point.z * y / height
            },
            next: {
                x: pointNext?.x,
                y: pointNext?.z * y / height
            }
        }
    } else if (position === "BACK") {
        return {
            start: {
                x: point.x,
                y: y - (point.z * y / height)
            },
            next: {
                x: pointNext?.x,
                y: y - (pointNext?.z * y / height)
            }
        }
    } else if (position === "RIGHT") {
        return {
            start: {
                x: x - (point.z * x / height),
                y: point.y
            },
            next: {
                x: x - (pointNext?.z * x / height),
                y: pointNext?.y
            }
        }
    } else if (position === "LEFT") {
        return {
            start: {
                x: point.z * x / height,
                y: point.y
            },
            next: {
                x: pointNext?.z * x / height,
                y: pointNext?.y
            }
        }
    } else {
        return {
            start: {
                x: point.x,
                y: point.y
            },
            next: {
                x: pointNext?.x,
                y: pointNext?.y,
            }
        }
    }
}
