import React from 'react';
import {Checkbox} from "@mui/material";

const CellCheckboxWithUnableWithComment = ({
                                               unable,
                                               setUnable,
                                               disabled,
                                               setModalOpen,
                                               setNewValue,
                                               setUnableCheckbox,
                                               row,
                                               setCurrentEditRow,
                                               setCurrentEditRowData
                                           }) => {
    const id = row.original.id;

    const handleCheckboxChange = (event) => {
        const newValue = event.target.checked;
        if (newValue === false) {
            setCurrentEditRowData(row.original)
            setNewValue(newValue);
            setUnableCheckbox(true);
            setCurrentEditRow(row);
            setModalOpen(true);
        } else {
            setUnable(id, newValue);
        }
    };

    return (
        <div style={{display: "flex", alignItems: "center", height: "13px", lineHeight: "13px"}}>
            <Checkbox
                disabled={disabled}
                onChange={handleCheckboxChange}
                size="small"
                checked={unable[id] || false}
                sx={{"& .MuiSvgIcon-root": {fontSize: 20}}}
            />
        </div>
    );
};

export default CellCheckboxWithUnableWithComment;