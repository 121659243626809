import React from 'react';
import UserTable from "../../components/UI/table/common/user/UserTable";

const Users = () => {
    return (
        <div>
            <UserTable/>
        </div>
    );
};

export default Users;