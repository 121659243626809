import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack} from "@mui/material";
import TextFieldWithSetValue from "../../../../textfield/TextFieldWithSetValue";

const ModalCreateCutOff = ({
                               open,
                               onClose,
                               title,
                               labelButtonSSubmit,
                               handleSubmit,
                               valuesMaterialPriceUnitData,
                               setValuesMaterialPriceUnitData
                           }) => {
    return (
        <Dialog open={open}>
            <DialogTitle textAlign="center">{title}</DialogTitle>
            <DialogContent>
                <form onSubmit={(e) => e.preventDefault()}>
                    <Stack
                        sx={{
                            width: '100%',
                            minWidth: {xs: '300px', sm: '360px', md: '400px'},
                            gap: '1.5rem',
                        }}
                    >
                        <TextFieldWithSetValue
                            label="Ширина"
                            values={valuesMaterialPriceUnitData}
                            setValues={setValuesMaterialPriceUnitData}
                            field={'length'}
                        />
                        <TextFieldWithSetValue
                            label="Высота"
                            values={valuesMaterialPriceUnitData}
                            setValues={setValuesMaterialPriceUnitData}
                            field={'width'}
                        />
                        <TextFieldWithSetValue
                            label="Отступ слева"
                            values={valuesMaterialPriceUnitData}
                            setValues={setValuesMaterialPriceUnitData}
                            field={'trimmingLeft'}
                        />
                        <TextFieldWithSetValue
                            label="Отступ справа"
                            values={valuesMaterialPriceUnitData}
                            setValues={setValuesMaterialPriceUnitData}
                            field={'trimmingRight'}
                        />
                        <TextFieldWithSetValue
                            label="Отступ сверху"
                            values={valuesMaterialPriceUnitData}
                            setValues={setValuesMaterialPriceUnitData}
                            field={'trimmingTop'}
                        />
                        <TextFieldWithSetValue
                            label="Отступ снизу"
                            values={valuesMaterialPriceUnitData}
                            setValues={setValuesMaterialPriceUnitData}
                            field={'trimmingBottom'}
                        />
                    </Stack>
                </form>
            </DialogContent>
            <DialogActions sx={{p: '1.25rem'}}>
                <Button onClick={onClose}>Выйти</Button>
                <Button color="secondary" onClick={handleSubmit} variant="contained">
                    {labelButtonSSubmit}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ModalCreateCutOff;

