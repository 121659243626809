import {Button} from "@mui/material";
import React from "react";
import {ReactComponent as BackIcon} from "../../Icons/back.svg";

const ButtonBack = ({onClick, text}) => {
    return <Button
        onClick={onClick}
        sx={{
            width: 93,
            height: 56,
            textTransform: 'none',
            fontSize: '16px',
            backgroundColor: "#F3F4F6",
            padding:"8px",
            display: "flex",
            justifyContent: "space-between",
            border: (theme) => `${theme.palette.primary.main} solid 1px`,
            color: (theme) => theme.palette.text.main,
            "&:hover": {
                backgroundColor: 'transparent',
            }
        }}>
        <BackIcon style={{width: "20px", height: "20px"}}/>
        {text}
    </Button>
}
export default ButtonBack