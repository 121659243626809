import React, {useCallback, useEffect, useMemo, useState} from 'react';
import MaterialReactTable from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField, Tooltip} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {Delete, Edit} from "@mui/icons-material";
import Box from "@mui/material/Box";

import HeaderService from "../../../../../../API/orders/orders/organization/HeaderService";
import CellCheckboxMHTUnable from "./CellCheckboxMHTUnable";
import HeaderUtil from "../../../../../../utils/orders/HeaderUtil";
import CellCheckboxMHTUnableModels from "./CellCheckboxMHTUnableModels";
import ButtonSettingsOrders from "../../../../button/ButtonSettingsOrders";
import FilterUtil from "../../../../../../utils/common/FilterUtil";

const ModelHeadersTable = () => {

    const [data, setData] = useState([]);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [change, setChange] = useState(null);

    const handleCreateNewRow = (values) => {
        data.push(values);
        setData([...data]);
    };

    useEffect(() => {
        HeaderService.getAll().then(function (response) {
            setData(response.data);
        })
            .catch(function (error) {
                console.log(error);
            });
    }, [change]);

    const handleSaveRowEdits = async ({exitEditingMode, row, values}) => {
        values.registered = new Date(values.registered)
        HeaderService.update(values)
            .then(function () {
                data[row.index] = values;
                setData([...data]);
            })
            .catch(function (error) {
                console.log(error);
            })
        exitEditingMode();
    };

    const handleDeleteRow = useCallback(
        (row) => {
            HeaderService.delete(row.original.id)
                .then(function () {
                    data.splice(row.index, 1);
                    setData([...data]);
                })
                .catch(function (error) {
                    console.log(error);
                })
        },
        [data],
    );


    const columns = useMemo(
        () => [
            {
                id: 'name',
                header: 'Наименование',
                accessorKey: 'name',
                minSize: 150,
            },
            {
                id: 'enabled',
                header: 'Статус',
                accessorKey: 'enabled',
                size: 8,
                maxSize: 8,
                enableEditing: false,
                filterVariant: 'multi-select',
                filterSelectOptions: FilterUtil.statusList,
                filterFn: (row, id, filterValue) => {
                    return FilterUtil.checkStatus(row.getValue(id), filterValue)
                },
                Cell: ({cell, row}) => <CellCheckboxMHTUnable
                    cell={cell}
                    id={data[row.index].id}
                    change={change}
                    setChange={setChange}
                >
                </CellCheckboxMHTUnable>,
            },
            {
                id: 'enabledModels',
                header: 'Для моделей',
                accessorKey: 'enabledModels',
                size: 8,
                maxSize: 8,
                enableEditing: false,
                filterVariant: 'multi-select',
                filterSelectOptions: FilterUtil.statusList,
                filterFn: (row, id, filterValue) => {
                    return FilterUtil.checkStatus(row.getValue(id), filterValue)
                },
                Cell: ({cell, row}) => <CellCheckboxMHTUnableModels
                    cell={cell}
                    id={data[row.index].id}
                    change={change}
                    setChange={setChange}
                >
                </CellCheckboxMHTUnableModels>,
            },
            {
                header: 'id',
                id: 'id',
                accessorKey: 'id',
                enableHiding: false,
                enableColumnActions: false,
                enableGrouping: false,
                enableEditing: false,
            },
        ],
        [data, setData],
    );

    return (
        <>
            <MaterialReactTable
                muiTableBodyProps={{
                    sx: () => ({
                        '& tr:nth-of-type(odd)': {
                            backgroundColor: 'lightgray',
                        },
                    }),
                }}
                muiTableBodyCellProps={{
                    sx: {
                        p: '0px 0px 0px 0px',
                        m: '0px',
                    },
                }}
                muiTableBodyRowProps={{
                    sx: {
                        height: '10px',
                    },
                }}
                isFullScreen={isFullScreen}
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        size: 120,
                    },
                }}
                columns={columns}
                data={data}
                editingMode="modal"
                enableEditing
                onEditingRowSave={handleSaveRowEdits}
                renderRowActions={({row, table}) => (
                    <Box sx={{display: 'flex', gap: '1rem'}}>
                        <Tooltip arrow placement="left" title="Редактировать">
                            <IconButton onClick={() => table.setEditingRow(row)}>
                                <Edit/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip arrow placement="right" title="Удалить">
                            <IconButton color="error" onClick={() => handleDeleteRow(row)}>
                                <Delete/>
                            </IconButton>
                        </Tooltip>
                    </Box>
                )}
                initialState={{
                    sorting: [{id: 'name', desc: false}],
                    columnVisibility: {
                        id: false,
                    },
                    isFullScreen: false,
                    density: "compact",
                    pagination: {pageIndex: 0, pageSize: 20},
                    columnPinning: {left: ['number']}
                }}
                localization={MRT_Localization_RU}
                renderTopToolbarCustomActions={() => (
                    <Box
                        sx={{display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap'}}
                    >
                        <Button
                            color="secondary"
                            onClick={() => setCreateModalOpen(true)}
                            variant="contained"
                        >
                            Создать найменование
                        </Button>
                        <ButtonSettingsOrders/>
                    </Box>
                )}
            />
            <CreateNewCustomerModal
                columns={columns}
                open={createModalOpen}
                onClose={() => setCreateModalOpen(false)}
                onSubmit={handleCreateNewRow}
            />
        </>
    );
};

export const CreateNewCustomerModal = ({open, columns, onClose, onSubmit}) => {

    const [values, setValues] = useState(() =>
        columns.reduce((acc, column) => {
            acc[column.accessorKey ?? ''] = '';
            return acc;
        }, {}),
    );


    const handleSubmit = () => {
        values.enabled = true;
        HeaderService.save(values).then(function (response) {
            onSubmit(response.data);
        })
            .catch(function (error) {
                console.log(error);
            });
        onClose();
    };

    return (
        <Dialog open={open}>
            <DialogTitle textAlign="center">Создать найменование</DialogTitle>
            <DialogContent>
                <form onSubmit={(e) => e.preventDefault()}>
                    <Stack
                        sx={{
                            width: '100%',
                            minWidth: {xs: '300px', sm: '360px', md: '400px'},
                            gap: '1.5rem',
                        }}
                    >
                        {columns.map((column) => (
                            HeaderUtil.checkField(column) &&
                            <TextField
                                margin="normal"
                                key={column.accessorKey}
                                label={column.header}
                                name={column.accessorKey}
                                onChange={(e) =>
                                    setValues({...values, [e.target.name]: e.target.value})
                                }
                            />
                        ))}
                    </Stack>
                </form>
            </DialogContent>
            <DialogActions sx={{p: '1.25rem'}}>
                <Button onClick={onClose}>Cancel</Button>
                <Button color="secondary" onClick={handleSubmit} variant="contained">
                    Сохранить
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ModelHeadersTable;