import React from 'react';
import {useParams} from "react-router-dom";
import PackagingItemTable from "../../../../components/UI/table/model-estimate/packaging/PackagingItemTable";
import Typography from "@mui/material/Typography";

const PackagingSetItem = () => {

    const params = useParams();

    return (
        <div>
            <Typography variant="h6" component="h6">
                Комплект упаковки артикул: {params.article}
            </Typography>
            <PackagingItemTable
                id={params.id}
                article={params.article}
            />
        </div>
    );
};

export default PackagingSetItem;