import axios from "axios";
import {apiVersionDetail, gatewayUrl, nameDetail} from "../../Constants";

const detailControllerRestUrl = gatewayUrl + nameDetail + apiVersionDetail + '/customers/orders/'

export default class HoleControllerService {
    static async save(orderId, detailId, data) {
        return await axios.post(`${detailControllerRestUrl}${orderId}/details/${detailId}/holes`, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async delete(orderId, detailId, holeId) {
        return await axios.delete(`${detailControllerRestUrl}${orderId}/details/${detailId}/holes/${holeId}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async put(orderId, detailId, holeId, data) {
        return await axios.put(`${detailControllerRestUrl}${orderId}/details/${detailId}/holes/${holeId}`, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
}
