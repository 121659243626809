import React from 'react';
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {TextField} from "@mui/material";
import dayjs from 'dayjs';

const OrderDatePicker = ({label, date, setDate}) => {
    return (
        <div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    label={label}
                    onChange={(newValue) => {
                        setDate(new Date(newValue))
                    }}
                    renderInput={(params) => (
                        <TextField
                            size={"small"}
                            margin="normal"
                            {...params}
                            sx={{
                                width: '100%',
                                minWidth: {xs: '300px', sm: '360px', md: '400px'},
                                gap: '1.5rem',
                            }}
                            variant="outlined"
                        />
                    )}
                    value={date ? dayjs(date) : null}
                />
            </LocalizationProvider>
        </div>
    );
};

export default OrderDatePicker;
