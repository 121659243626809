import React from 'react';
import ArrivalMaterialTable from "../../../../../components/UI/table/warehouse/material/arrival/ArrivalMaterialTable";

const ArrivalMaterialArchive = () => {
    return (
        <div>
            <ArrivalMaterialTable
                isCreateOrUpdate={false}
                method={'archive'}
            />
        </div>
    );
};

export default ArrivalMaterialArchive;