import React, {useState} from 'react';
import {Checkbox} from "@mui/material";

const CellCheckbox = ({cell, disabled}) => {

    const [status, setStatus] = useState(cell.getValue);

    return (
        <div style={{display: "flex", alignItems: "center", height: "13px", lineHeight: "13px"}}>
            <Checkbox
                disabled={disabled}
                size="small"
                checked={status}
                sx={{"& .MuiSvgIcon-root": {fontSize: 20}}}
            />
        </div>
    );
};

export default CellCheckbox;