import React from 'react';
import {List, ListItem, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import Box from "@mui/material/Box";
import {useNavigate} from "react-router-dom";
import UploadFileIcon from "@mui/icons-material/UploadFile";

const SettingsCloud = () => {

    const navigate = useNavigate();

    const redirectSettingsCloudLoadGroup = () => {
        navigate('/organization/settings-cloud-load-group');
    };

    const redirectSettingsCloudLoadMaterial = () => {
        navigate('/organization/settings-cloud-load-material');
    };

    return (
        <div>
            <Box>
                <nav aria-label="secondary mailbox folders">
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton onClick={redirectSettingsCloudLoadGroup}>
                                <ListItemIcon>
                                    <UploadFileIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Импорт групп"/>
                            </ListItemButton>
                        </ListItem>
                    </List>
                </nav>
                <nav aria-label="secondary mailbox folders">
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton onClick={redirectSettingsCloudLoadMaterial}>
                                <ListItemIcon>
                                    <UploadFileIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Импорт базы материалов"/>
                            </ListItemButton>
                        </ListItem>
                    </List>
                </nav>
            </Box>
        </div>
    );
};

export default SettingsCloud;