import {Box} from "@mui/material";
import React, {useEffect, useState} from "react";
import DetailService from "../../../../../API/orders/cutting/detail/DetailService";
import DetailTable from "../../../../../components/UI/table/cutting/details/DetailTable";
import {useParams} from "react-router-dom";
import Typography from "@mui/material/Typography";
import DetailUtil from "../../../../../utils/cutting/DetailUtil";
import MaterialsUtil from "../../../../../utils/warehouse/MaterialsUtil";

const DetailsRequestsCuttingOptimization = () => {

    const [data, setData] = useState([]);
    const [unableCut, setUnableCut] = useState({});
    const [unableTurn, setUnableTurn] = useState({});
    const [selectedIds, setSelectedIds] = useState([]);
    const [modalStatusOpen, setModalStatusOpen] = useState(false);
    const [status, setStatus] = useState(true);
    const [typeStatus, setTypeStatus] = useState(null);
    const [change, setChange] = useState(false);
    const [isError, setIsError] = useState(false);
    const [materialList, setMaterialList] = useState(new Map());

    const params = useParams();
    const optimizationId = params.optimizationId;
    const number = params.number;

    useEffect(() => {

        DetailService.getAllByOptimizationId(optimizationId).then(function (response) {
            let data = response.data;
            const error = DetailUtil.checkDuplicatePositions(data, true);
            if (error) {
                setIsError(true);
            }
            setData(data);
            data.forEach((row) => {
                MaterialsUtil.addMaterial(materialList, row.material)
                unableCut[row.id] = row.cut;
                unableTurn[row.id] = row.turn;
            });
        })
            .catch(function (error) {
                console.log(error);
            });
    }, [change]);

    return (

        <Box sx={{
            '& > *': {
                marginTop: '20px',
            }
        }}>
            <Typography variant="h6" component="h6">
                Детали на раскрой оптимизации {number}
            </Typography>
            <DetailTable
                data={data}
                setData={setData}
                unableCut={unableCut}
                unableTurn={unableTurn}
                setUnableCut={setUnableCut}
                setUnableTurn={setUnableTurn}
                selectedIds={selectedIds}
                setSelectedIds={setSelectedIds}
                modalStatusOpen={modalStatusOpen}
                setModalStatusOpen={setModalStatusOpen}
                status={status}
                setStatus={setStatus}
                typeStatus={typeStatus}
                setTypeStatus={setTypeStatus}
                isColumnMaterial={true}
                isColumnOrder={true}
                warehouse={'WarehouseFinishedDetail'}
                enableGrouping={true}
                columnGrouping={['materialName']}
                expanded={true}
                pageSize={100}
                isGetTXTSELCO={true}
                isRedirectOptimizations={true}
                isRedirectPlans={true}
                optimizationNumber={number}
                isOptimization={true}
                optimizationId={optimizationId}
                change={change}
                setChange={setChange}
                isError={isError}
                materialList={Array.from(materialList.values())}
                enableRowSelection={true}
                isButtonRunModify={true}
                enableEditing={true}
            />
        </Box>
    );
};

export default DetailsRequestsCuttingOptimization;