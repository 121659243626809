import React from 'react';
import {TextField} from "@mui/material";

const EntityList = ({label, values, setValues, field, disabled, allowedStatuses, statusesList}) => {

    return (
        <div>
            <TextField
                disabled={disabled}
                key={field}
                sx={{
                    width: '100%',
                    minWidth: {xs: '300px', sm: '360px', md: '400px'},
                    gap: '1.5rem',
                }}
                margin="normal"
                select
                label={label}
                value={values === null ? "" : (values[field] === null ? "" : values[field])}
                name={field}
                onChange={(e) =>
                    setValues({...values, [e.target.name]: e.target.value})
                }
                SelectProps={{
                    native: true,
                }}
            >
                {allowedStatuses.map((statusKey, index) => (
                    <option key={index} value={statusKey}>
                        {statusesList[statusKey]}
                    </option>
                ))}
            </TextField>
        </div>
    );
};

export default EntityList;