import axios from "axios";
import {apiVersionDetail, gatewayUrl, nameDetail} from "../../Constants";

const detailControllerRestUrl = gatewayUrl + nameDetail + apiVersionDetail + '/customers/orders/'

export default class VariableControllerService {
    static async get(orderId, detailId) {
        return axios.get(`${detailControllerRestUrl}${orderId}/details/${detailId}/variables`);
    }

    static async save(orderId, detailId, data) {
        return await axios.post(`${detailControllerRestUrl}${orderId}/details/${detailId}/variables`, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async delete(orderId, detailId, variableId) {
        return await axios.delete(`${detailControllerRestUrl}${orderId}/details/${detailId}/variables/${variableId}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async edit(orderId, detailId, variableId, data) {
        return await axios.put(`${detailControllerRestUrl}${orderId}/details/${detailId}/variables/${variableId}`, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
}
