import React from 'react';
import {Tooltip} from "@mui/material";
import {materialReserveTypePrice} from "../../../../../../data/MaterialReserveTypePrice";

const CellMaterialEstimateUnitTypePrice = ({value, row}) => {

    const averagePriceIncrease = row.original.averagePriceIncreaseInBasePrice

    const absentAveragePriceIncrease = averagePriceIncrease === null;
    const roundedAveragePriceIncrease = absentAveragePriceIncrease ? 0 : averagePriceIncrease.toFixed(3);

    const titleLast = 'Последняя цена по приходу материала'
    const increased = absentAveragePriceIncrease ? '' : averagePriceIncrease > 1 ? 'увеличена' : 'уменьшена'
    const titleBase = absentAveragePriceIncrease ? 'нет данных по поступлениям поставщика, установленного по умолчанию, по данному производителю материала'
        : 'базовая цена ' + increased + ' на среднее отклонение ' + averagePriceIncrease + ' поставщика, установленного по умолчанию, по данному производителю материала'
    const titleComposite = 'состовная цена полученная из цены целых листов или использованных полос'

    const title = value === 'BASE' ? titleBase : (value === 'LAST' ? titleLast : titleComposite);
    const coefficient = value === 'BASE' ? absentAveragePriceIncrease ? '(1)' : '(' + roundedAveragePriceIncrease + ')' : '';

    return (
        <div>
            <Tooltip arrow placement="right" title={title}>
                <span
                    size="small"
                    sx={{minWidth: 'auto', marginLeft: 'auto'}}
                    color="error">
                   {materialReserveTypePrice[value]}{coefficient}
                </span>
            </Tooltip>
        </div>
    );
};

export default CellMaterialEstimateUnitTypePrice;