import React, {useEffect, useState} from 'react';
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import OrderService from "../../../../../API/orders/orders/organization/OrderService";
import OrderUtil from "../../../../../utils/orders/OrderUtil";
import OrderTable from "../../orders/organization/order/OrderTable";

const ModalDeleteOrdersInIssuingEdge = ({
                                            open,
                                            onClose,
                                            deleteOrdersInIssuingEdge,
                                            issuingEdgesId,
                                            titleModal
                                        }) => {

    const [data, setData] = useState([]);
    const [additionallyColumn, setAdditionallyColumn] = useState([]);
    const [customerList, setCustomerList] = useState([]);
    const [nameList, setNameList] = useState([]);
    const [attributeList, setAttributeList] = useState([]);
    const [planningList, setPlanningList] = useState([]);

    useEffect(() => {
        if (issuingEdgesId) {
            OrderService.getAllByIssuingEdgesId(issuingEdgesId).then(function (response) {
                const data = response.data;
                setData(data);
                setCustomerList(OrderUtil.getListField(data, 'customerName'));
                setNameList(OrderUtil.getListField(data, 'header'));
                setPlanningList(OrderUtil.getListField(data, 'state'));
            })
                .catch(function (error) {
                    console.log(error);
                });
        }

    }, [issuingEdgesId]);

    return (
        <div>
            <Dialog open={open} fullWidth={true} maxWidth='lg'>
                <DialogTitle textAlign="center">{titleModal}</DialogTitle>
                <DialogContent>
                    <Box sx={{mt: '0.2rem'}}>
                        <Box sx={{mt: '0.2rem'}}>
                            <OrderTable
                                data={data}
                                setData={setData}
                                customerList={customerList}
                                nameList={nameList}
                                planningList={planningList}
                                enableEditing={false}
                                isIssuingEdge={true}
                                deleteOrdersInIssuingEdge={deleteOrdersInIssuingEdge}
                            />
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions sx={{p: '1.25rem'}}>
                    <Button onClick={onClose}>Выйти</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ModalDeleteOrdersInIssuingEdge;