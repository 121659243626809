import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack} from "@mui/material";
import TextFieldWithSetValue from "../../../textfield/TextFieldWithSetValue";

const ModalOpenCreatePlanOrOptimization = ({
                                               title,
                                               label,
                                               field,
                                               modalOpen,
                                               handleClose,
                                               handleSubmit,
                                               values,
                                               setValues,
                                               isCalculationOptimization
                                           }) => {
    return (
        <div>
            <Dialog open={modalOpen} onClose={handleClose}>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <DialogContent>
                        <form onSubmit={(e) => e.preventDefault()}>
                            <Stack spacing={2}>
                            </Stack>
                            <Stack
                                sx={{
                                    width: '100%',
                                    minWidth: {xs: '300px', sm: '360px', md: '400px'},
                                    gap: '1.5rem',
                                }}
                            >
                                <TextFieldWithSetValue
                                    label={label}
                                    values={values}
                                    setValues={setValues}
                                    field={field}
                                    disabled={isCalculationOptimization}
                                />
                            </Stack>
                        </form>
                    </DialogContent>
                    <DialogActions sx={{p: '1.25rem'}}>

                        <Button color="secondary" variant="contained" onClick={handleClose}>Выйти</Button>
                        <Button color="secondary" onClick={handleSubmit} variant="contained">
                            Создать
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default ModalOpenCreatePlanOrOptimization;