import React from 'react';
import {TextField} from "@mui/material";

const TextFieldWithSetValue = ({label, values, setValues, field, disabled}) => {
    return (
        <div>
            <TextField
                size={"small"}
                sx={{
                    width: '100%',
                    minWidth: {xs: '300px', sm: '360px', md: '400px'},
                    gap: '0.01rem',
                }}
                margin="normal"
                key={field}
                label={label}
                name={field}
                disabled={disabled == null ? false : disabled}
                defaultValue={values === null ? "" : values[field]}
                onChange={(e) =>
                    setValues({...values, [e.target.name]: e.target.value})
                }
            />
        </div>
    );
};

export default TextFieldWithSetValue;