import React from 'react';
import {Button} from "@mui/material";
import {useNavigate} from "react-router-dom";

const ButtonSettingsOrders = () => {

    const navigate = useNavigate();

    const redirectSettingsOrders = () => {
        navigate('/organization/settings-orders');
    };

    return (
        <div>
            <Button
                size="small"
                color="secondary"
                onClick={redirectSettingsOrders}
                variant="contained"
            >
                Вернуться к настройкам
            </Button>
        </div>
    );
};

export default ButtonSettingsOrders;