import React from 'react';
import {Dialog, DialogContent} from "@mui/material";
import CuttingImageGallery from "./CuttingImageGallery";

const ModalImageGallery = ({
                               open,
                               images,
                               weight,
                               height,
                               setOpenModalImageGallery,
                               currentPage,
                               setCurrentPage
                           }) => {
    return (
        <div>
            <Dialog open={open} fullScreen={true} fullWidth={true} maxWidth='lg'>
                <DialogContent>
                    <CuttingImageGallery
                        images={images}
                        isLoading={false}
                        setOpenModalImageGallery={setOpenModalImageGallery}
                        weight={weight}
                        height={height}
                        buttonClose={true}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                    />
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default ModalImageGallery;