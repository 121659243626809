import React from 'react';
import {TextField} from "@mui/material";

const TextFieldArrivalMaterialModuleItem = ({label, value, field, disabled, maxWidth}) => {
    return (
        <div>
            <TextField
                sx={{
                    width: '100%',
                    gap: '1.5rem',
                    maxWidth: maxWidth,
                }}
                size={"small"}
                margin="normal"
                key={field}
                label={label}
                name={label}
                disabled={disabled == null ? false : disabled}
                value={value === null ? " " : (value === undefined ? " " : value)}
            />
        </div>
    );
};

export default TextFieldArrivalMaterialModuleItem;