import React, {useEffect, useState} from 'react';
import ReservationMaterialTable from "../../../../components/UI/table/warehouse/reservation/ReservationMaterialTable";
import MaterialReserveService from "../../../../API/orders/reservation/MaterialReserveService";

const ReservationPieceMaterial = () => {

    const [data, setData] = useState([]);
    const [change, setChange] = useState(false);
    const [orderList, setOrderList] = useState([]);

    useEffect(() => {
        MaterialReserveService.getAllNotArchivePieceMaterial().then(function (response) {
            const data = response.data;
            setData(data);
        })
            .catch(function (error) {
                console.log(error);
            });
    }, []);

    return (
        <div>
            <ReservationMaterialTable
                data={data}
                setData={setData}
                pageSize={100}
                enableGrouping={true}
                columnGrouping={["materialName"]}
                expanded={false}
                isColumnMaterialWarehouse={false}
                isColumnOptimization={false}
                orderList={orderList}
                change={change}
                setChange={setChange}
                titleModalUpdateOrAddMaterialArrival={"Создать приход штучных материалов"}
                typeMaterial={'PIECE'}
            />
        </div>
    );
};

export default ReservationPieceMaterial;