import * as React from 'react';
import {useEffect, useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import {Container} from "@mui/material";
import MainMenu from "./MainMenu";
import {authorization, logout} from "../../../Constants";
import OrganizationCustomerService from "../../../API/orders/orders/organization/OrganizationCustomerService";
import {determineAccessibility} from "./determineAccessibility";

const MenuAppBar = () => {
        const profileStorage = JSON.parse(localStorage.getItem("profile"))
        const auth = authorization + '/oauth2/authorization/up-me-client-authorization-code';
        const [profile, setProfile] = useState(profileStorage)
        const [accessibility, setAccessibility] = useState({})
        const [menuOpen, setMenuOpen] = useState({})

        useEffect(() => {
            OrganizationCustomerService.profile().then(function (response) {
                handleAuth(response)
            }).catch(handleError);
        }, []);

        const handleAuth = (response) => {
            localStorage.setItem("profile", JSON.stringify(response.data))
            setProfile(response.data)
            setAccessibility(() => determineAccessibility(response.data.userRoles))
        }

        const handleError = () => {
            const currentUrl = window.location.href
            if (currentUrl !== authorization + "/") {
                window.location.href = authorization + "/"
            }
            localStorage.setItem("profile", JSON.stringify(null))
            setProfile(null)
            setAccessibility([])
        }

        const handleChange = () => {
            if (profile) {
                OrganizationCustomerService.profileHead().then(function () {
                    window.location.href = logout;
                    localStorage.setItem("profile", JSON.stringify(null))
                }).catch(function () {
                    window.location.href = auth
                });
            } else {
                window.location.href = auth
            }
        }

        return (
            <div>
                <Box sx={{width: "100%", flexGrow: 1}}>
                    <AppBar position="static" sx={{width: "100%", flexGrow: 1}}>
                        <Container maxWidth="xl">
                            <Toolbar disableGutters>
                                <Typography
                                    variant="h6"
                                    noWrap
                                    component="a"
                                    href="/"
                                    sx={{
                                        mr: 2,
                                        display: {xs: 'none', md: 'flex'},
                                        fontWeight: 700,
                                        letterSpacing: '.3rem',
                                        color: 'inherit',
                                        textDecoration: 'none',
                                    }}
                                >
                                    UpMe
                                </Typography>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                }}>
                                    {accessibility?.menu?.map(menu => {
                                        const subMenu = accessibility.subMenu.filter((subMenu) => subMenu.menuType === menu.type)
                                        return <MainMenu
                                            key={menu.type}
                                            name={menu.type}
                                            handleMenu={(event) => setMenuOpen({
                                                type: menu.type,
                                                anchor: event.currentTarget
                                            })}
                                            anchorEl={menuOpen.type === menu.type && menuOpen.anchor}
                                            handleClose={() => setMenuOpen({})}
                                            menuItems={subMenu}
                                            labelName={menu.name}
                                        />
                                    })}
                                </Box>
                                <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}}>
                                </Box>

                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                }}>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={profile !== null}
                                                    onChange={handleChange}
                                                    aria-label="login switch"
                                                />
                                            }
                                            label={profile ? 'Выход' : 'Вход'}
                                        />
                                    </FormGroup>
                                </Box>
                            </Toolbar>
                        </Container>
                    </AppBar>
                </Box>
            </div>
        );
    }
;

export default MenuAppBar;
