import React from 'react';
import OrderConstructorTable from "../../../components/UI/table/orders/organization/constructors/OrderConstructorTable";

const Constructors = () => {
    return (
        <div>
            <OrderConstructorTable/>
        </div>
    );
};

export default Constructors;