export default class DetailUtil {

    static checkDuplicatePositions(details, isOptimization) {

        const positions = new Map();

        for (const detail of details) {

            const position = detail.position;

            if (positions.has(position)) {
                let error = !DetailUtil.equals(detail, positions.get(position), isOptimization);

                if (error) {
                    return true;
                }
            } else {
                positions.set(position, detail);
            }
        }

        return false;
    }

    static equals(detail1, detail2, isOptimization) {

        if (isOptimization) {
            if (detail1.material.id !== detail2.material.id) {
                return true
            }
            if (detail1.modified.toString() === 'true') {
                return true
            }

            if (detail2.modified.toString() === 'true') {
                return true
            }

        } else {
            if (detail1.materialName !== detail2.materialName) {
                return true
            }
        }

        if (detail1.name !== detail2.name) {
            return false;
        }
        if (detail1.length !== detail2.length) {
            return false;
        }
        if (detail1.height !== detail2.height) {
            return false;
        }
        if (detail1.turn !== detail2.turn) {
            return false;
        }
        if (detail1.l1 !== detail2.l1) {
            return false;
        }
        if (detail1.w1 !== detail2.w1) {
            return false;
        }
        if (detail1.w2 !== detail2.w2) {
            return false;
        }
        return detail1.groove === detail2.groove;
    }

    static compareEqual(row1, row2) {

        if (row1.position !== row2.position) {
            return false;
        }

        if (row1.name !== row2.name) {
            return false;
        }
        if (row1.length !== row2.length) {
            return false;
        }
        if (row1.height !== row2.height) {
            return false;
        }
        if (row1.turn !== row2.turn) {
            return false;
        }
        if (row1.l1 !== row2.l1) {
            return false;
        }
        if (row1.w1 !== row2.w1) {
            return false;
        }
        if (row1.w2 !== row2.w2) {
            return false;
        }
        return row1.groove === row2.groove;
    }

    static getDefaultAttribute(attributes) {
        let defaultAttribute;
        for (const attribute of attributes) {
            if (attribute.enabledDefault) {
                defaultAttribute = attribute;
                break;
            }
        }
        return defaultAttribute
    }
}