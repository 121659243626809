export const materialWarehouseStatuses = {
    "PLANNED": "Плановый",
    "CALCULATION" : "Расчетный",
    "WAITING_FOR_AN_APPLICATION" : "Ожидание закупки",
    "EXECUTION_OF_APPLICATION": "Оформление закупки",
    "RECONCILIATION_OF_DATA": "Согласование закупки",
    "WAITING_FOR_DELIVERY": "Ожидание доставки",
    "IN_STOCK": "В наличии",
    "RECEIPT_AFTER_CUTTING": "Ожидание после раскроя",
    "ARCHIVE" : "Архив"
}