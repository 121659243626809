import React from 'react';
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import OrderDatePicker from "../../../../datepicker/OrderDatePicker";

const ModalExecuteJob = ({
                             date,
                             setDate,
                             open,
                             onClose,
                             onExecute
                         }) => {

    return (
        <div>
            <Dialog open={open}>
                <DialogTitle textAlign="center">Выполнить</DialogTitle>
                <DialogContent>
                    <Box sx={{mt: '0.2rem'}}>
                        <OrderDatePicker
                            label="Дата оформления"
                            date={date}
                            setDate={setDate}
                        />
                    </Box>
                </DialogContent>
                <DialogActions sx={{p: '1.25rem'}}>
                    <Button onClick={onExecute}>Выполнить</Button>
                    <Button onClick={onClose}>Выйти</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ModalExecuteJob;