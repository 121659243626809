import React, {useState} from 'react';
import {Checkbox} from "@mui/material";
import UserService from "../../../../../API/common/UserService";

const CellCheckboxUTUnable = ({cell, values, change, setChange}) => {

    const [status, setStatus] = useState(cell.getValue);
    const handle = () => {
        values.enabled = !status;
        UserService.update(values,
        ).then(function () {
            setStatus(!status)
            setChange(!change)
        })
            .catch(function (error) {
                console.log(error);
            });
    };

    return (
        <div>
            <Checkbox onChange={handle}
                      checked={status}
                      sx={{'& .MuiSvgIcon-root': {fontSize: 28}}}
            />
        </div>
    );
};

export default CellCheckboxUTUnable;