import React, {useState} from 'react';
import FileService from "../../API/orders/orders/organization/FileService";
import UploadFileForm from "./UploadFileForm";

const UploadOrderEstimateFileForm = ({label, year, orderId, change, setChange}) => {

    const [selectedFile, setSelectedFile] = useState(null);

    const handleUpload = async (event) => {
        event.preventDefault();
        FileService.uploadOrderEstimateFile(year, orderId, selectedFile)
            .then(function () {
                setChange(!change)
                setSelectedFile(null)
                event.target.reset();
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    return (
        <div>
            <UploadFileForm
                handleUpload={handleUpload}
                selectedFiles={selectedFile}
                setSelectedFile={setSelectedFile}
                label={label}
            />
        </div>
    );
};

export default UploadOrderEstimateFileForm;