import React, {useCallback, useEffect, useMemo, useState} from 'react';
import MaterialReactTable from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import {Button, Tooltip} from "@mui/material";
import {Delete} from "@mui/icons-material";
import Box from "@mui/material/Box";
import {Link, useNavigate} from "react-router-dom";
import DateRangeFilter from "../../../filter/DateRangeFilter";
import CellCheckboxCurrentWithUnable from "../../../сheckbox/CellCheckboxCurrentWithUnable";
import EstimateUtil from "../../../../../utils/EstimateUtil";
import ModelEstimateService from "../../../../../API/model-estimate/estimate/ModelEstimateService";
import {UploadModelEstimateFileForm} from "./UploadModelEstimateFileForm";
import DateTimeUtil from "../../../../../utils/common/DateTimeUtil";

const BasicModelEstimateTable = ({
                                     modelId,
                                 }) => {
//TODO исправить
    const [data, setData] = useState([]);
    /*const [createModalOpen, setCreateModalOpen] = useState(false);*/
    /*  const [currents, setCurrents] = useState({});*/
    const [change, setChange] = useState(false);
    const [unabledChangeCurrent, setUnabledChangeCurrent] = useState(false);


    useEffect(() => {

        ModelEstimateService.getAllByModelId(modelId,).then(function (response) {
            const data = response.data;
            setData(data);
            /*  setUnabledChangeCurrent(EstimateUtil.checkInWork(data));
              data.forEach((row) => {
                  currents[row.id] = row.current;

              });*/
        })
            .catch(function (error) {
                console.log(error);
            });
    }, [change]);

    const handleDeleteRow = useCallback((row) => {
        ModelEstimateService.delete(row.original.id,)
            .then(function () {
                data.splice(row.index, 1);
                setData([...data]);
            })
            .catch(function (error) {
                console.log(error);
            })
    }, [data]);

    const navigate = useNavigate();

    const redirectOrders = () => {
        navigate('/orders/');
    };

    const columns = useMemo(() => [{
        id: 'number',
        header: 'Номер',
        accessorKey: 'number',
        minSize: 20,
        maxSize: 20,
        Cell: ({cell, row}) => (<li key={cell.getValue()}>
            <Link
                to={'/organization/basic-models/' + modelId + '/estimates/' + data[row.index].id}>{cell.getValue()}</Link>
        </li>),
    }, {
        accessorFn: (row) => {
            if (row.date === null) {
            } else {
                return new Date(row.date)
            }
        }, id: 'date', header: 'Дата', sortingFn: 'datetime', size: 10, Cell: ({cell}) => {
            if (cell.getValue() !== undefined) {
                return cell.getValue().toLocaleDateString();
            }
        },
        filterFn: (row, id, filterValue) => {
            const startDate = filterValue?.startDate;
            const endDate = filterValue?.endDate;
            if (startDate && endDate) {
                return (
                    DateTimeUtil.checkDateBefore(row.getValue(id), startDate) &&
                    DateTimeUtil.checkDateAfter(row.getValue(id), endDate)
                );
            }
            return true;
        },
        Filter: ({column}) => <DateRangeFilter
            column={column}
        />,
    }, /*{
        id: 'current',
        header: 'Текущий',
        accessorKey: 'current',
        size: 8,
        maxSize: 8,
        enableEditing: false,
        Cell: ({cell, row}) => <CellCheckboxCurrentWithUnable
            id={row.id}
            unable={currents}
            setUnable={setCurrents}
            orderId={modelId}
            disabled={unabledChangeCurrent}
            unableMethod={ModelEstimateService.unableCurrent}
        />,
    },*/
    ], [data, setData]);

    return (<>
        <MaterialReactTable
            muiTableBodyProps={{
                sx: () => ({
                    '& tr:nth-of-type(odd)': {
                        backgroundColor: 'lightgray',
                    },
                }),
            }}
            muiTableBodyCellProps={{
                sx: {
                    p: '0px 0px 0px 10px', m: '0px 0px 0px 0px',
                },
            }}
            muiTableBodyRowProps={{
                sx: {
                    height: '10px',
                },
            }}
            displayColumnDefOptions={{
                'mrt-row-actions': {
                    muiTableHeadCellProps: {
                        align: 'center',
                    }, size: 15, minSize: 15,
                },
            }}
            columns={columns}
            data={data}
            editingMode="modal"
            enableEditing
            /*onEditingRowSave={handleSaveRowEdits}*/
            renderRowActions={({row, table}) => (<Box sx={{
                display: 'flex', gap: '0.1rem', justifyContent: 'flex-start', maxWidth: '50px'
            }}>
                <Tooltip arrow placement="right" title="Удалить">
                    <Button
                        size="small"
                        sx={{minWidth: 'auto', marginLeft: 'auto'}}
                        color="error"
                        onClick={() => handleDeleteRow(row)}>
                        <Delete sx={{minWidth: 'auto', marginLeft: 'auto', fontSize: 20}}/>
                    </Button>
                </Tooltip>
            </Box>)}
            initialState={{
                columnVisibility: {
                    id: false,
                }, isFullScreen: false, density: "compact", pagination: {pageIndex: 0, pageSize: 20},
            }}
            localization={MRT_Localization_RU}
            getRowId={(originalRow) => originalRow.id}
            renderTopToolbarCustomActions={() => (<Box
                sx={{
                    display: 'flex',
                    gap: '0.5rem',
                    paddingLeft: '0.5rem',
                    paddingTop: '0.5rem',
                    paddingBottom: '0.2rem',
                    flexWrap: 'wrap',
                }}
            >
                {/* <Button
                    size="small"
                    color="secondary"
                    onClick={() => setCreateModalOpen(true)}
                    variant="contained"
                >
                    Создать смету вручную
                </Button>*/}
                <Button
                    size="small"
                    color="secondary"
                    onClick={redirectOrders}
                    variant="contained"
                >
                    Вернуться в заказы
                </Button>
                <UploadModelEstimateFileForm
                    modelId={modelId}
                    label={'Загрузить смету модели'}
                    change={change}
                    setChange={setChange}
                />
            </Box>)}
        />
    </>);
};

export default BasicModelEstimateTable;