import {warehouseFinishedDetailRestUrl} from "../warehousefinisheddetail/WarehouseFinishedDetailService";
import axios from "axios";
import ObjectUtil from "../../../../utils/common/ObjectUtil";
import {apiVersionOrders, gatewayUrl, nameOrders} from "../../../../Constants";

const detailOrderRestUrl = gatewayUrl + nameOrders + apiVersionOrders + '/organizations/details-order'

export default class DetailService {

    static async getAllByOptimizationId(optimizationId) {
        return axios.get(detailOrderRestUrl + '/by-optimization-id?optimizationId=' + optimizationId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async getAllWorkingCuttingMapId(workingCuttingMapId) {
        return axios.get(detailOrderRestUrl + '/by-working-cutting-map-id?workingCuttingMapId=' + workingCuttingMapId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async getAllByRequestsCuttingId(requestsCuttingId) {
        return axios.get(detailOrderRestUrl + '/by-requests-cutting-id?requestsCuttingId=' + requestsCuttingId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async unableCutOrTurn(id, status, typeStatus, warehouse) {
        let url = null;
        if (warehouse === 'WarehouseFinishedDetail') {
            url = warehouseFinishedDetailRestUrl
        } else if (warehouse === 'orders') {
            url = detailOrderRestUrl
        }
        return axios.patch(url + '/' + id + '/unable-' + typeStatus + '?status=' + status, [], {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async updateCutOrTurnByIds(ids, status, typeStatus, warehouse) {
        let url = null;
        if (warehouse === 'WarehouseFinishedDetail') {
            url = warehouseFinishedDetailRestUrl
        } else if (warehouse === 'orders') {
            url = detailOrderRestUrl
        }
        let data = ObjectUtil.createUpdateRequestObjectIds(ids, status);
        await axios.post(url + '/update-cut-and-turn-by-ids'
            + '?status=' + status + '&typeStatus=' + typeStatus,
            JSON.stringify(data), {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
    }

    static async delete(id, warehouse) {
        let url = null;
        if (warehouse === 'WarehouseFinishedDetail') {
            url = warehouseFinishedDetailRestUrl
        } else if (warehouse === 'orders') {
            url = detailOrderRestUrl
        }
        await axios.delete(url + '/' + +id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async updateOptimization(data, optimizationId) {
        return await axios.post(detailOrderRestUrl + '/' + data.id + '/update-optimization' + '?optimizationId=' + optimizationId, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async update(data, warehouse) {
        let url = null;
        if (warehouse === 'WarehouseFinishedDetail') {
            url = warehouseFinishedDetailRestUrl
        } else if (warehouse === 'orders') {
            url = detailOrderRestUrl
        }
        return await axios.post(url + '/' + data.id, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async save(data, requestsCuttingId, warehouse) {
        let url = null;
        if (warehouse === 'WarehouseFinishedDetail') {
            url = warehouseFinishedDetailRestUrl
        } else if (warehouse === 'orders') {
            url = detailOrderRestUrl
        }
        return await axios.post(url + '?requestsCuttingId=' + requestsCuttingId, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async createTXTFileSelco(ids, optimizationNumber) {
        let data = ObjectUtil.createRequestObjectIds(ids);
        return await axios.post(detailOrderRestUrl + '/download/file-txt-selco' + '?optimizationNumber=' + optimizationNumber, JSON.stringify(data), {
            responseType: "arraybuffer",
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async saveOptimizations(data, optimizationId, materialId) {
        return await axios.post(detailOrderRestUrl + '/save-for-optimization' + '?optimizationId=' + optimizationId + '&materialId=' + materialId, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async modifyOptimizations(optimizationId) {
        let data = ObjectUtil.createRequestObjectIds([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]);
        return await axios.post(detailOrderRestUrl + '/modify-optimizations' + '?optimizationId=' + optimizationId, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async deleteFromOptimization(id, optimizationId) {
        return await axios.patch(detailOrderRestUrl + '/' + id + '/delete-from-optimization' + '?optimizationId=' + optimizationId, [], {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async getAllByCuttingMapDataObjectId(ordersCuttingMapDataObjectId) {
        return await axios.get(detailOrderRestUrl + '/by-orders-cutting-map-data-object-id' + '?ordersCuttingMapDataObjectId=' + ordersCuttingMapDataObjectId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async getAllNotUsedDetailsWorkingCuttingMapId(workingCuttingMapId) {
        return axios.get(detailOrderRestUrl + '/not-used-details-by-working-cutting-map-id?workingCuttingMapId=' + workingCuttingMapId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
}