import axios from "axios";
import {apiVersionOrders, gatewayUrl, nameOrders} from "../../../../../Constants";

const ratingJobWorkerRestUrl = gatewayUrl +  nameOrders + apiVersionOrders + '/organizations/ratings-job-worker'

export default class RatingJobWorkerService {

    static async getAllByWorkerId(id) {
        return axios.get(ratingJobWorkerRestUrl + '/workers/' + id);
    }
}