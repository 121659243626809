import axios from "axios";
import {apiVersionOrders, gatewayUrl, nameOrders} from "../../../../../Constants";

const jobRestUrl = gatewayUrl + nameOrders + apiVersionOrders + '/organizations/jobs'

export default class JobService {

    static async getJobsByOrderId(id) {
        return axios.get(jobRestUrl + '/' + id);
    }

    static async getJobsByEstimateId(estimateId) {
        return axios.get(jobRestUrl + '/estimate/' + estimateId);
    }

    static async unableWorkerByJobsId(id, workerId, status) {
        return axios.patch(jobRestUrl + '/' + id + '?workerId=' + workerId + '&status=' + status, [], {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static async executeJob(id, date) {
        return axios.post(jobRestUrl + '/' + id + '/execute', JSON.stringify(date), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
}