import React, {useEffect, useMemo, useState} from 'react';
import MaterialReactTable from 'material-react-table';
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import CellWJTFCs from "./CellWJTFCs";
import WorkerService from "../../../../../../API/orders/orders/organization/job/WorkerService";
import Box from "@mui/material/Box";
import JobService from "../../../../../../API/orders/orders/organization/job/JobService";
import CellCheckboxWithUnable from "../../../../сheckbox/CellCheckboxWithUnable";


const WorkerJobTable = ({jobId, jobData, setJobData, currentRow, onClose}) => {

    const [data, setData] = useState([]);

    const [unable, setUnable] = useState({});

    useEffect(() => {
        WorkerService.getAllByJobId(jobId).then(function (response) {
            let data = response.data;
            setData(data);
            data.forEach((row) => {
                unable[row.id] = row.enabled;
            });
        })
            .catch(function (error) {
                console.log(error);
            });
    }, []);

    const handleUnableCheckbox = (id, status) => {
        JobService.unableWorkerByJobsId(jobId, id, status)
            .then(function (response) {
                let data = response.data;
                handleUpdate(data);
                handleUnable(id, status)
            })
            .catch(function (error) {
                console.log(error);
            })
        onClose();
    };

    const handleUpdate = (values) => {
        jobData[currentRow.index] = values;
        setJobData([...jobData]);
    };

    const handleUnable = (id, status) => {
        unable[id] = status
        setUnable(prevState => ({
            ...prevState,
            [id]: status
        }));
    };

    const columns = useMemo(
        () => [
            {
                header: 'ФИО',
                accessorKey: 'workerTechnicalMap',
                minSize: 25,
                enableEditing: false,
                Cell: ({cell}) => <CellWJTFCs cell={cell}></CellWJTFCs>,
            },
            {
                header: 'Статус',
                accessorKey: 'enabled',
                size: 8,
                Cell: ({cell, row}) => <CellCheckboxWithUnable
                    id={row.id}
                    unable={unable}
                    setUnable={handleUnableCheckbox}
                >
                </CellCheckboxWithUnable>,
            },
            {
                header: 'id',
                accessorKey: 'id',
                enableHiding: false,
                enableColumnActions: false,
                enableGrouping: false,
                enableEditing: false,
            },
        ],
        [data, setData],
    );

    return <MaterialReactTable
        muiTableBodyProps={{
            sx: () => ({
                '& tr:nth-of-type(odd)': {
                    backgroundColor: 'lightgray',
                },
            }),
        }}
        muiTableBodyCellProps={{
            sx: {
                p: '0px 0px 0px 10px',
                m: '0px 0px 0px 0px',
            },
        }}
        muiTableBodyRowProps={{
            sx: {
                height: '10px',
            },
        }}
        displayColumnDefOptions={{
            'mrt-row-actions': {
                muiTableHeadCellProps: {
                    align: 'center',
                },
                size: 15,
                minSize: 15,
            },
        }}
        columns={columns}
        data={data}
        rowHeight={13}
        columnSpacing={1}
        initialState={{
            columnVisibility: {
                id: false,
            },
            isFullScreen: false,
            density: "compact",
            pagination: {pageIndex: 0, pageSize: 50},
        }}
        enableEditing={false}
        enableRowSelection={false}
        getRowId={(originalRow) => originalRow.id}
        renderRowActions={({row, table}) => (
            <Box sx={{
                display: 'flex',
                gap: '0.1rem',
                justifyContent: 'flex-start',
                maxWidth: '50px'
            }}>
            </Box>
        )}
        localization={MRT_Localization_RU}
        renderTopToolbarCustomActions={({table}) => {
            const setCurrency = (typeCurrentCurrency) => {
            };

            return (
                <div>
                </div>
            );
        }}
    />;
};

export default WorkerJobTable;