import React, {useEffect, useState} from 'react';
import EstimateTableItem from "../../../../components/UI/table/orders/organization/estimate/EstimateTableItem";
import {useNavigate} from "react-router-dom";
import {TabContext, TabPanel} from "@mui/lab";
import {Box, Button, Tabs} from "@mui/material";
import Tab from "@mui/material/Tab";
import JobService from "../../../../API/orders/orders/organization/job/JobService";
import ProductionCosts from "../../../../components/UI/table/orders/organization/estimate/ProductionCosts";
import {
    UpdateOrAddBasicModelsModule
} from "../../../../components/UI/table/model-estimate/model/UpdateOrAddBasicModelsModule";
import {UpdateOrAddModelsModule} from "../../../../components/UI/table/model-estimate/model/UpdateOrAddModelsModule";
import UploadOrderEstimateFileForm from "../../../../components/form/UploadOrderEstimateFileForm";
import Typography from "@mui/material/Typography";
import CalculationTable from "../../../../components/UI/table/orders/organization/estimate/CalculationTable";
import JobMetricOrderOrganizationTable
    from "../../../../components/UI/table/orders/organization/estimate/JobMetricOrderOrganizationTable";
import MaterialEstimateUnitService from "../../../../API/orders/estimate/organization/MaterialEstimateUnitService";
import WarehouseMaterialSettingsService from "../../../../API/orders/warehouse/WarehouseMaterialSettingsService";
import MaterialCostTable from "../../../../components/UI/table/orders/organization/estimate/MaterialCostTable";
import JobMetricOrderOrganizationService
    from "../../../../API/orders/metric/organization/JobMetricOrderOrganizationService";

const EstimateItem = ({
                          estimateId,
                          orderId,
                          orderNumber,
                          orderHeaderId,
                          year,
                          isButtonPurchase,
                          enableEditing,
                          isActual,
                          inWork,
                          orderCloud,
                          isImport,
                          detailed
                      }) => {

    const [value, setValue] = useState('2');
    const [valueCalculationTable, setValueCalculationTable] = useState('2');
    const [technicalMapData, setTechnicalMapData] = useState([]);
    const [currentEstimateState, setCurrentEstimateState] = useState()
    const [dataModel, setDataModel] = useState([]);
    const [baseCurrency, setBaseCurrency] = useState("");
    const [alternativeCurrency, setAlternativeCurrency] = useState("");

    useEffect(() => {

        WarehouseMaterialSettingsService.getWithBaseAndAlternativeCurrency().then(function (response) {
            let data = response.data;
            setBaseCurrency(data.baseCurrency.curAbbreviation);
            setAlternativeCurrency(data.alternativeCurrency.curAbbreviation);
        })
            .catch(function (error) {
                console.log(error);
            });
    }, []);


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeCalculationTable = (event, newValue) => {
        setValueCalculationTable(newValue);
    };


    useEffect(() => {
        JobService.getJobsByOrderId(orderId).then(function (response) {
            setTechnicalMapData(response.data);
        })
            .catch(function (error) {
                console.log(error);
            });
    }, []);

    const isCalculation = currentEstimateState === 'CALCULATION'
    const isButtonInWork = currentEstimateState === 'CALCULATION';

    const navigate = useNavigate();

    const redirectOrder = () => {
        navigate('/organization/orders/' + orderId + '/' + orderNumber + '/' + orderHeaderId + '/' + year);
    };

    const redirectOrders = () => {
        navigate('/organization/orders/');
    };

    const redirectRequestsCutting = () => {
        navigate('/organization/requests-cutting');
    };

    const [modalBasicModelsOpen, setBasicModalModelsOpen] = useState(false);
    const [modalModelsOpen, setModalModelsOpen] = useState(false);
    const [basicModelId, setBasicModelId] = useState(null);
    const [change, setChange] = useState(false);

    const openBasicModels = () => {
        setBasicModalModelsOpen(true);
    };

    const closeModels = () => {
        setModalModelsOpen(false);
        setBasicModelId(null);
    };

    const closeBasicModels = () => {
        setBasicModalModelsOpen(false);
    };

    const [materialEstimateUnits, setMaterialEstimateUnits] = useState([]);
    useEffect(() => {
        MaterialEstimateUnitService.getAllByOrderId(orderId).then(function (response) {
            const data = response.data;
            setMaterialEstimateUnits(data);
        })
            .catch(function (error) {
                console.log(error);
            });
    }, []);

    const [JobMetricOrderOrganization, setJobMetricOrderOrganization] = useState([]);

    useEffect(() => {
        JobMetricOrderOrganizationService.getAllByOrderIdForOrganization(orderId).then(function (response) {
            let data = response.data;
            setJobMetricOrderOrganization(data);
        })
            .catch(function (error) {
                console.log(error);
            });
    }, []);

    return (

        <Box sx={{
            '& > *': {
                marginTop: '0px',
            }
        }}>
            <Box
                sx={{display: 'flex', gap: '0.5rem', p: '0.5rem', flexWrap: 'wrap'}}
            >
                {<Button
                    size="small"
                    color="success"
                    onClick={() => redirectOrder()}
                    variant="contained"
                >
                    Вернуться в заказ
                </Button>}
            </Box>
            <TabContext value={value}>
                <Box sx={{
                    borderBottom: 1, borderColor: 'divider'
                }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="ИТОГИ" value='1'/>
                        <Tab label="Себестоимость материалов" value='2'/>
                        <Tab label="Себестоимость работ" value='3'/>
                        <Tab label="Стоимость материалов" value='4'/>
                        <Tab label="Стоимость работ" value='5'/>
                        {isImport && <Tab label="Импорт" value='6'/>}
                    </Tabs>
                </Box>
                <TabPanel value='1'>
                    <TabContext value={valueCalculationTable}>
                        <Box sx={{
                            borderBottom: 1, borderColor: 'divider'
                        }}>
                            <Tabs value={valueCalculationTable} onChange={handleChangeCalculationTable}
                                  aria-label="basic tabs example">
                                <Tab label="Расчетная" value='1'/>
                                <Tab label="Фактическая" value='2'/>
                            </Tabs>
                        </Box>
                        <TabPanel value='1'>
                            <CalculationTable
                                data={materialEstimateUnits}
                                setData={setMaterialEstimateUnits}
                                baseCurrency={baseCurrency}
                                alternativeCurrency={alternativeCurrency}
                                orderId={orderId}
                                orderNumber={orderNumber}
                                orderHeaderId={orderHeaderId}
                                year={year}
                                isActual={true}
                                inWork={currentEstimateState === 'IN_WORK'}
                                isFact={false}
                                redirectOrder={redirectOrder}
                            />
                        </TabPanel>
                        <TabPanel value='2'>
                            <CalculationTable
                                data={materialEstimateUnits}
                                setData={setMaterialEstimateUnits}
                                baseCurrency={baseCurrency}
                                alternativeCurrency={alternativeCurrency}
                                orderId={orderId}
                                orderNumber={orderNumber}
                                orderHeaderId={orderHeaderId}
                                year={year}
                                isActual={true}
                                inWork={currentEstimateState === 'IN_WORK'}
                                isFact={true}
                            />
                        </TabPanel>
                    </TabContext>
                </TabPanel>
                <TabPanel value='2'>
                    <TabContext value={valueCalculationTable}>
                        <Box sx={{
                            borderBottom: 1, borderColor: 'divider'
                        }}>
                            <Tabs value={valueCalculationTable} onChange={handleChangeCalculationTable}
                                  aria-label="basic tabs example">
                                <Tab label="Расчетная" value='1'/>
                                <Tab label="Фактическая" value='2'/>
                            </Tabs>
                        </Box>
                        <TabPanel value='1'>
                            <EstimateTableItem
                                baseCurrency={baseCurrency}
                                alternativeCurrency={alternativeCurrency}
                                orderId={orderId}
                                orderNumber={orderNumber}
                                orderHeaderId={orderHeaderId}
                                year={year}
                                isButtonPurchase={true}
                                enableEditing={true}
                                isActual={true}
                                inWork={currentEstimateState === 'IN_WORK'}
                                orderCloud={false}
                                isFact={false}
                            />
                        </TabPanel>
                        <TabPanel value='2'>
                            <EstimateTableItem
                                baseCurrency={baseCurrency}
                                alternativeCurrency={alternativeCurrency}
                                orderId={orderId}
                                orderNumber={orderNumber}
                                orderHeaderId={orderHeaderId}
                                year={year}
                                isButtonPurchase={false}
                                enableEditing={false}
                                isActual={true}
                                inWork={currentEstimateState === 'IN_WORK'}
                                orderCloud={false}
                                isFact={true}
                            />
                        </TabPanel>
                    </TabContext>

                </TabPanel>
                <TabPanel value='3'>
                    <TabContext value={valueCalculationTable}>
                        <Box sx={{
                            borderBottom: 1, borderColor: 'divider'
                        }}>
                            <Tabs value={valueCalculationTable} onChange={handleChangeCalculationTable}
                                  aria-label="basic tabs example">
                                <Tab label="Расчетная" value='1'/>
                                <Tab label="Фактическая" value='2'/>
                            </Tabs>
                        </Box>
                        <TabPanel value='1'>
                            <ProductionCosts
                                orderId={orderId}
                                calculation={true}
                            />
                        </TabPanel>
                        <TabPanel value='2'>
                            <ProductionCosts
                                orderId={orderId}
                                calculation={false}
                            />
                        </TabPanel>
                    </TabContext>

                </TabPanel>
                <TabPanel value='4'>
                    <MaterialCostTable
                        materialEstimateUnits={materialEstimateUnits}
                        setMaterialEstimateUnits={setMaterialEstimateUnits}
                        baseCurrency={baseCurrency}
                        alternativeCurrency={alternativeCurrency}
                        orderId={orderId}
                        orderNumber={orderNumber}
                        orderHeaderId={orderHeaderId}
                        year={year}
                        isButtonPurchase={true}
                        enableEditing={true}
                        isActual={true}
                    />
                </TabPanel>
                <TabPanel value='5'>
                    <JobMetricOrderOrganizationTable
                        data={JobMetricOrderOrganization}
                        setData={setJobMetricOrderOrganization}
                        orderId={orderId}
                        baseCurrency={baseCurrency}
                        alternativeCurrency={alternativeCurrency}
                    />
                </TabPanel>
                <TabPanel value='6'>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        '& > *': {
                            marginRight: '10px',
                        }
                    }}>
                        <Typography variant="h6" component="h6">
                            Импорт сметы из файла сметы базис-мебельщика *.xls
                        </Typography>
                        <UploadOrderEstimateFileForm
                            year={year}
                            orderId={orderId}
                            label={'Загрузить смету проекта'}
                            change={change}
                            setChange={setChange}
                        />
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        '& > *': {
                            marginRight: '10px',
                        }
                    }}>
                        <Typography variant="h6" component="h6">
                            Создание сметы на основе моделей (с заявками на раскрой, необходимо наличие модуля
                            облако-моделей)
                        </Typography>
                        <Button
                            size="small"
                            color="success"
                            onClick={openBasicModels}
                            variant="contained"
                        >
                            Добавить модель
                        </Button>
                    </Box>
                    <UpdateOrAddBasicModelsModule
                        open={modalBasicModelsOpen}
                        setBasicModelId={setBasicModelId}
                        setModalModelsOpen={setModalModelsOpen}
                        onClose={closeBasicModels}
                        orderIdHeader={orderHeaderId}
                    />
                    <UpdateOrAddModelsModule
                        open={modalModelsOpen}
                        setModelsOpen={setModalModelsOpen}
                        setBasicModalModelsOpen={setBasicModalModelsOpen}
                        onClose={closeModels}
                        basicModelId={basicModelId}
                        orderId={orderId}
                        change={change}
                        setChange={setChange}
                    />
                </TabPanel>
            </TabContext>
        </Box>
    );
};

export default EstimateItem;