const TOKENS = ["-", "+", "*", "/"];
const PRIORITY_TOKENS = ["*", "/"];

const getVars = (str) => {
    let acc = str;
    const divider = "@@";

    TOKENS.forEach((token, i) => {
        acc = acc.split(TOKENS[i]).join(divider);
    })

    return acc.split(divider);
}

const getTokens = (str) => {
    return str.split("").reduce((acc, it) => TOKENS.includes(it) ? [...acc, it] : acc, [])
}

const doCalc = (v1, v2, token) => {
    switch (token) {
        case "-": {
            return v1 - v2;
        }
        case "+": {
            return v1 + v2;
        }
        case "*": {
            return v1 * v2;
        }
        case "/": {
            return v1 / v2;
        }
        default: {
            throw new Error()
        }
    }
}

export const calcValueOfStringWithVar = (variables, expression) => {
    const trimmed = expression.replace(/\s/g, '');

    const vars = getVars(trimmed)
    const tokens = getTokens(trimmed)
    const priorityTokens = tokens.filter((it) => PRIORITY_TOKENS.includes(it));

    const values = vars.map((it) => {
        const variable = variables.find(({name}) => it === name);

        if (variable) {
            return variable.value;
        }
        const asNumber = Number(it)
        if (Number.isNaN(asNumber) || !Number.isInteger(asNumber)) {
            throw new Error()
        }

        return asNumber
    })

    let result = [...values];
    let tempTokens = [...tokens]

    priorityTokens.forEach((token) => {
        const tokenIndex = tempTokens.findIndex((it) => it === token);
        const prevValue = result[tokenIndex];
        const nextValue = result[tokenIndex + 1];
        const currentResult = doCalc(prevValue, nextValue, token);

        if (!Number.isFinite(currentResult)) throw new Error()

        result.splice(tokenIndex, 2, currentResult);
        tempTokens.splice(tokenIndex, 1);
    })

    tempTokens.forEach((token) => {
        const tokenIndex = tempTokens.findIndex((it) => it === token);
        const prevValue = result[tokenIndex];
        const nextValue = result[tokenIndex + 1];
        const currentResult = doCalc(prevValue, nextValue, token);

        if (!Number.isFinite(currentResult)) throw new Error()

        result.splice(tokenIndex, 2, currentResult);
    })

    return result[0]
}
