import React, {useState} from 'react';
import Box from "@mui/material/Box";
import StarIcon from '@mui/icons-material/Star';
import {Rating} from "@mui/lab";
import Typography from "@mui/material/Typography";

const RatingWorker = ({val, name, workerId}) => {

    const [value, setValue] = useState(val);
    const [hover, setHover] = useState(-1);

    const labels = {
        0.5: 'Useless',
        1: 'Useless+',
        1.5: 'Poor',
        2: 'Poor+',
        2.5: 'Ok',
        3: 'Ok+',
        3.5: 'Good',
        4: 'Good+',
        4.5: 'Excellent',
        5: 'Excellent+',
    };

    function getLabelText(value) {
        return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
    }

    return (
        <div>
            <Box sx={{
                display: 'flex',
                alignItems: 'flex-start',
                '& > *': {
                    marginTop: '20px',
                    marginLeft: '20px',
                }
            }}>
                <Box sx={{width: '350px'}}>
                    <Typography component="legend">{name}</Typography>
                </Box>
                <Rating
                    name={workerId}
                    value={value}
                    precision={0.5}
                    getLabelText={getLabelText}
                    /*onChange={(event, newValue) => {
                        setValue(newValue);
                    }}*/
                    onChangeActive={(event, newHover) => {
                        setHover(newHover);
                    }}
                    emptyIcon={<StarIcon style={{opacity: 0.55}} fontSize="inherit"/>}
                />
                {/*{value !== null && (
                <Box sx={{ ml: 2 }}>{labels[hover !== -1 ? hover : value]}</Box>
            )}*/}
            </Box>
        </div>
    );
};

export default RatingWorker;